import { z } from 'zod';
import {special as specialModel} from 'Shared/model/Specials';
import {Breadcrumbs, CreateComponent, DocumentQueries} from 'Common/config/PageConfigTypes';
import {PageFunctions} from 'Browser/PageFunctions';
import * as t from 'Shared/model/basic';
import {AccessAreaName} from 'Common/permissions/AccessArea';
import {BackendPageConfig, backendData, backendParams} from 'Common/BackendPageConfig';
import {VenueUrls} from 'Common/VenueUrls';

export const specialsParams = backendParams.extend({ }).strict();	
export type SpecialsParams = z.infer<typeof specialsParams>;


export const specialsData = backendData.extend({
	specials: z.array(specialModel),

	/* Components: */
	createSpecial: z.object({
		id: t.id
	})
}).strict();
export type SpecialsData = z.infer<typeof specialsData>;


export class Specials extends BackendPageConfig<SpecialsData,SpecialsParams>
{
	static readonly pageName = 'backend/specials';
	name() { return Specials.pageName; }

	access() { return 'venueMenuEditor' as AccessAreaName; }

	defaultSettings()
	{
		return {
			...super.defaultSettings(),
			htmlClasses: `${this.htmlClasses} specials`,
			template: 'App/backend/specials/specialsPage.njk'
		};
	}

	documents(params:SpecialsParams)
	{
		return <DocumentQueries> ({
			...super.documents(params),
			specials: {
				type: 'array',
				collection: 'special'
			}
		});
	}

	components()
	{
		return ({
			breadcrumbs: <Breadcrumbs<SpecialsData>>{
				type: 'breadcrumbs',
				parent: {page:'backend/venue',component:'breadcrumbs'},
				link: () => '/admin/specials' 
			},
			createSpecial: <CreateComponent<SpecialsData>>{
				type: 'create',
				collection: 'special',
				initialValues: () => ({times:{frequency:'once'},imageDimensions:'portrait'}),
				redirect: (data:SpecialsData) => `/admin/special/${data.createSpecial.id}`
			}
		});
	}

	beforeDisplay(data:SpecialsData) 
	{
		super.beforeDisplay(data);

		const funcs = new PageFunctions();
		const urls = new VenueUrls(this.build,this.venue.key);

		for (const special of data.specials) {
			if (special.image==undefined)
				special.image = <any>{};
			special.image!.url = funcs.createImageUrl(urls,special.image,'Special-image','tiny');
		}
	}
}

