import { css } from '@emotion/css';
import { usePage } from 'Shared/artists/PageProvider';
import {Nav} from 'Shared/backend/nav/Nav';
import { borderBackgroundColour, maxBodyWidth } from 'Shared/backend/theme';


const contentStyle = () => css({
	backgroundColor: borderBackgroundColour
});

const contentBlockStyle = () => css({
	border: 'solid #eee 1px',
	padding: '2em 2em',
	backgroundColor: 'white', 
	margin: '0 auto',
	maxWidth: maxBodyWidth,
	minHeight: '90vh'
});


export function BackendWrap(props)
{
	return (
		<div class={contentStyle()}>
			{/*XXX {props.backend.navTemplate} */}
			<Nav settings={usePage().data.venue.settings}/>

			<div class={contentBlockStyle()}>
				{props.children}
			</div>
		</div>
	);
}

