import Transport from 'Common/logs/Transport';

export default class BrowserTransport extends Transport
{
	public async open() { }

	public close() { }

	public async output(args:string[])
	{
		console.log(...args);
	}
}

