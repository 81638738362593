import {PageFunctions} from 'Browser/PageFunctions';
import {VenueUrls} from 'Common/VenueUrls';
import {Location} from 'Common/config/PageConfigTypes';
import {Image,ImageSize} from 'Shared/model/basic';

export function createImageUrl(urls:VenueUrls,field:Image|undefined,assetFolder:string,size:ImageSize)
{
	if (field != undefined)
		field.url = (new PageFunctions()).createImageUrl(urls,field,assetFolder,size);
}

export function createImageUrls(urls:VenueUrls,arrayField:any[],imageField:string,assetFolder:string,size:ImageSize)   //XXX any
{
	for (const item of arrayField ?? [])
		if (item[imageField] != undefined)
			item[imageField].url = (new PageFunctions()).createImageUrl(urls,item[imageField],assetFolder,size);
}


//XXX note that the length of location can be less than the depth of doc
export function locateSubDocument<Doc>(doc:Doc,location:Location) 
{
	let data = doc;

	for (let i=0; i<location.length; i++) 
		data = (<any>data)[location[i]];		//XXX any

	return data;
}

//XXX it *may* be better just to place the fields in 'location'. Not sure at this stage.
//XXX note that the length of location can be less than the depth of doc
export function locateUsingFields<Doc>(doc:Doc,fields:[keyof Doc,...string[]],location:Location) 	//XXX the typing for fields is incomplete
{
	let data = doc;

	for (let i=0; i<location.length; i++) 
		data = (<any>data)[fields[i]][location[i]];		//XXX any

	return data;
}

export function splitLocationAndField(location:Location)
{
	return [
		location.slice(0,-1),
		location[location.length - 1]
	];
}
