import {AccessArea} from 'Common/permissions/AccessArea';
import {Role} from 'Common/permissions/Role';

export class AdminRole extends Role
{
	constructor()
	{
		super('admin');
	}

	public toJson()
	{
		return {
			type: this.name
		};
	}

	public topAccess(): AccessArea
	{
		return new AccessArea('admin');
	}

	public canAccess(area:AccessArea)
	{
		return true;
	}
}

