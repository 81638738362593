export function ListenOnSpotify()
{
	return (
<svg id="spotify" class="icon" version="1.0" xmlns="http://www.w3.org/2000/svg"
 viewBox="0 0 900 330"
 preserveAspectRatio="xMidYMid meet">
<g transform="translate(0,327) scale(0.1,-0.1)">
<path d="M0 1635 l0 -1635 4500 0 4500 0 0 1635 0 1635 -4500 0 -4500 0 0
-1635z m4109 1319 c28 -36 26 -58 -5 -83 -33 -26 -58 -27 -84 -1 -39 39 -11
110 44 110 15 0 33 -11 45 -26z m-581 4 c9 -9 12 -97 12 -335 l0 -323 23 -5
c12 -3 83 -6 157 -8 l135 -2 0 -35 0 -35 -205 0 -205 0 -3 365 c-1 201 0 371
3 377 6 16 67 17 83 1z m1414 -145 l3 -58 65 -5 c64 -5 65 -5 65 -35 0 -29 -1
-30 -55 -33 -30 -2 -60 -8 -67 -14 -10 -8 -13 -53 -13 -175 0 -161 0 -164 25
-188 16 -17 35 -25 59 -25 30 0 37 -4 42 -26 10 -38 -16 -59 -74 -59 -56 1
-98 20 -126 58 -19 26 -21 46 -26 220 l-5 192 -35 11 c-29 10 -36 17 -38 43
-3 27 0 31 22 31 45 0 56 10 56 50 0 48 21 70 65 70 l34 0 3 -57z m-3057 2
c138 -24 241 -56 335 -105 118 -60 176 -97 250 -158 443 -366 577 -968 333
-1491 -130 -278 -404 -523 -704 -629 -457 -162 -959 -44 -1310 307 -97 97
-165 203 -271 423 -10 23 -25 80 -53 213 -32 149 -32 291 0 440 32 152 45 198
56 209 5 6 9 16 9 23 0 14 61 143 88 185 180 283 426 469 732 554 157 44 384
56 535 29z m3617 -56 c64 -14 101 -40 133 -95 26 -44 42 -135 31 -178 l-6 -26
-164 0 c-91 0 -171 -3 -180 -6 -21 -8 -20 -37 3 -82 45 -88 95 -102 281 -80
22 3 25 -1 28 -34 4 -42 2 -43 -99 -57 -108 -15 -209 13 -262 74 -46 53 -57
90 -57 200 0 123 14 160 85 230 34 33 55 44 97 52 29 6 54 11 55 12 1 0 26 -4
55 -10z m-922 -8 c20 -5 26 -14 28 -44 4 -42 -2 -44 -71 -25 -51 13 -84 7
-115 -22 -45 -42 -14 -96 78 -135 98 -42 140 -92 140 -170 0 -26 -7 -59 -16
-74 -39 -67 -139 -100 -242 -80 -73 13 -97 36 -80 75 13 28 23 29 74 8 117
-49 218 47 133 127 -17 16 -43 32 -58 36 -37 9 -95 47 -120 79 -16 20 -21 41
-21 84 0 53 3 61 39 98 22 22 50 42 63 45 36 7 140 6 168 -2z m1614 -5 c85
-35 115 -131 116 -367 0 -165 -3 -172 -67 -167 l-38 3 -5 195 c-4 164 -8 199
-23 221 -46 68 -146 64 -202 -10 -18 -23 -21 -46 -25 -216 l-5 -190 -47 -3
-48 -3 -2 235 c-1 130 -2 251 -2 269 -1 31 1 32 42 35 39 3 42 1 42 -22 0 -37
18 -41 49 -12 16 15 41 31 57 36 45 13 121 11 158 -4z m957 4 c47 -13 111 -70
140 -122 30 -58 33 -224 5 -278 -49 -92 -98 -132 -186 -151 -116 -25 -227 28
-279 133 -80 160 -4 384 143 418 46 12 136 11 177 0z m699 -9 c19 -10 49 -38
65 -62 l30 -44 0 -210 0 -210 -45 0 -45 0 -5 195 c-4 164 -8 199 -23 221 -21
31 -57 49 -98 49 -32 0 -103 -43 -116 -70 -4 -8 -9 -100 -12 -205 l-6 -190
-47 -3 c-42 -3 -48 -1 -53 20 -3 13 -4 133 -3 268 l3 245 38 3 c38 3 38 3 53
-46 3 -9 14 -6 38 13 19 15 47 30 63 35 50 15 126 11 163 -9z m-3740 -236 c3
-132 2 -251 -1 -265 -5 -22 -11 -25 -50 -25 l-44 0 -3 255 c-1 140 0 261 3
268 3 9 19 12 47 10 l43 -3 5 -240z m2830 -537 c45 -31 74 -88 65 -128 -4 -17
-24 -49 -44 -71 -35 -36 -42 -39 -91 -39 -48 0 -56 4 -91 39 -34 34 -39 44
-39 85 0 109 113 173 200 114z m620 -41 c24 -12 25 -17 25 -97 l0 -85 -75 3
c-94 5 -122 -12 -124 -73 -1 -38 0 -39 39 -47 22 -4 92 -7 154 -6 107 0 116
-1 133 -22 10 -12 18 -28 18 -36 0 -7 6 -28 14 -46 8 -18 22 -53 31 -78 10
-25 23 -58 30 -75 13 -29 32 -80 62 -160 30 -82 54 -128 62 -119 5 5 19 38 31
74 13 36 38 108 57 160 18 52 44 127 58 167 45 135 41 131 145 135 50 2 95 2
100 0 14 -5 12 -49 -4 -85 -14 -33 -38 -94 -63 -162 -9 -22 -22 -58 -30 -80
-9 -22 -22 -58 -30 -80 -8 -22 -21 -53 -28 -70 -14 -33 -39 -98 -62 -160 -8
-22 -21 -53 -28 -70 -7 -16 -20 -50 -30 -75 -51 -134 -75 -178 -127 -230 -37
-38 -59 -51 -112 -65 -63 -17 -69 -17 -138 0 -88 21 -128 40 -128 62 0 25 51
131 65 137 8 3 29 1 47 -5 61 -17 107 -11 133 17 14 15 25 32 25 38 0 17 -41
125 -96 254 -8 17 -21 48 -29 67 -8 19 -21 50 -29 68 -7 17 -24 57 -36 87 -12
30 -33 78 -47 107 -23 51 -25 52 -77 58 -30 4 -68 4 -85 0 l-31 -7 0 -307 c0
-225 -3 -310 -12 -319 -16 -16 -180 -16 -196 0 -9 9 -12 94 -12 320 l0 308
-32 0 c-55 0 -59 8 -56 106 l3 89 40 5 c30 4 41 10 43 25 14 99 38 168 69 205
52 60 100 78 213 79 58 0 105 -4 120 -12z m-3623 -41 c82 -15 238 -88 254
-118 11 -20 -5 -53 -59 -125 -29 -39 -50 -41 -100 -10 -82 52 -131 67 -218 67
-125 0 -167 -31 -167 -123 0 -59 74 -101 263 -147 31 -7 60 -18 164 -59 16 -6
54 -38 85 -70 53 -53 58 -63 71 -129 15 -79 4 -152 -32 -223 -34 -66 -115
-128 -206 -156 -72 -22 -234 -22 -312 -1 -124 35 -290 130 -290 166 0 10 14
33 31 52 17 19 37 45 46 57 24 36 49 38 91 6 83 -64 148 -91 242 -98 91 -7
143 2 180 33 36 31 49 91 28 132 -14 27 -101 79 -132 80 -10 0 -39 6 -65 14
-25 8 -73 23 -106 32 -214 64 -298 173 -274 354 28 210 233 318 506 266z
m2507 -42 c13 -5 16 -23 16 -94 0 -49 3 -95 6 -104 5 -13 23 -16 98 -16 58 0
96 -4 104 -12 17 -17 16 -162 -1 -176 -7 -6 -56 -13 -108 -14 l-94 -3 0 -206
c1 -164 4 -210 15 -224 12 -14 28 -16 105 -14 l90 4 3 -69 c4 -89 -8 -107 -87
-131 -73 -22 -170 -16 -230 14 -47 25 -96 81 -106 125 -5 17 -11 137 -14 266
l-6 235 -45 5 -45 5 -3 83 c-3 95 5 112 53 112 41 0 45 10 45 115 0 69 3 86
18 94 18 11 160 15 186 5z m-1491 -244 c104 -51 174 -138 208 -255 18 -63 18
-216 1 -275 -51 -171 -187 -285 -353 -298 -75 -5 -170 21 -226 64 -60 46 -62
42 -65 -133 l-3 -158 -88 -3 c-57 -2 -94 1 -103 9 -12 10 -14 98 -14 535 0
341 4 525 10 529 6 3 48 8 95 10 91 4 101 -1 107 -49 4 -37 18 -42 46 -16 19
18 85 55 132 74 8 3 53 4 100 3 73 -3 95 -8 153 -37z m889 11 c106 -41 203
-131 246 -226 10 -22 24 -73 32 -113 48 -257 -167 -501 -440 -501 -172 0 -329
97 -403 249 -28 57 -32 77 -35 165 -3 105 7 148 55 241 42 80 146 159 263 199
66 22 208 15 282 -14z m1131 2 c4 -2 7 -186 7 -408 0 -358 -2 -404 -16 -409
-9 -3 -53 -6 -99 -6 -70 0 -86 3 -94 18 -13 25 -15 766 -1 791 8 16 23 21 73
24 60 3 114 -1 130 -10z"/>
<path d="M1320 2176 c-4 -3 -62 -12 -130 -21 -281 -35 -360 -72 -360 -169 0
-34 6 -48 33 -74 38 -37 80 -41 175 -17 150 38 222 45 460 45 239 0 326 -8
502 -46 130 -28 277 -81 387 -140 102 -54 203 -13 203 83 0 56 -31 86 -141
140 -115 56 -159 74 -234 96 -27 8 -73 22 -101 31 -29 9 -63 16 -77 16 -14 0
-38 5 -54 11 -66 26 -644 65 -663 45z"/>
<path d="M1230 1735 c-256 -38 -316 -66 -314 -146 2 -44 15 -64 56 -85 28 -15
40 -13 188 27 19 5 97 14 173 20 144 12 383 1 477 -22 157 -37 259 -73 389
-135 62 -30 122 -54 133 -54 11 0 35 15 54 34 54 54 44 100 -31 151 -93 64
-310 149 -460 181 -203 43 -488 55 -665 29z"/>
<path d="M1175 1323 c-182 -32 -228 -62 -204 -132 13 -37 53 -51 114 -40 201
38 235 41 442 36 114 -2 226 -10 248 -16 22 -7 65 -19 95 -28 67 -19 129 -43
238 -94 45 -22 87 -39 93 -39 21 0 59 50 59 78 0 42 -33 72 -130 119 -88 42
-260 103 -291 103 -10 0 -44 7 -76 15 -77 20 -472 19 -588 -2z"/>
<path d="M5372 2664 c-42 -33 -78 -102 -62 -118 12 -12 196 -22 236 -12 20 5
24 13 24 42 0 29 -8 44 -39 75 -33 33 -45 39 -82 39 -32 0 -53 -7 -77 -26z"/>
<path d="M6978 2657 c-96 -73 -104 -251 -16 -339 21 -21 49 -40 63 -44 73 -18
149 36 178 127 22 66 21 97 -3 165 -41 118 -135 157 -222 91z"/>
<path d="M4670 1437 c-19 -6 -53 -29 -75 -52 -72 -72 -95 -188 -54 -280 68
-154 251 -191 356 -71 85 97 84 249 -4 346 -58 65 -136 85 -223 57z"/>
<path d="M5633 1440 c-69 -10 -123 -53 -157 -125 -108 -228 144 -455 342 -308
18 14 45 43 60 66 23 37 26 52 26 127 0 99 -22 151 -82 198 -33 25 -105 54
-128 51 -5 -1 -32 -5 -61 -9z"/>
</g>
</svg>
	);
};
