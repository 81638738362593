import {TheTownieConfig as BBBBBBBB_Config} from 'Shared/config/TheTownieConfig';
import {TheDockConfig as CCCCCCCC_Config} from 'Shared/config/TheDockConfig';
import {DifferentDrummerConfig as DDDDDDDD_Config} from 'Shared/config/DifferentDrummerConfig';
import {IndieIslandConfig as EEEEEEEE_Config} from 'Shared/config/IndieIslandConfig';
import Assert from 'Common/Assert';
import {ArtistsConfig} from 'Common/artists/ArtistsConfig';
import {SiteBasics} from 'Common/SiteBasics';
import {IVenueConfig} from 'Shared/config/IVenueConfig';

export class ConfigFactoryCreator 
{
	create(site:SiteBasics):ArtistsConfig|IVenueConfig
	{
		switch(site.key) {
			case '_ARTISTS': return new ArtistsConfig();
			case 'BBBBBBBB': return new BBBBBBBB_Config();
			case 'CCCCCCCC': return new CCCCCCCC_Config();
			case 'DDDDDDDD': return new DDDDDDDD_Config();
			case 'EEEEEEEE': return new EEEEEEEE_Config();
		}
		Assert.check(false);
	}
}


/* cf new site procedure:
	1. create custom config files:
		Custom/AAAAAAAA/view/Views
		Custom/AAAAAAAA/model/Model
	   etc
	    simplest versions can be almost empty
	2. Add eg 'Custom/AAAAAAAA/Config'
	3. Add a line to this file
 */

 //TODO in future - adjust Webpack (or its replacement) to distribute site customisations separately
