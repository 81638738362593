import {PageFunctions} from 'Browser/PageFunctions';
import {VenueUrls} from 'Common/VenueUrls';
import {Components, DocumentQueries} from 'Common/config/PageConfigTypes';
import {AccessAreaName} from 'Common/permissions/AccessArea';
import {FrontendPageConfig, frontendData} from 'Common/FrontendPageConfig';
import { z } from 'zod';

export const contactParams = z.object({}).strict();	
export type ContactParams = z.infer<typeof contactParams>;

export const contactData = frontendData.extend({
	params: contactParams,
	pageDisplayName: z.string(),
}).strict();
export type ContactData = z.infer<typeof contactData>;


export class Contact extends FrontendPageConfig<ContactData,ContactParams>
{
	static readonly pageName = 'frontend/contact';
	name() { return Contact.pageName; }

	access() { return 'venueFrontend' as AccessAreaName; }

	title() { return 'Contact'; }

	defaultSettings()
	{
		return {
			...super.defaultSettings(),
			htmlClasses: `${this.htmlClasses} contactPage`,
			template: 'App/frontend/contactPage/standard.njk',
			pageDisplayName: 'Contact'
		};
	}

	documents(params:ContactParams):DocumentQueries
	{
		return ({
			...super.documents(params),
			/* venue is included in all venue page requests */
		});
	}

	components()
	{
		return <Components<ContactData>>({
			...super.components(),
			navMore: {
				type: 'overlay',
				panes: {
					navMore: false
				}
			}
		});
	}

	beforeDisplay(data:ContactData)
	{
		super.beforeDisplay(data);

		const funcs = new PageFunctions();
		const urls = new VenueUrls(this.build,this.venue.key);

		data.venue.logo!.url = funcs.createImageUrl(urls,data.venue.logo,'Venue-logo','full');
		data.venue.photo!.url = funcs.createImageUrl(urls,data.venue.photo,'Venue-photo','full');
		data.venue.logoLandscape!.url = funcs.createImageUrl(urls,data.venue.logoLandscape,'Venue-logoLandscape','large');
	}
}

