import {css} from '@emotion/css';
import {palette} from 'Shared/artists/Palette';

export function steps()
{
	return css({
		'.icon': {
			gridArea: 'icon',
			width: '2em',
			display: 'inline-block'
		},

		'label .icon': {
			margin: '0 0.5em',
			verticalAlign: 'middle',
		},

		'svg.icon': {
			strokeWidth: '0.1em'
		},

		'.step .stepIcon': {
			marginTop: 6
		},

		'a.active': {
			color: palette.active
		},

		'a.inactive': {
			color: palette.inactive
		},

		'label.active': {
			color: palette.active
		},

		'.step': {
			display: 'grid',
			gap: '1em',
			gridTemplateColumns: '30px 1fr',
			padding: '0 6px',
			width: '100%'
		},
	});
}

