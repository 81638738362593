import {ArtistProps} from 'Shared/artists/ProfilePage';
import {FacebookCircle} from 'Shared/backend/fonts/icons/FacebookCircle.svg';
import {IconColour} from 'Shared/backend/fonts/icons/IconColour';
import {InstagramCircle} from 'Shared/backend/fonts/icons/InstagramCircle.svg';
import {VideoCircle} from 'Shared/backend/fonts/icons/VideoCircle.svg';
import {Wrap, Text} from 'Shared/forms/Inputs';
import {Show, createEffect, createSignal, mergeProps} from 'solid-js';
import {usePage} from 'Shared/artists/PageProvider';
import {facebookVideo, instagramVideo, youtube} from 'Browser/InputTypes';
import {EditComponent} from 'Common/components/EditComponent';
import {css} from '@emotion/css';
import {ProfileData} from 'Common/pages/artist/Profile';
import {ArtistDoc} from 'Shared/model/Artist';

const style = css({
	width: '40%',
	paddingBottom: '3em',

	'.videoEmbed': {
		position: 'relative',
		width: '100%',
		height: 0,
		paddingTop: '56.25%',
	},

	'.middle': {
		position: 'absolute',
		width: '100%',
		height: '100%',
		left: 0,
		top: 0,
		padding: '0 0 0 0'
	},

	iframe: {
		width: '100%',
		height: '100%',
		backgroundSize: 'cover',
		backgroundPosition: 'center center',
		overflow: 'hidden'
	}
});
const instagramStyle = css({
	width: '4%',
	paddingBottom: '3em',

	'.inner': {
		transform: 'scale(50%)',
		translate: '0 -25%'
	},

	'.outer': {
		height: 300,
	//	overflow: 'hidden'
	}
});

const placeholderStyle = css({
	width: '40%',
	aspectRatio: '5/3',
	color: '#ccc',
	backgroundColor: '#eee',
	border: 'solid 1px #ddd',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center'
});


//FIXME cj after townie rollout get instagram videos displaying on lineup preview, insta was putting message over it even though video appeared on frontend page 

export function Videos(artist:ArtistProps)
{
	return <>
		<h4>Videos
			<x-notes>VOS displays on venue website & drafts Facebook and Instagram Posts for venue and artists to post to their socials</x-notes>
		</h4> 

		<div class='step'>
			<div class='stepIcon'>
				<VideoCircle fill={artist.facebookVideoUrl || artist.instagramVideoUrl || artist.youtubeVideoUrl || artist.musicEmbedId 
					? IconColour.active : IconColour.inactive} 
				/>
			</div>

			<div>
				<FacebookVideoUrl {...artist} />
				<InstagramVideoUrl {...artist} />
				<YoutubeUrl {...artist} />
			</div>
		 </div>
	</>;
}	

function FacebookVideoUrl(artist:ArtistProps)
{
	const label = () => {
		if (artist.facebookVideoUrl)
			return (
				<a class='active' target='_blank' href={artist.facebookVideoUrl}>
					<FacebookCircle fill={IconColour.active} />
					Facebook video link
				</a>
			);
		else if (artist.facebook)
			return (
				<a class='inactive' target='_blank' href={`https://www.facebook.com/${artist.facebook}/videos`}>
					<FacebookCircle fill={IconColour.inactive} />
					Search Facebook for video
				</a>
			);
		else
			return (
				<span class='inactive'>
					<FacebookCircle fill={IconColour.inactive} />
					Facebook video link
				</span>
			);
	}

	const enableSearchMessage = () => !artist.facebook && !artist.facebookVideoUrl ? 'Tip: supply a Facebook handle to enable search' : undefined;

	return (
		<div>
			<Wrap label={label()} notes={enableSearchMessage()}>
				<Text component={artist.editArtist} store={artist} field='facebookVideoUrl' processInput={facebookVideo} 
					placeholder='Paste video share link here' 
				/>
			</Wrap>

			<Show when={artist.facebookVideoUrl} fallback={<Placeholder/>}>
				{/* preview video */}
				<label> Play video to check it&rsquo;s working </label>
				<div class={style}>
					<div class='videoEmbed'>
						<div class='middle'>
							<iframe class='fbVideo' 
								src={`https://www.facebook.com/plugins/video.php?href=${artist.facebookVideoUrl}%2F&show_text=false&width=100&height=60&t=0`} 
								allowfullscreen={true}
								allow='autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share' 
							/>
						</div>
					</div>
				</div>
			</Show>
		</div>
	);
}

function InstagramVideoUrl(artist:ArtistProps)
{
	const [value,setValue] = createSignal(artist.instagramVideoUrl);

	createEffect(() => {
		if (artist.inBrowser && value())
			initInstagram();
	});


	const enableSearchMessage = () => !artist.instagram && !artist.instagramVideoUrl ? 'Tip: supply an Instagram handle to enable search' : undefined;

	const label = () => {
		if (artist.instagramVideoUrl)
			return (
				<a target='_blank' class='active' href={artist.instagramVideoUrl} >
					<InstagramCircle fill={IconColour.active} />
					Instagram video link     
				</a>
			);
		else if (artist.instagram)
			return (
				<a target='_blank' class='inactive' href={`https://www.instagram.com/${artist.instagram}/reels`}>
					<InstagramCircle fill={IconColour.inactive} />
					Search instagram for video
				</a>
			);
		else
			return (
				<span class='inactive'>
					<InstagramCircle fill={IconColour.inactive} />
					Instagram video link
				</span>
			);
	}

	return (
		<div>
			<Wrap label={label()} notes={enableSearchMessage()}>
				<Text component={artist.editArtist} store={artist} field='instagramVideoUrl' processInput={instagramVideo} 
					placeholder='Paste video share link here' 
					setSignal={setValue}
				/>
			</Wrap>

			<Show when={artist.instagramVideoUrl} fallback={<Placeholder/>}>
				<div class={instagramStyle}>
					<div class='videoEmbed'>
						<div class='outer'>
							<div class='inner'>
								<blockquote class='instagram-media' data-instgrm-permalink={artist.instagramVideoUrl} data-instgrm-version='14'/>
							</div>
						</div>
					</div>
				</div>
			</Show> 
		</div>
	);
}

function initInstagram()
{
	const node = document.getElementById('instagramScript');
	if (node) {
		node.remove();
		delete window['instgrm'];
	}

	const script = document.createElement('script');
	script.id = 'instagramScript';
	script.type = 'text/javascript';
	script.src = '//www.instagram.com/embed.js';
	document.body.appendChild(script);
}

function YoutubeUrl(artist:ArtistProps)	 
{
	const label = () => artist.youtubeVideoUrl ?
			<a class='active' target='_blank' href={`http://www.youtube.com/results?search_query=${artist.name}+music`}>
				<VideoCircle fill={IconColour.active} />
				YouTube link
			</a>
		:
			<a target='_blank' class='inactive' href={`http://www.youtube.com/results?search_query=${artist.name}+music`}			>
				<VideoCircle fill={IconColour.inactive} />
				Search YouTube for video
			</a>;

	return (
		<div>
			<Wrap label={label()}>
				<Text component={artist.editArtist} store={artist} field='youtubeVideoUrl' processInput={youtube} 
					placeholder='Paste video share link here' 
				/>
			</Wrap>

			<Show when={artist.youtubeVideoUrl} fallback={<Placeholder/>}>
				{/* preview video */}
				<label> Play video to check it&rsquo;s working</label>
				<div class={style}>
					<div class='videoEmbed'>
						<div class='middle'>
							<iframe class='youtubeVideo' 
								src={`https://www.youtube.com/embed/${artist.youtubeVideoUrl}?modestbranding=1`}
								title='YouTube video player' allowfullscreen
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
							>
							</iframe>
						</div>
					</div>
				</div>
			</Show>
		</div>
	);
}

function Placeholder()
{
	return (
		<div class={placeholderStyle}>
			Missing video 
		</div>
	);
}
