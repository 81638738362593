import {VenueRoutes as SharedRoutes, VenueRoutesType} from 'Shared/routes/VenueRoutes';
import {Home as BackendHome} from 'Shared/view/backend/Home2'
import {FoodMenu as FrontendFoodMenu} from 'Shared/view/frontend/DifferentDrummerFoodMenu';
import {DrinksMenu as FrontendDrinksMenu} from 'Shared/view/frontend/DifferentDrummerDrinksMenu';


export class DifferentDrummerRoutes extends SharedRoutes
{
	frontend()
	{

		return <VenueRoutesType> ({
			...super.frontend(),

//			'/': [frontendHomePage(this.settings.sharedPages.home), () => ({})],		

			'/food':	[FrontendFoodMenu,() => ({})],
			'/drinks':	[FrontendDrinksMenu,() => ({})],
		});
	}

	backend()
	{
		return <VenueRoutesType>({
			...super.backend(),

//			'/admin': 				[BackendVenue, ()=>({})],
			'/admin/home': 			[BackendHome, ()=>({})],
			'/admin/home/:tab': 	[BackendHome, params=>({tab:params.tab})],
		});
	}
}
