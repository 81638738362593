import {IPageData} from 'Common/PageConfig';
import {Page} from 'Common/pages/Page';

export type Params = {[name:string]:any}; 

export interface ArtistPageData extends IPageData {
};


export class ArtistPage extends Page<ArtistPageData>
{
}

