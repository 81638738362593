import {TheTownieView} from 'Shared/view/TheTownieView';
import {TheTownieRoutes} from 'Shared/routes/TheTownieRoutes';
import {BuildBasics} from 'Common/BuildBasics';
import {Links} from 'Shared/config/Link';
import {Nav as TheTownieNav} from 'Shared/config/TheTownieNav';
import {IVenueConfig} from 'Shared/config/IVenueConfig';
import {VenueBasics} from 'Common/VenueBasics';


export class TheTownieConfig implements IVenueConfig
{
	view() { return (new TheTownieView()).view(); }

	link() { return Links; }

	nav() { return TheTownieNav; }

	routes(inBrowser:boolean,build:BuildBasics,site:VenueBasics)
	{
		return (new TheTownieRoutes(inBrowser,build,site)).routes();
	}
}
