import {LinkOpen} from 'Shared/backend/fonts/icons/LinkOpen.svg';
import {ImageUpload2} from 'Shared/forms/ImageUpload';
import {SingleSelect, Wrap} from 'Shared/forms/Inputs';
import {Show} from 'solid-js'
import { usePage } from 'Shared/artists/PageProvider';
import { IItemProps } from 'Shared/backend/menu/MenuItemPage';


export interface IImageSelector
{
	itemProps: IItemProps;
}

export function ImageSelector(props:IImageSelector)
{
	const page = usePage();
	const setImage = value => props.itemProps.setStore(...props.itemProps.location,'image',value);

	return (<>
		<header-regular>Image</header-regular>

		<div id='builder_sharePoster'>
			<Show when={props.itemProps.item.brandName}>
				<div class='search'>
					<a href={`http://images.google.com/images?um=1&hl=en&safe=active&nfpr=1&q=${props.itemProps.item.brandName}+${props.itemProps.item.name}+logo`} target='_blank'>
						<label>
							Search Google for image &nbsp; <LinkOpen/>
						</label>
					</a>
				</div>
			</Show>

			<Wrap label='Dimensions' required={false}
				instructionsAfterLabel='Will be cropped to square on your menu, but full dimensions will show on details page'
			>
				<SingleSelect {...props.itemProps} field='imageDimensions' required={true} 
					options={ {
						'landscape':	'▬ Landscape',
						'portrait':		'▋ Portrait',
						'square':		'■ Square'
					} } 
				/>
			</Wrap>

			<x-field class='imgUploadButton'>
				<ImageUpload2 
					{...props.itemProps}
					location={[...props.itemProps.location,'image']} 
					permission='photo' 
					image={props.itemProps.item.image} 
					setImage={setImage}
					page={page} 
					dimensions={props.itemProps.item.imageDimensions} 
					previewSize='medium'
				/>
			</x-field>
		</div>
	</>);
}	

