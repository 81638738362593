
import {For} from 'solid-js';

export function ThirdPartyServices(props)
{
	return (
		<div class='thirdPartyServices'>
			<For each={props.links}>{ service =>
				<a href={service.url} target='_blank'>
					<button>
						{ service.title != 'other' ? service.title : service.other }
					</button>
				</a>
			}</For>
		</div>
	);
}

