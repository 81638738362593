import {Component} from 'Common/components/Component';
import {Tabs as TabsConfig} from 'Common/config/PageConfigTypes';
import Assert from 'Common/Assert';
import { z } from 'zod';


//TODO probably delete...
export const tabsData = z.object({
	selected: z.string().optional()
});
export type TabsData = z.infer<typeof tabsData>;



/*
	This component is mostly used to handle the selected tab which needs to be
	stored, and possible read from or written to the URL, session, or elsewhere.
 */
export class Tabs<PageData> extends Component
{
	constructor(
		instanceName:string,
		private config:TabsConfig<PageData>,
		private pageData:PageData
	)
	{
		super(instanceName);
		this.changeTabIndex = this.changeTabIndex.bind(this);
	}

    componentType():string
    {
        return 'tabs';
    }

	changeTabIndex(target:EventTarget)
	{
		const myData = (<any>this.pageData)[this.name];
		myData.selected = Assert.htmlElement(target).dataset.pane ?? undefined;
		return [null,[]];
	}

//XXX ==> initLocalData() ... combine with beforeDisplay()?
//
//XXX guess there is a difference between initLocalData() and beforeDisplay() when components are kept
//    between page refreshes. In this case initLocalData() is just called the first time and beforeDisplay()
//    is called every time. 
//    I suspect currently the components are created once per page load. Might be better recreated every time.

	load(): TabsData
	{
		return {selected: this.config.initial(this.pageData)};
	}
}

