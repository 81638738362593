import {DocumentQuery} from 'Common/config/PageConfigTypes';
import {Params} from 'Common/pages/IPage';
import {ListMessage, ReadMessage} from 'Common/Messages';
import {IConnectionToServer} from 'Common/IConnectionToServer';
import Assert from 'Common/Assert';

export class DocumentLoader
{
	constructor(
		private server:IConnectionToServer,
		private pageName: string,
		private params:Params
	)
	{ }

	async load(name:string,config:DocumentQuery)
	{
		if (config.type=='object') {
			const msg = new ReadMessage(this.pageName,name,this.params);
			const doc = await this.server.sendOperation(msg);
			return doc==undefined ? {} : doc;
		}

		if (config.type=='array') {
			const msg = new ListMessage(this.pageName,name,this.params);
			return await this.server.sendOperation(msg);		
		}

		Assert.check(false);
	}
}

