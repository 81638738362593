export function SoundcloudButton()
{
	return (
<svg id="soundcloud" class="icon" version="1.0" viewBox="0 0 900 330" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg">
  <defs></defs>
  <g transform="matrix(0.1, 0, 0, -0.1, 0, 327)">
    <path d="M0 1635 l0 -1635 4500 0 4500 0 0 1635 0 1635 -4500 0 -4500 0 0
-1635z m3325 1189 c22 -7 46 -14 53 -14 15 0 68 -22 117 -47 17 -8 48 -25 70
-36 94 -49 212 -149 283 -242 25 -33 49 -62 52 -65 18 -15 80 -112 80 -124 0
-8 4 -17 9 -20 16 -10 69 -134 97 -226 29 -98 33 -114 44 -194 5 -44 12 -81
13 -83 2 -1 19 1 38 6 176 43 185 45 240 32 30 -7 72 -16 94 -20 108 -22 250
-131 334 -256 94 -140 145 -392 106 -521 -8 -27 -15 -59 -15 -71 0 -22 -10
-48 -56 -150 -29 -63 -121 -176 -184 -224 -25 -20 -71 -48 -104 -63 -122 -57
-95 -56 -1022 -56 l-856 0 -29 29 -29 29 0 1110 0 1110 31 26 c17 14 38 26 47
26 9 0 26 7 36 15 11 8 31 15 44 15 13 0 33 5 44 11 56 30 380 32 463 3z
m-955 -272 c21 -17 24 -31 32 -173 6 -85 11 -514 11 -954 2 -862 -1 -923 -45
-957 -30 -23 -35 -23 -67 10 -26 26 -27 31 -29 147 -1 66 -7 190 -13 275 -9
116 -9 289 0 680 6 289 13 619 14 734 l2 208 28 24 c34 29 38 29 67 6z m3140
-124 c19 -7 56 -28 81 -46 55 -40 55 -53 -3 -108 -33 -31 -43 -36 -57 -26 -54
39 -98 54 -154 55 -51 0 -62 -3 -78 -24 -26 -33 -24 -54 9 -77 25 -17 53 -27
153 -52 61 -16 139 -62 167 -98 37 -49 44 -160 13 -220 -41 -81 -127 -122
-256 -122 -100 0 -173 24 -233 75 -45 38 -46 51 -6 95 40 45 60 49 95 21 101
-79 269 -64 269 25 0 47 -33 65 -190 104 -121 31 -190 105 -190 205 0 97 74
180 185 209 32 8 157 -2 195 -16z m698 -7 c67 -34 109 -78 144 -149 31 -64 32
-72 32 -192 1 -99 -3 -132 -17 -159 -9 -19 -17 -39 -17 -45 0 -6 -20 -35 -45
-63 -63 -71 -131 -103 -225 -103 -38 0 -78 4 -88 9 -89 43 -112 59 -144 101
-21 27 -38 52 -38 58 0 5 -8 24 -17 43 -14 27 -17 60 -17 159 1 112 4 131 27
181 42 91 95 142 186 176 51 19 167 11 219 -16z m1654 10 c4 -4 8 -161 8 -350
1 -274 -1 -346 -12 -352 -7 -5 -36 -9 -64 -9 -63 0 -57 -7 -215 232 -68 104
-130 188 -136 188 -10 0 -13 -51 -15 -207 l-3 -208 -55 -3 c-30 -1 -60 1 -67
6 -12 8 -28 644 -17 689 6 21 12 23 69 23 63 0 64 -1 99 -44 20 -23 36 -46 36
-51 0 -4 14 -24 30 -45 17 -21 30 -41 30 -45 0 -4 14 -24 30 -45 17 -21 30
-41 30 -45 0 -4 14 -24 30 -45 17 -21 30 -42 30 -47 0 -11 40 -46 47 -40 2 3
7 94 10 204 3 127 9 200 16 205 12 8 108 -1 119 -11z m528 -5 c79 -20 115 -41
162 -96 56 -64 77 -131 77 -247 1 -120 -23 -188 -94 -259 -57 -58 -117 -88
-196 -100 -70 -11 -260 -5 -276 8 -10 8 -13 89 -13 343 0 184 3 340 6 349 5
14 27 16 143 16 80 -1 159 -6 191 -14z m-1710 -236 c4 -205 7 -239 23 -265 43
-70 120 -93 182 -55 68 42 76 73 84 355 l6 200 59 3 c32 2 63 -1 68 -6 10 -10
12 -434 2 -489 -11 -59 -42 -110 -94 -155 -79 -68 -165 -89 -262 -62 -77 21
-115 45 -153 95 -53 71 -57 95 -62 352 -3 131 -2 245 1 253 4 12 20 14 73 12
l68 -3 5 -235z m-4705 150 c13 -25 18 -110 27 -508 15 -602 15 -811 0 -1089
-12 -242 -22 -283 -65 -283 -51 0 -54 11 -68 230 -15 235 -18 630 -7 965 4
127 10 333 14 458 7 244 10 257 59 257 17 0 29 -9 40 -30z m-417 2 c19 -19 23
-76 39 -502 12 -343 14 -928 3 -1055 -7 -77 -15 -176 -17 -220 -4 -67 -8 -83
-26 -96 -18 -15 -23 -15 -43 -3 -23 15 -21 3 -42 269 -9 119 -8 821 1 1105 14
396 21 500 37 510 21 14 28 12 48 -8z m-366 -573 c6 -134 12 -273 14 -309 13
-263 15 -441 5 -550 -6 -69 -16 -192 -21 -274 -11 -165 -19 -189 -63 -169 -22
10 -24 18 -30 125 -4 62 -10 151 -14 198 -9 103 -10 577 -1 750 4 69 10 205
14 303 7 164 9 179 28 193 18 12 22 12 39 -4 16 -16 19 -49 29 -263z m-401
-113 c1 -44 15 -236 29 -411 10 -117 10 -182 1 -290 -6 -77 -16 -207 -22 -290
-6 -101 -15 -157 -25 -172 -36 -56 -52 -10 -65 192 -6 94 -13 202 -17 240 -7
88 -7 238 1 330 3 39 11 153 17 255 9 147 15 188 28 203 16 18 17 18 34 -3 10
-12 18 -36 19 -54z m-403 -69 c6 -7 16 -62 22 -122 6 -61 15 -139 20 -175 13
-85 13 -317 0 -400 -6 -36 -15 -112 -21 -170 -10 -102 -21 -134 -43 -127 -12
4 -17 44 -36 247 -6 69 -15 151 -21 183 -7 40 -7 81 0 130 6 40 16 128 22 197
22 256 22 250 37 250 5 0 14 -6 20 -13z m5357 -88 c5 -5 16 -9 25 -9 23 0 130
-98 130 -120 0 -19 -52 -51 -102 -63 -20 -5 -32 -2 -45 12 -55 57 -130 66
-192 20 -59 -42 -81 -94 -81 -194 0 -57 6 -92 19 -122 26 -55 91 -103 142
-103 37 0 109 33 109 51 0 4 10 13 21 20 17 8 28 8 48 -2 45 -24 81 -52 81
-66 0 -19 -108 -123 -128 -123 -10 0 -24 -6 -30 -12 -9 -9 -42 -13 -95 -12
-136 1 -218 51 -278 171 -31 63 -34 77 -37 175 -5 153 18 220 103 304 39 38
75 64 96 69 19 5 45 12 59 16 31 9 141 0 155 -12z m1210 -9 c57 -24 121 -83
147 -134 22 -45 48 -161 48 -216 0 -51 -25 -159 -48 -205 -29 -58 -84 -112
-139 -137 -68 -30 -190 -30 -253 0 -63 31 -114 82 -147 149 -25 52 -28 70 -31
182 -3 108 -1 130 19 185 31 83 114 166 190 189 65 20 150 15 214 -13z m1405
6 c188 -39 268 -157 258 -375 -6 -117 -27 -169 -98 -239 -79 -78 -186 -107
-355 -95 l-110 8 0 350 0 350 55 6 c101 11 184 9 250 -5z m-2190 -271 c3 -148
8 -275 12 -281 4 -6 59 -13 133 -17 69 -3 129 -9 135 -12 5 -3 10 -30 10 -60
0 -39 -4 -54 -16 -59 -31 -12 -389 -7 -402 6 -18 18 -17 669 0 686 8 8 34 12
68 10 l55 -3 5 -270z m1260 40 c6 -252 9 -267 70 -319 24 -20 42 -26 79 -26
41 0 53 5 83 34 49 48 57 94 60 329 2 110 6 203 9 208 3 6 32 9 65 7 l59 -3 6
-70 c11 -119 10 -362 -2 -412 -25 -108 -70 -172 -147 -210 -98 -48 -241 -35
-322 30 -29 22 -68 81 -85 127 -23 60 -35 501 -15 526 10 11 29 14 74 12 l61
-3 5 -230z"></path>
    <path d="M5994 2272 c-61 -53 -69 -77 -69 -193 0 -99 2 -108 28 -148 69 -105
175 -108 252 -7 26 34 27 41 28 150 0 100 -3 118 -22 150 -35 55 -71 76 -133
76 -43 0 -59 -5 -84 -28z"></path>
    <path d="M8206 2274 c-3 -9 -6 -98 -6 -199 0 -101 3 -190 6 -199 14 -35 159
-6 207 41 46 44 71 121 63 187 -12 103 -47 153 -122 174 -62 18 -140 16 -148
-4z"></path>
    <path d="M6765 1347 c-61 -34 -85 -90 -85 -199 0 -110 18 -159 73 -199 70 -51
159 -31 206 44 43 71 43 233 -1 305 -23 37 -84 72 -125 72 -15 0 -46 -10 -68
-23z"></path>
    <path d="M8185 1343 c-3 -10 -3 -105 1 -211 8 -222 6 -217 95 -203 75 13 101
24 135 60 37 40 52 82 53 150 2 118 -43 189 -130 209 -78 18 -145 16 -154 -5z"></path>
  </g>
</svg>
	);
}

/*
{# vs 2 with more soundbars }
<svg id="soundcloud" class="icon" version="1.0" viewBox="0 0 900 330" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg">
  <defs></defs>
  <g transform="matrix(0.1, 0, 0, -0.1, 0, 327)">
    <path d="M0 1635 l0 -1635 4500 0 4500 0 0 1635 0 1635 -4500 0 -4500 0 0
-1635z m3234 1205 c55 -5 104 -14 110 -20 5 -5 19 -10 30 -10 32 0 225 -98
298 -152 183 -135 341 -366 414 -608 31 -105 34 -115 45 -195 6 -44 12 -81 13
-83 2 -1 30 5 62 15 67 20 242 23 309 6 77 -20 169 -77 246 -153 99 -98 160
-217 189 -368 24 -125 24 -162 1 -277 -18 -89 -24 -107 -63 -195 -71 -158
-258 -309 -413 -335 -27 -5 -430 -9 -895 -10 -879 -1 -886 -1 -912 39 -4 6 -8
511 -8 1122 0 1091 0 1111 20 1129 25 23 117 65 142 65 10 0 29 5 41 11 44 24
225 33 371 19z m-655 -167 c20 -17 22 -32 32 -258 6 -132 12 -573 13 -980 1
-761 -5 -919 -39 -954 -18 -18 -72 -18 -90 0 -36 37 -49 675 -32 1559 10 493
17 610 39 632 23 23 50 23 77 1z m2701 -94 c61 -19 138 -61 165 -89 l26 -27
-51 -49 c-28 -27 -55 -50 -59 -52 -5 -2 -28 10 -52 26 -56 37 -128 53 -177 40
-108 -29 -93 -107 28 -141 213 -61 237 -72 284 -131 28 -35 31 -45 34 -125 3
-73 0 -95 -16 -127 -46 -89 -141 -135 -281 -136 -108 -1 -200 31 -269 93 -41
37 -40 57 6 100 54 51 64 52 114 15 106 -81 287 -59 288 35 0 27 -46 79 -71
79 -7 0 -34 6 -59 14 -25 8 -79 25 -120 37 -57 17 -83 32 -115 63 -76 76 -80
205 -10 291 38 46 87 72 190 98 26 7 100 0 145 -14z m770 -1 c68 -21 91 -35
141 -84 110 -107 141 -379 62 -540 -45 -93 -102 -140 -218 -178 -67 -22 -106
-20 -184 6 -52 17 -78 34 -122 78 -67 66 -91 116 -114 229 -17 83 -16 114 6
216 28 125 119 236 219 267 95 30 131 31 210 6z m568 -267 c3 -223 5 -258 21
-290 40 -76 110 -111 180 -90 51 15 84 50 110 117 19 48 21 74 21 287 0 181 3
235 13 236 12 1 115 1 130 0 4 -1 7 -122 7 -269 0 -244 -2 -273 -21 -326 -11
-32 -27 -65 -35 -73 -8 -8 -19 -23 -24 -34 -13 -23 -81 -68 -130 -86 -40 -14
-181 -17 -208 -4 -9 4 -39 19 -65 32 -58 28 -92 65 -124 135 -25 56 -29 96
-38 422 -6 223 -12 211 97 202 l63 -5 3 -254z m839 254 c10 -3 153 -210 153
-222 0 -2 20 -31 45 -63 25 -32 45 -61 45 -63 0 -9 65 -102 75 -107 6 -3 12
79 15 225 l5 230 63 5 c35 3 68 3 73 -1 5 -3 8 -164 7 -395 l-3 -389 -40 -3
c-92 -8 -86 -13 -260 247 -88 132 -163 237 -167 235 -4 -3 -8 -110 -8 -239 0
-203 -2 -234 -16 -239 -25 -9 -122 -7 -129 3 -11 18 -7 774 5 781 10 6 112 3
137 -5z m-5087 -13 c20 -16 23 -32 31 -138 11 -158 14 -464 13 -1194 -1 -649
-5 -721 -46 -752 -30 -23 -32 -22 -65 6 -24 21 -27 31 -30 92 -1 38 -7 176
-14 307 -9 179 -9 361 0 745 6 279 13 596 14 706 l3 198 27 24 c34 29 38 29
67 6z m6217 -4 c67 -26 143 -95 169 -154 26 -60 38 -131 38 -219 -1 -129 -22
-188 -100 -274 -81 -90 -182 -120 -402 -121 -57 0 -112 5 -123 10 -18 10 -19
25 -19 389 0 208 3 381 7 384 3 4 88 7 189 7 164 0 188 -2 241 -22z m-6821
-169 c17 -19 20 -46 26 -333 14 -584 17 -671 22 -806 4 -74 1 -207 -4 -295 -6
-88 -15 -227 -19 -309 -7 -128 -11 -151 -26 -163 -28 -20 -53 -16 -70 11 -12
18 -17 62 -21 182 -3 88 -9 200 -15 251 -12 117 -12 277 1 551 5 119 11 367
14 549 5 287 8 336 22 358 20 31 45 32 70 4z m190 -17 c32 -21 35 -58 46 -530
15 -596 15 -808 0 -1089 -12 -241 -22 -283 -65 -283 -57 0 -62 26 -73 395 -3
99 -7 188 -9 198 -4 21 10 747 21 1080 5 161 10 211 21 223 16 15 41 18 59 6z
m-398 -20 c16 -16 20 -45 27 -213 23 -542 30 -1157 15 -1339 -7 -80 -15 -182
-17 -227 -4 -71 -7 -84 -27 -96 -20 -13 -25 -13 -43 2 -20 14 -23 33 -35 191
-21 279 -16 1066 10 1543 6 104 12 141 22 147 21 14 28 12 48 -8z m624 -39
c33 -83 40 -1687 7 -1789 -15 -47 -42 -68 -68 -55 -33 18 -48 60 -52 146 -6
124 -4 1309 2 1500 7 201 14 225 64 225 29 0 37 -5 47 -27z m-823 -56 c13 -17
32 -145 31 -212 -1 -27 4 -210 11 -405 16 -447 16 -595 1 -845 -16 -269 -28
-324 -69 -325 -31 0 -42 45 -48 195 -4 83 -10 183 -15 222 -9 78 -6 536 4 693
7 110 20 543 18 610 -2 63 37 102 67 67z m-194 -221 c12 -18 18 -78 27 -267 6
-134 12 -275 14 -314 13 -248 14 -425 5 -530 -6 -66 -16 -189 -21 -274 -9
-149 -17 -181 -46 -181 -31 0 -41 29 -48 147 -4 65 -10 154 -14 198 -8 95 -8
549 0 725 4 69 10 205 14 304 6 161 9 180 27 197 26 24 23 24 42 -5z m-365
-486 c5 -85 14 -200 20 -255 10 -87 8 -288 -5 -430 -1 -22 -8 -105 -14 -185
-11 -149 -23 -200 -47 -200 -26 0 -35 42 -44 222 -6 101 -15 231 -20 290 -5
63 -6 150 0 215 5 59 8 115 7 123 -1 8 2 44 6 80 4 36 9 114 12 175 4 75 11
117 21 134 16 23 16 23 35 5 16 -16 20 -44 29 -174z m-213 120 c2 -19 8 -66
13 -105 15 -97 33 -351 33 -465 0 -120 -20 -381 -33 -437 -6 -23 -10 -65 -10
-93 0 -37 -4 -52 -16 -57 -28 -10 -36 14 -46 134 -6 65 -12 136 -15 158 -11
89 -12 457 -2 560 6 61 14 157 17 215 7 112 14 133 40 128 11 -2 18 -15 19
-38z m381 8 c7 -7 12 -31 12 -55 0 -23 4 -64 10 -90 10 -51 32 -340 32 -433 1
-91 -20 -390 -32 -460 -6 -36 -11 -85 -13 -110 -2 -40 -5 -45 -27 -48 -25 -3
-26 0 -38 70 -7 40 -15 143 -18 228 -3 85 -9 180 -14 210 -9 59 -7 108 10 355
6 88 11 171 10 185 0 23 16 119 25 148 5 15 27 16 43 0z m-573 -88 c3 -10 10
-65 16 -122 5 -57 14 -132 19 -168 12 -83 13 -316 0 -400 -5 -36 -14 -112 -20
-170 -11 -100 -26 -140 -48 -126 -5 3 -12 38 -16 78 -4 40 -8 84 -11 98 -8 53
-27 277 -27 320 0 78 22 333 32 370 6 19 10 59 10 88 0 45 3 52 19 52 11 0 22
-9 26 -20z m5230 -64 c54 -29 125 -101 125 -127 0 -10 -25 -29 -65 -50 l-65
-33 -33 32 c-21 20 -50 35 -83 43 -109 25 -187 -36 -218 -171 -15 -66 -15 -77
1 -138 30 -123 89 -182 180 -182 52 0 113 32 133 70 6 11 18 20 28 20 23 0
122 -67 122 -83 0 -20 -87 -114 -123 -133 -56 -29 -160 -42 -230 -29 -94 18
-148 51 -202 125 -113 154 -104 437 17 576 73 83 156 116 278 111 68 -4 95
-10 135 -31z m1279 19 c15 -8 32 -15 38 -15 20 0 98 -68 126 -110 36 -54 56
-112 72 -205 22 -128 -28 -303 -110 -385 -72 -72 -193 -110 -290 -90 -190 40
-290 182 -290 410 0 253 131 408 345 409 52 1 90 -4 109 -14z m-853 -15 c5 -8
9 -146 9 -306 0 -221 3 -294 13 -301 6 -6 75 -13 151 -16 76 -3 142 -10 145
-15 3 -6 4 -39 1 -74 l-5 -63 -230 0 -230 0 -3 385 c-1 212 0 390 2 395 6 17
136 12 147 -5z m1394 8 c3 -2 7 -116 11 -253 7 -279 14 -311 77 -359 60 -46
157 -35 205 23 30 37 35 79 46 331 l11 265 65 -2 c36 -1 69 -2 73 -2 4 -1 7
-130 5 -288 l-3 -288 -27 -55 c-31 -63 -85 -117 -143 -145 -60 -28 -234 -27
-291 2 -61 32 -103 77 -144 153 -23 44 -24 53 -28 324 -4 238 -2 281 10 290
14 10 124 13 133 4z m1062 1 c67 -16 146 -56 182 -93 16 -17 42 -56 58 -86 26
-51 28 -65 31 -192 3 -110 0 -144 -12 -169 -9 -18 -16 -36 -16 -40 0 -16 -47
-77 -81 -106 -99 -84 -147 -98 -349 -98 l-165 0 -3 385 c-1 212 0 391 3 397 6
17 284 18 352 2z"></path>
    <path d="M5895 2423 c-31 -8 -74 -54 -97 -103 -29 -64 -30 -216 -1 -280 32
-69 70 -100 134 -107 106 -11 164 39 195 167 17 69 17 79 2 152 -24 116 -74
170 -162 174 -28 2 -60 0 -71 -3z"></path>
    <path d="M8328 2423 c-17 -4 -18 -24 -18 -243 0 -274 -8 -254 105 -243 141 14
215 99 215 247 0 76 -27 152 -66 188 -40 35 -179 65 -236 51z"></path>
    <path d="M6735 1383 c-44 -23 -72 -51 -95 -95 -29 -56 -29 -241 0 -296 42 -79
78 -102 157 -102 88 0 130 35 165 135 35 99 14 250 -43 311 -32 34 -91 64
-126 64 -15 -1 -41 -8 -58 -17z"></path>
    <path d="M8305 1368 c-3 -13 -4 -124 -3 -248 l3 -225 55 1 c78 0 163 28 199
65 20 20 38 54 52 102 21 70 21 73 4 141 -27 116 -65 152 -180 172 -105 19
-124 18 -130 -8z"></path>
  </g>
</svg>

*/
