import { z } from 'zod';
import * as t from 'Shared/model/basic';

export const section = z.object({
	name: z.string(),
	contentType: z.array(z.string()),
	textBlock: z.string(),
	document: z.string(),
	wysiwig: z.string(),   //TODO rename to notes or something...
	linkURL: z.string(),   //TODO rename to linkUrl
	linkButton: z.string(),
	embedUrl: z.string(),
	embedHeight: z.string(),
}).partial();

export const staffResource = z.object({
	_id: t.id,
	siteId: t.id,
	position: z.number(),	//XXX or put all in one doc?
	name: z.string(),
	summary: z.string(),
	introduction: z.string(),

	nav: z.array(z.string()),
	tags: z.array(z.string()),

	section: z.array(section)

	//+data field probably contains extra fields
	//XXX slug?
}).partial().required({
	_id: true,
	siteId: true,
	section:true
//	slug: true    MAY BE REQUIRED
});


export type StaffResource = z.infer<typeof staffResource>;

