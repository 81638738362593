import { z } from 'zod';
import * as t from 'Shared/model/basic';
import {appleUrl, facebookVideoUrl, spotifyTrackUrl} from 'Shared/model/lineup';
import {genreKeys, performanceTypeKeys} from 'Shared/ArtistTypes';

/*
	WARNING: this is used mostly in the artist profile system, but is also being used a bit
	in the ArtistSearchWidget. XXX maybe in future use more in the event lineup form.
 */
export const sharedArtistFields = {
	name: z.string(),
	performanceType: performanceTypeKeys,
	image: t.image,		 //XXX better to put imageDimensions into image so both defined or undefined together
	mainGenre: genreKeys,
	otherGenre1: genreKeys,
	otherGenre2: genreKeys,
	extraGenre: z.string().max(100),
	tagline: z.string().max(200),
	biography: z.string().max(1000),
	country: z.string().max(100),
	state: z.string().max(8),
	city: z.string().max(100),
	musicEmbedPlatform: z.enum(['bandcamp','soundcloud']),
	musicEmbedId: z.string().max(32),
	videoEmbedPlatform: z.enum(['youtube','vimeo','facebookVideo']),
	videoEmbedId: z.string().max(32),
	facebookVideoUrl: facebookVideoUrl,

	instagramVideoUrl: t.url, //XXX improvable
	youtubeVideoUrl: t.url, //XXX improvable
	bandcamp: t.url, //XXX improvable

	spotify: spotifyTrackUrl,
	appleMusic: appleUrl,
	website: t.url,
	facebook: t.facebook,
	instagram: t.instagram,
	soundcloud: t.soundcloud,
	twitter: t.twitter,
};

export const artistDoc = z.object({
	_id: t.id,
//	siteId: t.id,
//	status: z.string().max(100),	//XXX can do better
	...sharedArtistFields
})
.partial().required({
	_id: true,
//	siteId: true,
//	status: true
});


export type ArtistDoc = z.infer<typeof artistDoc>;

