import {SelectorWidget} from 'Browser/widgets/SelectorWidget';
import Cropper from 'cropperjs';
import Assert from 'Common/Assert';
import {VenueUrls} from 'Common/VenueUrls';
import {PageFunctions} from 'Browser/PageFunctions';
import { ImageUploaderComponent } from 'Common/components/ImageUpoaderComponent';
import {IPageWrapper} from 'Browser/pages/PageWrapper';

/* 
    XXX adding coloured (or just black) borders might be helpful for some images in the future.
    Probably would require a centering button and colour picker too
 */

export class ImageUploadWidget extends SelectorWidget
{
    constructor(
		private pageWrapper:IPageWrapper,
		selectors:string[] = ['.bf-imageUpload'])
    {
        super(selectors);
		this.transformImage = this.transformImage.bind(this);
    }

	static key() { return 'imageUpload'; }

    initInstance(anchorNode:HTMLElement)
    {
		const dropPane = anchorNode;
        super.initInstance(anchorNode);

		['dragenter','dragover','dragleave','drop'].forEach(ev => 
			dropPane.addEventListener(ev,e => {
				e.preventDefault();
				e.stopPropagation(); },false));

		['dragenter','dragover'].forEach(ev => 
			dropPane.addEventListener(ev,
				() => dropPane.classList.add('bf-showDragTarget'),false));

		['dragleave','drop'].forEach(ev => 
			dropPane.addEventListener(ev,
				() => dropPane.classList.remove('bf-showDragTarget'),false));

		dropPane.addEventListener('drop',
			/* Ignoring async */ //e => this.handleFiles(anchorNode,Assert.toExists<DataTransfer>(e.dataTransfer).files),false);
			/* Ignoring async */ e => this.handleFiles(anchorNode,Assert.have(e.dataTransfer).files),false);

		Assert.htmlElement(dropPane.querySelector('input[type=file]'))
			//.addEventListener('change',/* Ignoring async */ e => this.handleFiles(anchorNode,Assert.toExists<any>(e.target).files));
			.addEventListener('change',/* Ignoring async */ e => this.handleFiles(anchorNode,(<any>e.target).files));  //XXX hacky cast

		anchorNode.querySelectorAll('button').forEach(n => {
			if (n.closest('.modal')==null)
				n.addEventListener('click',e => e.stopPropagation())
		});

		anchorNode.addEventListener('click', () => 
			Assert.htmlElement(anchorNode.querySelector('input[type=file]')).click());

		/* URL modal: */
//TODO put in separate function
		const compName = Assert.toString(anchorNode.dataset.component);

		const comp = this.pageWrapper.page.component(compName);
		if (comp==null)
			throw new Error(`Invalid component name ('${compName}') in image uploader in NJK file`);

		const imageUpdater = <ImageUploaderComponent><unknown>comp;

		const location = JSON.parse(Assert.toString(anchorNode.dataset.location));

		/* Remove image: */

		Assert.htmlElement(anchorNode.querySelector('.bf-removeImage')).addEventListener('click',async () => {
			if (!confirm('Are you sure you want do delete this image?'))
				return;
			imageUpdater.deleteImage(location);
			this.pageWrapper.refresh();
		});
	}

	async handleFiles(anchorNode:HTMLElement,files:FileList)
	{
		const showCropper = 'crop' in anchorNode.dataset;

		/*
			ratio, minWidth and minHeight are all optional. If ratio is supplied only one of
			minWidth and minHeight are required to provide minimum dimensions.
		 */
		const ds = anchorNode.dataset;

		const ratio = 'ratio' in ds ? Number(ds.ratio) : null;
		let minWidth = 'minWidth' in ds ? Number(ds.minWidth) : null;
		let minHeight = 'minHeight' in ds ? Number(ds.minHeight) : null;

		if (minHeight==null && (ratio!=null && minWidth!=null))
			minHeight = Math.round(minWidth / ratio);
		if (minWidth==null && (ratio!=null && minHeight!=null))
			minWidth = Math.round(minHeight * ratio);
		if (minWidth==null) minWidth = 0;
		if (minHeight==null) minHeight = 0;

		let file = files[0];

		let dataUrl:string;
		if (showCropper) {
			const blob = await this.transformImage(file,ratio,minWidth,minHeight);
			if (blob==null) return;
//TODO test cancel here wrt null...
			dataUrl = await this.fileOrBlobToDataUrl(blob);
			file = new File([blob],'trimmedImage');
		}
		else {
			dataUrl = await this.fileOrBlobToDataUrl(file);  //XXX NB returning Promise<string|ArrayBuffer|null> 
			if (!await this.checkImageSize(dataUrl,minWidth,minHeight)) {
				alert('The image is too small');
				return;
			}
		}

		const compName = Assert.toString(ds.component);
		const imageUpdater = <ImageUploaderComponent>this.pageWrapper.page.component(compName);  
		const def = imageUpdater.def;

		const location = JSON.parse(Assert.have(anchorNode.dataset.location));

		imageUpdater.uploadingImage(location,dataUrl);
		await this.pageWrapper.display();

		const docId = this.pageWrapper.page.data[def.collection]._id;

		const urls = new VenueUrls(window.build,window.site.key);
		const uploadUrl = urls.uploadImageUrl(this.pageWrapper.page.name(),compName,docId,location);

		let fieldData = await this.uploadFile(file,uploadUrl);

		if (window.build.storeForNewAssets=='local')
			fieldData = {...fieldData,dev:true};

		const funcs = new PageFunctions();
		const url = funcs.createImageUrl(urls,fieldData,def.assetFolder,def.previewSize);

		imageUpdater.loadedFile(location,{...fieldData,previewUrl:url});

		this.pageWrapper.refresh();
	}

	async checkImageSize(image:string,minWidth:number,minHeight:number)
	{
		const im = new Image();
		return await new Promise((resolve,reject) => {
			im.onload = () => {
				resolve(im.height >= minHeight && im.width >= minWidth);
			}
			im.src = image;
		});
	}

	async fileOrBlobToDataUrl(fileOrBlob:File|Blob):Promise<string>
	{
		/*
			For simplicity I'm aways returning a string. Might be better to return an ArrayBuffer instead or
			else pass string|ArrayBuffer back.

			Note on FF for cropping fileOrBlob=blob & FileReader returns a string.
		 */
//XXX when trimming getting a Blob

		return await new Promise((resolve,reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(fileOrBlob);
			reader.onloadend = () => {
				const out = reader.result instanceof ArrayBuffer ? reader.result.toString() : reader.result;
				resolve(Assert.toString(out));
			}
		});
	}

	private async uploadFile(file:File,uploadUrl:string)
	{
		const formData = new FormData();

		formData.append('file',file);

		const ret = await fetch(uploadUrl,{method:'POST',body:formData});
		const body = await ret.json();
		if (body?.errorType!=null)
			throw new Error('Error on server');

//XXX    Could possibly move the image-related functions into this file
		return body;
	}

	private async transformImage(file:Blob,ratio:number|null,minWidth:number,minHeight:number):Promise<Blob|null>
	{
		/* Create an image node for Cropper.js: */
		const image = new Image();

		return await new Promise((resolve,reject) => {
			image.onload = async () => 
				resolve(await this.displayCropper(image,file.type,ratio,minWidth,minHeight));
			image.addEventListener('error', err => {
				alert('Invalid image file type');
				reject(new Error('Invalid image file type'));
			});
			image.src = URL.createObjectURL(file);
		});
	}

	private async displayCropper(image:HTMLImageElement,mimeType:string,ratio:number|null,minWidth:number,minHeight:number):Promise<Blob|null>
	{
		if (image.height < minHeight || image.width < minWidth) {
			alert('The image is too small');
			return await Promise.resolve(null);
		}

		/* Create the image editor overlay: */
		const editor = document.createElement('div');
		editor.id = 'bf-cropperOuter';
		Assert.htmlElement(document.getElementById('content')).style.height='600px';
		editor.appendChild(image);
		document.body.appendChild(editor);

		const bar = document.createElement('div');
		bar.id = 'bf-cropperBar';
		const confirmButton = document.createElement('button');
		confirmButton.textContent = 'Confirm';
		bar.appendChild(confirmButton);
		const message = document.createElement('div');
		message.id = 'bf-cropperMessage'
		message.innerText = 'Click and drag to crop image';
		bar.appendChild(message);
		editor.appendChild(bar);

		const cropper = new Cropper(image, <any>{
			viewMode: 1,
			autoCropArea: 0.9,
			guides: false,
			center: false,
			zoomable: false,
			imageSmoothingEnabled: true,
			imageSmoothingQuality: 'high',
//BUG the box moves when trying to shrink a minimally sized box
			crop: (event:any) => {
				const width = event.detail.width;
				const height = event.detail.height;

				if (width < minWidth || height < minHeight) {
					cropper.setData({
						width: Math.max(minWidth,width),
						height: Math.max(minHeight,height),
					});
				}
			}
		});
		if (ratio!=null)
			cropper.setAspectRatio(ratio);

		const me = this;
		return await new Promise((resolve,reject) => {
			confirmButton.addEventListener('click',async () => 
				resolve(await me.performCrop(editor,cropper,mimeType)));
		});
	}

	async performCrop(editor:HTMLElement,cropper:Cropper,mimeType:string):Promise<Blob|null>
	{
		const canvas = cropper.getCroppedCanvas();
		document.body.removeChild(editor);
		(<HTMLElement>document.getElementById('content')).style.height='initial';

		return await new Promise((resolve,reject) => 
			canvas.toBlob(blob => resolve(blob),mimeType,0.95)
		);
	}
}

