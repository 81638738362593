import {ITimesFunctions} from 'Common/TimesFunctions';

export class PastEventsQueryClause
{
	constructor(
		private times:ITimesFunctions,
		private field:string,
		private cutoffInPast: object
	) {}

	singleEndInPast()
	{
		const startOfToday = this.times.db(this.times.today());
		return ({ $or: [
			{$and: [
				{ [`${this.field}.allDay`]: {$ne:true} },
				{ [`${this.field}.endSingle`]: {$lt: this.times.db(this.times.now()) } },
			]},
			/*TODO use a schema to prevent this. This test is required for the moment though. */
			{$and: [
				/* NOTE: {$eq:null} matches undefined, not present, and null */
				{ [`${this.field}.endSingle`]: {$eq: null } },
				{ [`${this.field}.startSingle`]: {$lt: startOfToday } }
			]},
			{$and: [
				{ [`${this.field}.allDay`]: true },
				{ [`${this.field}.startSingle`]: {$lt: startOfToday } }
			]}
		]});
	}

	limitSinglesInPast()
	{
		const pastCutoff =this.times.db(this.times.today().minus(this.cutoffInPast)); 

		return ({ $or: [
			{$and: [
				{ [`${this.field}.allDay`]: {$ne:true} },
				{ [`${this.field}.endSingle`]: {$gte: pastCutoff} },
			]},
			/*TODO use a schema to prevent this. This test is required for the moment though. */
			{$and: [
				{ [`${this.field}.endSingle`]: {$eq: null } },
				{ [`${this.field}.startSingle`]: {$gte: pastCutoff } }
			]},
			{$and: [
				{ [`${this.field}.allDay`]: true },
				{ [`${this.field}.startSingle`]: {$gte: pastCutoff } }
			]}
		]});
	}

	pastSingles()
	{
		return ({$and: [
			{ [`${this.field}.frequency`]: 'once' } ,
			this.singleEndInPast(),
			this.limitSinglesInPast()
		]});
	}

	pastRepeats()
	{
		return ({$and: [
			{ [`${this.field}.frequency`]: {$ne:'once' } } ,
			/* Past events. Will include extra occasionally.  Also relies on endData==undefined returning false */
			{ [`${this.field}.endDate`]: {$lt: this.times.dbDate(this.times.today())} },
			/* Limit how old: */
			{ [`${this.field}.endDate`]: {$gte: this.times.dbDate(this.times.minus(this.times.today(),this.cutoffInPast)) } },
		]});
	}

	create()
	{
		return {'$or':[this.pastSingles(),this.pastRepeats()]};
	}
}

