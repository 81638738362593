import {HttpErrorMessage} from 'Browser/HttpErrorInterface';
import BuildError from 'Common/config/BuildError';

export class ConfigPageError extends BuildError
{
	constructor(
		readonly message:string,
		readonly page:string|null,
		readonly component:string|null
	)
	{ super(); }

	static type():string { return 'configPageError'; }

	public toString():string
	{
		let ret='Page configuration error:';
		let sep = '';
		let sep2 = '';

		if (this.page!=null) {
			sep = ',';
			sep2 = ':';
			ret += ` page '${this.page}'`;
		}

		if (this.component!=null) {
			ret += `${sep} component '${this.component}'`;
			sep2 = ':';
		}

		ret += `${sep2} ${this.message}`;
		return ret;
	}

	public toJson():HttpErrorMessage
	{
		return {httpCode:200,errorType:ConfigPageError.type(),component:this.component,page:this.page,message:this.message};
	}

	public static fromJson(data:any):any
	{
		return new ConfigPageError(data.message,data.page,data.component);
	}
}

