import {Home as FrontendHome} from 'Shared/view/frontend/Home2';
import {Menu as FrontendMenu} from 'Shared/view/frontend/Menu';
import {Menu as FrontendFood} from 'Shared/view/frontend/Food';
import {Menu as FrontendDrinks} from 'Shared/view/frontend/Drinks';
import {Event as FrontendEvent} from 'Shared/view/frontend/Event';
import {Events as FrontendEvents} from 'Shared/view/frontend/Events';
import {Hire as FrontendHire} from 'Shared/view/frontend/Hire';
import {HireEnquiry as FrontendHireEnquiry} from 'Shared/view/frontend/HireEnquiry';
import {Contact as FrontendContact} from 'Shared/view/frontend/Contact';
import {Reservations as FrontendReservations} from 'Shared/view/frontend/Reservations';
import {StaffResources as FrontendStaffResources} from 'Shared/view/frontend/StaffResources';
import {StaffResource as FrontendStaffResource} from 'Shared/view/frontend/StaffResource';
import {PageNotFound as FrontendPageNotFound} from 'Shared/view/frontend/PageNotFound';

import {Venue as BackendVenue} from 'Shared/view/backend/Venue';
import {Home as BackendHome} from 'Shared/view/backend/Home2';
import {ListMenu as BackendListMenu} from 'Shared/view/backend/ListMenu';
import {Menus as BackendMenus} from 'Shared/view/backend/Menus';
import {MenuSection as BackendMenuSection} from 'Shared/view/backend/MenuSection';
import {MenuDesigner as BackendMenuDesigner} from 'Shared/view/backend/MenuDesigner';
import {MenuItem as BackendMenuItem} from 'Shared/view/backend/MenuItem';
import {Specials as BackendSpecials} from 'Shared/view/backend/Specials';
import {Special as BackendSpecial} from 'Shared/view/backend/Special';
import {Events as BackendEvents} from 'Shared/view/backend/Events';
import {Event as BackendEvent} from 'Shared/view/backend/Event';
import {Hire as BackendHire} from 'Shared/view/backend/Hire';
import {Contact as BackendContact} from 'Shared/view/backend/Contact';
import {StaffResources as BackendStaffResources} from 'Shared/view/backend/StaffResources';
import {StaffResource as BackendStaffResource} from 'Shared/view/backend/StaffResource';
import {Settings as BackendSettings} from 'Shared/view/backend/Settings';
import {PageNotFound as BackendPageNotFound} from 'Shared/view/backend/PageNotFound';
import {IView} from 'Shared/view/IView';


export class VenueView implements IView
{
//FIXME use this in routes: need to share the same overrides!

//TODO cf using Shared/view|pages/FrontendHire.ts etc instead

	view() 
	{
		return <any> ({
			[FrontendHome.pageName]: FrontendHome,
			[FrontendMenu.pageName]: FrontendMenu,
			[FrontendFood.pageName]: FrontendFood,
			[FrontendDrinks.pageName]: FrontendDrinks,
			[FrontendEvent.pageName]: FrontendEvent,
			[FrontendEvents.pageName]: FrontendEvents,
			[FrontendHire.pageName]: FrontendHire,
			[FrontendContact.pageName]: FrontendContact,
			[FrontendReservations.pageName]: FrontendReservations,
			[FrontendHireEnquiry.pageName]: FrontendHireEnquiry,
			[FrontendPageNotFound.pageName]: FrontendPageNotFound,
			[FrontendStaffResource.pageName]: FrontendStaffResource,
			[FrontendStaffResources.pageName]: FrontendStaffResources,

			[BackendVenue.pageName]:		BackendVenue,
			[BackendHome.pageName]:			BackendHome,
			[BackendListMenu.pageName]:		BackendListMenu,
			[BackendMenus.pageName]:		BackendMenus,  //XXX are Solid ones really required here - image uploader probably needs
			[BackendMenuSection.pageName]:	BackendMenuSection,
			[BackendMenuDesigner.pageName]:	BackendMenuDesigner,
			[BackendMenuItem.pageName]:		BackendMenuItem ,
			[BackendSpecials.pageName]:		BackendSpecials,
			[BackendSpecial.pageName]:		BackendSpecial,
			[BackendEvents.pageName]: BackendEvents,
			[BackendEvent.pageName]: BackendEvent,
			[BackendHire.pageName]: BackendHire,
			[BackendContact.pageName]: BackendContact,
			[BackendStaffResources.pageName]: BackendStaffResources,
			[BackendStaffResource.pageName]: BackendStaffResource,
			[BackendSettings.pageName]: BackendSettings,
			[BackendPageNotFound.pageName]: BackendPageNotFound,
		});
	}
}

