import {Component} from 'Common/components/Component';
import {UpdateMessage} from 'Common/Messages';
import {IPage} from 'Common/pages/IPage';
import {IConnectionToServer} from 'Common/IConnectionToServer';
import { Document,IPageData } from 'Common/PageConfig';
import { EditComponent as EditComponentDef, Location } from 'Common/config/PageConfigTypes';
import {InvalidFieldValueError} from 'Browser/InvalidFieldValueError';
import {Id} from 'Common/Id';


/* A view onto a data subdocument that allows it to be editted in a form. */
export class EditComponent<PD extends IPageData,Doc extends Document> extends Component
{
	constructor(name:string,
		readonly page:IPage<PD>,
		readonly server:IConnectionToServer,
		//XXX using NestedArrayLocation instead of Location:any may be a possibility
		readonly def: EditComponentDef<Doc>
	)
	{
		super(name);
		this.updateField = this.updateField.bind(this);
	}

	static readonly Id = 'edit2'; //TODO rename to 'edit' AFTER removing NjkEditComponent

	componentType() { return EditComponent.Id; }

	updateField(docId:Id,location:Location,fieldName:string,value:any)
	{
		try {
			const fields = {[fieldName]:value};

			const message = new UpdateMessage(this.page.name(),this.name,docId,location,fields);

			this.server.sendOperationOptimistically(message);
		}
		catch(err) {
			if (err instanceof InvalidFieldValueError)
				alert(err.message);
			else
				throw err;
		}
	}
}

export function id(component:string,location:Location,field:string)
{
	return `${component}-`+JSON.stringify(location)+`-${field}`;
}

