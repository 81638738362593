import {Component} from 'Common/components/Component';
import {ShowcaseOverlayComponent as Config} from 'Common/config/PageConfigTypes';

export class ShowcaseOverlayComponent extends Component
{
	constructor(
		instanceName:string,
		private config:Config,
		private localData: {[key:number]:number|undefined}
	)
	{
		super(instanceName);
		this.toggle = this.toggle.bind(this);
		this.hide = this.hide.bind(this);
	}

	static Id = 'showcaseOverlay';

    public componentType():string { return ShowcaseOverlayComponent.Id; }

	async load()
	{
		return {};
	}

	public toggle(widgetIndex:number,index:number)
	{
		if (this.localData?.[widgetIndex] == index)
			delete this.localData[widgetIndex];
		else
			this.localData[widgetIndex] = index;
	}

	public hide()
	{
		for (const key of Object.keys(this.localData))
			delete this.localData[key];
	}
}
