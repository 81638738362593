/* 
	Can set a #hex color or the name of color if it's defined in iconsAsMacros.njk
	'default' colors should be set to the most logical and commonly used color for the icon 
	(eg 'red' warning icons),
	or write textColour with no brackets '' if you want to match the theme's textColour.
*/

import {ISvgColours, IconColour} from 'Shared/backend/fonts/icons/IconColour';

export function FacebookCircle(props:ISvgColours)	
{
//XXX cf iconColour could be an enum or set of constants


	return (
		<svg id='facebook_circle' class='icon' 
			style={`fill:${props.fill ?? IconColour.textColour}; stroke:${props.stroke ?? '#fff0' };`} 
			viewBox="0 0 64 64" enable-background="new 0 0 64 64" xmlns="http://www.w3.org/2000/svg">
		  <defs></defs>
		  <path class='foregroundColour' d="M 62 31.831 C 62 14.961 48.573 2 32 2 C 15.427 2 2 14.961 2 31.831 C 2 47.073 12.971 59.707 27.313 62 L 27.313 40.658 L 19.693 40.658 L 19.693 31.831 L 27.313 31.831 L 27.313 25.102 C 27.313 17.45 31.788 13.223 38.644 13.223 C 41.928 13.223 45.36 13.819 45.36 13.819 L 45.36 21.329 L 41.576 21.329 C 37.851 21.329 36.688 23.685 36.688 26.099 L 36.688 31.831 L 45.008 31.831 L 43.677 40.658 L 36.688 40.658 L 36.688 62 C 51.029 59.707 62 47.073 62 31.831 Z" bx:origin="-0.033333 -0.033536"></path>
		</svg>
	);
}
