import {SelectorWidget} from 'Browser/widgets/SelectorWidget';
import Assert from 'Common/Assert';

export class StickySubNavWidget extends SelectorWidget
{
    constructor()
    {
        super(['.stickySubNav']);

		this.scroll = this.scroll.bind(this);
		this.scrollLeft = this.scrollLeft.bind(this);
		this.scrollRight = this.scrollRight.bind(this);
    }

	static key() { return 'stickySubNav'; }

	initInstance(anchorNode:HTMLElement)
    {
        super.initInstance(anchorNode);

		this.updateDisplayOfArrows(anchorNode);

		window.addEventListener('resize', () => {
			window.requestAnimationFrame(() => this.updateDisplayOfArrows(anchorNode))
		});
    }

	/* 
		Note this is automatically triggered when scrollLeft and scrollRight are called,
		as well as in response to the user dragging the scrollbar.
	 */
	scroll(node:HTMLElement)
	{
		const root = Assert.have(node.closest(this.selectors.join(',')));
		const scrollPane = Assert.have(root.querySelector('.stickyItemsScroll'));

		/* Note most browsers support the 'scrollend' event, which could be used instead */
		const lastPosition = scrollPane.scrollLeft;
		setTimeout(() => {
			if (scrollPane.scrollLeft == lastPosition)
				this.updateDisplayOfArrows(node);
		},100);
	}

	scrollLeft(node:HTMLElement)
	{
		const root = Assert.have(node.closest(this.selectors.join(',')));
		const scrollPane = Assert.have(root.querySelector('.stickyItemsScroll'));
		Assert.htmlElement(root.querySelector('.leftArrow')).style.display = 'none';

		/* Think the browser trims these... */
		/* The 0.85 ensures a bit of overlap when paging left or right */

		scrollPane.scrollBy({left: -scrollPane.clientWidth * 0.85, behavior: 'smooth'});
		/* The scroll event will be called by the event handler */
	}

	scrollRight(node:HTMLElement)
	{
		const root = Assert.have(node.closest(this.selectors.join(',')));
		const scrollPane = Assert.have(root.querySelector('.stickyItemsScroll'));
		Assert.htmlElement(root.querySelector('.rightArrow')).style.display = 'none';

		scrollPane.scrollBy({left: scrollPane.clientWidth * 0.85,behavior: 'smooth'});
	}

	private updateDisplayOfArrows(node:HTMLElement)
	{
		/* 
			Don't bother showing arrows if real close to the edge. Mobiles seem to struggle
			with exactly edge. cf having scroll 'click' into next tag.
		 */
		const cutoff = 3;

		const root = Assert.have(node.closest(this.selectors.join(',')));
		const scrollPane = Assert.have(root.querySelector('.stickyItemsScroll'));

		const leftDisplay = scrollPane.scrollLeft > cutoff ? 'block' : 'none';
		Assert.htmlElement(root.querySelector('.leftArrow')).style.display = leftDisplay;

		const rightDisplay = scrollPane.scrollLeft >= scrollPane.scrollWidth - scrollPane.clientWidth - cutoff ? 'none' : 'block';
		Assert.htmlElement(root.querySelector('.rightArrow')).style.display = rightDisplay;
	}
}

