import {SelectorWidget} from 'Browser/widgets/SelectorWidget';

export class ParallaxWidget extends SelectorWidget
{
	private initialised = false;

	private scrollRates:number[] = [];
	private foregrounds:HTMLElement[] = [];
	private backgrounds:HTMLElement[] = [];
	private elementTops:number[] = [];
	private elementHeights:number[] = [];

	static key():string { return 'parallax'; }

	initInstance(anchorNode:HTMLElement):void
    {
        super.initInstance(anchorNode);

//FIXME inconsistent selector handling
		const background = <HTMLElement>anchorNode.querySelector('.parallaxWidget-background');

		this.foregrounds.push(anchorNode);
		this.backgrounds.push(background);
		this.scrollRates.push(Number(anchorNode.dataset.scrollRate));

		/* Note "image" is of the format: "url('../dinge.jpg')" */
		(<any>background.style.backgroundImage) = anchorNode.dataset.image;

		if (!this.initialised) {
			this.initialised = true;

			window.addEventListener('scroll', e => {
				window.requestAnimationFrame(() => this.afterScroll());
			});	
			window.addEventListener('resize', e => {
				window.requestAnimationFrame(() => { 
					this.findHeightsAndPositions();
					this.afterScroll(); 
				});
			});

			/* For some reason calling findHeightsAndPositions() immediately doesn't work.  */
			window.requestAnimationFrame(() => {
				this.findHeightsAndPositions();
				this.afterScroll();
			});
		}
	}

	beforeUpdate(fromNode:HTMLElement,toNode:HTMLElement):boolean 
	{
		return !fromNode?.classList.contains('parallaxWidget-background');
	}

//FIXME Probably should have this in JsPageWidget... is it going to cause any duplications?
//      Have other widgets (eg CalendarEntryWidget) overridden things?
// ... Initial load is calling twice.
// NB this is being called from BrowserPageMixin.postFirstDisplayInit()
	afterDisplay(node:HTMLElement) 
	{
this.initInstance(node);
	}

	findHeightsAndPositions():void
	{
		/*
			Note that the viewport height changes on mobiles as location bars and buttons are
			hidden so I can't store the height here. 
				document.documentElement.clientHeight gives the initial height
				window.innerHeight gives the current height
		 */

		for (let i=this.foregrounds.length-1; i>=0; i--)
		{
			const rect = this.foregrounds[i].getBoundingClientRect();

			this.elementTops[i] = rect.top + window.pageYOffset;
			this.elementHeights[i] = rect.height;
		}
	}

	afterScroll():void
	{
		for (let i=this.backgrounds.length-1; i>=0; i--)
			this.adjustBackground(i,window.pageYOffset);
	}

	adjustBackground(i:number,scrollPos:number):void
	{
		const background = this.backgrounds[i];
		const topInViewport = this.elementTops[i] - scrollPos;

//XXX this appears to suffer the same problem on iphone:
//const topInViewport = this.foregrounds[i].getBoundingClientRect().top;

		const viewportHeight = window.innerHeight;
		let offset = '-100%';

		if (topInViewport + viewportHeight < 0)
			/* Has it scrolled off the top? */
			offset = '-100%';
		else if (viewportHeight - topInViewport > 0)
		{
			/* Add extra height to the background image when images < 100vh */
			const elementHeight = this.elementHeights[i];
			const scrollRate = this.scrollRates[i];

			const extraHeight = (viewportHeight - elementHeight) * scrollRate;
			if (extraHeight>=1)
			{
				const newHeight = elementHeight + extraHeight;
				background.style.height = newHeight+'px';
//TODO check this is not inadvertently being called when not needed
			}

			/* Reduce the scroll of the background: */
			const fractionUpPage = topInViewport / elementHeight; 
			offset = (-fractionUpPage * viewportHeight * scrollRate) + 'px';
		}

		background.style.transform = 'translateY('+offset+')';
	}
}

