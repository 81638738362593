import {PageFunctions} from 'Browser/PageFunctions';
import {VenueUrls} from 'Common/VenueUrls';
import {  DocumentQueries, OverlayComponent } from 'Common/config/PageConfigTypes';
import {venueDoc} from 'Shared/model/venue';
import { z } from 'zod';

export const footerParams = z.object({}).strict();	
export type FooterParams = z.infer<typeof footerParams>;


export const footerData = z.object({
	params: footerParams,
	venue: venueDoc.extend({
		vosLogo: z.string().optional()
	})
});
export type FooterData = z.infer<typeof footerData>;


export function footerDocuments():DocumentQueries
{
	return ({
/* This data is now retrieved for all venue sites and added in VenuePageConfig from VenueBasics
		venue: {
			type: 'object',
			collection:'venue'
		}
*/		
	});
}

export function footerComponents()
{
	return ({
		navMore: <OverlayComponent>{
			type: 'overlay',
			panes: {
				navMore: false
			}
		}
	});
}

export function footerDisplay(data:FooterData,urls:VenueUrls)
{
	const funcs = new PageFunctions();

	if (data.venue.logo != undefined)
		data.venue.logo.url = funcs.createImageUrl(urls,data.venue.logo,'Venue-logo','full');

	if (data.venue.photo != undefined)
		data.venue.photo.url = funcs.createImageUrl(urls,data.venue.photo,'Venue-photo','full');

	data.venue.vosLogo = urls.resourceUrl('VOS-website-by.png');
}
