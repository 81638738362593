import {Phone} from 'Shared/frontend/fonts/icons/Phone.svg';
import {Pin} from 'Shared/frontend/fonts/icons/Pin.svg';
import {SocialIcons} from 'Shared/frontend/footer/SocialIcons';
import {Show} from 'solid-js';
import { VenueDoc } from 'Shared/model/venue';

interface IBaseFooter {
	venue: VenueDoc;
}

export function BaseFooter(props: IBaseFooter)
{
	return (
		<footer>
			<h4>{props.venue.name}</h4>

			<div class='footerInner'>
				<div class='footerLeft'>
					<a class='phoneLink' href={`tel:${props.venue.phoneNumber}`}>
						<div class='leadingIcon'>
							<Phone />
						</div>
						{props.venue.phoneNumber}
					</a>

					<a class='addressLink' href={props.venue.mapUrl} target='_blank'>
						<div class='leadingIcon'>
							<Pin />
						</div>
						<address>
							{props.venue.streetAddress}<br/>
							{props.venue.suburb+' '}
							{displayState(props.venue.state)+' '}
							{props.venue.postcode}
						</address>
					</a>

					<Show when={props.venue.postalAddress}>
						<p>Postal Address: {props.venue.postalAddress}</p>
					</Show>

					<SocialIcons venue={props.venue} />
				</div>

				<div class='footerRight'>
					<a class='vosLogo' target='_blank' href='https://ontoitmedia.com/vos'>
						<img src={props.venue.vosLogo} alt='Website by VOS' />
					</a>
				</div>
			</div>
		</footer>
	);
}

export function displayState(state:string)
{
	const states = {act:'ACT',nsw:'NSW',nt:'NT',qld:'Qld',sa:'SA',tas:'Tas',vic:'Vic',wa:'WA'};
	return states[state];
}
