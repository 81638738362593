
export class AssertError extends Error
{
	constructor(message?:string)
	{
		let msg = 'Assertion check failed'
		if (message!=null)
			msg += ': '+message;
		super(msg);
	}
}

export class Assert
{
	public static check(condition:boolean,message?:string): asserts condition
	{
		if (!condition) 
			throw new AssertError(message);
	}

	/* The to*() functions asset not null and not undefined */
	public static have<T>(value:T): NonNullable<T>
	{
		this.exists<T>(value);
		return value;
	}

	public static exists<T>(obj: T): asserts obj is NonNullable<T>
	{
		this.check(obj!=null,'Object does not exist');
	}

//XXX MAKE Assert global?

	public static property<T,P>(property:string,obj:T): T & P
	{
		if (typeof obj != 'object' || obj==null) 
			throw new AssertError(`obj is not an ojbject`);
		if (!(property in obj))
			throw new AssertError(`Missing property '${property}'`);
		return <T & P>obj;
	}

	/* 
		A full check would include the property types, not just the property name,
		SO ITS ONLY A PARTIAL TYPE CHECK.
		This would require creating a proper JS object though to perform the runtime check (yuck).
	 */
	public static hasProperty<T,P>(property:string,obj:T): asserts obj is T & P
	{
		if (typeof obj != 'object' || obj==null) 
			throw new AssertError(`obj is not an ojbject`);
		if (!(property in obj))
			throw new AssertError(`Missing property '${property}'`);
	}

	/* 
		Casts to a child class.
		The target child class CANNOT BE ABSTRACT, unfortunately.
		Abstract classes are Typescript types, unlike Javascript classes which are (constructor) functions.
		Also cannot be used to cast to interfaces and other typescript-specific types.

		SOME ALTERNATIVES for when this doesn't work:
		1. Use one of the property-based asserts in this file instead (note: only a partial type check though).
		2. Create a proper Javascript class
		3. Use an ordinary cast - e.g. <blah>, possibly in conjunctions with Assert.check()

		SOME PROSPECTIVE ALTERNATIVES:
		4. Implement  castWithCheck<TARGET_TYPE>(condition,source) 
			But if are any generally-useful conditions we should probably create dedicated asserts.
			For odd cases an ordinary cast + an Assert.check() is probably simplest.
		5. nocheck<TARGETTYPE>(source)
			This is basically just a cast, but probably worse than a regular cast as some of the
			type checking Typescript does during casts is hidden.
	 */
	public static child<U,V extends U>(target:{new(...args:any[]):V},source:U):V
	{
		if (!(source instanceof target)) {
			let message = `The value is not a child of the type '${target.name}'.`;
			if (source==null)
				message += ` The value was null or undefined.`;
			else if (typeof source == 'object') {
				//Assert.hasProperty('constructor',source);
				message += ` The value is of class '${(<any>source)?.constructor.name}'`;
			}
			else
				message += ` The value is not an object - it has typeof: `+(typeof source);
			throw new AssertError(message);
		}

		return <V>source;
	}

	public static htmlElement(value:Element|EventTarget|undefined|null): HTMLElement
	{
		if (value instanceof HTMLElement)
			return value;
		throw new AssertError('Not an HTMLElement');
	}

	public static htmlInputElement(value:Element|undefined|null): HTMLInputElement
	{
		if (value instanceof HTMLInputElement)
			return value;
		throw new AssertError('Not an HTMLInputElement');
	}

	public static htmlFormElement(value:Element|undefined|null): HTMLFormElement
	{
		if (value instanceof HTMLFormElement)
			return value;
		throw new AssertError('Not an HTMLFormElement');
	}

	public static htmlButtonElement(value:Element|undefined|null): HTMLButtonElement
	{
		if (value instanceof HTMLButtonElement)
			return value;
		throw new AssertError('Not a HTMLButtonElement');
	}

	public static htmlSelectElement(value:Element|undefined|null): HTMLSelectElement
	{
		if (value instanceof HTMLSelectElement)
			return value;
		throw new AssertError('Not a HTMLSelectElement');
	}

	public static htmlImageElement(value:Element|undefined|null): HTMLImageElement
	{
		if (value instanceof HTMLImageElement)
			return value;
		throw new AssertError('Not a HTMLImageElement');
	}

	public static event(value:Element|undefined|null): Event
	{
		if (value instanceof Event)
			return value;
		throw new AssertError('Not an Event');
	}

	public static target(value:Element|undefined|null): EventTarget
	{
		if (value instanceof EventTarget)
			return value;
		throw new AssertError('Not an EventTarget');
	}

	public static toString(value:any):string
	{
		if (typeof value !== 'string') 
			throw new AssertError('Not a string');
		return value;
	}

	public static toNumber(value:any):number
	{
		if (typeof value !== 'number') 
			throw new AssertError('Not a number');
		return value;
	}
}

export default Assert;
