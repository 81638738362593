import {INav} from 'Common/config/INav';

//TODO reckon I could import the svgs here and possibly even bundle them (if not too big)

//TODO reuse most or all of Shared/config/Nav here... 

export const Nav:INav = {
/*TODO should use link.json to get links I guess */
/* Events was What's On, Menus was Menu  */

	desktop: [
		{label:'Home',		link:'frontend/home'},
		{label:'Menu',		link:'frontend/menu'},
		{label:'Events',	link:'frontend/events'},
		/* deactivated theDock
		{label:'Hire',		link:'frontend/hire'},
		{label:'Club',		link:'frontend/members'},
		*/
		{label:'Contact',	link:'frontend/contact'}
	],
	mobile: {
		maxButtons: 5,
		includeMoreButton: true,
		links: [
			{label:'Home',		link:'frontend/home',		icon:'home.svg.njk'},
			{label:'Menu',		link:'frontend/menu',		icon:'foodMenu.svg.njk'},
			{label:'Events',	link:'frontend/events',		icon:'calendar.svg.njk'},
			{label:'Contact',	link:'frontend/contact',	icon:'contact.svg.njk'},
			/* deactivated theDock
			{label:'Hire',		link:'frontend/hire', 		icon:'contact.svg.njk'},
			{label:'Club',		link:'frontend/members', 	icon:'contact.svg.njk'},
			*/
		]
	}
};
