import {css} from '@emotion/css';

export function outerPage()
{
	return css({
		margin: '1em auto',
		maxWidth: 700,
		border: '1px solid #ddd',
		padding: '2em 2em',

		'nav': {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
			marginBottom: '3em',
		}
	});
}

