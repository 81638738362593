
import { Show, For, Match, Switch } from 'solid-js';
import { CleanTime, date } from 'Shared/frontend/Macros';
import { DateTime } from 'luxon';

export function weekday(day) 
{
	const dayMap = {
		mon:'Monday', 
		tue:'Tuesday', 
		wed:'Wednesday', 
		thu:'Thursday', 
		fri:'Friday', 
		sat:'Saturday', 
		sun:'Sunday'
	};

	return dayMap[day];
}

export function DaysOfWeeklyActivity(days)
{
	return <For each={days}>{day => weekday(day) + 's '}</For>;
}

export function DateTitle(props)
{
	return (
		<Switch>
			<Match when={props.times.frequency == 'once'}>
				<Show when={props.times.startSingle}>
					{date(props.times.startSingle,'EEE, dd LLL')}
					{' '}
					<CleanTime time={props.times.startSingle} />
				</Show>
			</Match>
			<Match when={props.times.frequency == 'daily'}>
				Daily
				<Show when={props.times.startTime}>
					{' '}
					<CleanTime time={props.times.startTime} />
				</Show>
			</Match>
			<Match when={props.times.frequency == 'weekly'}>
				{DaysOfWeeklyActivity(props.times.weekdays)}
				{' '}
				{props.days}
				<Show when={props.times.startTime}>
					{' '}
					<CleanTime time={props.times.startTime} />
				</Show>
			</Match>
			<Match when={props.times.frequency == 'monthly'}>
				{props.times.week}
				{' '}
				{weekday(props.times.weekday)} of month,
				<Show when={props.times.startTime}>
					{' '}
					<CleanTime time={props.times.startTime} />
				</Show>
			</Match>
		</Switch>
	);
}


export function DateTitleWithEndTimes(props)
{
	return (
		<Switch>
			<Match when={props.frequency == 'once'}>
				<Show when={props.startSingle}>
					{ DateTime.fromISO(props.startSingle).toFormat('EEE, dd LLL') }
				</Show>
				<Show when={props.startSingle}>
					{' '}
					<CleanTime time={props.startSingle}/>
				</Show>
				<Show when={props.endSingle} fallback=' until close'>
					{' '}
					until <CleanTime time={props.endSingle}/>
				</Show>
			</Match>
			<Match when={props.frequency == 'daily'}>
				Every Day
				<Show when={props.startTime && !props.hideTimesForRepeated}>
					{' '}
					<CleanTime time={props.startTime}/>
				</Show>
			</Match>
			<Match when={props.frequency == 'weekly'}>
				<DaysOfWeeklyActivity {...props.weekdays} />
				{' '}
				{props.days}
				<Show when={props.startTime && !props.hideTimesForRepeated}>
					{' '}
					<CleanTime time={props.startTime}/>
				</Show>

				<Show when={props.endTime && !props.hideTimesForRepeated} fallback=' until close'>
					{' '}
					until <CleanTime time={props.endTime}/>
				</Show>
			</Match>
			<Match when={props.frequency == 'monthly'}>
				{props.week}
				{' '}
				{weekday(props.weekday)} of month,

				<Show when={props.startTime && !props.hideTimesForRepeated}>
					{' '}
					<CleanTime time={props.startTime}/>
				</Show>

				<Show when={props.endSingle} fallback=' until close'>
					{' '}
					until <CleanTime time={props.endSingle}/>
				</Show>
			</Match>
		</Switch>
	);
}

