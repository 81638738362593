import { z } from 'zod';
import * as t from 'Shared/model/basic';

//slider if used outside of vertical sections
export const slider = z.object({
	caption: z.string(),
	url: t.url
});

//used for slider inside vertical sections/showcase
export const carouselItem = z.object({
	image: t.image,
	imageCaption: z.string(),
	itemTeaser: z.string(),
	itemTitle: z.string(),
	itemTagline: z.string(),
	addItemDetails:z.boolean(),
	itemDetails: z.string(),
	addItemButton:z.boolean(),
	buttonLink: t.url,
	linkTarget_self:z.boolean(),
	buttonText: z.string(),
});


export const showcase = z.object({
	sectionInternalName: z.string(),
	layout: z.enum(['carousel','noImage','imageRight','imageLeft','imageAbove','imageBelow','imageAsBackground','gallery','slideshow','carouselAllEvents','carouselMusic','carouselNonMusic','carouselSpecials','parallax']),
	stickyButtons:z.boolean(),
	teaser: z.string(),
	title: z.string(),
	tagline: z.string(),
	addDetails:z.boolean(),
	details: z.string(),
	image: t.image,
	logo: t.image.optional(), // Used in parallax
	addButton:z.boolean(),
	link: t.url, //link is for button
	target_self:z.boolean(), //target is for button
	buttonText: z.string(),
	carouselToScroll:z.number(),
	items: z.array(carouselItem)
});


export const home = z.object({
	_id: t.id,
	siteId: t.id,
	banner: z.string(),
	featuredProductImage: t.image,
	slider: z.array(showcase),
	eventCarouselSize: z.number(),
	showcase: z.array(showcase),
})
.partial().required({
	_id: true,
	siteId: true,
	slider: true,
	showcase: true
});

export type Home = z.infer<typeof home>;

