import {theme} from 'Shared/artists/Theme';
import {forms} from 'Shared/artists/Forms';
import {css, injectGlobal} from '@emotion/css';
import {LoginBanner} from 'Shared/server/LoginBanner';
import {AuthenticationForm, RegistrationForm} from 'Shared/users/AuthenticationForm';

const style = () => css({
	background: 'white',

	'.vosLogo': {
		display: 'block',
		width: '5em',
		margin: '0 auto'
	}
});

//TODO share LoginBanner with the rgistration message i guess...?


export function LoginPage(props)
{
	injectGlobal([theme,forms]);

	return (
		<div class={style()}>
			<LoginBanner />

			<AuthenticationForm {...props} />
		</div>
	);
}

export function LoginOrRegisterPage(props)
{
	injectGlobal([theme,forms]);

	return (
		<div class={style()}>
			<LoginBanner/>

			<AuthenticationForm {...props} />

			<RegistrationForm {...props} />
		</div>
	);
}
