import { z } from 'zod';
import * as t from 'Shared/model/basic';

export const role = z.object({
	type: z.string(),
	siteId: t.id,		//TODO siteId and artistId should be either/or
	artistId: t.id
});

export const roles = z.array(role);

export const personDoc = z.object({
	_id: t.id,
	firstName: z.string(),
	lastName: z.string(),
	email: z.string(),
	hash: z.string(),
	hashUse: t.dateTime,
	lastSuccessfulLogin: t.dateTime,
	lastSuccessfulIp: z.string(),
	lastThrottleCount: z.number(),
	roles: roles,
	googleConnected: z.boolean(),
	facebookConnected: z.boolean(),
})
.required({
	_id: true,
});

export type PersonDoc = z.infer<typeof personDoc>;

