import {CreateArrayItemMessage2, MoveMessage} from 'Common/Messages';
import {locateSubDocument} from 'Common/ViewUtils';
import {Location} from 'Common/config/PageConfigTypes';
import {PageProvider, usePage} from 'Shared/artists/PageProvider';
import {BackendWrap} from 'Shared/backend/BackendWrap';
import {LinkOpen} from 'Shared/backend/fonts/icons/LinkOpen.svg';
import {SectionIds, SectionItem, itemStyle} from 'Shared/backend/menu/SectionItem';
import {createUrl } from 'Shared/backend/menu/SectionPage';
import { DragDropProvider, DragDropSensors, SortableProvider, DragEventHandler, createDroppable, Draggable, Droppable} from '@thisbeyond/solid-dnd';
import {For, Match, Switch, batch, createMemo} from 'solid-js';
import {createSignal} from 'solid-js';
import {SetStoreFunction, createStore} from 'solid-js/store';
import {Id} from 'Common/Id';
import {MenuSection, SectionData} from 'Shared/view/backend/MenuSection';
import {menuSelector} from 'Shared/frontend/menuPage/MenuPage';
import {Show} from 'solid-js';
import AjaxConnectionToServer from 'Browser/AjaxConnectionToServer';
import {MenuData} from 'Shared/view/backend/Menu';
import { css, injectGlobal } from '@emotion/css';
import { MenuItemsHeader } from 'Shared/backend/menu/MenuItemsHeader';
import { MenuDoc } from 'Shared/model/Menu';
import { theme } from 'Shared/backend/theme';

const sectionHeaderStyle = () => css({
	display: 'flex',
	padding: '6px 10px',
	alignItems: 'baseline',
	backgroundColor: '#555',
	color: 'white',
	fontSize: 20,
	margin: '5px 0',
	a: {
		paddingRight: 10,
		color: 'white'
	}
});


export function ListMenuPage(props:SectionData)
{
	injectGlobal([theme]);
// FIXME breadcrumbs ==> x-breadcrumbs #}
//TODO	<breadcrumbs>{{ crumbs.course(true,breadcrumbs.urls,params.course|capitalize) }}</breadcrumbs> 

	let location = [] as Location;
	for (const s of props.params.sections ?? []) 
		location = [...location,'sections',s];

	/* Add reordering IDs to containers and items */
	props.menu.reorderingId = 's0';
	addIndexesAndCollapsed(/*mod*/props.menu,1,1);

	const [menuStore,setMenu] = createStore(props.menu);
	const [startDragLocation,setStartDragLocation] = createSignal();

	const onDragStart:DragEventHandler = e => {
		setStartDragLocation(itemLocation(menuStore,e.draggable.id));
	};

	const frontendUrl = `/${menuStore.slug}#`+ menuSelector(menuStore,location);

//	<DragDropDebugger />

	return (
		<PageProvider page={props.page}>
			<BackendWrap>
				<div class='headingBar'>
					<h2>{props.name?.toUpperCase()}
						<a href={frontendUrl} class='linkInline' target='_blank'>
							<LinkOpen/>
						</a>
					</h2>
				</div>

				<h1>{props.menu.title} (single list)</h1>

				<MenuItemsHeader />

				<DragDropProvider 
					onDragStart={onDragStart} 
					onDragOver={({draggable,droppable}) => dragOver(menuStore,setMenu,draggable,droppable)} 
					onDragEnd={({draggable,droppable}) => endDrag(props.page,menuStore,setMenu,startDragLocation(),draggable,droppable)} 
				>
					<DragDropSensors />

					<Section params={props.params} menu={menuStore} setMenu={setMenu} location={[]} sectionIds={[]} />

					<Show when={menuStore.sections}>
						<Sections params={props.params} menu={menuStore} setMenu={setMenu} location={['sections']} sectionIds={[]} />
					</Show>
				</DragDropProvider>
			</BackendWrap>
		</PageProvider>
	);
}

/* Called when dragged over another item OR into another container. */
function dragOver(menuStore:MenuDoc,setMenu:SetStoreFunction<MenuDoc>,draggable:Draggable,droppable:Droppable) 
{
	if (!droppable)
		return;

	const sourceSectionId = containerOrItemsContainer(menuStore,draggable.id).reorderingId;
	const targetSectionId = containerOrItemsContainer(menuStore,droppable.id).reorderingId;

	if (sourceSectionId != targetSectionId)
		changeSection(menuStore,setMenu,targetSectionId,draggable,droppable) 
}

function changeSection(menuStore:MenuDoc,setMenu:SetStoreFunction<MenuDoc>,targetSectionId:string,draggable:Draggable,droppable:Droppable) 
{
	const targetItemIds = locateSubDocument(menuStore,locationOfContainer(menuStore,droppable.id)).collapsed ? 
		[] :
		(containerOrItemsContainer(menuStore,targetSectionId).items ?? []).map(i => i.reorderingId);

	let targetIndex = targetItemIds.indexOf(droppable.id);
	if (targetIndex == -1) 
		targetIndex = targetItemIds.length - 1;

	const sourceContainer = locationOfContainer(menuStore,draggable.id);
	const targetContainer = locationOfContainer(menuStore,droppable.id);

	//XXX would be nice to allow ESC to abort a move. Would need to use setMenu and startDragLocation to undo the setMenu()s below...
	batch(() => {
		setMenu(...sourceContainer,'items',items => items.filter(item => item.reorderingId != draggable.id));

		if (locateSubDocument(menuStore,[...targetContainer,'items'])==undefined)
			setMenu(...targetContainer,'items',[]);

		setMenu(...targetContainer,'items', items => [...items.slice(0,targetIndex), draggable.data, ...items.slice(targetIndex)]);
	});
}

function endDrag(page,menuStore:MenuDoc,setMenu:SetStoreFunction<MenuDoc>,startDragLocation:Location,draggable:Draggable,droppable:Droppable)
{	
	const targetSectionId = containerOrItemsContainer(menuStore,droppable.id).reorderingId;
	const targetItemIds = (containerOrItemsContainer(menuStore,targetSectionId).items ?? []).map(i => i.reorderingId);

	const targetIndex = targetItemIds.indexOf(droppable.id);
	const sourceContainer = locationOfContainer(menuStore,draggable.id);
	const targetContainer = locationOfContainer(menuStore,droppable.id);

	batch(() => {
		setMenu(...sourceContainer,'items', items => items.filter(item => item.reorderingId != draggable.id));
		setMenu(...targetContainer,'items', items => [ ...items.slice(0,targetIndex), draggable.data, ...items.slice(targetIndex) ]);
	});

	/* Update the database: */
	moveInDb(page.server,menuStore._id,startDragLocation,[...targetContainer,'items',targetIndex]);
}


/* 'draggable' can loop up either an item OR a section. This will look up either. */
function addIndexesAndCollapsed(/*mod*/menuOrSection,numItems:number,numContainers:number): {numItems:number,numContainers:number}
{
	for (const item of menuOrSection.items ?? []) {
		const id = 'i' + (numItems++);
		item.reorderingId = id;
	}

	/* Ass sectionIds for sections and subsections: */
	let i = 0;
	for (const section of menuOrSection.sections ?? []) {
		const id = (section.widget==undefined ? 's' : 'w') + (numContainers++);
		section.reorderingId = id;
		section.collapsed = false;

		({numItems,numContainers} = addIndexesAndCollapsed(section,numItems,numContainers));
	}
	return {numItems:numItems,numContainers:numContainers};
}

function itemLocation(menuOrSection,id:string)
{
	for (let i=0; i < (menuOrSection.items?.length ?? 0); i++) 
		if (menuOrSection.items[i].reorderingId == id)
			return ['items',i];

	for (let i=0; i < (menuOrSection.sections?.length ?? 0); i++) {
		const location = itemLocation(menuOrSection.sections[i],id);
		if (location != null)
			return ['sections',i,...location];
	}

	return null;
};

function containerOrItemsContainer(menuOrSection,id:string)
{
	if (menuOrSection.reorderingId == id)
		return menuOrSection;

	for (const item of menuOrSection.items ?? []) 
		if (item.reorderingId == id)
			return menuOrSection;

	for (const section of menuOrSection.sections ?? [])  {
		if (section.reorderingId == id)
			return section;

		const subsection = containerOrItemsContainer(section,id);
		if (subsection != null)
			return subsection;
	}
	return null;
};

/* 
	If id belongs to a container, return the location of that container.
	If id belongs to an item, return the location of the item's container.
*/
function locationOfContainer(menuOrSection,id:string)
{

	if (menuOrSection.reorderingId == id)
		return [];

	for (let i=0; i < (menuOrSection.items?.length ?? 0); i++) 
		if (menuOrSection.items[i].reorderingId == id)
			return [];

	for (let i=0; i < (menuOrSection.sections?.length ?? 0); i++) {
		const location = locationOfContainer(menuOrSection.sections[i],id);
		if (location != null)
			return ['sections',i,...location];
	}

	return null;
};

interface ISections {
	params,
	menu: MenuData,
	setMenu,
	location: Location,
	sectionIds: SectionIds
}

function Sections(props: ISections)
{
	const sections = () => locateSubDocument(props.menu,props.location);
	const sectionIds = (section,i:number) => [...props.sectionIds, {index:i, name:(section.title ?? '').replace(/\W/g,'').toLowerCase()}];

	return (
		<For each={sections()}>{(section,i) => 
			<Show when={section.widget==undefined}>
				<Section
					params={props.params}
					menu={props.menu}
					setMenu={props.setMenu}
					location={[...props.location,i()]} 
					sectionIds={sectionIds(section,i())}
				/>

				<Show when={section.sections}>
					<Sections 
						params={props.params}
						menu={props.menu}
						setMenu={props.setMenu}
						location={[...props.location,i(),'sections']} 
						sectionIds={sectionIds(section,i())}
					/>
				</Show>
			</Show>
		}</For>
	);
}

interface ISection {
	params,
	menu: MenuData,
	setMenu,
	location: Location,
	sectionIds: SectionIds
}

function Section(props:ISection)
{
	const section = createMemo(() => locateSubDocument(props.menu,props.location) ?? []);

	const itemIds = () => {
		if (section().collapsed) return [];
		return (section().items ?? []).map(i => i.reorderingId);
	};

	const droppable = createDroppable(section().reorderingId);
	const page = usePage();

	/* 
		Pre-calculate the menu item style to reduce the number of Emotion css() calls...
		Emotion appears to rely solely on a (quick) hash to prevent duplicate classes, but given the size of the menu page and
		the use of item reordering it *may* be worth performing this optimisation here.
		Note that if Emotion were to use == to check for reference equality this wouldn't scale, so I doubt they do it. 
		Additionally no IDs appear to be added to the argument passed to css(), which would be another method of checking for reference equality.
	*/
	const sharedItemStyle = createMemo(() => itemStyle(usePage().data.venue.settings.pages?.menu?.itemType != 'imageless'));

	return (
		<Switch>
			<Match when={section().collapsed}>
				<SectionsHeader menu={props.menu} setMenu={props.setMenu} location={props.location} />
			</Match>
			<Match when={true}>
				<div use:droppable>
					<SectionsHeader menu={props.menu} setMenu={props.setMenu} location={props.location} />

					<SortableProvider ids={itemIds()}>
						<For each={section().items ?? []}>{(item,index) => 
							<SectionItem
								item={item}
								reorderingId={item.reorderingId}
								url={createUrl(`/admin/menu-item/${props.params.slug}`,props.sectionIds,item.name,index())}
								itemStyle={sharedItemStyle()}
							/> 
						}</For>
					</SortableProvider>

					<button onClick={() => createItemAndRedirect(page,props.params,props.menu,props.location,props.sectionIds)}>Add item</button>
				</div>
			</Match>
		</Switch>
	);
}

export function extendedSectionTitle(menu:MenuData,location:Location,title:string)
{
	if (location.length == 1)
		return 'Unclassified';

	let ret = '';
	for (let i=1; i < location.length; i++) 
		if (location[i] == 'sections')
			ret += (ret=='' ? '' : ': ') + locateSubDocument(menu,location.slice(0,i)).title;
	return ret + (ret=='' ? '' : ': ') + title;
}

interface ISectionsHeader {
	menu: MenuData,
	setMenu,
	location: Location
}

function SectionsHeader(props:ISectionsHeader)
{
	const section = createMemo(() => locateSubDocument(props.menu,props.location) ?? []);

	return (
		<div class={sectionHeaderStyle()}>
			<a href='' onClick={() => props.setMenu(...props.location,'collapsed',!section().collapsed)}>
				<Switch>
					<Match when={section().collapsed}>
						&#8863;
					</Match>
					<Match when={true}>
						&#8862;
					</Match>
				</Switch>
			</a>
			{extendedSectionTitle(props.menu,props.location,section().title)}
		</div>
	);
}

async function createItemAndRedirect(page,params,menu:MenuData,location:Location,sectionIds:SectionIds)
{
	const msg = new CreateArrayItemMessage2(page.name(),'createItem',menu._id,[...location,'items'],
		{status:'unpublished',imageType:'photo',imageDimensions:'square'}); 

	await page.server.sendOperation(msg);

	const num = locateSubDocument(menu,[...location,'items'])?.length ?? 0;

	window.pageJs( createUrl(`/admin/menu-item/${params.slug}`,sectionIds,'new',num) );
}

function moveInDb(server:AjaxConnectionToServer,docId:Id,source:Location,target:Location)
{
	const msg = new MoveMessage(MenuSection.pageName,'reorderItems',docId,source,target);
	server.sendOperationOptimistically(msg);
}

