import { z } from 'zod';
import * as t from 'Shared/model/basic';
import {genreKeys} from 'Shared/ArtistTypes';

/* Valid formats:
	https://www.facebook.com/watch/?v=10153949778421332
	https://www.facebook.com/abbemay/videos/2305474926170084/ 
 */
export const facebookVideoUrl = z.union([
	z.string().regex(/https?:\/\/www\.facebook\.com\/watch\/\?v=[0-9]+/),
	z.string().regex(/https?:\/\/www\.facebook\.com\/[^\/]+\/videos\/[0-9]+/)
]);			

/* Spotify also has artist and album urls */
export const spotifyTrackUrl = z.string().regex(/^https?:\/\/(?:[^\/]+\.|)spotify\.com\/track\/.+/);

export const appleUrl = z.string().regex(/^https?:\/\/(?:[^\/]+\.|)apple\.com\/.+/);

/* TODO put in separate 'inputter' - assuming still used. Move of a 'view' thing?


TODO Spotify inputter... can/should Zod help here?

fromInputString(value:string) { 
	const bits = value.match(/^https?:\/\/(?:[^\/]+\.|)spotify\.com\/track\/(.+)/);
	if (bits!=null) 
		return 'https://play.spotify.com/track/'+bits[1];
	return value;
}
*/

/*
Facebook inputter

    fromInputString(value:string) 
    { 
        //TODO? Could test UID using:  http://graph.facebook.com/[ID]  Maybe even show a preview
        const match1 = /^\s*https?:\/\/(?:www\.)?facebook\.com\/([^\/\s&]+)/.exec(value);
        if (match1!=null)
            return match1[1];

        const match2 = /^\s*@?([^\/\s]+)/.exec(value);
        if (match2!=null)
            return match2[1];

        * This should fail during validation: *
        return value;       
	}
*/	


/* Instagram inputter: 

    fromInputString(value:string) 
    { 
        const match1 = /^\s*https?:\/\/(?:www\.)?instagram\.com\/([^\/\s\?#&]+)/.exec(value);
        if (match1!=null)
            return match1[1];

        const match2 = /^\s*@?([^\/\s]+)/.exec(value);
        if (match2!=null)
            return match2[1];

        * This should fail during validation: *
        return value;       
	}
*/	

/* Twitter inputter:
    fromInputString(value:string) 
    { 
        const match1 = /^\s*https?:\/\/(?:www\.)?twitter\.com\/([^\/\s\?#&]+)/.exec(value);
        if (match1!=null)
            return match1[1];

        const match2 = /^\s*@?([^\/\s]+)/.exec(value);
        if (match2!=null)
            return match2[1];

        * This should fail during validation: *
        return value;       
	}
*/

export const lineupSet = z.object({
	setTime: t.time,
	name: z.string().max(200),
	mainGenre: genreKeys,
	otherGenre1: genreKeys,
	otherGenre2: genreKeys,
	extraGenre: z.string().max(100),
	tagline: z.string().max(200),
	biography: z.string().max(1000),
	country: z.string().max(100),
	state: z.string().max(8),
	city: z.string().max(100),
	musicEmbedPlatform: z.enum(['bandcamp','soundcloud']),
	musicEmbedId: z.string().max(32),
	videoEmbedPlatform: z.enum(['youtube','vimeo','facebookVideo']),
	videoEmbedId: z.string().max(32),
	facebookVideoUrl: facebookVideoUrl,
	spotify: spotifyTrackUrl,
	appleMusic: appleUrl,
	website: t.url,
	facebook: t.facebook,
	instagram: t.instagram,
	twitter: t.twitter,
	image: t.image,
	task_musicFb:z.boolean(),
	task_videoFb:z.boolean()
})
.strict()
.partial().required({
});

export const lineup = z.array(lineupSet);

export type Lineup = z.infer<typeof lineup>;

