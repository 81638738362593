import {ThirdPartyServices} from 'Shared/frontend/footer/ThirdPartyServices';
import {PhoneAndAddress} from 'Shared/frontend/footer/PhoneAndAddress';
import {SocialIcons} from 'Shared/frontend/footer/SocialIcons';
import {For, createSignal} from 'solid-js'

function Contents(props)
{
	const [active,setActive] = createSignal(false);

	return (
		<For each={props.nav}>{ item =>
			<a href={props.links[item.link].target}>
				<button 
					tabindex='-1' 
					class={`${item.class} ${item.link==props.currentPage ? 'active' : ''}`}
					onclick={item.link==props.currentPage && setActive(!active())} 
				>
					<div class='label'>{item.label}</div>
				</button>
			</a>
		}</For>
	);
}


export function NavMore(props)
{
	return (
		<div id='navMoreOverlay' class='mobile'>
			<div class='logo'> 
				<img src={props.venue.logo.url} /> 
			</div>

			<nav>
				<Contents nav={props.config.nav.desktop} links={props.config.link} currentPage={props.pageName} venue={props.venue} />
			</nav>

			<ThirdPartyServices links={props.venue.thirdPartyService} venue={props.venue} />
			<PhoneAndAddress venue={props.venue}/>
			<SocialIcons venue={props.venue}/>
		</div>
	);
}
