
export type AccessAreaName = 
	'venueFrontend' |
	'venueBareEditor' |
	'venueFullEditor' |
	'venueEventEditor' |
	'venueMenuEditor' |
	'venueDeveloper' |
	'artistEditor' |
	'admin' |
	'unrestricted';


export class AccessArea
{
	constructor(
		readonly name:AccessAreaName
	)
	{ }

	public subAreas():AccessArea[]
	{
		switch(this.name) {
			case 'unrestricted': return [
				new AccessArea('venueFrontend')
			];
			case 'venueEventEditor': return [
				new AccessArea('venueBareEditor')
			];
			case 'venueMenuEditor': return [
				new AccessArea('venueBareEditor')
			];
			case 'venueFullEditor': return [
				new AccessArea('venueBareEditor'),
				new AccessArea('venueEventEditor'),
				new AccessArea('venueMenuEditor'),
			];
			case 'venueDeveloper': return [ 
				new AccessArea('venueFullEditor'),
				new AccessArea('venueBareEditor'),
				new AccessArea('venueEventEditor'),
				new AccessArea('venueMenuEditor'),
			];
			default: return [];
		}
	}

	public includes(other:AccessArea)
	{
		for (const area of this.allIncludedAreas())
			if (area.name == other.name)
				return true;
		return false;
	}

	private allIncludedAreas()
	{
		let areas:AccessArea[] = [this];
		for (const area of this.subAreas())  
			areas = [...areas,...area.allIncludedAreas() ];
		return areas;
	}
}

