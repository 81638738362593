
export const palette = {
	background: 'white',
	button: '#7e94a5',

	active: '#44C4A1',
	inactive: '#666',  //XXX share with 'disabled'?

	error: 'red',
	errorBackground: 'pink'
};

//TODO move more colours into here
