import {css, injectGlobal} from '@emotion/css';
import {SolidHtmlEditorWidget} from 'Browser/widgets/SolidHtmlEditorWidget';
import {CreateMessage2, DeleteMessage2} from 'Common/Messages';
import {VenueUrls} from 'Common/VenueUrls';
import {EditComponent} from 'Common/components/EditComponent';
import { Location } from 'Common/config/PageConfigTypes';
import {PageProvider, usePage} from 'Shared/artists/PageProvider';
import {BackendWrap} from 'Shared/backend/BackendWrap';
import {Checkbox, Html, SingleSelect, Text, Wrap} from 'Shared/forms/Inputs';
import {IStoreRepeaterDelete, StoreRepeater, addItemAndOpen,deleteItem} from 'Shared/forms/StoreRepeater';
import {MenuDoc} from 'Shared/model/Menu';
import {MenuData} from 'Shared/view/backend/MenuDesigner';
import {For,Switch,Match,Show,createSignal,mergeProps,createEffect} from 'solid-js';
import {createStore} from 'solid-js/store';
import {locateSubDocument} from 'Common/ViewUtils';
import { repeaterColour } from 'Shared/forms/Repeater';
import { textColour, theme } from 'Shared/backend/theme';


const menuTitleStyle = () => css({
	display: 'block',
	fontSize: 30,
	margin: '20px 0',
	color: textColour,
	'&:visited': {
		color: textColour
	}
});

const sectionStyle = () => css({
	borderLeft: `5px solid ${repeaterColour}`,
	paddingLeft: 8 
});

export const singleLineStyle = () => css({
	display: 'flex',
	alignItems: 'center',
	gap: 10
});

export const productTypes = {
	food: 'Food',
	drinks: 'Drink',
	beers: 'Beer',
	wines: 'Wine'
};

export const sectionProductTypes = {
	mixed: 'Mixed',

	food: 'Food',
	drinks: 'Drinks',
	beers: 'Beers',
	wines: 'Wines'
};

const htmlEditorOptions = {
	height: 200,
	menubar: false,
	plugins: ['link'],
	toolbar: 'removeformat | undo redo | bold italic underline | alignleft aligncenter alignright alignjustify | outdent indent | link ',
	statusbar: true,
	resize: true 
};


export type MenuDesignerProps = MenuDoc & { 
	setStore: (...args: any[]) => void,
	editArtist: EditComponent<MenuData,MenuDoc>
};

//% extends 'App/backend/backendRoot.njk' %}

//	<breadcrumbs>{{ crumbs.menu(true,breadcrumbs.urls) }}</breadcrumbs>
//					<a href="/admin/menu/{name}">{name|capitalize}</a>
//		<div id='content' class={outerPage()}>


//TODO right justify 'Go to menu contents'


export function MenuDesignerPage(props)
{
	const [menusStore,setMenusStore] = createStore(props.menus);

	const [current,setCurrent] = createSignal(undefined);

//	injectGlobal([theme,forms]);
	injectGlobal([theme]);

	return <PageProvider page={props.page}>
		<BackendWrap>
			<p>
				<a href='/admin/menus'>Go to menu items</a>
			</p>

			<div>
				<div class='headingBar'>
					<h1>Menu Designer</h1>				
				</div>

				<Show when={menusStore.length > 0} fallback={<>There are no menus yet</>}>
					Available menus:
				</Show>

				<For each={menusStore}>
					{(menu,index) => {
						const menuStore = () => menusStore[index()];
						const setMenuStore = (...args) => setMenusStore(index(),...args);
						const menuProps = mergeProps(menuStore,{setStore: setMenuStore});

						return (<>
							<Switch>
								<Match when={index()==current()}>
									<a href=''onClick={() => setCurrent(undefined)} class={menuTitleStyle()}>&#x2795; {menu.title}</a> 
									<Menu
										menusStore={menusStore} setMenusStore={setMenusStore}
										store={menuProps} setStore={setMenuStore}
										setCurrent={setCurrent} docId={menu._id} 
									/>
								</Match>
								<Match when={true}>
									<a href=''onClick={() => setCurrent(index())} class={menuTitleStyle()}>&#x2796; {menu.title}</a>
								</Match>
							</Switch>
						</>);
					}}
				</For>

				<a href='' onClick={() => createMenu(props,menusStore,setMenusStore,setCurrent)}>Add menu</a>
			</div>
		</BackendWrap>
	</PageProvider>;
}

function Menu(props)
{
	const page = usePage();
	const edit = page.component('edit') as EditComponent<MenuData,MenuDoc>;
	const editList = page.component('editDocs') as EditComponent<MenuData,MenuDoc>;
	const repeaterPermission = page.config.permissions().sections;

	const url = () => '/menu/'+props.index;
	const data = () => locateSubDocument(props.store,[]);

	const title = i => typeof i.widget != 'undefined' ? 'WIDGET: '+widgetOptions[i.widget] : i.title;

	let itemsNode!:HTMLElement;

	return <div>
		<MenuFields {...props} component={edit} />

		<label>Sections</label>

		<div>
			<StoreRepeater {...props} ref={itemsNode} page={page} permission='sections' location={[]} field='sections' renderTitle={title} 
				deleteItem={(event,index:number) => deleteSection(event,{...props,page:page,location:[],permission:'sections'},index)}
			>
				{(item,index) => <SectionsAndWidgets {...props} {...item} location={['sections',index]} url={url()} index={index} />}
			</StoreRepeater>

			<button onClick={() => addItemAndOpen({...props,permission:'sections',location:[],field:'sections',page:page},itemsNode,{})}>
				<i class='fas fa-plus'/> Add section
			</button>

			<button onClick={() => addItemAndOpen({...props,permission:'sections',location:[],field:'sections',page:page}, itemsNode,
					{widget: 'buttonRow'})}>
				<i class='fas fa-plus'/> Add widget
			</button>
		</div>

		<p><a href='' onClick={() => deleteMenu(page,props)}>Delete menu</a></p>
	</div>
}

async function deleteSection(event:Event,props:IStoreRepeaterDelete,index:number)
{
	event.stopPropagation();

	const items = locateSubDocument(props.store,[...props.location,'sections',index,'items']) ?? [];
	const sections = locateSubDocument(props.store,[...props.location,'sections',index,'sections']) ?? [];

	if (items.length > 0 || sections.length > 0) {
		alert('You need to delete out the menu items, subsections and widgets first');
		return;
	}

	if (!confirm('Are you sure you wish to delete this item?'))
		return;

	await deleteItem({...props,field:'sections',page:props.page},index);
}


function SectionsAndWidgets(props)
{
	const page = usePage();
	const edit = page.component('edit') as EditComponent<MenuData,MenuDoc>;
	const editList = page.component('editDocs') as EditComponent<MenuData,MenuDoc>;
	const repeaterPermission = page.config.permissions().sections;

	const url = () => props.url+'/'+props.index;
	const data = () => locateSubDocument(props.store,props.location);

	let itemsNode!:HTMLElement;

	return <div class={sectionStyle()}>
		<Switch>
			<Match when={typeof data().widget != 'undefined'}>
				<WidgetFields {...props} component={edit} />
			</Match>

			<Match when={true}>
				<SectionContents {...props} component={edit} />
			</Match>
		</Switch>
	</div>
}

function MenuFields(props)
{
	let htmlNode:HTMLDivElement;

	createEffect(() => {
		const htmlEditor = new SolidHtmlEditorWidget(new VenueUrls(usePage().build,usePage().site), htmlEditorOptions);
		htmlEditor.init(htmlNode);
	});

	return <>
		<Wrap label='Menu title' required={true}>
			<Text {...props} field='title' />
		</Wrap>

		{/* TODO auto-populate by default. cf having an enable checkbox */}
		<Wrap label='URL slug' required={true}>
			<Text {...props} field='slug' />
		</Wrap>

		<Wrap label='Product type' required={true} disable={true}>
			<SingleSelect {...props} field='productType' options={sectionProductTypes} />
		</Wrap>

		<Wrap label='Teaser'>
			<Text {...props} field='teaser' placeholder='Optional'/>
		</Wrap>

		<Wrap label='Tagline'>
			<Text {...props} field='tagline' placeholder='Optional'/>
		</Wrap>

		<Wrap label='Details'>
			<Html {...props} field='details' placeholder='Optional' ref={htmlNode!} />
		</Wrap>
	</>;
}

const widgetOptions = {buttonRow:'Button Row',specials:'Specials'};

function WidgetFields(props)
{
	const data = () => locateSubDocument(props.store,props.location);

	return (
		<Wrap label='Widget type' required={true}>
			<SingleSelect {...props} field='widget' required={true} options={widgetOptions} />

			<Switch>
				<Match when={data().widget == 'buttonRow'}>
					<ButtonRow {...props} />
				</Match>
				<Match when={data().widget == 'specials'}>
					SPECIALS - could put in tagline etc
				</Match>
			</Switch>
		</Wrap>
	);
}

function ButtonRow(props)
{
	const page = usePage();
	let itemsNode!: HTMLElement;

	/* XXX
		'Menu Shortcuts' vs 'Shortcuts' - I'm thinking menu shortcuts might have the food/drinks colour distinction.
		The stickiness could potentially be subsumed within 'Menu Shortcuts'.
	 */
	const rowStyleOptions = {
		food: 'Food',
		drinks: 'Drinks',
		plain: 'Plain'
	};

	return (<>
		<div>
			<Wrap label='Sticky'>
				<Checkbox {...props} field='sticky' />
			</Wrap>

			<StoreRepeater {...props} ref={itemsNode} page={page} permission='sections' location={props.location} field='buttons' renderTitle={i => i.label } >
				{(item,index) => <>
					<Wrap label='Label'>
						<Text {...props} location={[...props.location,'buttons',index]} field='label' required={true} />
					</Wrap>

					<Wrap label='Style'>
						<SingleSelect {...props} location={[...props.location,'buttons',index]} field='style' required={true} options={rowStyleOptions} />
					</Wrap>

					<Wrap label='URL'>
						<Text {...props} location={[...props.location,'buttons',index]} field='url' required={true} />
					</Wrap>
				</> }
			</StoreRepeater>

			<button onClick={() => addItemAndOpen({...props,page:page,permission:'sections',location:props.location,field:'buttons'}, itemsNode,{})}>
				<i class='fas fa-plus'/> Add button
			</button>
		</div>
	</>);
}

function SectionContents(props)
{
	const page = usePage();
	const edit = page.component('edit') as EditComponent<MenuData,MenuDoc>;
	const editList = page.component('editDocs') as EditComponent<MenuData,MenuDoc>;
	const repeaterPermission = page.config.permissions().sections;

	const url = () => props.url+'/'+props.index;
	const data = () => locateSubDocument(props.store,props.location);

	let itemsNode!:HTMLElement;

	return <>
		<SectionFields {...props} component={edit} />

		<label>Sections</label>

		<div>
			<StoreRepeater {...props} ref={itemsNode} page={page} permission='sections' location={props.location} field='sections' renderTitle={i => i.title} 
				deleteItem={(event,index:number) => deleteSection(event,{...props,page:page,permission:'sections'},index)}
			>
				{(item,index) => <SectionsAndWidgets {...props} {...item} location={[...props.location,'sections',index]} url={url()} index={index} />}
			</StoreRepeater>

			<button onClick={() => addItemAndOpen({...props,page:page,permission:'sections',location:props.location,field:'sections'}, itemsNode,{})}>
				<i class='fas fa-plus'/> Add section
			</button>

			<button onClick={() => addItemAndOpen({...props,page:page,permission:'sections',location:props.location,field:'sections'}, itemsNode,
					{widget: 'buttonRow'})}>
				<i class='fas fa-plus'/> Add widget
			</button>
		</div>
		{/*TODO add a more cautious delete*/}
	</>;
}

function SectionFields(props)
{
	let htmlNode:HTMLDivElement;

	createEffect(() => {
		const htmlEditor = new SolidHtmlEditorWidget(new VenueUrls(usePage().build,usePage().site), htmlEditorOptions);
		htmlEditor.init(htmlNode);
	});

//TODO 'required' should be read from the input field by default. Possibly overridable in Wrap

//FIXME - if ancestor is not 'mixed' fixed descendant product types
//					<SingleSelect {...props} field='productType' options={productTypes} value={parentProductType} disable={true} />

	const parentProductType = calculateProductType(props.store,props.location.slice(0,-1));  //TODO memoise

	return (<>
		<Wrap label='Title' required={true}>
			<Text {...props} field='title' required={true} />
		</Wrap>

		<Switch>
			<Match when={parentProductType == 'mixed'}>
				<Wrap label='Product type' required={true}>
					<SingleSelect {...props} field='productType' options={sectionProductTypes} />
				</Wrap>
			</Match>
			<Match when={true}>
				<Wrap classes={singleLineStyle()} label='Product type' disable={true}>
					<div>{sectionProductTypes[parentProductType]}</div>
				</Wrap>
			</Match>
		</Switch>

		<Wrap label='Teaser'>
			<Text {...props} field='teaser' placeholder='Optional' />
		</Wrap>

		<Wrap label='Tagline'>
			<Text {...props} field='tagline' placeholder='Optional'/>
		</Wrap>

		<Wrap label='Details'>
			<Html {...props} field='details' placeholder='Optional' ref={htmlNode!} />
		</Wrap>
	</>);
}

//FIXME use createResource() I guess (necessary?)
async function createMenu(props,menusStore,setMenusStore,setCurrent)
{
	const {id} = await props.page.server.sendOperation(new CreateMessage2(props.page.name(),'createMenu',{}));

	const numMenus = menusStore.length;
	setMenusStore([...menusStore, {_id:id}]);
	setCurrent(numMenus);
}

async function deleteMenu(page,props)
{
//TODO probably have an archive option instead. Maybe allow deletion after a day or something.

	const sections = props.store.sections ?? [];
	const items = props.store.items ?? [];

	if (sections.length > 0 || items.length > 0) {
		alert('You need to delete out the menu items, subsections and widgets first');
		return;
	}

	if (!confirm('Are you sure you wish to delete this menu?'))
		return;

    await page.server.sendOperation(new DeleteMessage2(page.name(),'deleteMenu',props.store._id));

	const i = props.menusStore.findIndex(e => e._id == props.store._id);
	props.setMenusStore([...props.menusStore.slice(0,i), ...props.menusStore.slice(i+1)]);
	props.setCurrent(undefined);
}

export function calculateProductType(store,location:Location)
{
	if (store.productType!=undefined && store.productType!='mixed')
		return store.productType;

	let data = store;
	for (let i=0; i<location.length; i++) {
		data = data[location[i]];

		if (data.productType!=undefined && data.productType!='mixed')
			return data.productType;
	}

	return 'mixed';
}

