
import Assert from 'Common/Assert';
import {onMount} from 'solid-js';

export function StickySubNav(props) 
{
	let root;

	onMount(() => {
		window.addEventListener('resize', () => {
			window.requestAnimationFrame(() => updateDisplayOfArrows(root))
		});
		updateDisplayOfArrows(root);
	});

	return (
		<div class='stickySubNav' ref={root}>
			<div class='stickySubNavInner'>
				<div class='stickyItemsScroll' onscroll={scroll}>
					<div class='stickyItems'>
						{props.children}
					</div>
				</div>

				<button class='leftArrow' onclick={scrollLeft}>
					&#10094;
				</button>

				<button class='rightArrow' onclick={scrollRight}>
					&#10095;
				</button>
			</div>
		</div>
	);
}

/* 
	Note this is automatically triggered when scrollLeft and scrollRight are called,
	as well as in response to the user dragging the scrollbar.
 */
function scroll(e:Event)
{
	const root = e.target.closest('.stickySubNav');
	const scrollPane = Assert.have(root.querySelector('.stickyItemsScroll'));

	/* Note most browsers support the 'scrollend' event, which could be used instead */
	const lastPosition = scrollPane.scrollLeft;
	setTimeout(() => {
		if (scrollPane.scrollLeft == lastPosition)
			updateDisplayOfArrows(root);
	},100);
}

function scrollLeft(e:Event)
{
	const root = e.target.closest('.stickySubNav');
	const scrollPane = Assert.have(root.querySelector('.stickyItemsScroll'));
	Assert.htmlElement(root.querySelector('.leftArrow')).style.display = 'none';

	/* Think the browser trims these... */
	/* The 0.85 ensures a bit of overlap when paging left or right */

	scrollPane.scrollBy({left: -scrollPane.clientWidth * 0.85, behavior: 'smooth'});
	/* The scroll event will be called by the event handler */
}

function scrollRight(e:Event)
{
	const root = e.target.closest('.stickySubNav');
	const scrollPane = Assert.have(root.querySelector('.stickyItemsScroll'));
	Assert.htmlElement(root.querySelector('.rightArrow')).style.display = 'none';

	scrollPane.scrollBy({left: scrollPane.clientWidth * 0.85,behavior: 'smooth'});
}

function updateDisplayOfArrows(root:HTMLElement)
{
	/* 
		Don't bother showing arrows if real close to the edge. Mobiles seem to struggle
		with exactly edge. cf having scroll 'click' into next tag.
	 */
	const cutoff = 3;

	const scrollPane = Assert.have(root.querySelector('.stickyItemsScroll'));

	const leftDisplay = scrollPane.scrollLeft > cutoff ? 'block' : 'none';
	Assert.htmlElement(root.querySelector('.leftArrow')).style.display = leftDisplay;

	const rightDisplay = scrollPane.scrollLeft >= scrollPane.scrollWidth - scrollPane.clientWidth - cutoff ? 'none' : 'block';
	Assert.htmlElement(root.querySelector('.rightArrow')).style.display = rightDisplay;
}

