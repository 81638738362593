
import {Links, Nav} from 'Shared/frontend/layouts/StandardDefaultLayout';
import {For} from 'solid-js';

interface INavWidgetContents {
	nav: Nav;
	links: Links;
	currentPage:string;
}

export function NavWidgetContents(props:INavWidgetContents)
{
	return (
		<For each={props.nav}>{ item =>

			<a href={props.links[item.link].target}>
				<button tabindex='-1' class={`${item.class} ${item.link == props.currentPage ? 'active' : ''}`}>
					<div class='label'>{item.label}</div>
				</button>
			</a>

		}</For>
	);
}
