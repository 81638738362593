
import {Links, Nav} from 'Shared/frontend/layouts/StandardDefaultLayout';
import {NavWidgetContents} from 'Shared/frontend/navWidget/NavWidgetContents';
import {ShrinkingLogo} from 'Shared/frontend/navWidget/ShrinkingLogo';
import {ShrinkingNavBar} from 'Shared/frontend/navWidget/ShrinkingNavBar';
import {Url} from 'Shared/model/basic';
import {Show} from 'solid-js';


export interface IShrinkingNavWithLogo {
	logoSide: 'left'|'right';
	logoUrl: Url;
	nav: Nav;
	links: Links;
	currentPage: string;
};

export function ShrinkingNavWithLogo(props:IShrinkingNavWithLogo)
{
	return (
		<ShrinkingNavBar>

			<Show when={props.logoSide=='left'}>
				<ShrinkingLogo shrinkDirection='left' logoUrl={props.logoUrl} />
			</Show>

			<div class='navButtons'>
				<NavWidgetContents nav={props.nav} links={props.links} currentPage={props.currentPage} />
			</div>
			<Show when={props.logoSide=='right'}>
				<ShrinkingLogo shrinkDirection='right' logoUrl={props.logoUrl} />
			</Show>

		</ShrinkingNavBar>
	);
}

