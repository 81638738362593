import {AccessAreaName} from 'Common/permissions/AccessArea';
import {FrontendPageConfig, frontendData} from 'Common/FrontendPageConfig';
import { z } from 'zod';

export const hireEnquiryParams = z.object({ }).strict();	
export type HireEnquiryParams = z.infer<typeof hireEnquiryParams>;


export const hireEnquiryData = frontendData.extend({
	pageDisplayName: z.string(),
}).strict();
export type HireEnquiryData = z.infer<typeof hireEnquiryData>;



export class HireEnquiry extends FrontendPageConfig<HireEnquiryData,HireEnquiryParams>
{
	static readonly pageName = 'frontend/hireEnquiry';
	name() { return HireEnquiry.pageName; }

	access() { return 'venueFrontend' as AccessAreaName; }

	defaultSettings()
	{
		return {
			...super.defaultSettings(),
			htmlClasses: `${this.htmlClasses} hireEnquiryPage`,
			template: 'App/frontend/hireEnquiryPage/standard.njk',
			pageDisplayName: 'Hire Enquiry'
		};
	}

	documents(params:HireEnquiryParams)
	{
		return ({
			...super.documents(params)
		});
	}

	components()
	{
		return ({
			...super.components()
		});
	}
}

