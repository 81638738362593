import { CSSInterpolation } from "@emotion/css";

/*
	Media queries, adapted from https://www.w3schools.com/howto/howto_css_media_query_breakpoints.asp 
 */
export const phone = `@media (max-width: 600x)`; 
/* Tablets in portrait mode + large phones */
export const tabletOrDesktop = `@media (min-width: 600px)`; 
export const phoneOrTablet = `@media (max-width: 992px)`; 
/* Desktops and laptops */
export const desktop = `@media (min-width: 992px)`; 


export const maxBodyWidth = '1100px';
export const textColour = '#000000'; 
export const headerBackground = '#1b2126';
export const headerText = 'white';
export const navHeight = '70px';
export const subHeaderBackground = '#fafafa';
export const subHeaderText = '#000000';
export const borderTop = subHeaderBackground;
export const alertColour = 'red'; 
export const borderBackgroundColour = '#1b2126';
export const backgroundColour = 'white';

export const navTextColour = 'white';
export const topBarHeight = '2em';

//XXX there is a palette...
export const linkColour = '#005394'; 
export const shadingColour = '#f5f5f5';
export const tab3Colour = '#fafafa';  //XXX The .less file uses lighten() to define

//XXX cf using a minimal 'sanitise' class in htmlClasses instead...


export const theme:CSSInterpolation = {
	a: {
		color: linkColour,

		'&:visited': {
			color: linkColour
		}
	}
};

