import {SelectorWidget} from 'Browser/widgets/SelectorWidget';
import {Component} from 'Common/components/Component';
import Assert from 'Common/Assert';
import {Urls} from 'Common/Urls';

const className = 'bf-html';

export class HtmlEditorWidget extends SelectorWidget
//XXX loading up the editor is a bit clunky. There's probably a cleaner method...
{
	private instanceData: Map<string,{component:Component,fieldName:string,settings:object}>;
	private loaded:boolean = false;

	constructor(
		private urls:Urls
	)
	{
		super(['.'+className]);
/*OLD The framework field uses 'bf-codeInner'. If we have to we could pass this in as a parameter instead.  */
		this.instanceData = new Map();

		this.loadTinyMce = this.loadTinyMce.bind(this);
		this.initTinyMceInstance = this.initTinyMceInstance.bind(this);
	}	

	static key() { return 'htmlEditor'; }

	initInstance(anchorNode:HTMLElement)
	{
		super.initInstance(anchorNode);

		setTimeout(async () => {
			await this.loadAndInitTinyMceInstance(anchorNode);
		},1);
	}

	private async loadAndInitTinyMceInstance(anchorNode:HTMLElement):Promise<void>
	{
		if (!this.loaded) {
			await this.loadTinyMce();
			this.loaded = true;
		}
		this.initTinyMceInstance(anchorNode);
	}

	private async loadTinyMce(): Promise<void>
	{
		/*
			Webpack could be used instead. The main advantage of that would be to cut down the size of
			the distribution. Generally though I want access to everything and then use options to restrict
			the features as these options may change from place to place.
		 */
		/* Using dynamic import might be cleaner.  */

		await new Promise((resolve,reject) => {
			const script = document.createElement('script');
			script.addEventListener('load',() => resolve(null),false);
			script.src = this.urls.buildUrl('resources/tinymce/tinymce.min.js');
			document.body.appendChild(script);
		});
	}

	private initTinyMceInstance(anchorNode:HTMLElement):void
	{
		const settings = JSON.parse(Assert.have(anchorNode.dataset.settings));

		const maxChars = settings.maxCharacters;

		/* Note that if used in a repeater the anchor ID might well be reused */
		this.destroyInstance(anchorNode);

		const s = { ...settings,
			selector: '#'+this.escapeSelector('tinymce-'+anchorNode.id),
			setup: (editor:any) => {

				editor.on('Change', (e:any) => {
					(<any>anchorNode).value = editor.getContent();
					anchorNode.dispatchEvent(new CustomEvent('change'));
				});

				if (maxChars!=undefined) {
					editor.on('PostRender', (e:any) => {
						const rightContainer = Assert.have(editor.editorContainer.querySelector('.tox-statusbar__right-container'));
						const counterNode = document.createElement('span')
						counterNode.className = 'htmlEditorCounter';
						rightContainer.insertBefore(counterNode,rightContainer.firstChild);
					});
					editor.on('input', (e:any) => {
						const counterSpan = <HTMLSpanElement>Assert.htmlElement(editor.editorContainer.querySelector('.htmlEditorCounter'));
						this.characterMessage(counterSpan,editor.getContent().length,maxChars);
					});
					editor.on('LoadContent', (e:any) => {
						const counterSpan = <HTMLSpanElement>Assert.htmlElement(editor.editorContainer.querySelector('.htmlEditorCounter'));
						this.characterMessage(counterSpan,editor.getContent().length,maxChars);
					});
				}
			}
		};

		/* 
			HACKY. Multiple editors wont appear in Contacts > General without this.
			Perhaps the DOM-diffing is still modifying the DOM? Or perhaps multiple instances don't like
			initialising simultaneously.
			NB 1ms is not enough with so many editors on the page.
		 */
		setTimeout(() =>(<any>window).tinymce.init(s),100);
	}

	beforeUpdate(fromNode:HTMLElement,toNode:HTMLElement):boolean 
	{
		return !fromNode?.classList.contains(className);
	}

	storeSettings(comp:Component,fieldName:string,settings:object):void
	{
		this.instanceData.set(comp.name+'-'+fieldName,{component:comp,fieldName:fieldName,settings:settings});
	}

	destroyInstance(anchorNode:HTMLElement):void 
	{
		if (anchorNode.id != null) {
			const id = (<any>window)?.tinymce?.get('tinymce-'+anchorNode.id);
			id?.remove();
		}
	}

	private characterMessage(span:HTMLSpanElement,numChars:number,maxChars:number)
	{
		span.innerHTML = `${numChars} characters used (max ${maxChars})`;

		if (numChars <= maxChars)
			span.classList.remove('tooManyCharacters');
		else
			span.classList.add('tooManyCharacters');
	}

	private escapeSelector(s:string)
	{
		return s
			.replaceAll('{', '\\{')
			.replaceAll('}', '\\}')
			.replaceAll('[', '\\[')
			.replaceAll(']', '\\]')
			.replaceAll(',', '\\,')
			.replaceAll(':', '\\:');
	}
}

