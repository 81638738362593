import { z } from 'zod';
import {Components,DocumentQueries} from 'Common/config/PageConfigTypes';
import {menuDoc} from 'Shared/model/Menu';
import {AccessAreaName} from 'Common/permissions/AccessArea';
import {BackendPageConfig, backendData} from 'Common/BackendPageConfig';
import {EditComponent} from 'Common/components/EditComponent';
import {EditableDocumentList} from 'Common/components/EditableDocumentList';
import {MenuDesignerPage} from 'Shared/backend/menu/MenuDesignerPage';


export const menuParams = z.object({}).strict();	
export type MenuParams = z.infer<typeof menuParams>;

export const menuData = backendData.extend({ 
	menu: menuDoc
}).strict();
export type MenuData = z.infer<typeof menuData>;


export class MenuDesigner extends BackendPageConfig<MenuData,MenuParams>
{
	static readonly pageName = 'backend/menuDesigner';
	name() { return MenuDesigner.pageName; }

	access() { return 'venueFullEditor' as AccessAreaName; }

	defaultSettings()
	{
		return {
			...super.defaultSettings(),
			htmlClasses: '',
			template: MenuDesignerPage
		};
	}

	documents(params:MenuParams): DocumentQueries 
	{
		return ({
			...super.documents(params),
			menus: {
				type:'array',
				collection: 'menu'
			}
		});
	}

	permissions()
	{
		return ({
			createMenu: { collection: 'menu' },
			deleteMenu: { collection: 'menu' },
			sections: {collection: 'menu' }
		});
	}

	components():Components<MenuData>
	{
		return ({
/*	TODO
			breadcrumbs: {
				type: 'breadcrumbs',
				parent: {page:Venue.pageName,component:'breadcrumbs'},
				link: () => '/admin/menu'
			}
*/		

			editDocs: {
				type: EditableDocumentList.Id,
				collection: 'menu',
//				locateListParent:	(doc:MenuDoc,location) => locateSubDocument(doc,location),
//				fieldName:			() => 'sections',
			},
/*			
			editList: {
				type: EditableArray.Id,
				collection: 'menu',
				locateListParent:	(doc:MenuDoc,location) => locateSubDocument(doc,location),
				fieldName:			() => 'sections',
			},
*/			
			create: {
				type: 'create',
				collection: 'menu'
			},
			edit: {
				type: EditComponent.Id,
				collection: 'menu',
				subdocument: []
			},


/*
			edit: {
				type: 'repeater',
//				selector: '#lineup repeater-items',
				collection: 'menu',
				locate:				(doc:MenuDoc,location:Location) => (<XXX>doc).sections[Assert.toNumber(location[0])],
				locateListParent:	(doc:MenuDoc) => doc,
				fieldName:			() => 'sections',
//				initialValues: () => ({
//					bookingStatus:'draft',
//					useAgreement: false
//				}),
//				processInput: (location:Location,field:string,value:string) => processInput(lineupFieldTypes(field),value), 
			},
*/		

		});
	}
}

