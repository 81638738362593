/*
	This abstract class can be subtyped to support JS widgets. It is used to ensure 
	that DOM patching doesn't interfere with the widget.
	A single class may be used to cover multiple instances of the widget.

	Most JS widgets are tied to a particular node and initialised against it.
	I'm calling these "anchor nodes".  

	Note that widgets will not necessarily be present when the page is first constructed, 
	so new nodes also need to be checked to see if they contain any of these "anchoring" nodes.
	So as well as ensuring that patching does not override already initialised code, this class
	can be used to initialise code that is added dynamically by taking advantage of the patcher.
 */

export abstract class JsPageWidget
{
	/* 
		IMPORTANT: we only have one object instance to cover ALL instances of the
		widget on the page. This makes registering the widget easy, but means passing data around
		child classes can be more difficult.
	 */
	protected anchorNodes:HTMLElement[] = [];

	static key() { throw new Error('key() needs to be implemented'); }

	/*	Calls the JS code needed to set up one instance of this type of widget. */
	initInstance(anchorNode:HTMLElement)
	{ 
		this.anchorNodes.push(anchorNode);
	}

	/* Called after initial patching */
	afterDisplay(anchorNode:HTMLElement):void {}

	/* Finds all the anchor nodes in a branch. */
	abstract findAnchorNodes(branch:HTMLElement):Iterable<HTMLElement>

	/* Text nodes can't be anchor nodes.  */
//TODO combine with 'findAnchorNodes'?
	abstract isAnchorNode(node:HTMLElement):boolean;

	/* 
		MorphDom calls this during patching.
		To avoid a node and its children return false.
		The 'toNode' can be modified if necessary (eg could copy stuff over from 'fromNode').

		NOTE: event handlers on the node should be unaffected if the node's HTML doesn't change.
		If parts of the HTML change may wish to re-attach handlers to those parts.
	 */
	beforeUpdate(fromNode:HTMLElement,toNode:HTMLElement):boolean { return true; }

	afterUpdate(node:HTMLElement):void { }

	/* MorphDom calls this during patching after a node is added */
	afterAdded(node:HTMLElement) {}

	/* 
		MorphDom calls this during patching before a node is removed.
		Return false to prevent removal.
	 */
	beforeRemove(node:HTMLElement):boolean { return true; }

//XXX MorphDOM has a few more methods I have added yet.

	destroyInstance(anchorNode:HTMLElement):void {}

    /* Destroys all the instances plus anything shared by all the widgets of this type. */
    destroyAll() 
	{ 
		this.destroyInstances(<HTMLElement><unknown>document);
	}

	/* Looks for widgets in a branch and initialises them if they exist.  */
	public callInitInstances(branch:HTMLElement):void
	{
		const nodes = this.findAnchorNodes(branch);
		for (const n of nodes) 
			this.initInstance(n);
	}

	public callAfterDisplay(branch:HTMLElement):void
	{
		const nodes = this.findAnchorNodes(branch);
		for (const n of nodes) 
			this.afterDisplay(n);
	}

	/*
		Cleanup the DOM and JS with respect to widgets than lie within this branch.
		Note there could be resources shared between all the widgets which should be left intact.
	 */
	public destroyInstances(branch:HTMLElement)
	{
		const nodes = this.anchorNodes.slice();

		let i = 0;
        for (const a of nodes) {
            if (branch.contains(a)) {
                this.destroyInstance(a);
				this.anchorNodes.splice(i,1);
			}
			i++;
		}
	}

	/* Handy utility method. Can be used with beforeUpdate(). */
	protected copyAllInlineStyles(fromNode:HTMLElement,toNode:HTMLElement)
	{
		for (let i = 0; i < fromNode.style.length; i++) {
			const name:string = fromNode.style[i]; 
			toNode.style[<any>name] = fromNode.style[<any>name];    //TODO any
		}
	}
}

