import {InvalidFieldValueError} from 'Browser/InvalidFieldValueError';

export function email(value:string) 
{
	/* 
		This isn't a full check, and a full check isn't worth it as implementations are likely to vary
		from the standard from time to time.  Need to send an email to be sure anyhow.
	 */

	const match1 = /^\s*(\S+@\S+)\s*/.exec(value);
	if (match1!=null)
		return match1[1];

	throw new InvalidFieldValueError(null,'invalid email');
}

export function websiteUrl(value:string) 
{ 
	const match1 = /^\s*(https?:\/\/[^\s]+)\s*/.exec(value);
	if (match1!=null)
		return match1[1];

	throw new InvalidFieldValueError(null,'invalid URL');
}

export function facebookPageUrl(value:string) 
{ 
	//TODO? Could test UID using:  http://graph.facebook.com/[ID]  Maybe even show a preview

	/* 
		Trim parameters and leading spaces. Facebook URLs include these when logged in - eg
			https://www.facebook.com/handsomesons?paipv=0&eav=Afbob-H1eTjtAUY4VyoHBJ41y3jA7D-8FHP1stCd_VLue4zT-4WtyJg87Ek9g43MFHY&_rdr 
	*/
	const bits = value.match(/\s*([^?]*).*/);
	const val = bits!.length==1 ? value : bits![1];

	/* 
		Trim trailing /s and spaces - eg
			https://www.facebook.com/p/The-Southern-Sons-100052319007071/
	*/
	const val2 = val.match(/^(.*?)\/?\s*$/)![1];

	/* https://www.facebook.com/p/The-Southern-Sons-100052319007071 */
	const match1 = /^https?:\/\/(?:www\.)?facebook\.com\/p\/([^\/]+)/.exec(val2);
	if (match1!=null)
		return match1[1];

	/* https://www.facebook.com/handsomesons */
	const match2 = /^https?:\/\/(?:www\.)?facebook\.com\/([^\/]+)/.exec(val2);
	if (match2!=null)
		return match2[1];

	/* handsomesons, @handsomesons */
	const match3 = /^@?([^\/\s]+)\s*$/.exec(val2);
	if (match3!=null)
		return match3[1];

	throw new InvalidFieldValueError(null,'invalid Facebook identifier');
}

export function instagramPageUrl(value:string) 
{ 
	const match1 = /^\s*https?:\/\/(?:www\.)?instagram\.com\/([^\/\s\?#&]+)\s*/.exec(value);
	if (match1!=null)
		return match1[1];

	/* handsomesons, @handsomesons */
	const match2 = /^@?([^\/\s]+)\s*$/.exec(value);
	if (match2!=null)
		return match2[1];

	throw new InvalidFieldValueError(null,'invalid Instagram identifier');
}

/* ------- Videos ----- */

/*
	Need to keep the ID so we re-wrap it in an embed code.

	https://www.youtube.com/watch?v=Yc9k9nvIHOU
*/
export function youtube(value:string) 
{ 
	let match = /^https?:\/\/(?:www\.)?(?:youtu\.be\/|youtube\.com\/embed\/|youtube\.com\/watch\?v=)([^\/"'\s\#]+)$/.exec(value);

	// NOTE the time parameter will be included, as will any other parameters, if they exist.
	//      This may not be desirable, although including the time probably is.
	if (match!=null)
		return match[1];

	/* Also accept stripped hash code (needed for re-saving! */
	match = /^\s*(\w+)\s*$/.exec(value);

	// NOTE the time parameter will be included, as will any other parameters, if they exist.
	//      This may not be desirable, although including the time probably is.
	if (match!=null)
		return match[1];

	throw new InvalidFieldValueError(null,'invalid Youtube URL');
}

/* Matches:
	https://www.instagram.com/reel/CqXHmTAjdOX/
 */
export function instagramVideo(value:string) 
{ 
	const match1 = /^\s*(https?:\/\/(?:[^.\/]+\.)?instagram\.com\/reel\/\S+)\s*/.exec(value);
	if (match1!=null)
		return match1[1];

	throw new InvalidFieldValueError(null,'invalid Instagram video URL');
}


/* Valid formats:
	https://www.facebook.com/watch/?v=10153949778421332
	https://www.facebook.com/abbemay/videos/2305474926170084/ 
	https://www.facebook.com/winterfox/videos/10221558253999139
	https://www.facebook.com/reel/2797924943847507
	https://fb.watch/s-bSj-lBea/
	https://www.facebook.com/share/v/E4CQjWGDixQocr1d/

	Shares on Youtube? And maybe elsewhere?
	https://www.facebook.com/share/p/8yXFHJu17PfnXVnX/
 */
export function facebookVideo(value:string)
{
	let bits = value.match(/^\s*(https?:\/\/www\.facebook\.com\/watch\/\?v=[0-9]+)\s*/)
	if (bits!=null) return bits[1];

	bits = value.match(/^\s*(https?:\/\/www\.facebook\.com\/[^\/]+\/videos\/[^\\]+)\s*/)
	if (bits!=null) return bits[1];

	bits = value.match(/^\s*(https?:\/\/fb\.watch\/[^\/\s]+\/?)\s*/)
	if (bits!=null) return bits[1];

	bits = value.match(/^\s*(https?:\/\/www\.facebook\.com\/reel\/\S+)\s*/)
	if (bits!=null) return bits[1];

	bits = value.match(/^\s*(https?:\/\/www\.facebook\.com\/share\/v\/\S+)\s*/)
	if (bits!=null) return bits[1];

	bits = value.match(/^\s*(https?:\/\/www\.facebook\.com\/share\/p\/\S+)\s*/)
	if (bits!=null) return bits[1];

	throw new InvalidFieldValueError(null,'invalid Facebook video URL');
}


/* ------------ Music provider links ------------ */

export function spotify(value:string) 
{ 
	const bits = value.match(/^https?:\/\/(?:[^\/]+\.|)spotify\.com\/track\/(.+)/);
	if (bits!=null) 
		return 'https://play.spotify.com/track/'+bits[1];

	if (value.match(/^https?:\/\/(?:[^\/]+\.|)spotify\.com\/artist\/.+/)!=null ||
		value.match(/^https?:\/\/(?:[^\/]+\.|)spotify\.com\/album\/.+/)!=null) 
		throw new InvalidFieldValueError(null,'only song URLs here please, not album or artist URLs');

	throw new InvalidFieldValueError(null,'invalid Spotify URL');
}

export function appleMusic(value:string)
{
	const bits = value.match(/^\s*(https?:\/\/(?:[^\/]+\.|)apple\.com\/\s+)\s*/);
	if (bits!=null) return bits[1];

	throw new InvalidFieldValueError(null,'expect an Apple Music URL');
}

/*
	https://shaunnwatt.bandcamp.com/track/scapegoat
*/
export function bandcamp(value:string)
{
	const bits = value.match(/^\s*(https?:\/\/[^\/.]*\.bandcamp\.com\/track\/\S+)\s*$/);
	if (bits!=null) return bits[1];

//TODO add errors if album or artist	

	throw new InvalidFieldValueError(null,'doesn\'t appear to be a Bandcamp track URL');
}

/*
	https://soundcloud.com/stephen-jones-196745153/sets/stephen-jones-jr-sons-cousins?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing
*/
export function soundcloud(value:string)
{
	const bits = value.match(/^\s*(https?:\/\/[^\/]*soundcloud\.com\/\S+)\s*$/);
	if (bits!=null) return bits[1];

	throw new InvalidFieldValueError(null,'invalid Soundcloud track URL');
}

