import { z } from 'zod';
import {staffResource as staffResourceModel} from 'Shared/model/StaffResource';
import {DocumentQueries } from 'Common/config/PageConfigTypes';
import * as t from 'Shared/model/basic';
import {StickySubNavWidget} from 'Browser/widgets/StickySubNavWidget';
import {IPageWrapper} from 'Browser/pages/PageWrapper';
import {AccessAreaName} from 'Common/permissions/AccessArea';
import {FrontendPageConfig, frontendData} from 'Common/FrontendPageConfig';

export const staffResourceParams = z.object({
	id: t.id
}).strict();	
export type StaffResourceParams = z.infer<typeof staffResourceParams>;

export const staffResourceData = frontendData.extend({
	pageDisplayName: z.string(),
	staffResource: staffResourceModel,
}).strict();
export type StaffResourceData = z.infer<typeof staffResourceData>;


export class StaffResource extends FrontendPageConfig<StaffResourceData,StaffResourceParams>
{
	static readonly pageName = 'frontend/staffResource';
	name() { return StaffResource.pageName; }

//TODO cf 'venueStaff' permission
//	access() { return 'venueFullEditor' as AccessAreaName; }
	access() { return 'venueFrontend' as AccessAreaName; }

	title() { return 'Resource'; }

	widgets(pageWrapper:IPageWrapper)
	{
		return {
			[StickySubNavWidget.key()]: new StickySubNavWidget()
		};
	}

	defaultSettings()
	{
		return {
			...super.defaultSettings(),
			htmlClasses: `${this.htmlClasses} staffResource`,
			template: 'App/frontend/staffResources/staffResourcePage.njk',
			pageDisplayName: 'Staff Resources'
		};
	}

	documents(params:StaffResourceParams):DocumentQueries
	{
		return ({
			...super.documents(params),
//XXX why are we putting all the docs on one page?		
			staffResources: {
				type: 'array',
				collection: 'staffResource',
				aggregate: () => [
					{'$sort': {'position':1}},
				],
			},
/*			
			staffResource: {
				type:'object',
				collection: 'staffResource',
				aggregate: [
					{$match: {_id:params.id}}
				],
			}
*/			
		});
	}
}

