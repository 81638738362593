import {css} from '@emotion/css';
import {palette} from 'Shared/artists/Palette';


function style()
{
	return css({
		display: 'inline-flex',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: '#395697',
		color: palette.background,
		borderWidth: 0,
		boxShadow: 'none',
		gap: 6,
		borderRadius: '0.4em',
		padding: '0.44em 2.25em',

		':focus': {
			outline: `1px dotted black`,
			outlineOffset: -'4px'
		},

		':hover': {
			filter: 'brightness(95%)'
		}
	});
};

export function FacebookAuthButton(props:{label:string,action:()=>void})
{
	return (
		<button class={style()} onClick={props.action}>
			<svg aria-hidden='true' width='18' height='18' viewBox='0 0 18 18'>
				<path d="M1.88 1C1.4 1 1 1.4 1 1.88v14.24c0 .48.4.88.88.88h7.67v-6.2H7.46V8.4h2.09V6.61c0-2.07 1.26-3.2 3.1-3.2.88 0 1.64.07 1.87.1v2.16h-1.29c-1 0-1.19.48-1.19 1.18V8.4h2.39l-.31 2.42h-2.08V17h4.08c.48 0 .88-.4.88-.88V1.88c0-.48-.4-.88-.88-.88H1.88z" fill="white"></path>
			</svg>
			<div>{props.label}</div>
		</button>
	);
}
