import {IPage} from 'Common/pages/IPage';
import {Component} from 'Common/components/Component';
import {CreateComponent} from 'Common/components/CreateComponent';
import {DeleteComponent} from 'Common/components/DeleteComponent';
import Assert from 'Common/Assert';
//import {NjkEditableArray} from 'Common/components/NjkEditableArray';
import {IPageData} from 'Common/PageConfig';
import {IPageWrapper} from 'Browser/pages/PageWrapper';
import {render} from 'solid-js/web';
import {Location} from 'Common/config/PageConfigTypes';

export class SolidPageWrapper implements IPageWrapper
{
	constructor(
		public page: IPage<IPageData>
	)
	{
		this.display = this.display.bind(this);
		this.refresh = this.refresh.bind(this);
		this.doDisplay = this.doDisplay.bind(this);
		this.createAndRedirect = this.createAndRedirect.bind(this);
		this.createItemAndRedirect = this.createItemAndRedirect.bind(this);
		this.confirmAndDelete = this.confirmAndDelete.bind(this);
	}

	async display():Promise<void>
	{
		await this.doDisplay();
	}

	async refresh():Promise<void>
	{
		await this.doDisplay();
	}

	private async doDisplay()
	{
		this.page.config.beforeDisplay(this.page.data);

		const title = this.page.data.titleTemplate(this.page.data);
		const template = this.page.data.template as (props:any)=>Element;	//XXX remove cast once NJK removed
		renderPage(title,template,this.page.data);

		for (const component of this.page.components()) 
			component.postDisplay();

		/* Note that callInitInstances() is called by MorphDOM callbacks */					
		for (const widget of this.page.widgets(this)) 
			widget.callAfterDisplay(document.documentElement);

		this.runEmbeddedScripts();
	}

	loadEndInit()
	{
//TODO remove these... not required for Solid...		
		for (const widget of this.page.widgets(this)) 
			widget.callInitInstances(document.documentElement);

		for (const component of this.page.components())
			component.postDisplay();

		for (const widget of this.page.widgets(this)) 
			widget.callAfterDisplay(document.documentElement);
	}

	private runEmbeddedScripts()
	{
		const pages = this.page.data.venue?.settings?.pages ?? {};
		let embed;
/*
TODO delete these page specific settings from PageSettings. Can test page.name() within the embed code
		if (this.page.name() == Home.pageName) 
			embed = pages.home?.frontend?.javascriptEmbedCode;
		else if (this.page.name() == Contact.pageName) 
			embed = pages.contact?.frontend?.javascriptEmbedCode;
		else if (this.page.name() == Reservations.pageName) 
			embed = pages.reservations?.frontend?.javascriptEmbedCode;
*/			

		if (embed) {
			const script = document.createElement('script');
			script.async = true;
			script.type = 'text/javascript';
			script.text = embed;
			document.getElementsByTagName('head')[0].appendChild(script);
		}

		//FIXME move into settings when possible (NB in NunjucksPageWrapper too)
		if (this.page.site.key=='DDDDDDDD' && this.page.name().startsWith('frontend/')) {

			const _hsq = window._hsq = window._hsq || [];
			_hsq.push(['setPath', window.location.pathname+window.location.search+window.location.hash]);

			if (window.isFirstPage) {
				console.log('SolidPageWrapper runEmbeddedScripts()  FIRST CALL');		

				const script = document.createElement('script');
				script.type = 'text/javascript';
				script.id = 'hs-script-loader';
				script.async = true;
				script.defer = true;
				script.src = '//js.hs-scripts.com/48040652.js';

				document.getElementsByTagName('head')[0].appendChild(script);
			}
			else {
				console.log('SolidPageWrapper runEmbeddedScripts()  LATER CALL');		
				_hsq.push(['trackPageView']);
			}
		}
	}


	/* Can be called during cleanup */
	leave()
	{
//		for (const widget of this.page.widgets(this))
//			widget.destroyAll();
//XXX perhaps should call component.leave() by default 
	}

//TODO delete these various event handlers I think...

	private evaluateRedirect(comp:Component,location:Location) 
	{
		Assert.exists(this.page.data);

		const def = (<any>comp).config;

		if (def?.redirect!=undefined) 
			document.location.href = def.redirect(location,this.page.data);
	}

//XXX Possibly put into CreateComponent or Page 
	public async createAndRedirect(compName:string,location:Location)
	{
		const comp = Assert.child(CreateComponent,this.page.component(compName));
		await comp.create();
		this.evaluateRedirect(comp,location);
	}

	public async createItemAndRedirect(compName:string,location:Location) 
	{
		const comp = Assert.child(EditableArray,this.page.component(compName));
		await comp.addItem(location,true);
		this.evaluateRedirect(comp,location);
	}

	public async confirmAndDelete(compName:string,location:Location)
	{
		if (!confirm('Are you sure you wish to delete this item?'))
			return;

		const comp = Assert.child(DeleteComponent,this.page.component(compName));
		await comp.delete(); 
		this.evaluateRedirect(comp,location);
	}
}


export function renderPage(title:string,template:(props:any)=>Element,data:any)
{
	const root = document.getElementById('root');
	root!.innerHTML = '';
	document.title = title;

	render (() => template(data), root!);
}


