import {Fonts as DifferentDrummerFonts} from 'Shared/frontend/fonts/differentDrummer';
import {Fonts as TheDockFonts} from 'Shared/frontend/fonts/theDock';
import {Questrial} from 'Shared/frontend/fonts/Questrial';
import {FrontendFonts} from 'Shared/view/VenueSettingsType';


export function lookupFont(settingName:FrontendFonts)
{
	switch(settingName) {
		case 'differentDrummer':	return DifferentDrummerFonts;
		case 'questrial':			return Questrial;
		case 'theDock':				return TheDockFonts;
	}
}
