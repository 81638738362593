import {Operation, Message} from 'Browser/Operation';
import {Id} from 'Common/Id';
import {Location} from 'Common/config/PageConfigTypes';
import {Event} from 'Shared/view/backend/Event';

export type PageLocation = any; //FIXME?

export class ListMessage implements Message
{ 
	static token = 'LIST'; 
	readonly operation = ListMessage.token;

	constructor(
		readonly page:string,
		readonly document:string,
		readonly location: PageLocation 	//TODO check how this is used
	) 
	{ }
}

export class ReadMessage implements Message
{ 
	static token = 'READ'; 
	readonly operation = ReadMessage.token;

	constructor(
		readonly page:string,
		readonly document:string,
		readonly location: PageLocation
	) 
	{ }
}

export class CreateMessage implements Message
{
	static token = 'CREATE'; 
	readonly operation = CreateMessage.token;

	constructor(
		readonly page:string,
		readonly component:string,
		readonly fields: {[name:string]:string}
	) 
	{ }
}

export class CreateMessage2 implements Message
{
	static token = 'CREATE2'; 
	readonly operation = CreateMessage2.token;

	constructor(
		readonly page:string,
		readonly permission:string,
		readonly fields: {[name:string]:string}
	) 
	{ }
}

export class DeleteMessage implements Message
{
	static token = 'DELETE'; 
	readonly operation = DeleteMessage.token;

	constructor(
		readonly page:string,
		readonly component:string,
		readonly id: Id,
	)
	{}
}

export class DeleteMessage2 implements Message
{
	static token = 'DELETE2'; 
	readonly operation = DeleteMessage2.token;

	constructor(
		readonly page:string,
		readonly permission:string,
		readonly id: Id,
	)
	{}
}

export class NjkUpdateMessage implements Message 
{
	static token = 'NJK_UPDATE'; 
	readonly operation = NjkUpdateMessage.token;

	constructor(
		readonly page:string,
		readonly component:string,
		readonly id:Id, 
		readonly location: PageLocation,
		readonly fields:{[name:string]:any}
	) 
	{ }
}

export class UpdateMessage implements Message 
{
	static token = 'UPDATE'; 
	readonly operation = UpdateMessage.token;

	constructor(
		readonly page:string,
		readonly component:string,
		readonly id:Id, 
		readonly location: PageLocation,
		readonly fields:{[name:string]:any}
	) 
	{ }
}

export class UpdateMessage2 implements Message 
{
	static token = 'UPDATE2'; 
	readonly operation = UpdateMessage2.token;

	constructor(
		readonly page:string,
		readonly permission:string,
		readonly id:Id, 
		readonly location: PageLocation,
		readonly fields:{[name:string]:any}
	) 
	{ }
}


export class UpdateKeyMessage implements Message 
{
	static token = 'UPDATE_KEY'; 
	readonly operation = UpdateKeyMessage.token;

	constructor(
		readonly page:string,
		readonly component:string,
		readonly id:Id, 
		readonly location: PageLocation,
		readonly oldField:string,
		readonly newField:string
	) 
	{ }
}

export class ReorderDocumentsMessage implements Message
{
	static token = 'REORDER_DOCUMENTS'; 
	readonly operation = ReorderDocumentsMessage.token;

	constructor(
		readonly page:string,
		readonly component:string, 
		readonly positions:Id[]
	) 
	{ }
}

/* --- Array messages: --- */

export class CreateArrayItemMessage implements Message
{
	static token = 'CREATE_ARRAY_ITEM'; 
	readonly operation = CreateArrayItemMessage.token;

	constructor(
		readonly page:string,
		readonly component:string,
		readonly id: Id,
		readonly location: Location,
		readonly fields: {[name:string]:string}
	) 
	{ }
}

export class CreateArrayItemMessage2 implements Message
{
	static token = 'CREATE_ARRAY_ITEM2'; 
	readonly operation = CreateArrayItemMessage2.token;

	constructor(
		readonly page:string,
		readonly permission:string,
		readonly id: Id,
		readonly location: Location,
		readonly fields: {[name:string]:string}
	) 
	{ }
}

export class ReorderArrayMessage implements Message
{
	static token = 'REORDER_ARRAY'; 
	readonly operation = ReorderArrayMessage.token;

	constructor(
		readonly page:string,
		readonly component:string, 
		readonly id:Id,
		readonly location: Location,
		readonly oldIndex: number,
		readonly newIndex: number
	) 
	{ }
}

export class ReorderArrayMessage2 implements Message
{
	static token = 'REORDER_ARRAY2'; 
	readonly operation = ReorderArrayMessage2.token;

	constructor(
		readonly page:string,
		readonly permission:string, 
		readonly id:Id,
		readonly location: Location,
		readonly oldIndex: number,
		readonly newIndex: number
	) 
	{ }
}

/*
	Moves a subdocument from one place in a document to another.
	CURRENTLY assuming these are array items, but they can lie at different places in the hierarchy.
*/
export class MoveMessage implements Message
{
	static token = 'MOVE'; 
	readonly operation = MoveMessage.token;

	constructor(
		readonly page:string,
		readonly permission:string, 
		readonly id:Id,
		readonly source: Location,
		readonly target: Location
	) 
	{ }
}

export class DeleteArrayItemMessage implements Message
{
	static token = 'DELETE_ARRAY_ITEM'; 
	readonly operation = DeleteArrayItemMessage.token;

	constructor(
		readonly page:string,
		readonly component:string,
		readonly id: Id,
		readonly location: Location,
		readonly index: number
	)
	{}
}

export class DeleteArrayItemMessage2 implements Message
{
	static token = 'DELETE_ARRAY_ITEM2'; 
	readonly operation = DeleteArrayItemMessage2.token;

	constructor(
		readonly page:string,
		readonly permission:string,
		readonly id: Id,
		readonly location: Location,
		readonly index: number
	)
	{}
}

/* --- Images --- */

export class UploadImageMessage implements Message
{
	static token = 'UPLOAD_IMAGE'; 
	readonly operation = UploadImageMessage.token;
}

export class CopyArtistImageMessage implements Message
{   
	static token = 'COPY_ARTIST_IMAGE'; 
	readonly operation = CopyArtistImageMessage.token;

	readonly page = Event.pageName;
	readonly component = 'lineupImage';

	constructor(
		readonly lineupIndex:number,
		readonly eventId: Id,
		readonly artistHash: string
	)
	{ }
}

export class DeleteImageMessage implements Message
{
	static token = 'DELETE_IMAGE'; 
	readonly operation = DeleteImageMessage.token;

	constructor(
		readonly page:string,
		readonly component:string,
		readonly id: Id,
		readonly location:Location
	)
	{}
}

export class DeleteImageMessage2 implements Message
{
	static token = 'DELETE_IMAGE2'; 
	readonly operation = DeleteImageMessage2.token;

	constructor(
		readonly page:string,
		readonly permission:string,
		readonly id: Id,
		readonly location:Location
	)
	{}
}

export class SearchArtistsMessage implements Message
{
	static token = 'SEARCH_ARTISTS'; 
	readonly operation = SearchArtistsMessage.token;

	constructor(
		readonly page:string,
		readonly terms:string
	)
	{}
}

export class ReadArtistMessage implements Message
{ 
	static token = 'READ_ARTIST'; 
	readonly operation = ReadArtistMessage.token;

	constructor(
		readonly page:string,
		readonly artistId:Id
	) 
	{ }
}


/* --- Login, rego, etc --- */

export class LoginMessage implements Message
{
	static token = 'LOGIN'; 
	readonly operation = LoginMessage.token;

	constructor(
		readonly userName:string,
		readonly password:string
	)
	{}
}

export class RegisterMessage implements Message
{
	static token = 'REGISTER'; 
	readonly operation = RegisterMessage.token;

	constructor(
		readonly firstName:string,
		readonly lastName:string,
		readonly userName:string
	)
	{}
}

export class ChangePasswordMessage implements Message
{
	static token = 'CHANGE_PASSWORD'; 
	readonly operation = ChangePasswordMessage.token;

	constructor(
		readonly password:string,
		readonly currentPassword:string|undefined
	)
	{}
}


/* --- Old operations for admin sites --- */

//TODO convert to Message when and if they are used again...

export class CreateVenueOperation extends Operation
{
    static token():string { return 'CREATE_SITE'; }

    message(name:string,timezone:string)
	{
		return {operation:CreateVenueOperation.token(),name:name,timezone:timezone};
	}
}

export class UpdateVenueOperation extends Operation
{
    static token():string { return 'UPDATE_VENUE'; }

//    message(siteId:number,field:string,value:string):UpdateVenueMessage
    message(siteKey:number,field:string,value:string)
	{
		return {siteKey:siteKey,operation:UpdateVenueOperation.token(),field:field,value:value}; 
	}
}

export class CreateDomainOperation extends Operation
{
    static token():string { return 'CREATE_DOMAIN'; }

    message(siteId:number,domain:string)
	{
		return {siteId:siteId,operation:CreateDomainOperation.token(),domain:domain};
	}
}

export class DeleteDomainOperation extends Operation
{
    static token():string { return 'DELETE_DOMAIN'; }

    message(id:number)
	{
		return {operation:DeleteDomainOperation.token(),id:id};
	}
}

export class ListVenuesOperation extends Operation
{
    static token():string { return 'LIST_VENUES'; }

    message()
	{
		return {operation:ListVenuesOperation.token() };
	}
}

export class ListGroupsOperation extends Operation
{
    static token():string { return 'LIST_GROUPS'; }

    message()
	{
		return { operation:ListGroupsOperation.token() };
	}
}

export class ListUsersOperation extends Operation
{
    static token():string { return 'LIST_USERS'; }

    message()
	{
		return { operation:ListUsersOperation.token() };
	}
}

export class RestartServerOperation extends Operation
{
	static token():string { return 'RESTART_SERVER'; }

	message()
	{
		return {operation:RestartServerOperation.token()};
	}
}

