import {SelectorWidget} from 'Browser/widgets/SelectorWidget';
import {DateTimeWidget} from 'Browser/widgets/DateTimeWidget';
import Assert from 'Common/Assert';
import {DateTime} from 'luxon';
import Flatpickr from 'flatpickr';
import {runOnComponent} from 'Browser/Run';
import {IPageWrapper} from 'Browser/pages/PageWrapper';

const className = 'calendarEntry';

export class CalendarEntryWidget extends SelectorWidget
{
	constructor(
		private pageWrapper:IPageWrapper
	)
	{
		super(['.'+className]);

		this.changeFrequency = this.changeFrequency.bind(this);
		this.gotStartSingle = this.gotStartSingle.bind(this);
		this.gotStartTime = this.gotStartTime.bind(this);
		this.gotStartDate = this.gotStartDate.bind(this);
		this.gotEndSingleOpen = this.gotEndSingleOpen.bind(this);
		this.gotEndDateOpen = this.gotEndDateOpen.bind(this);
		this.gotEndTimeOpen = this.gotEndTimeOpen.bind(this);
		this.clearEndSingle = this.clearEndSingle.bind(this);
		this.clearEndDate = this.clearEndDate.bind(this);
		this.clearEndTime = this.clearEndTime.bind(this);
	}	

	static key() { return 'calendarEntry'; }

	afterDisplay(anchorNode: HTMLElement): void 
	{
		this.initOnOpenHandlers(anchorNode);
	}

	private initOnOpenHandlers(anchorNode:HTMLElement)
	{
		const endDateTime = anchorNode.querySelector('.singleDateTimes .endSingleField .bf-dateTime-input');
		if (endDateTime!=undefined && (<any>endDateTime)._flatpickr==undefined) 
			(<any>endDateTime)._flatpickr.config.onOpen.push(this.gotEndSingleOpen);

		const endDate = anchorNode.querySelector('.recurringDateTimes .endDateField .bf-dateTime-input');
		if (endDate!=undefined && (<any>endDate)._flatpickr==undefined) 
			(<any>endDate)._flatpickr.config.onOpen.push(this.gotEndDateOpen);

		const endTime = anchorNode.querySelector('.recurringDateTimes .endTimeField .bf-dateTime-input');
		if (endTime!=undefined && (<any>endTime)._flatpickr==undefined) 
			(<any>endTime)._flatpickr.config.onOpen.push(this.gotEndTimeOpen);
	}

	public changeFrequency(node:HTMLElement)
	{
		const dateTimeWidget = Assert.have(this.pageWrapper.page.widget(this.pageWrapper,'dateTimePicker'));

		const root = Assert.htmlElement(node.closest('.calendarEntry'));
		const [compName,path] = getComponentAndPath(root);

		dateTimeWidget.destroyInstances(root);

		runOnComponent(null,compName,'updateSetting',path,'frequency');

		dateTimeWidget.callInitInstances(root);
		this.initOnOpenHandlers(root);
	}

 	public gotStartSingle(node:HTMLElement)
	{
		const root = Assert.htmlElement(node.closest('.singleDateTimes'));
		const startNode = Assert.htmlElement(root.querySelector(`.startSingleField`));
		const endNode = Assert.htmlElement(root.querySelector(`.endSingleField`));
 		this.startBody('startSingle',root,startNode,endNode);
	}

 	public gotEndSingleOpen(selectedDates:Date[],dateStr:string,instance:Flatpickr.Instance)
	{
		const root = Assert.have(instance.element.closest(`.singleDateTimes`));
		const startNode = Assert.htmlElement(root.querySelector('.startSingleField'));
		const endNode = Assert.htmlElement(root.querySelector('.endSingleField'));
		this.openBody(startNode,endNode);
	}

 	public clearEndSingle(node:HTMLInputElement)
	{
		const endNode = Assert.htmlElement(node.closest('.endInputter')?.querySelector('.bf-dateTime'));
		const widget = <DateTimeWidget>this.pageWrapper.page.widget(this.pageWrapper,'dateTimePicker');
		widget.clearSelection(endNode);
	}

 	public gotStartTime(node:HTMLElement)
	{
		const root = Assert.htmlElement(node.closest('.recurringTimes'));
		const startNode = Assert.htmlElement(root.querySelector('.startTimeField'));
		const endNode = Assert.htmlElement(root.querySelector('.endTimeField'));
 		this.startBody('startTime',root,startNode,endNode);
	}

 	public gotEndTimeOpen(selectedDates:Date[],dateStr:string,instance:Flatpickr.Instance)
	{
		const root = Assert.have(instance.element.closest(`.recurringTimes`));
		const startNode = Assert.htmlElement(root.querySelector('.startTimeField'));
		const endNode = Assert.htmlElement(root.querySelector('.endTimeField'));
		this.openBody(startNode,endNode);
	}

 	public clearEndTime(node:HTMLInputElement)
	{
		const endNode = Assert.htmlElement(node.closest('.endInputter')?.querySelector('.bf-time'));
		const widget = <DateTimeWidget>this.pageWrapper.page.widget(this.pageWrapper,'dateTimePicker');
		widget.clearSelection(endNode);
	}

 	public gotStartDate(node:HTMLElement)
	{
		const root = Assert.htmlElement(node.closest('.recurringDates'));
		const startNode = Assert.htmlElement(root.querySelector('.startDateField'));
		const endNode = Assert.htmlElement(root.querySelector('.endDateField'));
 		this.startBody('startDate',root,startNode,endNode);
	}

 	public gotEndDateOpen(selectedDates:Date[],dateStr:string,instance:Flatpickr.Instance)
	{
		const root = Assert.have(instance.element.closest(`.recurringDates`));
		const startNode = Assert.htmlElement(root.querySelector('.startDateField'));
		const endNode = Assert.htmlElement(root.querySelector('.endDateField'));
		this.openBody(startNode,endNode);
	}

 	public clearEndDate(node:HTMLInputElement)
	{
		const endNode = Assert.htmlElement(node.closest('.endInputter')?.querySelector('.bf-date'));
		const widget = <DateTimeWidget>this.pageWrapper.page.widget(this.pageWrapper,'dateTimePicker');
		widget.clearSelection(endNode);
	}

 	private startBody(settingName:string,root:HTMLElement,startNode:HTMLElement,endNode:HTMLElement)
	{
		const [compName,path] = getComponentAndPath(root);

		//XXX might be better calling directly
		runOnComponent(null,compName,'updateSetting',path,settingName);

		const startValueNode = Assert.htmlInputElement(startNode.querySelector('.bf-value'));
		const endValueNode = Assert.htmlInputElement(endNode.querySelector('.bf-value'));

		const startValue = startValueNode.value;
		const endValue = endValueNode.value;

		if (startValue!=null && endValue!=null) {
			const start = DateTime.fromISO(startValue);
			const end = DateTime.fromISO(endValue);

			if (start > end) {
				endValueNode.value = '';

				/* Think this updates the page data along the way. All done in the current thread. */
				endNode.dispatchEvent(new CustomEvent('change'));

				this.pageWrapper.refresh();
				//XXX would be neater calling via DateTimeWidget
				getFlatpickr(endNode,'.bf-dateTime-input').setDate('',false);
			}
		}
	}

 	private openBody(startNode:HTMLElement,endNode:HTMLElement)
	{
		const startValueNode = Assert.htmlInputElement(startNode.querySelector('.bf-value'));
		const endValueNode = Assert.htmlInputElement(endNode.querySelector('.bf-value'));

		const startDateTime = startValueNode.value;
		const endDateTime = endValueNode.value;

		const flatpickr = getFlatpickr(endNode,'.bf-dateTime-input');
		const start = DateTime.fromISO(startDateTime);

		if (endDateTime==null || endDateTime=='') 
			flatpickr.setDate(start.toJSDate());

		flatpickr.set('minDate',start.toJSDate());
	}
}

function getFlatpickr(root:HTMLElement,selector:string):Flatpickr.Instance
{
	return <Flatpickr.Instance>(<any>root.querySelector(selector))._flatpickr;
}

function getComponentAndPath(root:HTMLElement):[string,string]
{
	const node = Assert.htmlElement(root.closest('.calendarEntry'));
	return [Assert.have(node.dataset.component),Assert.have(node.dataset.path)];
}

