import {Roles} from 'Common/permissions/Roles';
import {RolesLoader} from 'Common/permissions/RolesLoader';

export class User 
{
	constructor(
		readonly firstName:string,
		readonly lastName:string,
		readonly email:string,
		readonly googleConnected:boolean,
		readonly facebookConnected:boolean,
		readonly havePassword:boolean,
		readonly roles: Roles
	)
	{ }

	public static fromJson(json:any):User
	{
		return new User(json.firstName,json.lastName,json.email,
			json.googleConnected,json.facebookConnected,json.havePassword,(new RolesLoader()).createRoles(json.roles));
	}
}

