import {VenueView} from 'Shared/view/VenueView';
import {Members as FrontendMembers} from 'Shared/view/frontend/Members';



export class TheTownieView extends VenueView
{
	view()
	{
		return <any>({
			...super.view(),
			[FrontendMembers.pageName]:	FrontendMembers,
		});
	}
}

