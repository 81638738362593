import {BuildBasics} from 'Common/BuildBasics';
import {Links} from 'Shared/config/Link';
import {Nav as TheDockNav} from 'Shared/config/TheDockNav';
import {IVenueConfig} from 'Shared/config/IVenueConfig';
import {VenueBasics} from 'Common/VenueBasics';
import {VenueView} from 'Shared/view/VenueView';
import {VenueRoutes} from 'Shared/routes/VenueRoutes';


export class TheDockConfig implements IVenueConfig
{
	view() { return (new VenueView()).view(); }

	link() { return Links; }

	nav() { return TheDockNav; }

	routes(inBrowser:boolean,build:BuildBasics,site:VenueBasics)
	{
		return (new VenueRoutes(inBrowser,build,site)).routes();
	}
}
