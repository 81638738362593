import Links from 'Common/Links';
import {Link as ConfigLink,ILinks as ConfigLinks} from 'Common/config/ILinks';
import Asset from 'Common/Assert';

export class VenueLinks extends Links
{
	private map: Map<string,ConfigLink>;

//XXX think we should probably remove this class now  

	constructor(linkConfig:ConfigLinks)
	{
		super();
//FIXME get rid of this map
		this.map = new Map(Object.entries(linkConfig));
	}

	link(name:string):ConfigLink
	{
		const ret = this.map.get(name);
		Asset.exists(ret);
		return ret;
	}

	public resolveLink(name:string,params:{[name:string]:string}):string|null    //XXX perhaps params should accept numbers too   
	{
		const template = this.map.get(name);
		if (template==null) {
			log.warn(`The link for page '${name}' is undefined`);
			return null;
		}

		if (template.type=='page') {
			let url = template.target;
			for (const [param,value] of Object.entries(params))
				url = url.replaceAll(':'+param,<string>value);
			return url;
		}
		if (template.type=='external')
			return template.target;
		throw new Error('Link type not implemented');
	}
}

