import ConfigError from 'Common/config/ConfigError';
import {ConfigPageError} from 'Common/config/ConfigPageError';
import CompilationError from 'Common/errors/CompilationError';

export default class BuildErrorFactory
{
	public static fromJson(data:any)
	{
		switch(data.errorType) {
			case ConfigError.type():		return ConfigError.fromJson(data);
			case ConfigPageError.type():	return ConfigPageError.fromJson(data);
			case CompilationError.type():	return CompilationError.fromJson(data);

			default: throw new Error('Unknown error: '+data.errorType);
		}
	}
}
