import {tabsData} from 'Common/components/Tabs';
import {MusicEvent, eventDoc,EventDoc} from 'Shared/model/Event';
import { z } from 'zod';
import {Components,DeleteComponent,DocumentQueries,ImageUploaderComponent, Location, OverlayComponent} from 'Common/config/PageConfigTypes';
import {IPage as CommonIPage} from 'Common/pages/IPage';
import * as imageSize from 'Shared/model/ImageSizes';
import {HtmlEditorWidget} from 'Browser/widgets/HtmlEditorWidget';
import {CalendarEntryWidget} from 'Browser/widgets/CalendarEntryWidget';
import {DateTimeWidget} from 'Browser/widgets/DateTimeWidget';
import {ImageUploadWidget} from 'Browser/widgets/ImageUploadWidget';
import {PageFunctions} from 'Browser/PageFunctions';
import Assert from 'Common/Assert';
import {HtmlCaptureWidget} from 'Browser/widgets/HtmlCaptureWidget';
import {MultiSelectWidget} from 'Browser/widgets/MultiSelectWidget';
import {Events} from 'Shared/view/backend/Events';
import {AppleField, FacebookField, FacebookVideoUrlField, InstagramField, SpotifyField, UrlField, YoutubeField} from 'Browser/NjkInputTypes';
import {processInput} from 'Browser/Input';
import {IPageWrapper} from 'Browser/pages/PageWrapper';
import {AccessAreaName} from 'Common/permissions/AccessArea';
import {BackendPageConfig, backendData, backendParams} from 'Common/BackendPageConfig';
import {VenueUrls} from 'Common/VenueUrls';
import {ArtistSearchWidget} from 'Browser/ArtistSearchWidget';
import {genres, performanceType,performanceTypeKeys, genreKeys} from 'Shared/ArtistTypes';


export const eventParams = backendParams.extend({
	slug: z.string(),
	topTab: z.string().optional(),
	secondTab: z.string().optional()
}).strict();	
export type EventParams = z.infer<typeof eventParams>;


//TODO Run a validation before render, at least in development

//XXX could potentially be generated in the build by processing 'event()'
//XXX to infer would require piecing extracted bits and pieces together and then you'd still miss out on
//    enumerations etc without explicitly replicating then as types as well as runtime things

export const eventData = backendData.extend({
	params: eventParams,
	event: eventDoc,
	outerTabs: tabsData,
	previewTabs: tabsData,
	performanceTypes: z.record(performanceTypeKeys,z.string()),
	genres: z.record(genreKeys,z.string())
}).strict();
export type EventData = z.infer<typeof eventData>;


//XXX maybe remove 'EventParams' here... could probably use EventData.params

export class Event extends BackendPageConfig<EventData,EventParams>
{
	static readonly pageName = 'backend/event';
	name() { return Event.pageName; }

	access() { return 'venueEventEditor' as AccessAreaName; }


	widgets(pageWrapper:IPageWrapper)
	{
		const urls = new VenueUrls(this.build,this.venue.key);

		return {
			[ImageUploadWidget.key()]: new ImageUploadWidget(pageWrapper),
			[HtmlEditorWidget.key()]: new HtmlEditorWidget(urls),
			[HtmlCaptureWidget.key()]: new HtmlCaptureWidget(pageWrapper),
			[DateTimeWidget.key()]: new DateTimeWidget(),
			[MultiSelectWidget.key()]: new MultiSelectWidget(),
			[CalendarEntryWidget.key()]: new CalendarEntryWidget(pageWrapper),
			[ArtistSearchWidget.key()]: new ArtistSearchWidget(pageWrapper)
		};
	}

	defaultSettings()
	{
		return {
			...super.defaultSettings(),
			htmlClasses: `${this.htmlClasses} event`,
			template: 'App/backend/eventPage/eventPage.njk'
		};
	}

	documents(params:EventParams): DocumentQueries
	{
		return ({
			...super.documents(params),
			event: {
				type:'object',
				collection: 'event',
				aggregate: () => [
					{$match: {slug:params.slug}}
				],
			}
		});
	}

//TODO just instantiate Components and Documents here directly
//TODO think remove urls and transforms from constructor	

	components(): Components<EventData>
	{
		const ret = <Components<EventData>>({
			breadcrumbs: {
				type: 'breadcrumbs',
				parent: {page:Events.pageName,component:'breadcrumbs'},
				link: (data:EventData) => '/admin/event/'+data.params.slug		//TODO move link routine to VenueLinks
			},
			editEvent: {
				type:'edit',
				collection: 'event',
				locate: (doc:EventDoc,) => doc,
				locateParams: () => ({}),
				beforeDbUpdate: (page:CommonIPage<EventData>,fields:{[name:string]:any}) => {
//TODO add monitoring of lineup and times
					if (page.data.event.poster!=undefined) {
						page.data.event.poster = {...page.data.event.poster,isCurrent:false};
						fields.poster = page.data.event.poster;
					}
				}
			},

			lineup: {
				type: 'repeater',
				selector: '#lineup repeater-items',
				collection: 'event',
				locate:				(doc:EventDoc,location:Location) => (<MusicEvent>doc).lineup[Assert.toNumber(location[0])],
				locateListParent:	(doc:EventDoc) => doc,
				fieldName:			() => 'lineup',
				initialValues: () => ({
					bookingStatus:'draft',
					useAgreement: false
				}),
				processInput: (location:Location,field:string,value:string) => processInput(lineupFieldTypes(field),value), 
			},


			deleteEvent: <DeleteComponent<EventData>>{
				type: 'delete',
				collection: 'event',
//FIXME place in links.json?
				redirect: () => `/admin/events`
			},
			editTimes: {
				type:'edit',
				collection: 'event',
				locate: (doc:EventDoc) => doc.times,
			},
			outerTabs : {
				type: 'tabs',
				names: ['details','marketing'],
				initial: (data:EventData) => data.params.topTab ?? 'details'
			},
			previewTabs: {
				type: 'tabs',
				names: ['poster','webpage'],
				initial: (data:EventData) => data.params.secondTab ?? 'poster'
			},
			poster: <ImageUploaderComponent>{
				type:'imageUploader', 
				collection: 'event',
				field: 'poster',
				locate: (doc:EventDoc) => doc,
				assetFolder: 'Event-poster',
				sizes: imageSize.eventPoster,
				previewSize: 'medium'
			},
//TODO try creating a wrapper method eg ...imageUploaderWithAspectSelector(****)    [ALT: separate component]. wrapper is lightweight
			/* NOTE: these components are using the same DB field and assets folder: */
//XXX ==> landscapeImage etc			
			landscapePhoto: <ImageUploaderComponent>{
				type:'imageUploader', 
				collection: 'event',
				field: 'image',
				locate: (doc:EventDoc) => doc,
				assetFolder: 'Event-image',
				sizes: imageSize.landscape, 
				previewSize: 'medium'
			},
			squarePhoto: <ImageUploaderComponent>{
				type:'imageUploader', 
				collection: 'event',
				field: 'image',
				locate: (doc:EventDoc) => doc,
				assetFolder: 'Event-image',
				sizes: imageSize.square, 
				previewSize: 'medium'
			},
			portraitPhoto: <ImageUploaderComponent>{
				type:'imageUploader', 
				collection: 'event',
				field: 'image',
				locate: (doc:EventDoc) => doc,
				assetFolder: 'Event-image',
				sizes: imageSize.portrait4x5,
				previewSize: 'medium'
			},
			suppliedPoster: <ImageUploaderComponent>{
				type:'imageUploader', 
				collection: 'event',
				field: 'image',
				locate: (doc:EventDoc) => doc,
				assetFolder: 'Event-image',
				sizes: imageSize.portrait,
				previewSize: 'medium'
			},
			facebookImage: <ImageUploaderComponent>{
				type:'imageUploader', 
				collection: 'event',
				field: 'facebookImage',
				locate: (doc:EventDoc) => doc,
				assetFolder: 'Event-facebookImage',
				sizes: imageSize.facebookBanner,
				previewSize: 'medium'
			},
			instagramImage: <ImageUploaderComponent>{
				type:'imageUploader', 
				collection: 'event',
				field: 'instragramImage',
				locate: (doc:EventDoc) => doc,
				assetFolder: 'Event-instagramImage',
				sizes: imageSize.square,
				previewSize: 'medium'
			},
			lineupImage: <ImageUploaderComponent>{
				type:'imageUploader', 
				collection: 'event',
				locate: (doc:EventDoc,location:Location) => (<MusicEvent>doc).lineup && (<MusicEvent>doc).lineup[Assert.toNumber(location[0])],
				field: 'image',
				assetFolder: 'Lineup-image', 	//TODO auto-construct assetFolder?
				sizes: imageSize.landscape,
				previewSize: 'medium'
			},
			overlays: <OverlayComponent>{
				type: 'overlay',
				panes: {
					imageSelectorOverlay: false,
					eventPageOverlay: false,
					announceFacebookOverlay: false,
					announceInstagramOverlay: false,
					videosOverlay: false,
					websiteMusicFacebookOverlay: false,
					previewArtistBooking: false,
				}
			},
			revealBooking: { type: 'extraData', initial: false },
			revealOffer: { type: 'extraData', initial: false },
			revealAgreement: { type: 'extraData', initial: false },
			revealMarketingAssets: { type: 'extraData', initial: false },
			revealConfirmation: { type: 'extraData', initial: false },
			revealProfile: { type: 'extraData', initial: false },
			revealPlan: { type: 'extraData', initial: false },
		});	

		return ret;
	}

	beforeDisplay(data:EventData)
	{
		super.beforeDisplay(data);

		const funcs = new PageFunctions();
		const urls = new VenueUrls(this.build,this.venue.key);

//XXX cf moving imageUploadUrl into the or component, widget, or njk
		if (data.event.poster==undefined) 
			data.event.poster = <any>{};
		funcs.imageUploadUrl(urls,data.event._id,Event.pageName,'poster',<any>data.event.poster,'Event-poster','event',[],'medium','defaults/profile4x5.png');
		(<any>data.event.poster).url = funcs.createImageUrl(urls,data.event.poster,'Event-poster','medium');
		

		//XXX would probably be better to put 'imageDimensions' into 'image' here - so everything is either defined or undefined
		if (data.event.image==undefined) 
			(<any>data.event).image = {};			//TODO any
		if (data.event.imageDimensions==undefined) 
			data.event.imageDimensions = 'portrait';

		funcs.imageUploadUrl(urls,data.event._id,Event.pageName,'landscapePhoto',<any>data.event.image,'Event-image','event',[],'medium','defaults/profile3x2.png');
		data.event.image!.url = funcs.createImageUrl(urls,data.event.image,'Event-image','medium');

		let i=0;
		for (const act of (<MusicEvent>data.event).lineup ?? []) {
			if (act.image==undefined) 
				(<any>act).image = {};			//TODO any
			funcs.imageUploadUrl(urls,data.event._id,Event.pageName,'landscapePhoto',act.image!,'Lineup-image','event',[i],'medium','defaults/profile3x2.png');
			act.image!.url = funcs.createImageUrl(urls,act.image,'Lineup-image','medium');
			i++;
		}

		this.updateLocatorBar(data);

		data.performanceTypes = performanceType;
		data.genres = genres;
	}

	private updateLocatorBar(d:EventData)
	{
		let url;
		const slug = d.params.slug;

		if (d.outerTabs?.selected == 'details') {
			if (d.previewTabs?.selected == 'webpage')
				url = `/admin/event/${slug}/details/webpage`;
			else
				url = `/admin/event/${slug}/details/poster`;
		}
		else if (d.outerTabs?.selected == 'marketing') 
			url = `/admin/event/${slug}/marketing`;
		else 
			Assert.check(false);  //XXX or redirect...

		(new PageFunctions()).updateLocationWithTabs(this.inBrowser,url);
	}
}


function lineupFieldTypes(field:string)
{
	switch(field) {
		case 'facebook':		return new FacebookField();
		case 'instagram':		return new InstagramField();
		case 'website':			return new UrlField();
		case 'youtubeVideoUrl':	return new YoutubeField();
		case 'facebookVideoUrl':return new FacebookVideoUrlField();
		case 'spotify':			return new SpotifyField();
		case 'appleMusic':		return new AppleField();
//		case 'bandcamp':		return new BandcampField();    XXX needs modification  
//		case 'soundcloud':		return new SoundcloudField();  XXX dont really trust this at the moment
	};
	return null;
}
