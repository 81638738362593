import {HttpErrorMessage} from 'Browser/HttpErrorInterface';
import BuildError from 'Common/config/BuildError';

export default class ConfigError extends BuildError
{
	readonly file:string|null;
	readonly message:string;
	readonly line:number|null;
	readonly column:number|null;
	readonly operation:number|null;
	readonly extract:string|null;

/*XXX
	- This extended info could go in child classes.
	- Could pass object into an object constructor instead eg {file:xxx,line:xxx}
 */

	/* 
		Warning: only use ConfigError locally (ie within a file). 
		Use BuildErrors to pass errors between files.
	 */
	constructor(file:string|null,message:string,
		line?:number|null,column?:number|null,
		operation?:number|null,extract?:string|null)
	{
		super();
		this.file = file;
		this.message = message;
		this.line = line ?? null;
		this.column = column ?? null;
		this.operation = operation ?? null;
		this.extract = extract ?? null;
	}

	static type():string { return 'configError'; }

	static msg(message:string)
	{
		return new ConfigError(null,message,null,null,null,null);
	}

	public toString():string
	{
		let ret='';
		if (this.file!=null) {
			ret += this.file;
			if (this.line!=null) {
				ret += '(line:'+this.line;
				if (this.column!=null)
					ret+=',column:'+this.column;
				ret+=')';
			}
			if (this.operation!=null) 
				ret+='(operation:'+this.operation+')';
			ret+=': ';
		}
		ret += this.message;
		if (this.extract!=null)
			ret+='  Extract:\n'+this.extract;
		return ret;
	}

	public toJson():HttpErrorMessage
	{
		const ret:any = {httpCode:200,errorType:ConfigError.type()};
		if (this.message!=null)		ret.message = this.message;
		if (this.line!=null)		ret.line = this.line;
		if (this.column!=null)		ret.column = this.column;
		if (this.operation!=null)	ret.operation = this.operation;
		if (this.extract!=null)		ret.extract = this.extract;
		return ret;
	}

	public static fromJson(data:any):ConfigError
	{
		return new ConfigError(data.file,data.message,data.line,data.column,data.operation,data.extract);
	}
}

