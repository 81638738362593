
export function TikTok()
{
	return (
		<svg id="tiktok" class="icon" version="1.0" xmlns="http://www.w3.org/2000/svg"
		  viewBox="0 0 900.000000 900.000000"
		 preserveAspectRatio="xMidYMid meet">

		<g transform="translate(0.000000,900.000000) scale(0.100000,-0.100000)">
		<path d="M4170 8909 c-480 -36 -973 -154 -1405 -339 -147 -63 -454 -217 -542
		-273 -24 -15 -44 -27 -47 -27 -2 0 -53 -33 -112 -73 -520 -347 -926 -753
		-1279 -1278 -41 -61 -75 -113 -75 -115 0 -3 -12 -23 -27 -47 -77 -119 -265
		-508 -335 -692 -24 -64 -112 -333 -132 -405 -126 -456 -180 -1001 -146 -1460
		14 -180 39 -391 56 -466 8 -33 14 -70 14 -82 0 -12 6 -48 14 -80 8 -31 22 -88
		31 -127 46 -200 137 -476 225 -680 61 -145 216 -452 272 -540 14 -22 27 -45
		29 -50 4 -11 135 -204 195 -290 84 -119 307 -377 447 -518 165 -165 376 -347
		551 -474 61 -45 267 -183 272 -183 3 0 23 -12 47 -27 94 -60 412 -218 577
		-288 114 -49 274 -105 430 -151 30 -9 73 -22 95 -30 23 -8 50 -14 61 -14 11 0
		40 -6 65 -14 145 -45 476 -96 745 -116 376 -27 869 8 1212 86 232 53 231 53
		452 123 174 56 213 70 355 131 148 63 448 214 543 273 20 13 42 26 47 28 11 4
		204 135 290 195 115 81 371 302 506 436 441 438 740 881 984 1458 70 164 169
		468 209 640 169 733 169 1368 0 2100 -20 88 -35 139 -93 320 -67 209 -94 277
		-182 468 -60 130 -182 365 -222 429 -15 24 -27 44 -27 47 0 2 -33 53 -73 112
		-344 515 -766 937 -1281 1281 -59 40 -110 73 -112 73 -3 0 -22 11 -44 25 -139
		88 -496 261 -695 337 -61 23 -329 110 -405 132 -53 14 -131 33 -255 61 -354
		79 -842 112 -1235 84z m1554 -1281 c2 -7 9 -69 16 -138 20 -205 90 -429 183
		-585 238 -402 583 -653 1026 -745 52 -11 131 -20 177 -20 45 0 89 -3 98 -6 14
		-5 16 -62 16 -539 l0 -534 -21 -6 c-49 -16 -371 15 -529 51 -128 29 -418 122
		-478 153 -9 5 -62 31 -117 59 -55 27 -116 59 -135 71 -19 11 -42 24 -50 28 -8
		4 -43 26 -78 50 -113 77 -100 217 -104 -1164 -4 -1102 -6 -1215 -22 -1293 -58
		-285 -129 -474 -259 -694 -89 -151 -166 -247 -321 -401 -148 -148 -275 -242
		-456 -338 -124 -66 -292 -134 -395 -160 -33 -9 -80 -21 -104 -27 -90 -24 -319
		-50 -436 -50 -153 0 -422 39 -546 78 -128 42 -163 54 -237 86 -46 20 -110 52
		-143 71 -33 19 -62 35 -65 35 -11 0 -210 136 -264 179 -153 125 -306 296 -414
		463 -60 92 -170 302 -186 353 -5 16 -19 62 -33 100 -37 109 -54 173 -71 270
		-37 222 -44 369 -26 543 25 224 65 382 149 587 59 143 182 349 284 475 133
		165 363 367 512 450 33 19 78 44 100 56 198 112 442 190 705 226 154 21 447
		15 488 -11 10 -6 13 -118 13 -541 0 -293 -4 -537 -8 -541 -4 -4 -60 1 -123 12
		-115 20 -153 19 -285 -2 -173 -29 -319 -101 -465 -229 -115 -101 -211 -253
		-261 -412 -20 -64 -22 -94 -23 -253 0 -170 2 -185 28 -264 49 -150 112 -254
		223 -369 281 -291 718 -361 1071 -171 167 89 308 232 391 395 32 65 44 96 72
		199 18 68 19 140 19 2281 0 1705 3 2213 12 2222 18 18 1067 17 1072 0z"/>
		</g>
		</svg>
	);
}

