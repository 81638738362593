import {staffResource as staffResourceModel} from 'Shared/model/StaffResource';
import {DocumentQueries } from 'Common/config/PageConfigTypes';
import {IPageWrapper} from 'Browser/pages/PageWrapper';
import {AccessAreaName} from 'Common/permissions/AccessArea';
import { z } from 'zod';
import {FrontendPageConfig, frontendData} from 'Common/FrontendPageConfig';
import {StickySubNavWidget} from 'Browser/widgets/StickySubNavWidget';


export const staffResourcesParams = z.object({}).strict();	
export type StaffResourcesParams = z.infer<typeof staffResourcesParams>;

const staffResourcesDoc = z.array(staffResourceModel);
type StaffResourcesDoc = z.infer<typeof staffResourcesDoc>;

export const staffResourcesData = frontendData.extend({
	pageDisplayName: z.string(),
	staffResources: staffResourcesDoc,
}).strict();
export type StaffResourcesData = z.infer<typeof staffResourcesData>;



export class StaffResources extends FrontendPageConfig<StaffResourcesData,StaffResourcesParams>
{
	static readonly pageName = 'frontend/staffResources';
	name() { return StaffResources.pageName; }

//TODO cf 'venueStaff' permission
//	access() { return 'venueFullEditor' as AccessAreaName; }
	access() { return 'venueFrontend' as AccessAreaName; }

	widgets(pageWrapper:IPageWrapper)
	{
		return {
			[StickySubNavWidget.key()]: new StickySubNavWidget()
		};
	}

	documents(params:StaffResourcesData):DocumentQueries
	{
		return ({
			...super.documents(params),

			staffResources: {
				type: 'array',
				collection: 'staffResource',
				aggregate: () => [
					{'$sort': {'position':1}},
				],
			},
		});
	}

	defaultSettings()
	{
		return {
			...super.defaultSettings(),
			htmlClasses: `${this.htmlClasses} staffResources`,
			template: 'App/frontend/staffResources/staffResourcesPage.njk',
			pageDisplayName: 'Staff Resources'
		};
	}
}

