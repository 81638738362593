import {Components, DocumentQueries} from 'Common/config/PageConfigTypes';
import { z } from 'zod';
import {AccessAreaName} from 'Common/permissions/AccessArea';
import {BackendPageConfig, backendData, backendParams} from 'Common/BackendPageConfig';
import {venueDoc} from 'Shared/model/venue';
import {EditComponent} from 'Common/components/EditComponent';
import {SettingsPage} from 'Shared/backend/settingsPage/SettingsPage';


export const settingsParams = backendParams.extend({
}).strict();	
export type SettingsParams = z.infer<typeof settingsParams>;


export const settingsData = backendData.extend({
	params: settingsParams,
	venue: venueDoc
}).strict();
export type SettingsData = z.infer<typeof settingsData>;


export class Settings extends BackendPageConfig<SettingsData,SettingsParams>
{
	static readonly pageName = 'backend/settings';
	name() { return Settings.pageName; }

	access() { return 'venueDeveloper' as AccessAreaName; }

	defaultSettings()
	{
		return {
			...super.defaultSettings(),
			htmlClasses: `${this.htmlClasses} settingsPage`,
			template: SettingsPage
		};
	}

	documents(params:SettingsParams): DocumentQueries 
	{
		return ({
			...super.documents(params),
			venue: {
				type: 'object',
				collection: 'venue'
			}
		});
	}

	components(): Components<SettingsData>
	{
//TODO add initData for settings. Create migration script or similar for existing sites.

//TODO add any extra settings (eg page settings & cf names again esp 'Shared pages')

		return ({
			edit: {
				type: EditComponent.Id,
				collection: 'venue',
				subdocument: ['settings']
			},
			editKey: {
				type:'editKey',
				collection: 'venue',
				subdocument: ['settings','implementations']
			},
		});
	}
}

