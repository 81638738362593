
import {BaseFooter} from 'Shared/frontend/footer/BaseFooter';
import {IConfig, Nav} from 'Shared/frontend/layouts/StandardDefaultLayout';
import {MobileFooterNav} from 'Shared/frontend/navWidget/MobileFooterNav';
import {VenueDoc} from 'Shared/model/venue';
import {Switch,Match} from 'solid-js';


interface IOuterBasic {
	classes: string;
	mainContent;
	nav: Nav;
	footer;
	venue: VenueDoc;
	config: IConfig;
	pageName: string;
	children;
}

export function OuterBasic(props:IOuterBasic)
{
	return (
		<div id='content' class={props.classes}>
			{props.children}

			<main>
				{props.mainContent}
			</main>

			<Switch>
				<Match when={props.footer!=null}>
					{props.footer}
				</Match>
				<Match when={true}>
					<BaseFooter venue={props.venue} />
				</Match>
			</Switch>

			<MobileFooterNav config={props.config} venue={props.venue} pageName={props.pageName} />
		</div>
	);
}

