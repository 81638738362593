import { Links as StandardLinks } from 'Shared/config/Link';
import {ILinks} from 'Common/config/ILinks';

//FIXME replace hard-coded page names...

export const Links:ILinks = {
	...StandardLinks,
	
	'backend/menus':	{type:'page', page:'backend/menu',		target:'/admin/menu'},
/*	
	'backend/course':	{type:'page', page:'backend/course',	target:'/admin/menu/:course'},
	'backend/menuItem':	{type:'page', page:'backend/menuItem',	target:'/admin/menuItem/:id'},

	'backend/solidMenu':{type:'page', page:'backend/menu',		target:'/admin/menu'},
*/
}; 

