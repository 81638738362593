import { z } from 'zod';
import {AccessAreaName} from 'Common/permissions/AccessArea';
import {IPageData} from 'Common/PageConfig';
import {Breadcrumbs} from 'Common/config/PageConfigTypes';
import {BackendPageConfig, backendData} from 'Common/BackendPageConfig';
import Assert from 'Common/Assert';


export const venueParams = z.object({ }).strict();	
export type VenueParams = z.infer<typeof venueParams>;

export const venueData = backendData.extend({
}).strict();
export type VenueData = z.infer<typeof venueData>;


export class Venue extends BackendPageConfig<VenueData,VenueParams>
{
	static readonly pageName = 'backend/venue';
	name() { return Venue.pageName; }

	access() { return 'venueBareEditor' as AccessAreaName; }

	components()
	{
		return ({
//FIXME  Have limit and page stuff in there by default

			breadcrumbs: <Breadcrumbs<IPageData>>{
				type: 'breadcrumbs',
				link: () => '/admin' 
			},
		});
	}

	defaultSettings()
	{
		return {
			...super.defaultSettings(),
			htmlClasses: Assert.have(this.htmlClasses),
			template: 'App/backend/venuePage.njk'
		};
	}
}

