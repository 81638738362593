import {genres, performanceType} from 'Shared/ArtistTypes';
import {ArtistProps} from 'Shared/artists/ProfilePage';
import {IconColour} from 'Shared/backend/fonts/icons/IconColour';
import {InfoCircle} from 'Shared/backend/fonts/icons/InfoCircle.svg';
import {SingleSelect, Text, Wrap} from 'Shared/forms/Inputs';

export function Style(artist:ArtistProps)
{
	return (<>
		<h4>Style</h4>

		<div class='step'>
			<div class='stepIcon'>
				<InfoCircle fill={artist.mainGenre || artist.extraGenre ? IconColour.active : IconColour.inactive} />
			</div>

			<div class='genres'>
				{/*
					TODO add 'performanceType' in lineup.json and artist profile, then uncomment below, change .location class from .threeColumn() to .fourColumn()
								once in artist profile  change 'set required' below to 'true'
				*/}
				
				<Wrap label='Performance type' required={true}>
					<SingleSelect component={artist.editArtist} store={artist} field='performanceType' 
						options={performanceType} required={true} placeholder='Original Band' 
					/>
				</Wrap>

				<Wrap label='Main genre' required={true}>
					<SingleSelect component={artist.editArtist} store={artist} field='mainGenre' options={genres} required={true} />
				</Wrap>

				<Wrap label='2nd genre' required={false}>
					<SingleSelect component={artist.editArtist} store={artist} field='otherGenre1' options={genres} required={false} />
				</Wrap>

				<Wrap label='Custom genre' required={false} instructionsAfterLabel='Type if not in the above genre list'>
					<Text component={artist.editArtist} store={artist} field='extraGenre' />
				</Wrap>
			</div>
		</div>
	</>);
}
