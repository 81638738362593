import { css } from '@emotion/css';
import {LinkOpen} from 'Shared/backend/fonts/icons/LinkOpen.svg';
import {StatusIcons} from 'Shared/backend/menu/StatusIcons';
import {SingleSelect} from 'Shared/forms/Inputs';
import { Url } from 'Shared/model/basic';
import {Show} from 'solid-js';
import { IItemProps } from 'Shared/backend/menu/MenuItemPage';

const bottomBarStyle = () => css({
	position: 'sticky',
	bottom: 0,
	zIndex: 5,
	display: 'flex',
	width: '100%',
	justifyContent: 'center',
	alignItems: 'center',
	background: 'white',
	borderTop: '1px solid lightgrey',
	padding: '1em 0 0.5em 0',
});

const statusBarStyle = () => css({
    display: 'flex',
	flexDirection: 'row',
	gap: 4,
    alignItems: 'center',
    fontSize: '1em',

    '.statusIcons': {
        display: 'flex'
    }
});


export interface IStatusBar {
	itemProps: IItemProps;
	link: Url;
}


export function StatusBar(props:IStatusBar)
{
//TODO create a migrator... Replace with a boolean. 'published'==>true, others==>false

	return (
		<div class={bottomBarStyle()}>
			<div class={statusBarStyle()}> 
				<StatusIcons status={props.itemProps.item.status} />

				<SingleSelect {...props.itemProps} field='status' required={true}
					options={ {'unpublished':'Hide','published':'Show'} } 
				/>

				<Show when={props.itemProps.item.status=='published'}>
					<a href={props.link} class='linkInline' target='_blank'><LinkOpen /></a>
				</Show>
			</div>
		</div>
	);
}

