/*
	Adds expanded/collapsed/between classes to .desktopNavContainer according to
	scroll position. These are just used for styling purposes and are not required for the
	operation of the nav.
		 Currently just works with "shrinkingNavBar". Note that some of the other navs 
	have the "stuck" class added instead.
 */

import {JsPageWidget} from 'Browser/widgets/JsPageWidget';
import Assert from 'Common/Assert';

export class ExpandedCollapsedWidget extends JsPageWidget
{
	private initialised = false;

	private collapsedComparator: HTMLElement|null = null;
	private expandedComparator: HTMLElement|null = null;
	private desktopNav: HTMLElement|null = null;

	static key():string { return 'expandedCollapsed'; }

//XXX looks dodgy
	isAnchorNode(node:HTMLElement):boolean { return false; }

//XXX looks dodgy
	findAnchorNodes(branch:HTMLElement):Iterable<HTMLElement>
	{
		this.collapsedComparator = document.querySelector('#collapsedNavComparator');
		this.expandedComparator = document.querySelector('#expandedNavComparator');
		this.desktopNav = document.querySelector('.desktopNavContainer');

		if (!this.initialised) {
			this.initialised = true;

			window.addEventListener('scroll', e => {
				window.requestAnimationFrame(() => this.afterScroll());
			});
			window.addEventListener('resize', e => {
			/* Think resize is called on first load too */
				window.requestAnimationFrame(() => this.afterScroll());
			});

//TODO MAYBE UNCOMMENT OR NEST IN A requestAnimationFrame
//			this.afterScroll();
		}

		return [];
	}

	afterScroll():void
	{
		Assert.exists(this.expandedComparator);
		Assert.exists(this.collapsedComparator);
		Assert.exists(this.desktopNav);

		const diff = this.expandedComparator.getBoundingClientRect().height - 
					 this.collapsedComparator.getBoundingClientRect().height;

		let expanded = false;
		let collapsed = false;
		let between = false;

		if (window.pageYOffset >= diff) 
			collapsed = true;
		else if (window.pageYOffset < 1) 
			expanded = true;
		else 
			between = true;

		if (collapsed)
			this.desktopNav.classList.add('collapsed');
		else
			this.desktopNav.classList.remove('collapsed');

		if (expanded)
			this.desktopNav.classList.add('expanded');
		else
			this.desktopNav.classList.remove('expanded');

		if (between)
			this.desktopNav.classList.add('between');
		else
			this.desktopNav.classList.remove('between');
	}
	

/*XXX may need to implement
	beforeUpdate(fromEl,toEl)
	{
	}
*/
}

