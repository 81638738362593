import {FrontendData} from 'Common/FrontendPageConfig';
import {crawlerMetaData} from 'Shared/frontend/HeadMacros';
import {lookupFont} from 'Shared/frontend/fonts/lookupFont';

export function HeadInsert(data:FrontendData & {title:string})
{
	return (
		lookupFont(data.frontend.fonts)() + 

		crawlerMetaData((<any>data).url,data.venue,data.title,data.venue.photo!,{}) + 		//XXX any
		`<style> 
			body > * {
				transition: opacity 0.3s;
			}

			.preload > * {
				opacity: 0;
			}

			.loaded > * {
				opacity: 1;
			}
		</style>`
	);
}
