import {Facebook} from 'Shared/frontend/fonts/icons/Facebook.svg';
import {Instagram} from 'Shared/frontend/fonts/icons/Instagram.svg';
import {Snapchat} from 'Shared/frontend/fonts/icons/Snapchat.svg';
import {TikTok} from 'Shared/frontend/fonts/icons/TikTok.svg';
import {Twitter} from 'Shared/frontend/fonts/icons/Twitter.svg';
import {For, Match, Switch, Show} from 'solid-js';

interface ISocialIcons {
	venue: VenueDoc;
}

export function SocialIcons(props:ISocialIcons)
{
	return (
		<div class='socialIcons'>
			<Show when={props.venue.facebook}>
				<a href={`https://www.facebook.com/${props.venue.facebook}`} target='_blank'>
					<div class='socialIcon'>
						<Facebook />
					</div>
				</a>
			</Show>

			<Show when={props.venue.instagram}>
				<a href={`https://www.instagram.com/${props.venue.instagram}`} target='_blank'>
					<div class='socialIcon'>
						<Instagram />

						{/* custom hack for townie to enable 2x instagram accounts */}
						<For each={props.venue.otherSocialMedia}>{ other =>
							<Show when={other.title != 'Instagram'}>
								<span>Venue</span>
							</Show>
						}</For>
					</div>
				</a>
			</Show>

			{/* custom hack for townie to enable 2x instagram accounts */}
			<For each={props.venue.otherSocialMedia}>{ other =>
				<Switch>
					<Match when={props.other?.title != 'Instagram'}>
						<a href={props.other?.url} target='_blank'>
							<div class='socialIcon'>
								<Instagram />
								<span>{other.title.replace('Instagram','')}</span>
							</div>
						</a>
					</Match>
					<Match when={true}>
						<a href={other.url} target='_blank'>
							<div class='socialIcon other'>{other.title}</div>
						</a>
					</Match>
				</Switch>
			}</For>

			<Show when={props.venue.twitter}>
				<a href={`https://www.twitter.com/${props.venue.twitter}`} target='_blank'>
					<div class='socialIcon'>
						<Twitter />
					</div>
				</a>
			</Show>

			<Show when={props.venue.tiktok}>
				<a href={`https://www.tiktok.com/${props.venue.tiktok}`} target='_blank'>
					<div class='socialIcon'>
						<TikTok />
					</div>
				</a>
			</Show>

			<Show when={props.venue.snapchat}>
				<a href={`https://www.snapchat.com/add/${props.venue.snapchat.replace('@','')}`} target='_blank'>
					<div class='socialIcon'>
						<Snapchat />
					</div>
				</a>
			</Show>
		</div>
	);
}

