import {DateTime,Duration as LuxonDuration} from 'luxon';

//XXX this definition is not working so well with Zod, but Zod is checking. 
//XXX could perhaps use a Zod definition here instead...
//export type SqlDateTime = `${number}-${number}-${number} ${number}:${number}:${number}`;
export type SqlDateTime = string;

export type Duration = { years?:number,quarters?:number,months?:number,
	weeks?:number,days?:number,hours?:number,minutes?:number,seconds?:number,milliseconds?:number};

export const timeUnits = ['milliseconds','seconds','minutes','hours','days','months','years'] as const;  
export type TimeUnit = typeof timeUnits[number];


export interface ITimesFunctions
{
	now():DateTime;
	today():DateTime;
	add(base:DateTime,duration:Duration):DateTime;
	minus(base:DateTime,duration:Duration):DateTime;
	floor(base:DateTime,unit:TimeUnit):DateTime;
	db(dateTime:DateTime):string;
	dbDate(dateTime:DateTime):string;
}


export class TimesFunctions implements ITimesFunctions
{
	constructor(
		private timezone:string,
		private testNow?:string
	)
	{ }

	now()
	{
		/*
			Note that setZone() is not equivalent to {zone:XXX}. 
			setZone() appears to perform a conversion (which is ok when applied to "now").
		 */
		return this.testNow==undefined ? 
			DateTime.now().setZone(this.timezone) : 
			DateTime.fromSQL(this.testNow,{zone:this.timezone});
	}

	today()
	{
		return this.floor(this.now(),'days');
	}

	add(base:DateTime,duration:Duration)
	{
		return base.plus(this.fillInDuration(duration));
	}

	minus(base:DateTime,duration:Duration)
	{
//TODO USE THIS INTEAD: DateTime.local(2014, 3, 3).startOf('month').toISODate(); //=> '2014-03-01'
//TODO maybe just try {days:1} etc WITHOUT Duration. DurationLike might handle the filling in for us?	
		return base.minus(this.fillInDuration(duration));
	}

//TODO use DateTime.endOf() instead?
	floor(base:DateTime,unit:TimeUnit)
	{
//TODO week is likely to be useful too
		const floorMap = {milliseconds:null,seconds:'milliseconds',minutes:'seconds',hours:'minutes',days:'hours',months:'days',years:'months'};
		const targetUnit = floorMap[unit];

		const zeros:any = {};

		let i = 0;
		while (i < timeUnits.length) {
			zeros[timeUnits[i]] = 0;
			if (timeUnits[i]==targetUnit)
				break;
			i++;
		}
		return base.set(zeros);
	}

	db(dateTime:DateTime)
	{
		return dateTime.toISO({includeOffset:false,suppressMilliseconds:true});
	}

	dbDate(dateTime:DateTime)
	{
		return dateTime.toSQL({includeOffset:false}).split(' ')[0]; 
	}

	/* Luxon's durations expect all the other units to be 0, so fill these in... */
	private fillInDuration(sparseDuration:Duration)
	{
		let obj = {years:0,months:0,days:0,hours:0,minutes:0,seconds:0,milliseconds:0};
		return LuxonDuration.fromObject({...obj,...sparseDuration});
	}
}

