import {JsPageWidget} from 'Browser/widgets/JsPageWidget';
import Assert from 'Common/Assert';

export class SelectorWidget extends JsPageWidget
{
	protected selectors:string[];

    constructor(selectors:string[])
    {
        super();
		this.selectors = selectors;
	}

	isAnchorNode(node:HTMLElement):boolean 
	{
        for (const selector of this.selectors) 
			if (node.matches(selector)) 
				return true;
		return false;
	}

	findAnchorNodes(branch:HTMLElement):Iterable<HTMLElement>
    {
		const nodes:Set<HTMLElement> = new Set();

        for (const selector of this.selectors) {
        	for (const node of branch.querySelectorAll(selector))
				nodes.add(Assert.htmlElement(node));

			if (branch.matches(selector)) 
				nodes.add(branch);
		}

        return nodes;
    }
}

