import {HttpErrorInterface,HttpErrorMessage} from 'Browser/HttpErrorInterface';

/*
	These are errors that shouldn't happen provided the messages sent by the browser 
	are as expected. If someone is hand crafting HTTP messages though they might show up.
	SecurityErrors could be filtered from logs in order to help detect hacking.
 */
export default class SecurityError extends Error implements HttpErrorInterface
{
	static type():string { return 'SecurityError'; }

	public toJson():HttpErrorMessage
	{
		return {httpCode:400, errorType:SecurityError.type()};
	}
}

