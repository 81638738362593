/*
	Add a --scroll-offset variable to the document element to keep track of the
	vertical scrolling position.
 */

import {JsPageWidget} from 'Browser/widgets/JsPageWidget';


export class ScrollRangeVariableWidget extends JsPageWidget
{
	private initialised = false;

	static key() { return 'scrollRangeVariable'; }

	isAnchorNode(node:HTMLElement):boolean { return node.matches('body'); }

//XXX should I be using branch instead?
	findAnchorNodes(branch:HTMLElement):Iterable<HTMLElement>
    {
        return document.querySelectorAll('body');
    }

	initInstance(anchorNode:HTMLElement):void
    {
        super.initInstance(anchorNode);

		if (!this.initialised) {
			this.initialised = true;

		   window.addEventListener('scroll', e => {
				window.requestAnimationFrame(() => {
					document.documentElement.style.setProperty('--scroll-offset',window.pageYOffset+'px');
				});
			});

			document.documentElement.style.setProperty('--scroll-offset',window.pageYOffset+'px');
		}
	}
}

