/*

FIXME these comments are out of date

	A component controls a related block of data that appears on a page.
	Currently display issues (i.e. updating the DOM) are handled by the *Page classes, not
	by the Component classes.
	
	An alternative approach would be to have components handle their own display.  This is
	not done as the display is handled by Nunjucks in its own layer.  It is, however, 
	possible to mirror components at the Nunjucks level.  
	Some of the pros of using the Nunjucks layering approach are:
		1. Easier to reskin
		2. Less technically oriented staff can modify the GUI
		3. Display is kept well away from JS code, including framework code.

	The *Page class calls Component.loadData() to ready its data for use. This function places all the
	displayable component data into the 'data' field. The data field may also contain parameters
	used by the component. A reference to the 'data' field will be accessed by the page and used
	to create a single MUTABLE SINGLETON data structure for the entire page. This data structure will
	be used for rendering the Nunjucks template and for evaluating values in the page.json file.

	Components should provide setter functions for those fields that require modification after
	the initial load, although strictly speaking the 'data' field could be hacked directly.

	Caching of state
	----------------

	[THIS SECTION NEEDS TO BE RECONSIDERED]

	Any data that is to be cached between page views should be added as a data member 
	to child classes. 

	By default each Component class just caches the last viewed data. Component 
	objects should NOT be destroyed when pages change - leaving them around allows the last set
	of data to be cached and quickly redisplayed. 

	When a page is redisplayed a check needs to be made to see that the key ID is the same
	as the last view.  If the keys match the old data can be shown. Any updates that have come in from
	the DB in the interim should have been merged in.

	Each page is registered with the DB interface when it is created and it is up to the page to pass
	updates to components.  
	NOTE: we should consider making the components directly responsible for getting these updates.
          If we do this, occasionally they may need to call the parent's display() function.
 */

//TODO maybe share type with Param?
export type FieldPath = {path:string};

//XXX maybe move elsewhere
/* The types provided by jsonpath-plus aren't great unfortunately, so create my own version: */
export type JSONPathItem = {
    path: string,
    value: any,
    parent: any,
    parentProperty: string,
    //hasArrExpr: undefined,  //Dont know what this is
    pointer: string
};
export type UpdateFieldValuesCallback = (ret:JSONPathItem)=>any;

export abstract class Component
{
	constructor(
		readonly name:string
	)
	{ }

    public abstract componentType():string;

	public load():any { return {}; }		//XXX any

	/* 
		A hook called by the page just after the display has occurred.
		Useful for initialising third party JS components.
	 */
	postDisplay() {}
}

