import UserError from 'Browser/UserError';
import SecurityError from 'Browser/SecurityError';
import BuildErrors from 'Common/config/BuildErrors';
import ConfigError from 'Common/config/ConfigError';
import {ConfigPageError} from 'Common/config/ConfigPageError';
import InvalidFieldValueErrors from 'Browser/InvalidFieldValueErrors';
import OperationError from 'Browser/OperationError';

/*
	We cannot put this factory code in BrowserError as it creates import loops.
 */
export default class BrowserErrorFactory
{
	public static fromServer(data:any)
	{
//XXX could possibly use Zod to parse 'data' here, if that would be useful... Its 8K minified and zipped

		switch(data.errorType) {
			case 'server':							return new UserError('The server had an error');
			case SecurityError.type():				return new UserError('You lack permissions');
			case ConfigError.type(): 				return new UserError(ConfigError.fromJson(data).toString());
			case ConfigPageError.type(): 			return new UserError(ConfigPageError.fromJson(data).toString());
			case OperationError.type(): 			return new UserError(OperationError.fromJson(data).toString());

//XXX possibly should map these to UserError as well...
			case BuildErrors.type():				return BuildErrors.fromJson(data);
			case InvalidFieldValueErrors.type():	return InvalidFieldValueErrors.fromJson(data);

			default: throw new Error('Unknown error: '+data.errorType);
		}
	}
}

