import {HttpErrorMessage} from 'Browser/HttpErrorInterface';
import BuildError from 'Common/config/BuildError';

export default class CompilationError extends BuildError
{
	readonly file:string;
	readonly message:string;
	readonly line:number|null;
	readonly column:number|null;
	readonly operation:number|null;
	readonly extract:string|null;

	/* 
		Warning: only use CompilationError locally (ie within a file). 
		Use BuildErrors to pass errors between files.
	 */
	constructor(file:string,message:string,
		line:number|null,column:number|null,
		operation:number|null,extract:string|null)
	{
		super();
		this.file = file;
		this.message = message;
		this.line = line;
		this.column = column;
		this.operation = operation;
		this.extract = extract;
	}

	static type():string { return 'compilationError'; }

	public toString():string
	{
		let ret='';
		if (this.file!=null) {
			ret += this.file;
			if (this.line!=null) {
				ret += '(line:'+this.line;
				if (this.column!=null)
					ret+=',column:'+this.column;
				ret+=')';
			}
			if (this.operation!=null) 
				ret+='(operation:'+this.operation+')';
			ret+=': ';
		}
		ret += this.message;
		if (this.extract!=null)
			ret+='  Extract:\n'+this.extract;
		return ret;
	}

	public toJson():HttpErrorMessage
	{
		const ret:any = {httpCode:200,errorType:CompilationError.type()};
		if (this.file!=null)		ret.file = this.file;
		if (this.message!=null)		ret.message = this.message;
		if (this.line!=null)		ret.line = this.line;
		if (this.column!=null)		ret.column = this.column;
		if (this.operation!=null)	ret.operation = this.operation;
		if (this.extract!=null)		ret.extract = this.extract;
		return ret;
	}

	public static fromJson(data:any):any
	{
		return new CompilationError(data.file,data.message,data.line,data.column,data.operation,data.extract);
	}
}

