import {Component} from 'Common/components/Component';
import {CreateArrayItemMessage, DeleteArrayItemMessage, ReorderArrayMessage, UpdateMessage} from 'Common/Messages';
import {IPage} from 'Common/pages/IPage';
import {IConnectionToServer} from 'Common/IConnectionToServer';
import {Location, EditArray} from 'Common/config/PageConfigTypes';
import {Id} from 'Common/Id';
import {Document, IPageData} from 'Common/PageConfig';

/*
	EditableArray is really a tree of arrays, of dynamic height and breadth.
 */

//TODO amend artist and settings GUIs to use this.
//TODO remove unused properties from def

export class EditableArray<PageData extends IPageData,Doc extends Document> extends Component
{
    constructor(
		private server:IConnectionToServer,
		private page:IPage<PageData>,
		instanceName:string,
		readonly def: EditArray<PageData,Doc>,
		private docId:Id
	)
    {
        super(instanceName);

		this.deleteItem = this.deleteItem.bind(this);
	}

	static readonly Id = 'editArray2';  //TODO rename to editArray when NJK removed

    componentType() { return 'editArray2'; }


	/* An array might be undefined initially. If so, use this to create it */
	async create(parentLocation:Location,field:string)
	{
		const msg = new UpdateMessage(this.page.name(),this.name,this.docId,parentLocation,{[field]:[]});
       	await this.server.sendOperation(msg);
	}

	async deleteItem(parentLocation:Location,index:number)
	{
//XXX if no items left cf deleting out the array entirely	
		const msg = new DeleteArrayItemMessage(this.page.name(),this.name,this.docId,parentLocation,index);

		if (this.def.redirect!=undefined) {
			await this.server.sendOperation(msg)
			location.href = this.def.redirect(parentLocation,this.page.data);
			return;
		}

		this.server.sendOperationOptimistically(msg)
	}

	async addItem(location:Location,wait:boolean)
	{
		const initial:any = this.def.initialValues!=undefined ? this.def.initialValues(location,this.page.data) : {}; 

		const msg = new CreateArrayItemMessage(this.page.name(),this.name,this.docId,location,initial);

		if (wait)
        	await this.server.sendOperation(msg);
		else
        	this.server.sendOperationOptimistically(msg);
	}

	reorder(parentLocation:Location,oldIndex:number,newIndex:number)
	{
		const msg = new ReorderArrayMessage(this.page.name(),this.name,this.docId,parentLocation,oldIndex,newIndex);
		this.server.sendOperationOptimistically(msg);
	}
}  

