import {VenueLinks} from 'Common/VenueLinks';
import {IPageData} from 'Common/PageConfig';
import {selectLinks,selectNav} from 'Browser/SiteTypeSelector';
import {Page} from 'Common/pages/Page';

export type Params = {[name:string]:any}; 

export interface IVenuePageData extends IPageData {
};


export class VenuePage extends Page<IVenuePageData>
{
	protected initMiscData():void
	{
		super.initMiscData();

		this.data = {
			...this.data,

			config: {
				nav: selectNav(this.site),
				link: selectLinks(this.site)  
			}
		};

		if (this.user!=null) 
			this.data = {...this.data,
				user: {...this.user, 
					access: this.user.roles.venueAccess(this.site.id)
				}
			};


		const venueLinks = new VenueLinks(this.data.config.link);
		//TODO make this an absolute URL. Used as the canonical path
		this.data.url = venueLinks.resolveLink(this.name(),this.params);
	}
}

