import {AccessAreaName} from 'Common/permissions/AccessArea';
import {FrontendPageConfig, frontendData} from 'Common/FrontendPageConfig';
import { z } from 'zod';
import Assert from 'Common/Assert';

export const pageNotFoundParams = z.object({ }).strict();	
export type PageNotFoundParams = z.infer<typeof pageNotFoundParams>;

export const pageNotFoundData = frontendData.extend({ }).strict();
export type PageNotFoundData = z.infer<typeof pageNotFoundData>;


export class PageNotFound extends FrontendPageConfig<PageNotFoundData,PageNotFoundParams>
{
	static readonly pageName = 'frontend/pageNotFound';
	name() { return PageNotFound.pageName; }

	access() { return 'venueFrontend' as AccessAreaName; }

	defaultSettings()
	{
		return {
			...super.defaultSettings(),
			htmlClasses: Assert.have(this.htmlClasses),
			template: 'App/frontend/pageNotFound.njk'
		};
	}
}


