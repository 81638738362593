import { FutureEventsQueryClause } from 'Shared/view/FutureEventsQueryClause';
import {ITimesFunctions} from 'Common/TimesFunctions';
import {IServerFunctions} from 'Server/ServerFunctions';
import {DocumentQuery} from 'Common/config/PageConfigTypes';

export function events(time:ITimesFunctions,field:string):DocumentQuery
{
	return ({
		type: 'array',

		collection:'event',
		/*
			We want events that haven't ended yet. We don't care when they started. 
			Note that "timesEnd" sometimes includes an extra day to cope with the "until close" situation.
		*/
		aggregate: () => [
			{$match: {
				$and: [
					{status: 'published'},
					(new FutureEventsQueryClause(time,'times',{months:6})).create()
				],
			}},
			{$limit: 1000}
		],

		transform: (funcs:IServerFunctions,doc:AsyncIterable<any>) => {		//XXX any 
			const eventsPlus = funcs.generateEvents(doc,'times',
				/*start:*/time.now(),
				/*end:*/  time.add(time.today(),{months:6} )
			);

			const filtered = funcs.filterTimes(eventsPlus,'times',{/*onceLimit:0,*/dailyLimit:1,weeklyLimit:1,monthlyLimit:1});
			return funcs.limit(filtered,30)
		}

	});
}

export function musicEvents(time:ITimesFunctions,field:string):DocumentQuery
{
	return ({
		type: 'array',
		collection:'event',
		aggregate: () => [
			{'$match': {
				'$and': [
					{status: 'published'},
					{eventType: 'music'},
					(new FutureEventsQueryClause(time,field,{months:6})).create()
				],
			}},
			{'$limit': 100},
			{'$sort': {timesStart:1,position:1}},
		],
		transform: (funcs:IServerFunctions,doc:AsyncIterable<any>) => {		//XXX any 
			const eventsPlus = funcs.generateEvents(doc,field,
				/*start:*/time.now(),
				/*end:*/  time.add(time.today(),{months:6} )
			);

			const filtered = funcs.filterTimes(eventsPlus,field,{/*onceLimit:0,*/dailyLimit:1,weeklyLimit:1,monthlyLimit:1});
			return funcs.limit(filtered,30)
		}
	});
}

export function nonMusicEvents(time:ITimesFunctions,field:string):DocumentQuery
{
	return ({
		type: 'array',
		collection:'event',
		aggregate: () => [
			{'$match': {
				'$and': [
					{status: 'published'},
					{eventType: {$ne:'music'}},	
					(new FutureEventsQueryClause(time,field,{months:6})).create()
				],
			}},
			{'$limit': 100},
			{'$sort': {timesStart:1,position:1}},
		],
		transform: (funcs:IServerFunctions,doc:AsyncIterable<any>) => {		//XXX any 
			const eventsPlus = funcs.generateEvents(doc,field,
				/*start:*/time.now(),
				/*end:*/  time.add(time.today(),{months:6} )
			);
			const filtered = funcs.filterTimes(eventsPlus,field,{/*onceLimit:0,*/dailyLimit:1,weeklyLimit:1,monthlyLimit:1});
			return funcs.limit(filtered,30)
		}
	});
}

