import UserError from 'Browser/UserError';
import InvalidFieldValueErrors from 'Browser/InvalidFieldValueErrors';

export class Runner
{
	private promise:Promise<void>;

	constructor(promise:Promise<void>)
	{
		this.promise = promise;
	}

	refresh():Runner
	{
		return new Runner( this.promise.then(() => window.pageWrapper.refresh()) );
	}

	redirect(url:string)
	{
		return new Runner( this.promise.then(() => {location.href = url;} ));
	}
}

export function runNoRefresh(event:Event|null,methodName:string,...args:any[]):Runner
{
	event?.preventDefault();
	event?.stopPropagation();

	const method = (<any>window.pageWrapper)[methodName];

	return runOperation(method,...args);
}

export function run(event:Event|null,methodName:string,...args:any[]):Runner
{
	event?.preventDefault();
	event?.stopPropagation();

	const method = (<any>window.pageWrapper)[methodName];

	return runOperation(method,...args).refresh();
}

export function runOnComponent(event:Event|null,componentName:string,methodName:string,...args:any[]):Runner
{
	event?.preventDefault();
	event?.stopPropagation();

	const component = window.pageWrapper.page.component(componentName);
	const method = (<any>component)[methodName];

	return runOperation(method,...args).refresh();
}

/*
	XXX might Solid's createResource() be used here/instead?
	XXX remove data updates in components after runOperation??
*/
//FIXME this is the Solid version. Shitty name
export function runOnComponent2(event:Event|null,componentName:string,methodName:string,...args:any[]):Runner
{
	event?.preventDefault();
	event?.stopPropagation();

	const component = window.pageWrapper.page.component(componentName);
	const method = (<any>component)[methodName];

//XXX HOW to trigger update in Solid from the server??
	return runOperation(method,...args);
//	return runOperation(method,...args).refresh();
}

export function runOnWidget(event:Event|null,widgetName:string,methodName:string,...args:any[]):Runner
{
	event?.preventDefault();
	event?.stopPropagation();

	const widget = window.pageWrapper.page.widget(window.pageWrapper,widgetName);
	const method = (<any>widget)[methodName];
	return runOperation(method,...args).refresh();
}

export function runOnWidgetNoRefresh(event:Event|null,widgetName:string,methodName:string,...args:any[]):Runner
{
	event?.preventDefault();
	event?.stopPropagation();

	const widget = window.pageWrapper.page.widget(window.pageWrapper,widgetName);
	const method = (<any>widget)[methodName];
	return runOperation(method,...args);
}

function runOperation(operation:(...rest:any[])=>Promise<void>,...args:any[]):Runner
{
	const promise:Promise<void> = new Promise((resolve,reject) => {
//		await window.page.ensureLoaded();

		/* Using Promise.resolve() works for promises and non-promises */
		Promise.resolve(operation(...args))
			.then(() => resolve())
			.catch(err => reject(err))
	});

	return new Runner(promise
		.catch(err => {
			if (err instanceof UserError)
				alert(err.toString());
			else if (err instanceof InvalidFieldValueErrors) {
			}
			else {
				log.error(err);
				alert('Operation failed');
			}
		})
	);
}

