import { z } from 'zod';
import {menuDoc as menuModel} from 'Shared/model/Menu';
import {special as specialModel} from 'Shared/model/Specials';
import {Components, DocumentQueries} from 'Common/config/PageConfigTypes';
import { createImageUrl, createImageUrls } from 'Common/ViewUtils';
import { OverlayComponent } from 'Common/config/PageConfigTypes';
import { specialsQueries } from 'Shared/view/frontend/specialsQueries';
import {StickySubNavWidget} from 'Browser/widgets/StickySubNavWidget';
import {AccessAreaName} from 'Common/permissions/AccessArea';
import {FrontendPageConfig, frontendData, frontendParams} from 'Common/FrontendPageConfig';
import {TimesFunctions} from 'Common/TimesFunctions';
import {VenueUrls} from 'Common/VenueUrls';



export const menuParams = frontendParams.extend({}).strict();	
export type MenuParams = z.infer<typeof menuParams>;

export const menuData = frontendData.extend({
	pageDisplayName: z.string(),
	menu: menuModel,
	specials: z.array(specialModel)
}).strict();
export type MenuData = z.infer<typeof menuData>;


export class Menu extends FrontendPageConfig<MenuData,MenuParams>
{
	static readonly pageName = 'frontend/food';
	name() { return Menu.pageName; }

	access() { return 'venueFrontend' as AccessAreaName; }

	title() { return 'Contact'; }

	widgets()
	{
		return {
			[StickySubNavWidget.key()]: new StickySubNavWidget()
		};
	}

	defaultSettings()
	{
		return {
			...super.defaultSettings(),
			htmlClasses: `${this.htmlClasses} menuPage`,
			template: 'App/frontend/menuPage/differentDrummerFood.njk',
			pageDisplayName: 'Menu',

/* XXX example of possible approach to simplify display of courses...			

			courseDisplay: {
				cocktails: 'App/frontend/menuPage/menuItemWithOverlay.njk',
				snacks: 'App/frontend/menuPage/menuItemTextOnly.njk',
			}
*/			
		};
	}

	documents(params:MenuParams)
	{
		const times = new TimesFunctions(this.venue.document.settings.general.timezone,this.venue.document.settings.general.testNow);

		return <DocumentQueries> ({
			...super.documents(params),

			menu: {
				type: 'object',
				collection: 'menu'
			},

			specials: specialsQueries(times,'times'),
		});
	}

	components():Components<MenuData>
	{
		return ({
			...super.components(),

			reveal: <OverlayComponent>{
				type: 'overlay',
				panes: {
					menuOverlay: false,
					specialsOverlay: false
				}
			},

			currentRecord: {
				type: 'extraData',
				initial: null
			},
		});
	}

	beforeDisplay(data:MenuData)
	{
		super.beforeDisplay(data);

		const urls = new VenueUrls(this.build,this.venue.key);

		for (const course of Object.values(data.menu.courses ?? {})) 
			for (const item of <any>course)
				createImageUrl(urls,item.image,`MenuItem-image`,'medium');

		createImageUrls(urls,data.specials,'image',`Special-image`,'medium');
	}
}

