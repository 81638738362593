import {MenuItemPrices, TitleWrap} from 'Shared/frontend/menuPage/StandardMenuItem';
import { productTypeSelector } from './MenuPage';

export function ImagelessMenuItem(props)
{
	return (<>
		<div class={`menuItem ${productTypeSelector(props.productType)}`}>
			<div class='detailsWrap'>
				<TitleWrap item={props.item} />

				{/* Used for dot leaders etc */}
				<div class='fillerWrap'></div> 

				<div class='descriptionWrap'>
					{props.item.tagline}
				</div>

				<MenuItemPrices item={props.item}/>
			</div>
		</div>
	</>);
}
