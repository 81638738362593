import {ReorderDocumentsMessage} from 'Common/Messages';
import Assert from 'Common/Assert';
import {Id} from 'Common/Id';
import {Component} from 'Common/components/Component';
import {EditDocumentList} from 'Common/config/PageConfigTypes';
import {IPage} from 'Common/pages/IPage';
import {IPageData} from 'Common/PageConfig';


export class EditableDocumentList extends Component
{
//FIXME either add as parameter to widget spec, or add .sortable into NJK
	private sortableItemSelector = '.sortableItem'; 

    constructor(
		private page:IPage<IPageData>,
		instanceName:string,
		readonly config: EditDocumentList
	)
    {
        super(instanceName);
	}

	static readonly Id = 'editDocumentList2';  

    componentType() { return EditableDocumentList.Id; }

	async load()
	{
		return {};
	}

	private createShuffleOperation(anchorNode:HTMLElement)
	{
//FIXME currently wont work with pagination as positions are reassigned... note that assigning positions to anything
//      with more than one page is problematic anyway, but will probably need to support eventually.

		const items:NodeListOf<HTMLElement> = anchorNode.querySelectorAll(this.sortableItemSelector);

		/* Create rows for message, creating nice new positions: */
		const rows:Id[] = [];
		for (let i=0; i<items.length; i++) {
			const item = items[i].dataset;
			rows.push(Assert.have(item.id));
		}

		/* 
			The page and component names are only used to look up the 'access' server side.
			Providing the table name from the browser instead of in the json can potentially allow nested sorting.
		 */
		return new ReorderDocumentsMessage(this.page.name(),this.name,<any>rows);
	}
}

