import {SelectorWidget} from 'Browser/widgets/SelectorWidget';
import { DateTime } from 'luxon';
import Assert from 'Common/Assert';
import flatpickr from 'flatpickr';
import {Date, DateTimes, Time} from 'Browser/DateTimes';

type DateTimeId = 'dateTime'|'date'|'time';

/* Integrates the Flatpickr date and time picker into pages.  */
export class DateTimeWidget extends SelectorWidget
{
	private closing:flatpickr.Instance|null|boolean;

    constructor()
    {
        super(['.bf-dateTime','.bf-date','.bf-time']);
		this.closing = null;
    }

	static key():string { return 'dateTimePicker'; }

	initInstance(anchorNode:HTMLElement):void
    {
        super.initInstance(anchorNode);

		const hidden = Assert.htmlInputElement(anchorNode.querySelector('.bf-value'));
		const me = this;
		const type = this.widgetType(anchorNode);
		const format = this.dateTimeFormat(type);
		const inputNode = Assert.htmlElement(anchorNode.querySelector('.bf-dateTime-input'));

		const dateTime = hidden.value=='' ? '' : DateTime.fromISO(hidden.value).toJSDate();

		flatpickr(inputNode, {
			enableTime: type!='date',
			noCalendar: type=='time', 
			dateFormat: format,
			allowInput: true,
			defaultDate: dateTime,

            onClose: (selectedDates,dateStr,instance) => {
				me.closing = instance;
				setTimeout(() => me.closing=false,100);
            },

			onChange: (selectedDates,dateStr,instance) => {
				if (selectedDates.length==0)
					hidden.value = '';
				else {
					const date = DateTime.fromJSDate(selectedDates[0]);
					hidden.value = this.toHiddenValue(type,date);
				}
			}
		});

		anchorNode.querySelector('.bf-dateTime-calendar')?.
			addEventListener('click',this.openCalendar.bind(me));

		anchorNode.querySelector('.bf-dateTime-clear')?.addEventListener('click',e => {
			this.clearSelection.bind(me)(Assert.htmlElement(e.target));
		});
    }

	widgetType(anchorNode:HTMLElement):DateTimeId
	{
		if (anchorNode.classList.contains('bf-date'))
			return 'date';
		else if (anchorNode.classList.contains('bf-time'))
			return 'time';
		return 'dateTime';
	}

	dateTimeFormat(widgetType:DateTimeId):string
	{
		switch(widgetType) {
			case 'dateTime': return 'd/m/Y h:iK';
			case 'date': return 'd/m/Y';
			case 'time': return 'h:iK';
		}
	}
	
	toHiddenValue(widgetType:DateTimeId,value:DateTime):string
	{
		switch(widgetType) {
			case 'dateTime': 	return (new DateTimes()).fromLuxon(value); 
			case 'date': 		return (new Date()).fromLuxon(value); 
			case 'time': 		return (new Time()).fromLuxon(value); 
		}
	}

	/*
		Would prefer this to be a toggle, but... clicking away from the calendar immediately closes
		the calendar, and we lose track of whether it was open or not. The setTimeout is the only
		method I've got to work.
	 */
	openCalendar(e:Event):void
	{
		e.preventDefault();
		const top = Assert.htmlElement(Assert.htmlElement(e.target).closest('div'));
		const f = this.getFlatpickr(top,'.bf-dateTime-input');
		if (this.closing == f)
			return;
		f.toggle()
	}

	clearSelection(node:HTMLElement):void
	{
		const top = Assert.htmlElement(Assert.htmlElement(node).closest('div'));
		const f = this.getFlatpickr(top,'.bf-dateTime-input');

		Assert.htmlInputElement(top.querySelector('input')).value = '';
		f.clear();
	}

	beforeUpdate(fromNode:HTMLElement,toNode:HTMLElement):boolean 
    {
		return !(this.isAnchorNode(fromNode) ||
			('classList' in fromNode && fromNode.classList.contains('.flatpickr-calendar')));
    }

    destroyInstance(anchorNode:HTMLElement):void
    {
		this.getFlatpickr(anchorNode,'.bf-dateTime-input').destroy();
    }

	getFlatpickr(root:HTMLElement,selector:string):flatpickr.Instance
	{
		const node = Assert.htmlElement(root.querySelector(selector));
//		return Assert.toClass<{_flatpickr:flatpickr.Instance}>(flatpickr,node)._flatpickr;
//XXX hacky cast
		return (<any>node)._flatpickr;
	}
}

