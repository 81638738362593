import {Phone} from 'Shared/frontend/fonts/icons/Phone.svg';
import {Pin} from 'Shared/frontend/fonts/icons/Pin.svg';
import {displayState} from 'Shared/frontend/footer/BaseFooter';
import {Show} from 'solid-js';

interface IPhoneAndAddress {
	venue
}

export function PhoneAndAddress(props:IPhoneAndAddress)
{
	return (
		<>
			<ul class='phoneAndAddress'>
				<li>
					<a class='phoneLink' href={'tel:'+props.venue.phoneNumber}>
						<div class='leadingIcon'>
							<Phone />  
						</div>
						{props.venue.phoneNumber}
					</a>
				</li>
				<li>
					 <a class='addressLink' href={props.venue.mapUrl} target='_blank'>
						<div class='leadingIcon'>
							<Pin /> 
						</div>

						<address>
							{props.venue.streetAddress}<br/>
							{props.venue.suburb+' '}
							{displayState(props.venue.state)+' '}
							{props.venue.postcode}
						</address>
					</a> 
				</li>
			</ul>
			<div>
				<Show when={props.venue.postalAddress}>
					<p>Postal Address: {props.venue.postalAddress}</p>
				</Show>
			</div>
		</>
	);
}

