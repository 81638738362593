import {Show,For, createSignal, JSX} from 'solid-js';
import { DragDropProvider, DragDropSensors, SortableProvider, closestCenter,DragOverlay, DragEventHandler, createSortable} from '@thisbeyond/solid-dnd';
import {createMemo} from 'solid-js';
import {css} from '@emotion/css';
import {Dynamic} from 'solid-js/web';

export const repeaterColour = '#f4f4f4';

const repeaterItemStyle = () => css({
	display: 'block',
	marginBottom: 20,
	transition: 'max-height 0.3s'
});

const barStyle = () => css({
    display: 'flex',
    alignItems: 'center',
    padding: '12px 25px 12px 0',
    cursor: 'pointer',
	userSelect: 'none',
	backgroundColor: repeaterColour,
	color: '#000',

	'.fa-ellipsis-v': {
		cursor: 'grabbing',
		padding: '4px 15px',
        color: 'gray'    //XXX this is the current value of  @repeaterBarGrabber;
	}
});

const disabledBarStyle = () => css({
	'.fa-ellipsis-v': {
		opacity: 0.25,
		cursor: 'pointer'
	}
});

const repeaterTitleStyle = () => css({
	flex: '1 0'
});

const buttonStyle = () => css({
	fontSize: 13,
	fontWeight: 400,
	lineHeight: '16px',
	userSelect: 'none',
	color: '#666',
	backgroundColor: 'inherit',
	border: 'unset',
	padding: '4px 9px',
	margin: '0 8px'
});

const overlayBarStyle = () => css({
	cursor: 'grab',
});

const underlayStyle = () => css({
	opacity: 0.25,
});

  
export interface IRepeaterProps<ItemData> {
	ref: HTMLElement,
	items:		ItemData[],
	setItems:	(items:ItemData[]) => void,
	deleteItem:	(e:Event,index:number) => void,
	reorder:	(fromIndex:number,toIndex:number) => void,
	renderTitle:	(current:ItemData) => string,
	renderAddLabel:	() => JSX.Element|string,
	children:		(item:ItemData[],index:number) => JSX.Element
}

export function Repeater<ItemData>(props:IRepeaterProps<ItemData>)
{
	const [current,setCurrent] = createSignal<number|undefined>(undefined);

	/* Used for sorting */
	//XXX The DND library doesn't work for id=0. The "underlay" breaks for the first item... so start with 1.
	//	  This is a little inconvenient, otherwise we could make id=index. Lodging a defect report...
	const ids = () => [...Array(props.items.length).keys()].map(v => v+1);
	const indexed = createMemo(() => props.items.map((e:ItemData,i:number) => ({id:i+1,data:e})));

	/* Initialise dragging */
	const [activeItem, setActiveItem] = createSignal(null);

	const onDragStart:DragEventHandler = e => {
		setCurrent(undefined);
		setActiveItem(props.renderTitle(props.items[Number(e.draggable.id) - 1]) as any);
	};

	const onDragEnd:DragEventHandler = ({draggable,droppable})  => {
		if (!draggable || !droppable) return;

		const fromIndex = indexed().findIndex( (item:{id:number}) => item.id == draggable.id);
		const toIndex = indexed().findIndex( (item:{id:number}) => item.id == droppable.id);

		if (fromIndex === toIndex) return;

		const newItems = props.items.slice();
		newItems.splice(toIndex, 0, ...newItems.splice(fromIndex, 1));
		props.setItems(newItems);

		props.reorder(fromIndex,toIndex);
	};

	const repeaterItems = () => (
		<repeater-items ref={props.ref}> 
			<For each={indexed()}>
				{(item,index) => { 
					return <Item 
						item={item.data} index={index()} id={item.id} current={current()} setCurrent={setCurrent}
						deleteItem={props.deleteItem} renderTitle={props.renderTitle} children={props.children}
					/>;
				} }
			</For>
		</repeater-items> 
	);

	const withDragDrop = () => (
		<DragDropProvider onDragStart={onDragStart} onDragEnd={onDragEnd} collisionDetector={closestCenter} >
			<DragDropSensors />
			<SortableProvider ids={ids()}>
				{repeaterItems()}
			</SortableProvider>

			<DragOverlay>
				<repeater-item class='collapsed'>
					<repeater-bar class={overlayBarStyle()}>
						<i class='fas fa-ellipsis-v'></i> 

						<repeater-title class={repeaterTitleStyle()}>
							{activeItem()}
						</repeater-title>
					</repeater-bar>
				</repeater-item>
			</DragOverlay>
		</DragDropProvider>
	);

	return (
		<Dynamic component={current() == undefined ? withDragDrop : repeaterItems} />
	);
}

interface ItemProps<ItemData> {
	current:number|undefined,
	setCurrent: (item:number|undefined) => void,
	id: number,
	index: number,
	item: ItemData,
	deleteItem: (e:Event,i:number) => void,
	renderTitle: (current:ItemData) => string,
	children: (item:ItemData[],index:number) => JSX.Element
}

function Item<ItemData>(props:ItemProps<ItemData>)
{
	/*
		Unfortunately DragDropProviders don't nest properly - parents nodes end up being dragged as well as children.
		To solve this I'm removing 'sortable' here. An alternative would be to remove DragDropProvider for the parents.
		Disabling sortability in this way means parent items have to be collapsed before sorting.
	*/
	const isSortable = props.current==undefined;
	const sortable = isSortable ? createSortable(props.id,/*Think this is meant to any*/props.item as any) : () => {};

	const show = () => props.current==props.index;
	const toggleShow = () => props.setCurrent(show() ? undefined : props.index);

	return (
		<repeater-item use:sortable  
			classList={{
				[repeaterItemStyle()]: true,
				expanded: show(),
				collapsed: !show(),
				[underlayStyle()]: (sortable as any).isActiveDraggable
			}}
		>
			<repeater-bar onClick={toggleShow}
				classList={{
					[barStyle()]: true,
					[disabledBarStyle()]: !isSortable
				}}
			>
				<i class='fas fa-ellipsis-v'></i> 
				<repeater-title class={repeaterTitleStyle()}>
					{ props.renderTitle(props.item) }
				</repeater-title>
				<repeater-buttons>
					<button-group>
						{show() ?
							<button class={buttonStyle()}><i class='fas fa-compress' title='Collapse'></i></button>
						:
							<button class={buttonStyle()}><i class='fas fa-expand' title='Expand'></i></button>
						}
					</button-group>
					<button class={buttonStyle()} onClick={e => props.deleteItem(e,props.index)}>
						<i class='fas fa-trash' title='Delete'></i>
					</button>
				</repeater-buttons>
			</repeater-bar>

			<Show when={show()}>
				{props.children([props.item], props.index) } 
			</Show>
		</repeater-item>
	);
}

