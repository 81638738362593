import {ISvgColours} from 'Shared/backend/fonts/icons/IconColour';

export function PinCircle(props:ISvgColours)
{
	return (
		<svg id="pin_circle" class="icon" 
			style={`fill:${props.fill}; stroke:${props.stroke};`}
			viewBox="0 0 64 64" enable-background="new 0 0 64 64" xmlns="http://www.w3.org/2000/svg">

  <g transform="matrix(0.007158, 0, 0, -0.007158, -305.958099, -8633.16018)" style="transform-origin: 305.958px 8633.16px;">
    <path d="M4140 8630 c-117 -4 -206 -11 -223 -18 -16 -7 -50 -12 -76 -12 -27 0
-65 -7 -84 -15 -20 -8 -52 -15 -70 -15 -19 0 -51 -7 -70 -15 -20 -8 -51 -15
-69 -15 -18 -1 -44 -7 -58 -15 -14 -8 -37 -14 -51 -15 -15 0 -40 -7 -55 -15
-16 -8 -37 -15 -46 -15 -10 0 -78 -20 -152 -45 -681 -225 -1299 -625 -1778
-1152 -117 -129 -143 -159 -242 -288 -75 -97 -114 -153 -193 -278 -91 -144
-97 -154 -129 -212 -15 -27 -42 -77 -61 -110 -18 -33 -37 -69 -42 -80 -5 -11
-24 -54 -44 -95 -30 -64 -81 -188 -132 -320 -35 -90 -71 -203 -110 -340 -7
-25 -20 -68 -29 -96 -9 -29 -16 -64 -16 -78 0 -15 -7 -43 -15 -63 -8 -19 -15
-53 -15 -75 0 -21 -7 -55 -15 -75 -8 -19 -15 -59 -15 -89 0 -29 -6 -81 -14
-116 -14 -63 -31 -345 -30 -513 0 -182 16 -462 30 -516 8 -31 14 -79 14 -107
0 -27 7 -69 15 -92 8 -23 15 -62 15 -85 0 -23 7 -59 15 -78 8 -20 15 -51 15
-69 1 -18 7 -44 15 -58 8 -14 14 -39 15 -56 0 -18 7 -45 15 -60 8 -16 15 -37
15 -47 0 -40 125 -382 206 -567 32 -73 121 -248 153 -301 11 -19 25 -43 30
-54 53 -117 319 -496 471 -670 66 -76 375 -382 451 -447 80 -69 306 -238 399
-299 78 -52 221 -140 245 -150 11 -5 36 -19 55 -30 53 -32 235 -122 320 -158
41 -18 84 -36 95 -41 11 -5 45 -19 75 -30 30 -12 64 -26 75 -30 11 -5 29 -11
40 -13 11 -3 45 -15 75 -27 30 -12 71 -25 90 -29 19 -4 45 -13 57 -19 12 -7
32 -12 45 -12 13 0 32 -6 43 -14 11 -7 36 -16 55 -19 41 -6 106 -23 170 -43
25 -8 59 -14 77 -14 17 0 44 -5 60 -11 98 -37 769 -61 1123 -39 267 17 334 26
470 60 25 6 65 15 90 19 25 4 52 11 60 16 8 5 32 12 55 16 22 4 55 12 73 18
17 6 47 16 65 22 17 6 46 14 62 16 17 3 35 10 42 15 7 6 27 14 45 18 34 8 157
53 193 69 11 5 76 33 145 62 128 53 295 135 385 189 28 16 59 34 70 39 23 11
159 98 253 163 100 68 230 166 322 242 110 91 416 397 506 506 154 186 342
458 434 625 18 33 36 65 40 70 31 41 169 339 216 465 13 36 28 74 33 85 6 11
20 52 32 90 12 39 26 74 31 80 4 5 8 20 8 35 1 14 7 36 15 50 8 14 14 34 15
45 0 11 7 33 15 49 8 15 15 40 15 55 1 14 7 37 15 51 8 14 14 39 15 56 0 17 7
48 15 67 8 20 15 52 15 71 0 19 5 47 11 63 7 15 15 71 20 123 5 52 13 129 18
170 13 97 13 811 0 915 -5 44 -14 123 -18 175 -5 52 -14 108 -20 123 -6 16
-11 45 -11 65 -1 20 -7 48 -15 62 -8 14 -14 40 -15 58 0 18 -5 43 -12 55 -6
12 -15 40 -19 62 -4 22 -12 55 -18 73 -25 72 -33 98 -41 127 -10 37 -29 89
-47 132 -7 17 -13 36 -13 42 0 6 -4 19 -9 29 -6 9 -31 71 -57 137 -97 247
-246 526 -403 755 -25 36 -58 85 -75 110 -17 25 -76 102 -131 171 -503 627
-1200 1112 -1940 1349 -55 18 -109 36 -120 40 -21 9 -88 27 -145 39 -19 4 -45
13 -57 19 -12 7 -36 12 -53 12 -17 0 -47 7 -67 15 -19 8 -50 15 -68 15 -17 0
-54 7 -81 15 -27 8 -74 15 -105 15 -31 0 -92 6 -135 14 -90 16 -548 24 -864
16z m550 -1259 c58 -6 125 -15 150 -20 25 -6 68 -15 95 -21 28 -6 68 -15 90
-19 22 -4 50 -13 62 -19 12 -7 32 -12 45 -12 13 0 33 -7 44 -15 10 -8 27 -15
36 -15 35 0 227 -94 358 -175 230 -143 455 -364 610 -600 71 -108 200 -364
200 -396 0 -8 5 -20 11 -26 6 -6 14 -26 19 -44 4 -19 13 -47 18 -64 31 -88 64
-235 79 -350 20 -148 4 -539 -26 -638 -9 -32 -24 -90 -41 -157 -34 -142 -108
-283 -414 -788 -61 -101 -119 -197 -130 -215 -29 -50 -279 -466 -296 -492 -8
-13 -94 -153 -190 -312 -96 -158 -194 -317 -217 -353 -24 -36 -43 -68 -43 -72
0 -4 -19 -35 -42 -70 -47 -70 -55 -82 -94 -148 -15 -25 -88 -143 -163 -263
-75 -119 -141 -225 -146 -235 -49 -84 -189 -282 -199 -282 -11 0 -58 58 -76
95 -3 6 -30 48 -61 95 -84 129 -124 192 -301 478 -90 144 -171 275 -180 290
-9 15 -23 37 -30 50 -7 12 -60 97 -117 190 -57 92 -136 219 -174 282 -39 63
-90 145 -114 182 -24 37 -43 69 -43 72 0 3 -19 34 -43 69 -24 34 -46 69 -50
77 -4 8 -22 39 -40 67 -18 29 -72 119 -122 199 -49 81 -97 157 -105 170 -182
284 -351 582 -400 704 -12 30 -28 67 -36 82 -8 14 -14 35 -14 45 0 11 -7 28
-15 39 -8 10 -15 33 -15 49 0 17 -7 41 -15 55 -8 14 -14 42 -15 63 0 21 -5 48
-11 59 -16 30 -30 176 -36 373 -5 162 3 267 28 390 5 28 13 70 18 95 4 25 13
55 19 67 7 12 12 31 12 42 0 11 6 35 14 53 8 18 22 53 31 78 87 221 214 427
384 619 185 209 375 355 611 468 63 30 127 61 142 69 14 8 35 14 46 14 11 0
25 6 31 14 6 8 26 16 44 19 18 2 46 9 62 15 225 80 451 102 755 73z" style="transform-origin: 305.958px 7715.32px;"></path>
    <path d="M4410 6189 c-252 -24 -484 -183 -638 -436 -55 -91 -82 -188 -88 -315
-12 -231 37 -403 159 -568 51 -68 106 -120 175 -167 69 -45 221 -123 242 -123
9 0 22 -6 28 -12 10 -10 65 -13 205 -13 214 0 225 3 361 75 243 127 371 280
452 540 24 78 24 322 -1 400 -47 152 -106 258 -197 355 -185 198 -429 290
-698 264z" style="stroke: url(#gradient-0);"></path>
  </g>
</svg>
	);
}

