import {Lineup} from 'Shared/model/lineup';

export function ArtistNames(artists:Lineup)
{
	let ret='';
	let i=0;

    for (const a of artists) {
		if (i > 0)
			ret += i==artists.length-1 ? ' and ' : ', ';

		ret += a.name;
		i++;
	}

	return ret;
}


