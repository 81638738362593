import {AccessAreaName} from 'Common/permissions/AccessArea';
import {BackendPageConfig, backendData} from 'Common/BackendPageConfig';
import { z } from 'zod';
import Assert from 'Common/Assert';

export const pageNotFoundParams = z.object({ 
}).strict();	
export type PageNotFoundParams = z.infer<typeof pageNotFoundParams>;

export const pageNotFoundData = backendData.extend({
}).strict();
export type PageNotFoundData = z.infer<typeof pageNotFoundData>;

//FIXME currently backend and frontend PageNotFounds are not returning 404


export class PageNotFound extends BackendPageConfig<PageNotFoundData,PageNotFoundParams>
{
	static readonly pageName = 'backend/pageNotFound';
	name() { return PageNotFound.pageName; }

	access() { return 'venueBackend' as AccessAreaName; }

	defaultSettings()
	{
		return {
			...super.defaultSettings(),
			htmlClasses: Assert.have(this.htmlClasses),
			template: 'App/backend/pageNotFound.njk'
		};
	}
}

