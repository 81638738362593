import {StatusPublished} from 'Shared/backend/fonts/icons/StatusPublished.svg';
import {Match,Switch} from 'solid-js';

export type StatusKey = 'published' | 'unpublished';

export interface IStatusIcons {
	status: StatusKey,
	scheduleDate?: XXX
}

export function StatusIcons(props: IStatusIcons)
{
	return (
		<div title={props.status=='published' ? 'Visible to the public' : 'Disabled'}>
			<Switch>
				<Match when={props.status == 'published'}>
					<StatusPublished />
				</Match>
				<Match when={true}>
					<StatusPublished fill='#ccc' />
				</Match>
			</Switch>
		</div>
	);
}
