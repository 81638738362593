import {VenueRoutes, VenueRoutesType} from 'Shared/routes/VenueRoutes';
import {Home as BackendHome} from 'Shared/view/backend/Home2'


//TODO delete this later if no special routes or pages required

export class IndieIslandRoutes extends VenueRoutes
{
	frontend()
	{
		return <VenueRoutesType> ({
			...super.frontend(),
//E.g.		'/menu':		[FrontendMenu, () => ({})],
		});
	}

	backend()
	{
		return <VenueRoutesType>({
			...super.backend(),

			'/admin/home': 			[BackendHome, ()=>({})],
			'/admin/home/:tab': 	[BackendHome, params=>({tab:params.tab})],
		});
	}
}

