import { z } from 'zod';
import {DocumentQueries} from 'Common/config/PageConfigTypes';
import {menuDoc as menuModel} from 'Shared/model/Menu';
import {AccessAreaName} from 'Common/permissions/AccessArea';
import {BackendPageConfig, backendData, backendParams} from 'Common/BackendPageConfig';
import {ListMenuPage} from 'Shared/backend/menu/ListMenuPage';


export const sectionParams = backendParams.extend({ 
	course: z.string().optional()
}).strict();	
export type SectionParams = z.infer<typeof sectionParams>;

export const sectionData = backendData.extend({
	params: sectionParams,
	menu: menuModel,

	/* Component data: */
	courseList: z.object({
		current: z.number().optional()
	})
}).strict();
export type SectionData = z.infer<typeof sectionData>;


export class ListMenu extends BackendPageConfig<SectionData,SectionParams>
{
	static readonly pageName = 'backend/listMenu';
	name() { return ListMenu.pageName; }

	access() { return 'venueFullEditor' as AccessAreaName; }

	defaultSettings()
	{
		return {
			...super.defaultSettings(),
			htmlClasses: this.htmlClasses,
			template: ListMenuPage
		};
	}

	documents(params:SectionParams): DocumentQueries 
	{
		return ({
			...super.documents(params),

//XXX probably best to lot the WHOLE menu and look at caching
			/* NOTE: If putting together a bill, should copy menu name and price - not just link to MenuItem as items can change. */
			menu: {
				type:'object',
				collection:'menu',
				aggregate: () => [
					{$match: {slug:params.slug}} //FIXME
//					{$match: {slug: 'menu'}}
				]
			}
		});
	}

	permissions()
	{
		return ({
			//XXX combine these 2?
			createItem: { collection: 'menu' },
			reorderItems: { collection: 'menu' }
		});
	}
}

