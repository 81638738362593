import {Component} from 'Common/components/Component';
import {UpdateKeyMessage} from 'Common/Messages';
import {IPage} from 'Common/pages/IPage';
import {IConnectionToServer} from 'Common/IConnectionToServer';
import { Document,IPageData } from 'Common/PageConfig';
import { EditKeyComponent as EditKeyComponentDef, Location } from 'Common/config/PageConfigTypes';
import {InvalidFieldValueError} from 'Browser/InvalidFieldValueError';
import {Id} from 'Common/Id';


/* A view onto a data subdocument that allows it to be editted in a form. */
export class EditKeyComponent<PD extends IPageData,Doc extends Document> extends Component
{
	constructor(name:string,
		readonly page:IPage<PD>,
		private server:IConnectionToServer,
		//XXX using NestedArrayLocation instead of Location:any may be a possibility
		readonly def: EditKeyComponentDef<Doc>
	)
	{
		super(name);
		this.updateField = this.updateField.bind(this);
	}

	componentType() { return 'editKey'; }

	updateField(docId:Id,location:Location,oldKey:string,newKey:string)
	{
		try {
			const message = new UpdateKeyMessage(this.page.name(),this.name,docId,location,oldKey,newKey);
			this.server.sendOperationOptimistically(message);
		}
		catch(err) {
			if (err instanceof InvalidFieldValueError)
				alert(err.message);
			else
				throw err;
		}
	}
}

