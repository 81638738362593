import {BandcampButton} from 'Shared/backend/fonts/icons/BandcampButton.svg';
import {IconColour} from 'Shared/backend/fonts/icons/IconColour';
import {LinkOpen} from 'Shared/backend/fonts/icons/LinkOpen.svg';
import {ListenOnApple} from 'Shared/backend/fonts/icons/ListenOnApple.svg';
import {ListenOnSpotify} from 'Shared/backend/fonts/icons/ListenOnSpotify.svg';
import {MusicCircle} from 'Shared/backend/fonts/icons/MusicCircle.svg';
import {SoundcloudButton} from 'Shared/backend/fonts/icons/SoundcloudButton.svg';
import {Text, Wrap} from 'Shared/forms/Inputs';
import { css } from '@emotion/css'
import {Show} from 'solid-js';
import {appleMusic, bandcamp, soundcloud, spotify} from 'Browser/InputTypes';
import {ArtistProps} from 'Shared/artists/ProfilePage';


const musicLinksStyle = css({
	label: {
		a: {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center'
		}
	},

	'.musicButton': {
		'.icon': {
			width: '6em'
		}
	}
});
 

export function MusicLinks(artist:ArtistProps)
{
	return <>
		<h4> Music
			<x-notes>VOS displays links on venue website &amp; drafts social media posts so fans can listen on their platform of choice</x-notes>
		</h4>
		<div class='step'>
			<div class='stepIcon'>
				<MusicCircle fill={artist.spotify || artist.appleMusic || artist.musicEmbedId ? IconColour.active : IconColour.inactive} />
			</div>
			<div class={musicLinksStyle}>
				<Spotify {...artist} />

				<AppleMusic {...artist} />

				<Bandcamp {...artist} />

				<Soundcloud {...artist} />
			</div>
		</div> 
	</>;
}

function Spotify(artist:ArtistProps)
{
//XXX the search here looks a bit odd

	const label = () => {
		if (exists(artist.spotify))
			return <a class='active'>Spotify song link</a>;
		else 
			return <a class='inactive' href={`https://open.spotify.com/search/${artist.name}/artists`} target="_blank">
				Search Spotify for a song <LinkOpen fill={IconColour.inactive} />
			</a>;
	}

	return <>
		<Wrap label={label()}>
			<Text component={artist.editArtist} store={artist} field='spotify' processInput={spotify} placeholder='Paste song share link' />
		</Wrap>
			
		<Show when={exists(artist.spotify)}>
			<a href={artist.spotify} target='_blank' class='musicButton'>
				<ListenOnSpotify />
			</a>
		</Show>
	</>;
}

function AppleMusic(artist:ArtistProps)
{ 
//XXX insert + or %20 between spaces in artist name search?
//XXX term, URL or JSON?  Inconsistent here...

	const label = () => {
		if (exists(artist.appleMusic))
			return <a class='active'>Apple song link</a>;
		else 
			return <a class='inactive' href={`https://music.apple.com/us/search?term=${artist.name}`} target='_blank'>
				Search Apple Music for a song
				<LinkOpen fill={IconColour.inactive} />
			</a>;
	}

	return <>
		<Wrap label={label()}>
			<Text component={artist.editArtist} store={artist} field='appleMusic' processInput={appleMusic} placeholder='Paste song share link' />
		</Wrap>

		<Show when={exists(artist.appleMusic)}>
			<a href={artist.appleMusic} target='_blank' class='musicButton'>
				<ListenOnApple />
			</a>
		</Show>
	</>;
}

function Bandcamp(artist:ArtistProps)
{
	const label = () => {
		if (exists(artist.bandcamp))
			return <a class='active'>Bandcamp page</a>;
		else
			return <a class='inactive' href={`https://bandcamp.com/search?q=${artist.name}&item_type`} target='_blank'>
				Search Bandcamp
				<LinkOpen fill={IconColour.inactive} />
			</a>;
	};

	return <>
		<Wrap label={label()}>
			<Text component={artist.editArtist} store={artist} field='bandcamp' processInput={bandcamp} placeholder='Add link to music or merch page' />
		</Wrap>

		<Show when={exists(artist.bandcamp)}>
			<a href={artist.bandcamp} target='_blank' class='musicButton'>
				<BandcampButton />
			</a>
		</Show>
	</>;
}

function Soundcloud(artist:ArtistProps)
{
	const label = () => {
		if (exists(artist.soundcloud))
			return <a class='active' >Soundcloud page</a>;
		else 
			return <a class='inactive' href={`https://soundcloud.com/search?q=${artist.name}`} target='_blank'>
				Search Soundcloud
				<LinkOpen fill={IconColour.inactive} />
			</a>;
	};

	return <>
		<Wrap label={label()}>
			<Text component={artist.editArtist} store={artist} field='soundcloud'  processInput={soundcloud} placeholder='Add link to artist/track/album' />
		</Wrap>

		<Show when={exists(artist.soundcloud)}>
			<a href={artist.soundcloud} target='_blank' class='musicButton'>
				<SoundcloudButton />
			</a>
		</Show>
	</>;
}

function exists(value?:string)
{
	return value!=undefined && value.trim()!='';
}

