import {AccessArea} from 'Common/permissions/AccessArea';
import {Role} from 'Common/permissions/Role';

export class PublicRole extends Role
{
	constructor()
	{
		super('public');
	}

	public toJson()
	{
		return {
			type: this.name
		};
	}

	public topAccess(): AccessArea
	{
		return new AccessArea('unrestricted');
	}

	public canAccess(area:AccessArea)
	{
		return (new AccessArea('unrestricted')).includes(area);
	}
}

