import {AccessArea} from 'Common/permissions/AccessArea';
import {Role} from 'Common/permissions/Role';
import { VenueRole as VenueRoleConfig } from 'Common/config/Role';
import {Id} from 'Common/Id';

export class VenueRole extends Role
{
	constructor(
		/* site come from the domain, so site.id might not match config.siteId */
		readonly config:VenueRoleConfig
	)
	{ 
		super(config.type);
	}

	public toJson()
	{
		return {
			type: this.name,
			siteId: this.config.siteId
		};
	}

	public topAccess()
	{
		switch(this.config.type) {
			case 'venueBareAdmin': return new AccessArea('venueBareEditor');
			case 'venueEventAdmin': return new AccessArea('venueEventEditor');
			case 'venueMenuAdmin': return new AccessArea('venueMenuEditor');
			case 'venueAdmin': return new AccessArea('venueFullEditor');
			case 'venueDeveloper': return new AccessArea('venueDeveloper');
		}
	}

	public canAccess(area:AccessArea,pageSiteId:Id)
	{
		if (this.config.siteId != pageSiteId)
			return false;

//TODO move this part to parent class...  Maybe the ID too...
		if (this.topAccess().includes(area))
			return true;

		return false;
	}
}

