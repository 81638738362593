/* 
	See https://clearleft.com/posts/how-to-use-variable-fonts-in-the-real-world for the syntax
	relating to variable fonts used here. Will probably go out of date. 
*/

export function OpenSans(resourcesPrefix:string)
{
	const font = `${resourcesPrefix}/fonts/OpenSans`;

	return `
		<style>
			@font-face {
				font-family: 'Open Sans';
				src: url('${font}/OpenSans-VariableFont_wdth,wght.woff2') format('woff2 support variations'),
					 url('${font}/OpenSans-VariableFont_wdth,wght.woff2') format('woff2-variations');
				font-weight: 1 999;
			}

			@font-face {
				font-family: 'Open Sans';
				src: url('${font}/OpenSans-Regular.woff') format('woff');
				font-weight: 400;
			}

			@font-face {
				font-family: 'Open Sans';
				src: url('${font}/OpenSans-SemiBold.woff') format('woff');
				font-weight: 600;
			}
		</style>
	`;
}
