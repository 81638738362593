
/*
	TODO maybe add zeros when no decimal places exist in order to standardise the format.
		cf twoZerosFormat(), oneZeroFormat(), noZerosFormat()
*/

export function stripZero(amount)
{
	const a = amount ?? '';

	if (a.match(/.*\.[0-9]0$/))
		return a.substring(0,amount.length - 1);
	else 
		return a;
}

export function stripZeros(amount)
{
	const a = amount ?? '';

	if (a.match(/.*\.00$/))
		return a.substring(0,amount.length - 3);
	else if (a.match(/.*\.[0-9]0$/))
		return a.substring(0,amount.length - 1);
	else
		return a;
}
