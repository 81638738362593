import {FoodMenu} from 'Shared/frontend/fonts/icons/FoodMenu.svg';
import {Calendar} from 'Shared/frontend/fonts/icons/Calendar.svg';
import {Home} from 'Shared/frontend/fonts/icons/Home.svg';
import {Nav} from 'Shared/frontend/fonts/icons/Nav.svg';
import {NavMore} from 'Shared/frontend/navWidget/NavMore';
import {Overlay} from 'Shared/frontend/overlayWidget/Overlay';
import {createSignal} from 'solid-js';
import {Show,For} from 'solid-js'
import {Contact} from 'Shared/frontend/fonts/icons/Contact.svg';

export function MobileFooterNav(props)
{
	const mobile = () => props.config.nav.mobile;
	const maxButtons = () => mobile().includeMoreButton ? mobile().maxButtons - 1 : mobile().maxButtons;

	const [visible,setVisible] = createSignal(false);

	return (<>
		<nav class='mobile mobileFooter'>
			<For each={mobile().links.slice(0,maxButtons())}>{item =>
				<a href={props.config.link[item.link].target}>
					<button tabindex='-1' class={`${item.class} ${item.link==props.pageName ? 'active' : ''}`}>
						<div class='buttonIcon'>
							{navIcon(item.icon)}
						</div>
						<div class='label'>{item.label}</div>
					</button>
				</a>
			}</For>

			<Show when={mobile().includeMoreButton}>
				<a href=''>
					<button tabindex='-1' onclick={() => setVisible(!visible())}>
						<div class='buttonIcon'>
							<Nav />
						</div>
						<div class='label'>More</div>
					</button>
				</a>
			</Show>
		</nav>

		<Show when={visible()}>
			<Overlay visible={visible()} close={() => setVisible(false)} classes='TODO'>
				<NavMore venue={props.venue} config={props.config} pageName={props.pageName} />
			</Overlay>
		</Show>
	</>);
}

function navIcon(name:string)
{
	switch(name) {
		case 'home.svg.njk': return <Home />;
		case 'foodMenu.svg.njk': return <FoodMenu />
		case 'calendar.svg.njk': return <Calendar />;
		case 'contact.svg.njk': return <Contact />;
	}
}

