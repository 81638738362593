import { IconColour } from "./IconColour";

export function StatusPublished(props)
{
	return (
		<svg id='status_published' class='icon' style={`fill:${props.fill ?? IconColour.green}; stroke:${props.stroke ?? IconColour.green};`} viewBox='0 0 64 64' enable-background='new 0 0 64 64' xmlns='http://www.w3.org/2000/svg'>
		  <defs></defs>
		  <path id="path0" d="M 24.771 2.59 C -1.526 9.964 -6.303 44.479 17.059 58.303 C 25.39 63.232 38.681 63.232 47.012 58.303 C 69.25 45.144 66.107 12.043 41.795 3.35 C 37.895 1.956 28.524 1.537 24.771 2.59 M 38.265 16.645 C 44.806 18.326 51.336 23.388 54.27 29.05 L 55.576 31.571 L 54.303 34.092 C 45.593 51.34 18.478 51.34 9.768 34.092 L 8.495 31.571 L 9.797 29.05 C 14.859 19.249 27.303 13.827 38.265 16.645 M 28.609 22.388 C 26.107 23.279 23.609 25.945 22.842 28.543 C 19.898 38.506 31.652 45.723 38.921 38.416 C 41.467 35.857 42.218 32.736 41.337 28.378 C 40.407 23.778 33.643 20.596 28.609 22.388 M 34.937 28.838 C 36.177 30.085 36.127 33.107 34.845 34.395 C 32.742 36.51 28.787 35.308 28.242 32.389 C 27.526 28.552 32.191 26.078 34.937 28.838" ></path>
		</svg>

	);

}
