import {HttpErrorInterface,HttpErrorMessage} from 'Browser/HttpErrorInterface';
import {InvalidFieldValueError} from 'Browser/InvalidFieldValueError';

/* Doesn't extend Error. Not interested in stack traces here.  */	
export default class InvalidFieldValueErrors implements HttpErrorInterface
{
	readonly errors:InvalidFieldValueError[];

	constructor(errors:InvalidFieldValueError[])
	{
		this.errors = errors;
	}

	static type():string { return 'invalidFieldValueErrors'; }

	public toJson():HttpErrorMessage
	{
		const errors:InvalidFieldValueError[] = [];
		for (const e of this.errors)
			errors.push(e.toJson());

		return {httpCode:200,errorType:InvalidFieldValueErrors.type(),fieldErrors:errors};
	}

	public static fromJson(data:any):InvalidFieldValueErrors
	{
		const errors:InvalidFieldValueError[] = [];

		for (const e of data.errors) 
			errors.push(new InvalidFieldValueError(e.field,e.message));

		return new InvalidFieldValueErrors(errors);
	}
}

