import {IndieIslandView} from 'Shared/view/IndieIslandView';
import {IndieIslandRoutes} from 'Shared/routes/IndieIslandRoutes';
import {BuildBasics} from 'Common/BuildBasics';
import {Links} from 'Shared/config/Link';
import {Nav as IndieIslandNav} from 'Shared/config/IndieIslandNav';
import {IVenueConfig} from 'Shared/config/IVenueConfig';
import {VenueBasics} from 'Common/VenueBasics';


export class IndieIslandConfig implements IVenueConfig
{
	view() { return (new IndieIslandView()).view(); }

	link() { return Links; }

	nav() { return IndieIslandNav; }

	routes(inBrowser:boolean,build:BuildBasics,venue:VenueBasics)
	{
		return (new IndieIslandRoutes(inBrowser,build,venue)).routes();
	}
}
