
export function Instagram(props)
{
	return (
		<svg id="instagram" class="icon" style={`fill:${props.fillColour}; stroke:${props.strokeColour};`} viewBox="0 0 64 64" enable-background="new 0 0 64 64" xmlns="http://www.w3.org/2000/svg" xmlns:bx="https://boxy-svg.com">
		  <defs></defs>
		  <path d="M 40.105 16.531 L 23.895 16.531 C 19.836 16.531 16.531 19.836 16.531 23.895 L 16.531 40.105 C 16.531 44.164 19.836 47.469 23.895 47.469 L 40.105 47.469 C 44.164 47.469 47.469 44.164 47.469 40.105 L 47.469 23.895 C 47.469 19.836 44.164 16.531 40.105 16.531 Z M 32 41.633 C 26.689 41.633 22.367 37.311 22.367 32 C 22.367 26.689 26.689 22.367 32 22.367 C 37.311 22.367 41.633 26.689 41.633 32 C 41.633 37.311 37.311 41.633 32 41.633 Z M 41.895 24.336 C 40.653 24.336 39.645 23.328 39.645 22.084 C 39.645 20.842 40.653 19.834 41.895 19.834 C 43.14 19.834 44.147 20.842 44.147 22.084 C 44.148 23.328 43.14 24.336 41.895 24.336 Z M 32 25.648 C 28.498 25.648 25.648 28.498 25.648 32 C 25.648 35.502 28.498 38.352 32 38.352 C 35.502 38.352 38.352 35.502 38.352 32 C 38.352 28.498 35.502 25.648 32 25.648 Z M 32 2 C 15.433 2 2 15.433 2 32 C 2 48.568 15.433 62 32 62 C 48.568 62 62 48.568 62 32 C 62 15.433 48.568 2 32 2 Z M 50.75 40.105 C 50.75 45.973 45.973 50.75 40.105 50.75 L 23.895 50.75 C 18.027 50.75 13.25 45.973 13.25 40.105 L 13.25 23.895 C 13.25 18.027 18.027 13.25 23.895 13.25 L 40.105 13.25 C 45.973 13.25 50.75 18.027 50.75 23.895 L 50.75 40.105 Z" bx:origin="-0.033333 -0.033333"></path>
		</svg>
	);
}

