import {Wrap,Text, SingleSelect} from 'Shared/forms/Inputs';

export const wineTypes = {
	red: 'Red',
	white: 'White',
	rose: 'Rose',
	sparkling: 'Sparkling'
};

export function WineDescription(props)
{
	return (<>

		<Wrap label='Description' required={false}>
			<Text {...props.itemProps} field='description' />
		</Wrap>

		<Wrap label='Wine type' required={false}>
			<SingleSelect {...props.itemProps} field='wineType' options={wineTypes} />
		</Wrap>

		<Wrap label='Year' required={false}>
			<Text {...props.itemProps} field='year' />
		</Wrap>

		<Wrap label='Region' required={false}>
			<Text {...props.itemProps} field='region' />
		</Wrap>
	</>);
}
