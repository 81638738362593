import {SolidHtmlEditorWidget} from 'Browser/widgets/SolidHtmlEditorWidget';
import {ArtistUrls} from 'Common/ArtistUrls';
import {usePage} from 'Shared/artists/PageProvider';
import {ArtistProps} from 'Shared/artists/ProfilePage';
import {DocumentCircle} from 'Shared/backend/fonts/icons/DocumentCircle.svg';
import {IconColour} from 'Shared/backend/fonts/icons/IconColour';
import {LinkOpen} from 'Shared/backend/fonts/icons/LinkOpen.svg';
import {Html, Text, Wrap} from 'Shared/forms/Inputs';
import {Show,createSignal,onMount, onCleanup} from 'solid-js';

export function Biography(artist:ArtistProps)
{
	const maxTaglineLength = 100;

	let htmlNode:HTMLDivElement;
	let taglineNode:HTMLInputElement;

	if (artist.inBrowser) {
		const htmlEditor = new SolidHtmlEditorWidget(new ArtistUrls(usePage().build),{
			height: 200,
			menubar: false,
			plugins: ['link'],
			toolbar: 'undo redo | link',
			statusbar: true,
			resize: true }
		);

		onMount(() => htmlEditor.init(htmlNode));
		onCleanup(() => htmlEditor.destroy(htmlNode));
	}

	const [taglineLength,setTaglineLength] = createSignal(artist.tagline?.length ?? 0);

	return <>
		<h4>Biography</h4>

		<div class='step biography'>
			<div class='stepIcon'>
				<DocumentCircle fill={artist.tagline || artist.biography ? IconColour.active : IconColour.inactive} />
			</div>
			<div>
				<Show when={artist.facebook || artist.website}>
					<label class={artist.tagline || artist.biography ? 'active' : 'inactive'} >
						Search for info on your&nbsp;

						<Show when={artist.facebook}>
							<a class='active' href={`https://www.facebook.com/${artist.facebook}`} target='_blank'>
								Facebook page
								<LinkOpen fill={IconColour.active} />
							</a>
						</Show>

						<Show when={artist.facebook && artist.website}>
							 or 
						</Show>

						<Show when={artist.website}>
							<a class='active' href={artist.website} target='_blank'>
								website 
								<LinkOpen fill={IconColour.active} />
							</a>
						</Show>
					</label>
				</Show>
		
				<x-field class='margin-bottom-wrap'>
					<Wrap label='Short description' instructionsAfterLabel='Imagine this is the only thing people will read about the artist'>
						<Text component={artist.editArtist} store={artist} field='tagline' ref={taglineNode!}
							onInput={value => {
								if (taglineNode.value!.length > maxTaglineLength)
									taglineNode.value = taglineNode.value.slice(0,maxTaglineLength)
								else
									setTaglineLength(value.length)
							} } 
						/>
					</Wrap>

					<x-notes>
						{maxTaglineLength - taglineLength()}/{maxTaglineLength} characters left
					</x-notes>
				</x-field>

				<Wrap label='More artist info' instructionsAfterLabel='Displayed after "Short Description" so do not repeat that'>
					<Html component={artist.editArtist} store={artist} field='biography' ref={htmlNode!} />
				</Wrap>
			</div>
		</div>
	</>;
}
