import {Components, DeleteComponent, DocumentQueries, ImageUploaderComponent} from 'Common/config/PageConfigTypes';
import { z } from 'zod';
import { artistDoc as artistDoc,ArtistDoc as ArtistDoc } from 'Shared/model/Artist';
import * as t from 'Shared/model/basic';
import {IServerFunctions} from 'Server/ServerFunctions';
import * as imageSize from 'Shared/model/ImageSizes';
import {ArtistPageConfig, artistData, artistParams} from 'Common/pages/artist/ArtistPageConfig';
import {EditComponent} from 'Common/components/EditComponent';
import {ProfilePage} from 'Shared/artists/ProfilePage';


export const profileParams = artistParams.extend({
	id: t.id
}).strict();	
export type ProfileParams = z.infer<typeof profileParams>;


export const profileData = artistData.extend({
	artist: artistDoc,
}).strict();
export type ProfileData = z.infer<typeof profileData> 



export class Profile extends ArtistPageConfig<ProfileData,ProfileParams>
{
	static readonly pageName = 'artist/profile';
	name() { return Profile.pageName; }

//XXX should this have access()?

	settings()
	{
		return {
			...super.settings(),
			template: ProfilePage
		};
	}

	documents(params:ProfileParams): DocumentQueries
	{
		return ({
//			...bannerQuery(),
			artist: {
				type:'object',
				collection: 'artist',
				aggregate: (funcs:IServerFunctions) => [
					{$match: {_id: funcs.toObjectId(params.id) }}	
				]
			}
		});
//TODO pass in server functions into transforms too, and remove them as PageConfig dependencies... 
	}

	components()
	{
		return <Components<ProfileData>>({
			editArtist: {
				type: EditComponent.Id,
				collection: 'artist',
				subdocument: []
			},
			deleteProfile: <DeleteComponent<ProfileData>>{
				type: 'delete',
				collection: 'artist',
				redirect: () => `/manager`
			},

//XXX cf using editArtist for collection and locate OR using spread or inheritance for the common parts
			image: <ImageUploaderComponent>{
				type:'imageUploader', 
				collection: 'artist',
				locate: (doc:ArtistDoc) => doc,
				field: 'image',
				assetFolder: 'PressShot', 	//TODO auto-construct assetFolder?
//TODO copy this image size. Have a common inteface??				
				sizes: imageSize.landscape,
				previewSize: 'medium'
			},
		});
	}
}

