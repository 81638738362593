
/* 	Note that JSONPath provides an alternative search methods etc, but the JSONPath libraries are surprisingly large. */ 

/*
	Attempts to crawl the data Mongo-style. This is a very basic implementation, but is hopefully OK.
	path: 'aa.bb.cc' etc

 */
export function findEmbedded(node:any,path:string|undefined):any
{
	if (path==undefined || node==undefined)
		return node;

	const bits = path.split('.');
	if (bits.length<=1) 
		return node[path];

	return findEmbedded(node[bits[0]],bits.slice(1).join('.'));
}

/*
	Takes a Mongo-style path and returns an object suitable for use as a projection in a 
	Mongo query. Only one object is included in the projection.

	Eg  'a.b.c'  returns   { a: {b: {c: 1}}}
 */
export function pathToProjection(path:string):object
{
	const bits = path.split('.');
	if (bits.length<=1)
		return {[path]:1};

	const child = pathToProjection(bits.slice(1).join('.'));
	return {[bits[0]]:child};
}

