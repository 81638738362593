import {InvalidFieldValueError} from 'Browser/InvalidFieldValueError';


export interface INjkInputType
{
	/*
		It is assumed:
		1. value is trimmed before being called
		2. if the trimmed value = '' input() will not be called - instead null or undefined will be returned.
		3. an invalid value will throw an InvalidFieldValueError
	 */

	input(value:string):string
}


/*
	These functions are used to massage user input into something standard.
	Typically use with text fields for URLs etc.
 */

export class EmailField implements INjkInputType
{
    input(value:string)
	{
		/* 
			This isn't a full check, and a full check isn't worth it as implementations are likely to vary
			from the standard from time to time.  Need to send an email to be sure anyhow.
		 */
		//TODO in time should check the domain name section contains only valid domain name characters
		if (value.match(/^\S+@\S+$/)==null) 
			throw new InvalidFieldValueError(null,'invalid email address');

        return value;
	}
}


export class PriceField implements INjkInputType
{
	/* Valid input formats:
			100		100.	100.0		100.00	
			.90		$100.00	€100.00		90c ?

		TODO trimming dollar symbols, Euros, intepreting cent symbols etc are locale specific.
			Currently not using an local specific parsing.
			NOTE in some cases the dollar symbol (or whatever) goes AFTER the amount
			Use of commas and the dot seem fairly standard.
	 */

    input(value:string) 
    { 
		/* Removing all spaces, tabs and commas. Could be overly forgiving I guess. */
		const stripped = value.replace(' \t,','');

        const bits = /^[\$€]?([0-9]*)(?:\.([0-9]{0,2}))?$/.exec(stripped);

        if (bits==null) 
			throw new InvalidFieldValueError(null,'invalid price');

		const front = bits[1]=='' ? '0' : bits[1];

		let back = bits[2] ?? '00';
		if (back.length == 0)
			back = '00';
		else if (back.length == 1)
			back = back+'0';

		return front+'.'+back;
	}
}

export class PasswordField implements INjkInputType
{
    input(value:string)
	{ 
		if (value.length<8) throw new InvalidFieldValueError(null,'password must be at least 8 characters long'); 
		if (value.length>8096) throw new InvalidFieldValueError(null,'password too long'); 
        return value;
	}
}

export class UrlField implements INjkInputType
{
    input(value:string)
	{
		//TODO in time should check the domain name section contains only valid domain name characters
		//     (and same for email domains)

		if (value.match(/^https?:\/\/\S+$/)==null) 
			throw new InvalidFieldValueError(null,'invalid URL');

        return value;
	}
}


export class SpotifyField extends UrlField
{
    input(value:string) 
	{ 
		const bits = value.match(/^https?:\/\/(?:[^\/]+\.|)spotify\.com\/track\/(.+)/);
		if (bits!=null) 
			return 'https://play.spotify.com/track/'+bits[1];

		if (value.match(/^https?:\/\/(?:[^\/]+\.|)spotify\.com\/artist\/.+/)!=null ||
		    value.match(/^https?:\/\/(?:[^\/]+\.|)spotify\.com\/album\/.+/)!=null) 
			throw new InvalidFieldValueError(null,'only song URLs here please, not album or artist URLs');

		throw new InvalidFieldValueError(null,'invalid Spotify URL');
	}
} 

export class AppleField extends UrlField
{
    input(value:string)
	{
		if (value.match(/^https?:\/\/(?:[^\/]+\.|)apple\.com\/.+/) == null) 
			throw new InvalidFieldValueError(null,'expect an Apple Music URL');
		return value;
	}
} 

export class BandcampField extends UrlField
{
    input(value:string)
    {
		let matches:string[]|null = null;
		let ret = '';
		if ((matches = value.match(/(?:^|\W)(album=\w+)/))!=null)
			ret += matches[1];

		if ((matches = value.match(/(?:^|\W)(track=\w+)/))!=null)
		{
			if (ret!='')
				ret += '/';
			ret += matches[1];
		}
		if (ret=='')
	        throw new InvalidFieldValueError(null,'invalid Bandcamp URL');
		return ret;
    }
}

export class SoundcloudField extends UrlField
{
	/* Example embed:
		<iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" 
			src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/245380211&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe>
	 */
    input(value:string)
    {
		let matches:string[]|null = null;
		if ((matches = value.match(/\Wapi\.soundcloud\.com\/([\w\/]+)/))!=null)
			return matches[1];
		if (value.match(/^[\w\/]+$/)!=null)
			return value;
        throw new InvalidFieldValueError(null,'invalid Soundcloud embed');
    }
}

export class FacebookField implements INjkInputType
{
    input(value:string) 
    { 
        //TODO? Could test UID using:  http://graph.facebook.com/[ID]  Maybe even show a preview
        const match1 = /^https?:\/\/(?:www\.)?facebook\.com\/([^\/\s&]+)/.exec(value);
        if (match1!=null)
            return match1[1];

        const match2 = /^@?([^\/\s]+)\s*$/.exec(value);
        if (match2!=null)
            return match2[1];

        throw new InvalidFieldValueError(null,'invalid Facebook identifier');
	}
}

export class XField implements INjkInputType
{
    input(value:string) 
    { 
        const match1 = /^https?:\/\/(?:www\.)?x\.com\/([^\/\s\?#&]+)/.exec(value);
        if (match1!=null)
            return match1[1];

        const match2 = /^@?([^\/\s]+)/.exec(value);
        if (match2!=null)
            return match2[1];

        throw new InvalidFieldValueError(null,'invalid X identifier');
	}
}

export class InstagramField implements INjkInputType
{
    input(value:string) 
    { 
        const match1 = /^\s*https?:\/\/(?:www\.)?instagram\.com\/([^\/\s\?#&]+)\s*/.exec(value);
        if (match1!=null)
            return match1[1];

        const match2 = /^\s*@?([^\/\s]+)\s*/.exec(value);
        if (match2!=null)
            return match2[1];

        throw new InvalidFieldValueError(null,'invalid Instagram identifier');
	}
}

export class YoutubeField implements INjkInputType
{
    input(value:string) 
    { 
		const match1 = /^https?:\/\/(?:www\.)?(?:youtu\.be\/|youtube\.com\/embed\/|youtube\.com\/watch\?v=)([^\/"'\s\#]+)$/.exec(value);

        // NOTE the time parameter will be included, as will any other parameters, if they exist.
        //      This may not be desirable, although including the time probably is.
        if (match1!=null)
            return match1[1];

        throw new InvalidFieldValueError(null,'invalid Youtube URL');
	}
}

export class VimeoField implements INjkInputType
{
	/* Matches:
		1.  https://vimeo.com/17972817
		2.  <iframe src="https://player.vimeo.com/video/17972817" width="640" height="360" ...
	 */
    input(value:string) 
    { 
		const match1 = /^https?:\/\/(?:[^.\/]+\.)?vimeo\.com\/([0-9]+)$/.exec(value);
        if (match1!=null)
            return match1[1];

        throw new InvalidFieldValueError(null,'invalid Vimeo URL');
	}
}

export class FacebookVideoUrlField extends UrlField
{
	/* Valid formats:
		https://www.facebook.com/watch/?v=10153949778421332
		https://www.facebook.com/abbemay/videos/2305474926170084/ 
		https://www.facebook.com/winterfox/videos/10221558253999139
		https://www.facebook.com/reel/2797924943847507
		https://fb.watch/s-bSj-lBea/
		https://www.facebook.com/share/v/E4CQjWGDixQocr1d/

		Shares on Youtube? And maybe elsewhere?
		https://www.facebook.com/share/p/8yXFHJu17PfnXVnX/
	 */
    input(value:string)
	{
		let bits = value.match(/^\s*(https?:\/\/www\.facebook\.com\/watch\/\?v=[0-9]+)\s*/)
		if (bits!=null) return bits[1];

		bits = value.match(/^\s*(https?:\/\/www\.facebook\.com\/[^\/]+\/videos\/[^\\]+)\s*/)
		if (bits!=null) return bits[1];

		bits = value.match(/^\s*(https?:\/\/fb\.watch\/[^\/\s]+\/?)\s*/)
		if (bits!=null) return bits[1];

		bits = value.match(/^\s*(https?:\/\/www\.facebook\.com\/reel\/\S+)\s*/)
		if (bits!=null) return bits[1];

		bits = value.match(/^\s*(https?:\/\/www\.facebook\.com\/share\/v\/\S+)\s*/)
		if (bits!=null) return bits[1];

		bits = value.match(/^\s*(https?:\/\/www\.facebook\.com\/share\/p\/\S+)\s*/)
		if (bits!=null) return bits[1];

        throw new InvalidFieldValueError(null,'invalid Facebook video URL');
	}
}

