import { z } from 'zod';

/*
	WARNING: these are shared by the artist profile GUI and the venue events.
*/

export const performanceType = {
	originalBand: 'Original Band',
	originalSolo: 'Original Solo',
	originalSoloWithBand: 'Original Solo Artist with Band',
	originalDuo: 'Original Duo',
	originalGroup: 'Original Group',
	originalEnsemble: 'Original Ensemble',
	originalCollective: 'Original Collective',
	dj: 'DJ',
	variety: 'Variety',
	burlesque: 'Burlesque',
	comedy: 'Comedy',
	coversSolo: 'Covers Solo',
	coversDuo: 'Covers Duo',
	coversBand: 'Covers Band',
	coversGroup: 'Covers Group',
	other: 'Other (not listed)'
};

export const genres = {
	alternative:'Alternative',
	blues: 'Blues',
	country: 'Country',
	chill: 'Chill',
	dance: 'Dance',
	dub: 'Dub',
	dubstep: 'Dubstep',
	electronica: 'Electronica',
	folk: 'Folk',
	funk: 'Funk',
	hiphop: 'Hip Hop',
	indie: 'Indie',
	jazz: 'Jazz',
	metal: 'Metal',
	pop: 'Pop',
	psychedelic: 'Psychedelic',
	punk: 'Punk',
	reggae: 'Reggae',
	rnb: 'RnB',
	roots: 'Roots',
	rock: 'Rock',
	soul: 'Soul',
	world: 'World'
};
			

//TODO move to a utility class
function getZodEnumFromObjectKeys<TI extends Record<string, any>, R extends string = TI extends Record<infer R, any> ? R : never>(input: TI): z.ZodEnum<[R, ...R[]]> 
{
	const [firstKey, ...otherKeys] = Object.keys(input) as [R, ...R[]];
	return z.enum([firstKey, ...otherKeys]);
}

export const genreKeys = getZodEnumFromObjectKeys(genres);
//export type genre = keyof typeof genres;

export const performanceTypeKeys = getZodEnumFromObjectKeys(performanceType);


