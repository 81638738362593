import {IPageData} from 'Common/PageConfig';
import {Page} from 'Common/pages/Page';
import {createContext,useContext} from 'solid-js';

const PageContext = createContext();

export function PageProvider(props:Page<IPageData> & {page:Page<IPageData>} & {children:any}) 
{
	return (
		<PageContext.Provider value={props.page}>
			{props.children}
		</PageContext.Provider>
	);
}

export function usePage():Page<IPageData>
{
	return useContext(PageContext) as Page<IPageData>;
}

