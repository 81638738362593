import {HttpErrorInterface,HttpErrorMessage} from 'Browser/HttpErrorInterface';

export default abstract class BuildError extends Error implements HttpErrorInterface
{
//	public static abstract type():string;

	public abstract toString():string;

	public abstract toJson():HttpErrorMessage;

//	public static abstract fromJson(data):any;
}

