import {HttpErrorInterface,HttpErrorMessage} from 'Browser/HttpErrorInterface';

/* 
	An error to be shown to the user at the end of an operation that failed as the result of bad user input.
	If a config-related error occurs then currently a ConfigError should be thrown instead.
	No stack is required.
*/
export default class OperationError implements HttpErrorInterface
{
	private message:string;

	constructor(message:string)
	{
		this.message = message;
	}

	static type():string { return 'operationError'; }

	toString():string
	{
		return this.message;
	}

	public toJson():HttpErrorMessage
	{
		return {httpCode:200,errorType:OperationError.type(),message:this.message};
	}

	public static fromJson(data:any):HttpErrorInterface
	{
		return new OperationError(data.message);
	}
}

