export default class FileTypes
{
	static mimeTypeSuffix(mime:string)
	{
        switch(mime) {
            case 'image/jpeg': return 'jpg';
            case 'image/png': return 'png';
        }
	}

	static suffix(type:string)
	{
        switch(type) {
            case 'jpeg': return 'jpg';
            case 'png': return 'png';
        }
        throw new Error('Unknown type: "'+type+'"');
	}

	static mimeType(suffix:string) 
	{
        switch(suffix) {
			case 'js': return 'text/javascript';
			case 'jpg': return 'image/jpeg';
            case 'jpeg': return 'image/jpeg';
            case 'png': return 'image/png';
            case 'gif': return 'image/gif';
            case 'ico': return 'image/x-icon';
            case 'css': return 'text/css';
            case 'eot': return 'application/vnd.ms-fontobject';
            case 'svg': return 'image/svg+xml';
            case 'ttf': return 'font/ttf';
            case 'woff': return 'font/woff';
            case 'woff2': return 'font/woff2';
			case 'map': return 'application/json'; 
			case 'txt': return 'text/plain'; 
			case 'html': return 'text/html'; 
        }
        throw new Error('Unknown suffix: "'+suffix+'"');
	}
}

