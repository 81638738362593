
export function Snapchat()
{
	return (
		<svg id="snapchat" class="icon" version="1.0" xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 510.000000 507.000000"  preserveAspectRatio="xMidYMid meet">
		<g transform="translate(0.000000,507.000000) scale(0.100000,-0.100000)">
		<path d="M685 4866 c-11 -8 -27 -16 -36 -16 -9 0 -22 -7 -29 -15 -7 -8 -20
		-15 -30 -15 -10 0 -23 -7 -30 -15 -7 -8 -18 -15 -25 -15 -21 0 -116 -68 -173
		-123 -50 -48 -132 -158 -132 -176 0 -5 -7 -14 -15 -21 -8 -7 -15 -20 -15 -30
		0 -10 -7 -23 -15 -30 -8 -7 -15 -22 -15 -34 0 -12 -7 -31 -15 -43 -23 -32 -33
		-1168 -23 -2493 8 -919 11 -1101 23 -1133 8 -20 15 -47 15 -58 0 -30 55 -124
		117 -202 61 -76 175 -175 240 -207 23 -12 45 -25 48 -31 4 -5 19 -9 34 -9 16
		0 48 -7 71 -15 37 -13 265 -15 1815 -15 1550 0 1778 2 1815 15 23 8 55 15 69
		15 15 0 32 5 37 10 6 6 29 21 52 33 70 38 172 129 233 209 33 42 59 81 59 86
		0 5 7 15 15 22 8 7 15 20 15 30 0 10 7 23 15 30 8 7 15 26 15 42 0 16 6 39 14
		51 29 46 40 552 39 1697 -2 1188 -16 1860 -39 1897 -8 12 -14 35 -14 51 0 16
		-7 35 -15 42 -8 7 -15 20 -15 30 0 10 -7 23 -15 30 -8 7 -15 19 -15 27 0 19
		-108 159 -156 202 -41 38 -131 101 -144 101 -4 0 -13 7 -20 15 -7 8 -21 15
		-32 15 -11 0 -29 6 -41 12 -55 32 -382 39 -2032 44 -1452 5 -1632 4 -1650 -10z
		m2030 -759 c76 -17 125 -32 168 -53 16 -8 32 -14 37 -14 25 0 146 -85 218
		-154 106 -102 174 -205 222 -336 22 -59 31 -225 25 -465 -5 -206 -3 -235 13
		-280 l18 -50 92 -3 c66 -2 98 1 114 12 34 20 111 29 157 16 42 -11 57 -35 49
		-76 -4 -21 -64 -63 -128 -88 -25 -10 -58 -24 -73 -32 -16 -8 -34 -14 -42 -14
		-7 0 -18 -7 -25 -15 -7 -8 -20 -15 -30 -15 -9 0 -40 -23 -69 -51 -51 -51 -51
		-52 -51 -115 0 -34 6 -75 14 -91 8 -15 24 -48 36 -73 11 -25 30 -58 40 -75 11
		-16 24 -40 29 -51 5 -12 13 -23 18 -25 4 -2 34 -36 65 -76 32 -39 81 -91 109
		-115 28 -24 68 -58 88 -75 20 -18 40 -33 45 -33 4 0 25 -13 46 -30 21 -16 45
		-30 53 -30 8 0 20 -7 27 -15 7 -8 22 -15 34 -15 11 0 30 -7 40 -15 11 -8 27
		-15 35 -15 22 0 101 -33 101 -42 0 -11 -38 -48 -49 -48 -5 0 -14 -7 -21 -15
		-7 -8 -24 -15 -39 -15 -14 0 -35 -7 -45 -15 -11 -8 -36 -15 -55 -15 -20 -1
		-47 -7 -61 -15 -14 -8 -41 -14 -60 -15 -76 0 -150 -62 -150 -124 0 -16 -9 -49
		-21 -73 l-21 -43 -251 0 c-206 -1 -256 -3 -271 -15 -11 -8 -25 -15 -32 -15 -7
		0 -46 -24 -88 -52 -42 -29 -84 -58 -94 -63 -9 -6 -42 -27 -72 -46 -52 -34
		-102 -58 -180 -89 -22 -9 -86 -16 -167 -18 -112 -3 -142 0 -199 18 -88 27
		-164 66 -292 151 -58 38 -111 69 -119 69 -7 0 -16 7 -19 15 -4 8 -14 15 -24
		15 -10 0 -23 7 -30 15 -7 8 -24 15 -37 15 -14 0 -49 7 -79 16 -49 14 -66 14
		-190 0 -222 -26 -244 -21 -244 58 -1 82 -83 166 -162 166 -16 0 -45 7 -65 15
		-19 8 -48 15 -62 15 -15 0 -36 7 -47 15 -10 8 -28 15 -38 15 -22 0 -116 58
		-116 71 0 11 78 49 100 49 9 0 25 7 36 15 10 8 29 15 40 15 12 0 27 7 34 15 7
		8 20 15 30 15 10 0 23 7 30 15 7 8 17 15 22 15 18 0 133 95 212 175 93 95 171
		194 195 252 6 11 15 24 20 28 6 3 11 14 11 24 0 10 7 24 15 31 8 7 15 23 15
		36 0 13 8 39 17 59 16 33 16 37 0 70 -9 20 -17 41 -17 48 0 17 -74 87 -92 87
		-8 0 -19 5 -26 12 -12 12 -71 36 -112 45 -14 3 -34 12 -45 19 -11 8 -25 14
		-33 14 -21 0 -73 43 -89 74 -14 27 -14 30 11 54 23 23 33 26 87 25 36 -1 71
		-9 85 -18 17 -11 49 -15 114 -15 80 0 92 2 113 23 22 22 22 24 19 286 -2 202
		1 286 13 353 8 48 15 97 15 109 0 35 88 200 149 279 60 78 157 164 226 200 22
		11 47 24 55 29 57 31 80 41 97 41 11 0 28 7 39 15 10 8 35 15 54 15 19 1 51 8
		70 15 46 19 316 13 415 -8z"/>
		<path d="M1943 2360 c-12 -5 -29 -25 -37 -45 -13 -31 -14 -41 -2 -69 13 -32
		34 -42 156 -75 26 -7 37 -36 21 -60 -5 -7 -29 -15 -54 -18 -40 -5 -48 -2 -77
		26 -18 17 -37 31 -41 31 -35 0 -2 -76 39 -87 15 -4 54 -7 87 -6 53 1 63 5 87
		32 56 62 30 101 -91 141 -68 22 -76 28 -76 50 0 46 85 53 123 10 32 -38 58
		-21 41 28 -7 20 -21 34 -41 41 -37 13 -104 13 -135 1z"/>
		<path d="M2283 2356 c-19 -9 -35 -11 -38 -5 -4 5 -13 9 -21 9 -11 0 -14 -26
		-14 -151 0 -138 1 -150 18 -147 14 3 17 17 20 97 3 90 15 131 47 153 18 13 73
		8 89 -8 21 -21 26 -43 29 -149 2 -76 5 -90 19 -93 26 -5 31 34 23 153 -9 128
		-19 146 -91 151 -29 3 -62 -1 -81 -10z"/>
		<path d="M2593 2363 c-28 -5 -53 -39 -53 -70 0 -30 15 -29 53 3 26 21 38 25
		70 21 43 -6 59 -21 55 -52 -2 -16 -19 -24 -82 -40 -92 -24 -132 -59 -121 -104
		15 -59 55 -75 148 -59 40 7 66 7 81 0 27 -12 62 3 48 21 -6 7 -12 60 -15 119
		-6 117 -15 144 -53 158 -25 10 -89 11 -131 3z m125 -204 c6 -50 -95 -90 -132
		-53 -19 19 -21 48 -3 62 14 12 59 20 102 18 25 -1 31 -6 33 -27z"/>
		<path d="M2940 2361 c-14 -4 -40 -6 -57 -3 -27 4 -33 1 -34 -14 -6 -101 -5
		-384 1 -394 4 -6 16 -10 26 -8 15 2 20 15 24 63 4 53 7 60 25 58 62 -8 133 -4
		154 8 32 20 61 81 61 130 0 47 -29 116 -60 144 -22 20 -100 29 -140 16z m109
		-67 c29 -24 31 -31 31 -87 0 -68 -3 -75 -40 -100 -35 -24 -74 -21 -109 9 -28
		23 -31 31 -31 86 0 53 3 63 29 89 37 37 79 38 120 3z"/>
		<path d="M2287 1894 c-10 -10 -8 -396 2 -412 5 -8 17 -12 27 -10 17 3 20 16
		24 110 6 128 18 148 86 148 57 0 64 -15 64 -134 0 -108 5 -126 31 -126 18 0
		19 9 19 126 0 125 0 126 -29 155 -22 22 -39 29 -68 29 -21 0 -45 -4 -52 -9
		-30 -19 -50 6 -53 67 -3 49 -6 57 -23 60 -12 2 -24 0 -28 -4z"/>
		<path d="M2974 1872 c-6 -4 -14 -24 -18 -44 -4 -21 -15 -43 -26 -51 -28 -19
		-28 -36 0 -50 24 -12 25 -17 30 -124 3 -62 10 -117 15 -122 16 -16 68 -23 85
		-11 13 9 12 13 -7 29 -32 27 -43 63 -43 139 0 74 6 88 34 93 27 5 31 26 7 34
		-13 4 -25 21 -32 48 -6 23 -13 48 -15 55 -4 14 -14 15 -30 4z"/>
		<path d="M2055 1773 c-48 -17 -74 -39 -91 -80 -32 -74 -3 -183 56 -214 54 -29
		123 -25 159 9 38 34 51 79 25 89 -11 4 -19 2 -22 -6 -11 -32 -55 -71 -81 -71
		-44 0 -93 58 -98 117 -5 42 -1 53 22 81 23 28 33 32 74 32 38 0 52 -5 71 -25
		27 -29 36 -31 44 -9 21 53 -82 104 -159 77z"/>
		<path d="M2697 1776 c-43 -16 -77 -43 -77 -62 0 -24 22 -26 51 -5 54 40 153
		23 136 -23 -5 -13 -58 -33 -137 -50 -77 -17 -97 -120 -30 -159 37 -22 113 -22
		135 -1 15 15 19 15 50 -2 44 -23 61 -11 44 32 -6 16 -11 56 -10 89 1 33 -4 83
		-9 112 -10 47 -14 53 -51 67 -47 19 -53 19 -102 2z m107 -162 c11 -30 6 -53
		-20 -83 -33 -39 -84 -43 -108 -9 -32 45 -9 88 47 88 18 0 39 5 47 10 21 13 27
		12 34 -6z"/>
		</g>
		</svg>
	);
}


