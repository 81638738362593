import {Wrap,Text, SingleSelect} from 'Shared/forms/Inputs';
import { IItemProps } from 'Shared/backend/menu/MenuItemPage';

export function BeerDescription(props:{itemProps:IItemProps})
{
	const states = {
		act: 'ACT',
		nsw: 'NSW',
		nt: 'NT',
		qld: 'QLD',
		sa: 'SA',
		tas: 'TAS',
		vic: 'VIC',
		wa: 'WA'
	};

	return (<>
		<flex-wrap>
			<x-short>
				<Wrap label='Region' required={false}>
					<Text {...props.itemProps} field='region' />
				</Wrap>
			</x-short>

			<x-short>
				<Wrap label='State/Territory' required={false}>
					<SingleSelect {...props.itemProps} field='state' options={states} />
				</Wrap>
			</x-short>

			<x-short>
				<Wrap label='Country' required={false}>
					<Text {...props.itemProps} field='country' />
				</Wrap>
			</x-short>
		</flex-wrap>

		<Wrap label='Short description' required={false}>
			<Text {...props.itemProps} field='tagline' />
		</Wrap>
	</>);
}

