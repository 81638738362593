import { Match, ParentProps, Show, Switch } from 'solid-js';
import { DateTitle } from 'Shared/frontend/PosterTitle';
import { PageFunctions } from 'Browser/PageFunctions';
import { VenueUrls } from 'Common/VenueUrls';
import { usePage } from 'Shared/artists/PageProvider';
import { Special } from 'Shared/model/Specials';


export function PosterOuter(props: ParentProps & {special:Special})
{
	const characterCount = props.special.tagline?.length ?? 0;
	const charactersLeft = 57 - characterCount;
	const overLimit = charactersLeft < 0;

	return (
		<x-poster class={`specialItem ${props.special.imageType} portraitDimension`}>
			<Show when={props.special.imageType !='suppliedPoster'} >
				<div class='dateBar'>
					<DateTitle times={props.special.times} />
				</div>
			</Show>

			{props.children}

			<Show when={overLimit || props.special.conditions || props.special.moreInfo}>
				<div class='detailsBackground'>
					<div class='detailsButton'>Details</div>
				</div>
			</Show>
		</x-poster>
	);
}


export function SpecialsPoster(props:{special:Special})
{
	const imageUrl = () => {
		if (!props.special.image?.hash)
			return '';

		const url = (new PageFunctions()).createImageUrl2(
			new VenueUrls(usePage().build,usePage().site.key),
			props.special.image.hash,
			props.special.image,
			'Special-image',
			'medium'
		);
		return url;
	};

	return (
		<Switch>
			<Match when={props.special.imageType == 'suppliedPoster' }>
				<PosterOuter special={props.special}>
					<Switch>
						<Match when={props.special.name != null && props.special.name != ''}>
							<div class='specialName'>{props.special.name}</div>
						</Match>
						<Match when={true}>
							<div class='topSpacer'></div>
						</Match>
					</Switch>

					<div class='portraitDimension'>
						<img src={imageUrl()} />
					</div>
				</PosterOuter>
			</Match>
			<Match when={props.special.imageType == 'photoPoster'}>
				<PosterOuter special={props.special}>
					<Switch>
						<Match when={props.special.name != null && props.special.name != ''}>
							<div class='specialName'>{props.special.name}</div>
						</Match>
						<Match when={true}>
							<div class='topSpacer'></div>
						</Match>
					</Switch>
					
					<div class='portraitDimension'>
						<img src={imageUrl()} />

						<Show when={props.special.tagline}>
							<div class='info' >
								{props.special.tagline}
							</div>
						</Show>
					</div>
				</PosterOuter>
			</Match>
			<Match when={props.special.imageType == 'autoNonMusicPoster'}>
				<PosterOuter special={props.special}>
					<div class='specialName'>{props.special.name}</div>

					<div class='autoDimension'>
						<Show when={props.special.tagline}>
							<div class='info'>
								{props.special.tagline}
							</div>
						</Show>
					</div>
				</PosterOuter>
			</Match>
		</Switch>
	);
}

