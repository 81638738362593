import Format from 'Common/logs/Format';

export default class BrowserFormat extends Format
{
	public message(level:string,...args:any[]): string[]
	{
		return [...this.levelPrefix(level),...args];
	}

	/*
		For colours see:
			https://stackoverflow.com/questions/9781218/how-to-change-node-jss-console-font-color
	 */
	private levelPrefix(level:string):string[]
	{
		switch(level) {
			case 'debug': return ['%cDEBUG%c:','color:cyan','color:none'  ];
			case 'debugBold': return ['%cDEBUG%c:','color:orange','color:none'  ];
			case 'info': return ['%cINFO%c:','color:green','color:none'  ];
			case 'warn': return ['%cWARNING%c:','color:blue','color:none'  ];
			case 'error': return ['%cERROR%c:','color:red','color:none'  ];
			case 'all': return ['ALL:' ];
			default:
				throw new Error(`Invalid logging level "${level}"`);
		}
	}
}

