/* 
	Fades in a page once it is loaded.
 */

import {JsPageWidget} from 'Browser/widgets/JsPageWidget';
import {afterDomLoaded} from 'Browser/ready';

export class FadePageInWidget extends JsPageWidget
{
	static key() { return 'fadePageIn'; }

	isAnchorNode(node:HTMLElement):boolean { return false; }

	findAnchorNodes(branch:HTMLElement):Iterable<HTMLElement> { return []; }

	callAfterDisplay(node:HTMLElement):void
	{
		afterDomLoaded(() => {
			document.body.classList.remove('preload');
			document.body.classList.add('loaded');
		});
	}
}

