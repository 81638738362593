import {createSignal} from 'solid-js';
import {Match, Show, Switch} from 'solid-js';
import {MenuItem,SectionWraps} from 'Shared/frontend/menuPage/MenuPage';
import { StatusKey } from 'Shared/backend/menu/StatusIcons';
import { usePage } from 'Shared/artists/PageProvider';
import { IItemProps } from 'Shared/backend/menu/MenuItemPage';


interface IPreviewTab {
	itemProps: IItemProps,
	status: StatusKey,
	link: string
}

export function PreviewTab(props:IPreviewTab)
{
	const [selected,setSelected] = createSignal('summary');
	const settings = usePage().data.venue.settings;

	return (
		<tab-pane>
			<tab-group id='contentTabs'>
				<x-tab class={selected()=='summary' ? 'active' : ''} onClick={() => setSelected('summary')}>Summary</x-tab>
				<x-tab class={selected()=='full'    ? 'active' : ''} onClick={() => setSelected('full')}   >Full</x-tab>
			</tab-group>

			<tab-pane-group>
				<Switch>
					<Match when={selected() == 'summary'}>
						<tab-pane>
							<Show when={props.itemProps.item.status=='published'}>
								<a target='_blank' href={props.link}>
									<button class='small'>View on website menu</button>
								</a>
								<br>
								</br>
							</Show>
						
							<div id='menuItemSummaryPreview' class={settings.frontend.venueClass}>
								<div class='frontend'>
									<PreviewItem {...props.itemProps} />
								</div>
							</div>
						</tab-pane>
					</Match>
					<Match when={selected() == 'full'}>
						<tab-pane>
							<div id='menuItemFullPreview' class={settings.frontend.venueClass}>
								<div class='frontend'>
									<div class='menuItemOverlay'>
										<div class='overlayBody'>
											<PreviewItem {...props.itemProps} />
										</div>
									</div>
								</div>
							</div>
						</tab-pane>
					</Match>
				</Switch>
			</tab-pane-group>
		</tab-pane>
	);
}

function PreviewItem(props:IItemProps)
{
	const settings = usePage().data.venue.settings;

	return (
		<SectionWraps menu={props.store} location={props.location}>
			<MenuItem item={props.item} settings={settings} menu={props.store} location={props.location} />
		</SectionWraps>
	);
}

