import {IconColour} from 'Shared/backend/fonts/icons/IconColour'

export function Hire(props)
{
	return (
		<svg id='hire' class='icon' style={`fill:${props.fill ?? IconColour.textColour}; stroke:${props.stroke ?? 'inherit'};`} viewBox='0 0 64 64' enable-background='new 0 0 64 64' xmlns='http://www.w3.org/2000/svg'>
		  <defs></defs>
		  <path id="path0" d="M 11.26 3.19 C 0.147 5.419 -2.769 20.063 6.651 26.33 C 9.314 28.101 10.541 28.395 15.537 28.459 L 19.389 28.509 L 19.391 23.069 C 19.393 17.026 19.434 16.6 20.159 15.085 C 23.12 8.895 31.838 8.821 34.799 14.96 C 35.598 16.615 35.647 17.096 35.647 23.15 L 35.647 28.499 L 43.989 28.459 C 53.969 28.412 54.247 28.368 57.288 26.386 C 66.791 20.192 63.865 5.306 52.73 3.193 C 51.384 2.938 12.532 2.935 11.26 3.19 M 53.478 11.075 C 54.199 11.447 54.568 12.264 54.393 13.1 C 54.302 13.532 46.863 21.159 46.179 21.52 C 45.133 22.074 44.652 21.805 42.122 19.246 C 39.693 16.791 39.449 16.258 40.272 15.212 C 41.218 14.01 42.165 14.231 44.05 16.094 L 45.355 17.384 L 48.479 14.275 C 52.171 10.601 52.345 10.489 53.478 11.075 M 26.072 14.242 C 24.887 14.672 23.96 15.53 23.409 16.705 L 23.09 17.387 L 23.057 29.55 C 23.039 36.24 22.998 41.714 22.966 41.714 C 22.934 41.714 21.856 40.832 20.572 39.755 C 17.721 37.364 17.348 37.164 15.749 37.164 C 12.492 37.164 10.5 40.443 11.988 43.357 C 12.403 44.171 12.52 44.279 17.242 48.229 L 22.072 52.269 L 35.29 52.269 L 48.509 52.269 L 48.509 45.42 C 48.509 37.17 48.471 36.916 47.035 35.351 C 45.673 33.866 45.225 33.749 38.195 33.038 C 35.226 32.738 32.621 32.463 32.406 32.427 L 32.016 32.362 L 31.981 24.874 L 31.947 17.387 L 31.622 16.693 C 30.648 14.616 28.119 13.499 26.072 14.242 M 23.051 58.024 C 23.117 61.658 21.763 61.308 35.769 61.308 C 49.774 61.308 48.42 61.658 48.486 58.024 L 48.524 55.909 L 35.769 55.909 L 23.013 55.909 L 23.051 58.024" style=""></path>
		</svg>
	);
}
