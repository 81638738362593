import {ProfileData} from 'Common/pages/artist/Profile';
import {Style} from 'Shared/artists/Style';
import {Location} from 'Shared/artists/Location';
import {PressShot} from 'Shared/artists/PressShot';
import {SocialSites} from 'Shared/artists/SocialSites';
import {Videos} from 'Shared/artists/Videos';
import {Biography} from 'Shared/artists/Biography';
import {MusicLinks} from 'Shared/artists/MusicLinks';
import {Text, Wrap} from 'Shared/forms/Inputs';
import {ArtistDoc} from 'Shared/model/Artist';
import {createStore} from 'solid-js/store';
import {mergeProps} from 'solid-js';
import {PageProvider} from 'Shared/artists/PageProvider';
import {outerPage} from 'Shared/artists/OuterPage';
import {steps} from 'Shared/artists/Steps';
import {theme} from 'Shared/artists/Theme';
import {forms} from 'Shared/artists/Forms';
import {injectGlobal} from '@emotion/css';
import {EditComponent} from 'Common/components/EditComponent';
import {Page} from 'Common/pages/Page';


export type ArtistProps = ArtistDoc & { 
	setStore: (...args: any[]) => void,
	editArtist: EditComponent<ProfileData,ArtistDoc>,
	inBrowser: boolean
};

export function ProfilePage(profileData:ProfileData & {page:Page<ProfileData>})
{
	const [artistStore,setArtistStore] = createStore(profileData.artist);
	const editArtist = profileData.page.component('editArtist') as EditComponent<ProfileData,ArtistDoc>;

//XXX or am I better passing through all props?
	const artist = mergeProps(artistStore,{
		setStore: setArtistStore,
		editArtist: editArtist,
		inBrowser: profileData.page.config.inBrowser});

	injectGlobal([theme,forms]);

//<ErrorBoundary fallback={err => <div>GOT ERROR: {err}</div>}>

	return (
		<PageProvider page={profileData.page}>
			<div id='content' class={outerPage()}>
				<nav>
					<x-breadcrumbs>
						<a href='/manager'>Top</a>
					</x-breadcrumbs>

					<div class='userDetails'>
						<a href='/user'>User details</a>  
					</div>
				</nav>		

				<main class={steps()}>
					<ArtistName 	{...artist}  />

					<Style			{...artist} />
					<Location		{...artist} />
					<SocialSites	{...artist} />  
					<PressShot		{...artist} />
					<Videos			{...artist} />
					<MusicLinks		{...artist} />
					<Biography		{...artist} />

					<br/>
					<br/>
					<br/>
					<a class='delete' href="" onclick={e => window.run(e,'confirmAndDelete','deleteProfile')}>Delete artist profile</a>
				</main>
			</div>
		</PageProvider>		
	);
}

function ArtistName(artist:ArtistProps)
{
//XXX BUG when the artist name is deleted the various search options should change

	return (
		<Wrap label='Artist name' required={true}>
			<Text component={artist.editArtist} store={artist} field='name' />
		</Wrap>
	);
}


