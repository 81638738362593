import {Link as ConfigLink} from 'Common/config/ILinks';

export default class Links 
{
	constructor()
	{
//XXX when the time comes DONT add the links to a map at startup. Generate as required to keep things lightweight	
	}

	link(name:string):ConfigLink
	{
		throw new Error('Not implemented yet');
	}
}

