import {AdminRole} from 'Common/permissions/AdminRole';
import {ArtistRole} from 'Common/permissions/ArtistRole';
import {PublicRole} from 'Common/permissions/PublicRole';
import {Roles} from 'Common/permissions/Roles';
import {VenueRole} from 'Common/permissions/VenueRole';
import { Role as ConfigRole } from 'Common/config/Role';
import {Role} from 'Common/permissions/Role';

export class RolesLoader
{
	createRoles(config:ConfigRole[]): Roles
	{
		const roles:Role[] = [new PublicRole()];

		for (const role of config) 
			switch(role.type) {
				case 'admin': 
					roles.push(new AdminRole());
					break;

				case 'public': 
					break;

				case 'artistAdmin': 
					roles.push(new ArtistRole(role));
					break;

				case 'venueAdmin': 
				case 'venueBareAdmin': 
				case 'venueEventAdmin': 
				case 'venueMenuAdmin': 
				case 'venueDeveloper': 
					roles.push(new VenueRole(role));
					break;
			}

		return new Roles(roles);
	}
}

