import {AccessArea} from 'Common/permissions/AccessArea';
import {Role} from 'Common/permissions/Role';
import { ArtistRole as ArtistRoleConfig } from 'Common/config/Role'; 
import {Id} from 'Common/Id';

export class ArtistRole extends Role
{
	constructor(
		private config: ArtistRoleConfig,
	) { 
		super('artistAdmin');
	}

	public id()
	{
		return this.config.artistId;
	}

	public toJson()
	{
		return {
			type: this.name,
			artistId: this.config.artistId
		};
	}

	public topAccess(): AccessArea
	{
		return new AccessArea('artistEditor');
	}

	public canAccess(area:AccessArea,pageArtistId:Id)
	{
		return area.name == 'artistEditor' && this.config.artistId == pageArtistId;
	}
}

