import {ITimesFunctions} from 'Common/TimesFunctions';
import {DocumentQuery} from 'Common/config/PageConfigTypes';
import {IServerFunctions} from 'Server/ServerFunctions';
import { FutureEventsQueryClause } from 'Shared/view/FutureEventsQueryClause';


export function specialsQueries(time:ITimesFunctions,field:string):DocumentQuery
{
	return <DocumentQuery>({
		type: 'array',
		collection:'special',
		aggregate: () => [
			{'$match': {
				'$and': [
					(new FutureEventsQueryClause(time,field,{months:6})).create()
				],
			}},
			{'$limit': 100},
			{'$sort': {'times.startSingle':1,'times.startDate':1}}
		],
		
		transform: (funcs:IServerFunctions,doc:AsyncIterable<any>) => {		//XXX any 
			const eventsPlus = funcs.generateEvents(doc,field,
				/*start:*/time.now(),
				/*end:*/  time.add(time.today(),{days:7} )
			);
			const filtered = funcs.filterTimes(eventsPlus,field,{/*onceLimit:0,*/dailyLimit:1,weeklyLimit:1,monthlyLimit:1});
			return funcs.limit(filtered,30)
		}
	});
}			
