
export function Overlay(props)
{
	return (
		<div class={`overlay ${props.classes}`}>
			<div class='overlayBackground' />
			<div class='overlayForeground'>
				<div class='overlayBody'>			
					<div class='closeButtonBar'>
						
						<button class='closeIcon' onclick={props.close}>X</button>
						<div style='clear:both' />
					</div>
					<div class='overlayContent'> 
						{props.children}
					</div>
				</div>
			</div>
		</div>
	);
}
