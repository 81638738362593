
import {ChangePasswordMessage} from 'Common/Messages';
import {Wrap} from 'Shared/forms/Inputs';
import {Show, createSignal} from 'solid-js';

//FIXME dynamically load
import {Banner, utilityPageStyle} from 'Shared/users/UtilityPageTheme';
import {css} from '@emotion/css';
import AjaxConnectionToServer from 'Browser/AjaxConnectionToServer';

const formStyle = () => css({
	maxWidth: 320
});


//	{% include "App/backend/banner.njk" %}
// TODO share banner with rego set page and MAYBE  login?

export function SetPassword(props)
{
//TODO load the password strength library dynamically

//	<form id='setPasswordForm' class='setPasswordForm' {{action|safe}} method='post'>

//TODO move these down
	const title = props.isRegistration ? 'Registration' : 'Account recovery';

	const action = props.isRegistration ? '/completeRegistration' : '/changePassword';


	//		<div id='passwordFormContainer'>

	return (
		<div class={utilityPageStyle()}>
			<Banner />

			<p>
				{ props.isRegistration ?
					`Please complete your registration account for ${props.email} by providing a password below.` :
					`Recover account for ${props.email}`
				}
			</p>

			<PasswordFormContent {...props} done={passwordChanged} />
		</div>
	);
}

function passwordChanged(props,ret)
{
	renderPage('Sent email',RegoEmailSent,{email:userName});
}


export function PasswordFormContent(props:UserData & {setShowPasswordForm:Setter<boolean>})
{
	let currentPassword!: HTMLInputElement;
	let newPassword!: HTMLInputElement;
	let confirmPassword!: HTMLInputElement;
	let passwordStrength!: HTMLMeterElement;

	const [enabled,setEnabled] = createSignal(false); 
	const [showStrength,setShowStrength] = createSignal(false); 

	const checkPassword = () => setEnabled(
		newPassword.value.length >=6 && 
		newPassword.value == confirmPassword.value &&
		(!currentPassword || currentPassword.value.length > 0)
	);

//TODO given better error feedback eg passwords dont match, password too short (or weak?), current password missing

//FIXME provide requireCurrentPassword

//XXX wrap IDs and 'fors' arent working

//XXX In login page consider using wraps.

//XXX "VOS" not centered
//XXX meter needs colouring etc

	return <div class={formStyle()}>
		<Show when={props.requireCurrentPassword}>
			<Show when={props.person.hash} fallback={<p>No password is currently set.</p>}>
				<Wrap label='Current password' required={true}>
					<input id='currentPassword' type='password' autocomplete='off' placeholder='********' ref={currentPassword} onInput={checkPassword} />
				</Wrap>
			</Show>
		</Show>

		<Wrap label='New password' required={true}>
			<input id='password' type='password' autocomplete='off' placeholder='********' ref={newPassword} 
				onInput={async e => {
					setShowStrength(e.target.value.length > 0);
					await updatePasswordStrength(e.target.value,passwordStrength);
					checkPassword();
				}}
			/>
		</Wrap>

		<Show when={showStrength()}>
			<div>
				{/* TODO improve meter. Use red and orange for low value */}
				Strength <meter ref={passwordStrength} max='5' value='0'></meter> 
			</div>
		</Show>

{/* TODO mark as error until matches () */} 
		<Wrap label='Confirm password' required={true}>
			<input id='confirmPassword' type='password' autocomplete='off' placeholder='********' ref={confirmPassword} onInput={checkPassword} />
		</Wrap>

		<button disabled={!enabled()} onClick={changePassword(currentPassword?.value,newPassword.value,props)}>
			{props.buttonLabel}
		</button>
	</div>
}

async function changePassword(currentPassword:string|undefined,newPassword:string,props)
{
	const server = new AjaxConnectionToServer();
	const ret = await server.sendOperation(new ChangePasswordMessage(newPassword,currentPassword));
	props.done(props,ret);
}


//TODO maybe use 'ref's on login and rego forms as well...

async function updatePasswordStrength(password:string,passwordStrength:HTMLMeterElement)
{
	const zxcvbnCore = await import('@zxcvbn-ts/core');
	const zxcvbnCommonPackage = (await import('@zxcvbn-ts/language-common')).default;
	const zxcvbnEnPackage = (await import('@zxcvbn-ts/language-en')).default;

	const options = {
		translations: zxcvbnEnPackage.translations,
		graphs: zxcvbnCommonPackage.adjacencyGraphs,
		dictionary: {
			...zxcvbnCommonPackage.dictionary,
			...zxcvbnEnPackage.dictionary,
		}
	}
	zxcvbnCore.zxcvbnOptions.setOptions(options)

	passwordStrength.value = zxcvbnCore.zxcvbn(password).score + 1;
}


