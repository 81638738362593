import {Url} from 'Shared/model/basic';

interface IShrinkingLogo {
	shrinkDirection: 'left'|'right';
	logoUrl: Url;
}

export function ShrinkingLogo(props:IShrinkingLogo)
{
	return (
		<div class={`logoSpace ${props.shrinkDirection=='left' ? 'shrinkLogoToLeft' : 'shrinkLogoToRight' }`}>
			<div class='logoUnderlay'>
				<div>
					<img class='navLogo' src={props.logoUrl} />
				</div>
			</div>
		</div>
	);
}

