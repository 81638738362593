import {IConnectionToServer} from 'Common/IConnectionToServer';
import BrowserErrorFactory from 'Browser/BrowserErrorFactory';
import {Message} from 'Browser/Operation';
import {hostUrl} from 'Common/Urls';

export default class AjaxConnectionToServer implements IConnectionToServer
{
	constructor()
	{
		this.sendOperation = this.sendOperation.bind(this); 
	}

	/*
		Sends an operation to the server. Data is returned asynchronously, so
		you can follow it up with a
			".then(data => {...})"
	 */
	async sendOperation(op:Message):Promise<any>
	{
		const message = this.createMessage(op);

		const response = await fetch(`${hostUrl()}/_operation`,message);
		const data = await response.json();

		if (response.ok) {
        	if (data?.errorType!=null)
				throw BrowserErrorFactory.fromServer(data);

			return data;
		}
        else if (data?.errorType!=null)
			throw BrowserErrorFactory.fromServer(data);

		log.error('Error on server. Response:',response);
		throw new Error('Problem communicating with server');
	}

	/*
		Sends an operation to the server asynchronously. Any error is dealt
		with here, rather than by the caller. 
	 */
	sendOperationOptimistically(op:Message):void
	{
		const message = this.createMessage(op);

		/*no await*/<void><unknown>
		fetch(`${hostUrl()}/_operation`,message).then(async response => {
			if (response.ok) {
				const data = await response.json();
				if (data?.errorType) {
					log.error(BrowserErrorFactory.fromServer(data));
					alert('Operation failed');
				}
			} else {
				log.error('Error on server. Response:',response);
				alert('Operation failed');
			}
		});
	}

	createMessage(op:Message):RequestInit
	{
		/* JSON doesn't support 'undefined'. Replace with null. */
		const body = JSON.stringify(op,(key,value) => value === undefined ? null : value);

		const options:any = {
			method: 'POST',
			cache: 'no-cache',
			redirect:'error',
			body: body
		};

		if (window.useVite)
			return {
				...options,
				mode: 'cors',
				headers: {
					'Access-Control-Allow-Origin':'*', 
					'Content-Type': 'application/json'
				}
			};
		else
			return {
				...options,
				mode: 'same-origin', 
				credentials: 'same-origin',
				headers: {
					'Content-Type': 'application/json'
				},
				referrerPolicy: 'same-origin', 
			};
	}
}

