import {Manager} from 'Common/pages/artist/Manager';
import {Profile} from 'Common/pages/artist/Profile';
import {User} from 'Common/pages/artist/User';
import {IView} from 'Shared/view/IView';

export class View implements IView
{
	view()
	{
		return <any> ({
			[Manager.pageName]: Manager,
			[Profile.pageName]: Profile,
			[User.pageName]: User
		});
	}
}

