import {SiteType} from 'Common/SiteType';
import {Id} from 'Common/Id';

export class SiteBasics
{
	constructor(
		readonly id:Id,
		readonly type:SiteType,
		readonly key:string,
	) 
	{
	}
}

