/* 
	An error with a message to be shown to the user. 
	No stack is required.
*/
export default class UserError
{
	private message:string;

	constructor(message:string)
	{
		this.message = message;
	}

	toString():string
	{
		return this.message;
	}
}

