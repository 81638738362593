/*
	Can set a #hex color or the name of color if it's defined in iconsAsMacros.njk
	'default' colors should be set to the most logical and commonly used color for the icon 
	(eg 'red' warning icons),
	or write textColour with no brackets '' if you want to match the theme's textColour.
*/

import {ISvgColours, IconColour} from 'Shared/backend/fonts/icons/IconColour'

export function PeopleCircle(props:ISvgColours)
{
	return (
		<svg id="people_circle" class="icon" 
			style={`fill:${props.fill ?? IconColour.textColour}; stroke:${props.stroke ?? IconColour.textColour};`}
			viewBox="0 0 64 64" enable-background="new 0 0 64 64" xmlns="http://www.w3.org/2000/svg">
		  <defs></defs>
		  <g transform="matrix(0.00673, 0, 0, -0.00672, -45.004448, -8949.316406)" style="transform-origin: 45.0045px 8949.31px; opacity: 0.9;">
			<path d="M4130 8938 c-263 -21 -630 -86 -810 -144 -25 -8 -53 -14 -62 -14 -10
		0 -32 -6 -50 -14 -41 -17 -181 -64 -227 -76 -18 -5 -38 -13 -44 -19 -6 -6 -19
		-11 -28 -11 -10 0 -30 -6 -46 -14 -15 -8 -53 -25 -83 -36 -103 -41 -300 -136
		-422 -203 -31 -18 -76 -43 -100 -56 -24 -13 -62 -36 -86 -52 -24 -16 -45 -29
		-47 -29 -9 0 -233 -155 -328 -228 -262 -198 -581 -506 -758 -732 -24 -30 -46
		-57 -49 -60 -18 -16 -177 -235 -215 -298 -6 -9 -28 -44 -50 -77 -46 -70 -60
		-92 -74 -120 -6 -11 -26 -47 -45 -80 -66 -115 -190 -371 -236 -485 -11 -30
		-28 -68 -36 -83 -8 -16 -14 -36 -14 -46 0 -9 -6 -25 -14 -36 -7 -11 -16 -31
		-19 -45 -3 -14 -11 -41 -17 -60 -38 -106 -46 -132 -84 -272 -8 -29 -22 -78
		-30 -109 -9 -31 -16 -66 -16 -78 0 -12 -6 -47 -14 -79 -15 -58 -31 -154 -63
		-367 -15 -100 -18 -187 -18 -530 0 -426 1 -435 51 -745 32 -194 97 -463 149
		-610 7 -19 18 -51 24 -70 7 -19 16 -48 21 -65 4 -16 12 -41 18 -55 5 -14 18
		-45 27 -70 10 -25 24 -61 33 -80 8 -19 21 -48 28 -65 52 -125 177 -374 225
		-450 10 -16 23 -39 28 -50 5 -11 21 -37 35 -58 14 -21 26 -40 26 -42 0 -3 20
		-34 45 -71 25 -36 45 -67 45 -70 0 -6 239 -325 259 -345 9 -9 41 -45 71 -80
		178 -209 507 -514 704 -653 23 -16 73 -53 113 -82 40 -30 74 -54 77 -54 3 0
		33 -19 68 -43 35 -24 70 -46 78 -50 8 -4 29 -16 45 -26 17 -11 48 -29 70 -41
		22 -12 65 -36 95 -53 106 -59 286 -145 390 -187 30 -12 69 -27 85 -34 56 -25
		137 -54 245 -88 36 -11 80 -27 98 -34 18 -8 40 -14 48 -14 9 0 37 -7 63 -15
		177 -55 507 -118 716 -135 61 -5 142 -14 180 -19 107 -16 482 -13 620 4 66 8
		140 15 164 15 40 0 127 13 286 42 33 6 80 14 105 18 25 4 68 13 95 20 28 6 77
		19 110 27 33 8 80 20 105 27 126 37 167 49 190 58 14 6 39 14 55 18 35 9 87
		28 130 46 17 7 50 20 75 29 50 19 87 35 140 57 41 18 358 175 380 188 8 5 56
		33 105 61 131 76 242 150 400 268 77 58 142 107 145 111 3 3 28 24 55 46 74
		58 123 103 285 264 187 187 241 248 381 429 91 118 254 352 272 391 4 8 16 29
		27 45 11 17 24 39 30 50 6 11 26 47 44 80 43 76 135 261 170 340 49 111 53
		120 71 165 9 25 22 59 29 75 36 85 115 335 150 470 96 378 136 706 136 1115 0
		363 -33 674 -102 960 -6 25 -14 63 -18 85 -3 22 -11 56 -17 75 -6 19 -19 62
		-28 95 -9 33 -21 74 -27 90 -5 17 -13 44 -18 60 -5 17 -14 46 -21 65 -136 389
		-286 695 -498 1020 -56 85 -255 356 -276 375 -6 6 -27 31 -47 56 -65 82 -251
		275 -399 415 -64 60 -220 191 -304 255 -104 80 -323 228 -380 259 -11 6 -33
		19 -50 29 -16 11 -50 31 -75 44 -25 14 -61 34 -80 46 -19 11 -42 23 -50 27 -8
		3 -22 9 -30 14 -26 15 -195 95 -265 125 -16 7 -46 20 -65 28 -81 35 -309 119
		-375 137 -16 5 -61 19 -100 30 -38 12 -88 25 -110 29 -22 4 -56 12 -75 18 -36
		12 -123 30 -265 57 -114 21 -290 44 -435 56 -144 12 -565 11 -720 -2z m628
		-2063 c146 -34 216 -58 327 -113 103 -51 205 -113 238 -146 9 -9 40 -37 69
		-62 67 -57 157 -161 212 -244 46 -70 109 -185 119 -216 3 -11 15 -44 26 -74
		33 -85 59 -204 72 -327 16 -158 -4 -358 -52 -498 -6 -16 -14 -44 -18 -60 -4
		-17 -12 -39 -18 -50 -6 -11 -27 -54 -47 -95 -39 -80 -48 -95 -110 -187 -56
		-81 -237 -260 -322 -316 -120 -79 -285 -157 -374 -176 -19 -5 -53 -13 -75 -19
		-127 -34 -304 -48 -410 -32 -219 32 -275 46 -390 95 -62 26 -161 78 -203 106
		-24 16 -45 29 -48 29 -10 0 -98 73 -167 140 -38 36 -99 106 -135 155 -81 110
		-82 112 -128 205 -20 41 -42 88 -50 103 -8 16 -14 35 -14 42 0 8 -6 29 -14 47
		-48 114 -71 347 -51 533 7 72 19 148 25 170 44 150 80 246 111 295 5 8 13 24
		18 35 5 11 21 37 35 58 14 21 26 40 26 44 0 5 53 76 75 99 5 6 28 31 51 55
		190 207 409 331 709 403 87 20 424 21 513 1z m2337 -730 c211 -33 370 -127
		499 -295 23 -30 46 -62 49 -70 4 -8 11 -22 15 -30 81 -143 110 -328 77 -500
		-37 -199 -137 -360 -298 -478 -235 -174 -544 -199 -807 -66 -139 70 -258 190
		-328 329 -46 90 -61 140 -78 250 -23 158 6 328 78 455 4 8 12 24 17 35 23 50
		123 164 186 212 124 95 216 136 355 158 103 17 132 17 235 0z m-4899 -24 c63
		-17 138 -49 226 -97 83 -45 230 -216 273 -319 55 -132 59 -154 59 -310 0 -156
		-4 -178 -59 -310 -83 -198 -308 -379 -522 -420 -397 -76 -743 131 -879 525
		-15 45 -19 84 -18 205 0 156 4 178 59 310 43 103 190 274 273 319 86 47 164
		80 221 96 93 25 273 26 367 1z m267 -1857 c134 -17 195 -40 315 -121 35 -24
		67 -43 72 -43 11 0 20 -27 14 -42 -3 -7 -33 -33 -67 -58 -75 -55 -206 -167
		-268 -229 -54 -54 -169 -194 -169 -205 0 -5 -13 -25 -28 -45 -16 -20 -32 -43
		-35 -51 -4 -8 -16 -30 -28 -49 -30 -51 -84 -160 -104 -211 -9 -25 -21 -53 -27
		-63 -12 -24 -48 -164 -48 -191 0 -12 -7 -30 -15 -40 -8 -11 -15 -34 -15 -52 0
		-17 -6 -36 -12 -41 -22 -17 -383 -35 -588 -30 -212 5 -289 14 -335 39 -56 30
		-145 152 -145 198 0 12 -7 35 -16 51 -32 63 -29 487 5 594 5 17 17 57 26 90
		18 63 59 151 99 213 33 50 162 182 180 182 3 0 25 14 49 30 25 17 49 30 54 30
		6 0 24 7 41 16 17 8 73 19 123 25 51 5 95 11 97 14 3 3 162 5 354 5 258 0 382
		-4 471 -16z m5038 -14 c91 -13 136 -35 240 -115 65 -50 183 -177 195 -211 4
		-10 18 -32 30 -49 12 -16 32 -58 43 -92 20 -59 21 -84 21 -430 l0 -368 -64
		-66 c-81 -85 -112 -101 -216 -112 -110 -12 -680 -13 -745 -1 l-50 9 -3 50 c-2
		28 -7 54 -13 60 -5 5 -9 17 -9 27 0 23 -37 145 -56 181 -8 16 -14 36 -14 46 0
		9 -7 26 -15 37 -8 10 -15 26 -15 35 0 26 -94 200 -156 291 -105 153 -210 263
		-412 428 -87 71 -121 119 -93 128 6 2 17 9 24 15 36 35 175 115 228 131 67 22
		935 26 1080 6z m-3776 -355 c28 -3 64 -12 80 -19 48 -22 223 -76 243 -76 10 0
		27 -7 38 -15 10 -8 34 -15 52 -15 18 0 42 -5 53 -11 11 -5 78 -16 149 -24 102
		-12 161 -13 277 -5 82 6 166 15 188 21 22 6 59 14 83 18 24 4 48 13 54 19 7 7
		22 12 34 12 13 0 25 4 28 9 3 4 27 14 53 21 27 7 52 17 58 21 5 5 18 9 30 9
		12 0 30 7 41 15 25 19 393 22 412 3 6 -6 30 -14 54 -19 129 -22 287 -95 445
		-204 112 -77 303 -288 344 -379 5 -12 18 -34 29 -51 11 -16 23 -37 26 -45 12
		-30 37 -86 50 -113 8 -16 14 -38 14 -48 0 -11 5 -30 11 -41 15 -27 39 -154 49
		-253 5 -44 13 -107 20 -140 15 -81 10 -409 -7 -427 -7 -6 -13 -29 -13 -49 0
		-21 -6 -50 -14 -66 -8 -15 -24 -50 -37 -78 -36 -80 -92 -148 -166 -202 -128
		-94 26 -85 -1628 -90 -803 -2 -1588 0 -1745 5 -322 9 -328 11 -439 96 -107 82
		-187 216 -206 346 -3 25 -10 68 -15 95 -12 68 -11 280 1 385 5 47 14 119 18
		160 10 86 39 210 52 225 5 5 9 18 9 30 0 12 7 30 15 41 8 10 15 25 15 33 0 14
		57 133 83 176 45 73 174 231 232 286 66 62 187 159 199 159 3 0 27 13 54 29
		146 86 227 120 287 121 17 0 39 7 49 15 11 9 42 15 70 15 28 0 58 5 66 10 14
		10 99 8 205 -5z" style="stroke-width: 200px;"></path>
		  </g>
		</svg>
	);
}

