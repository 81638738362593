
export function HeadInsert()
{
	return (
		`<style> 
			.preload > * {
				opacity: 0;
			}

			.loaded > * {
				opacity: 1;
			}
		</style>`
	);
}
