import {BuildBasics} from 'Common/BuildBasics';
import {IRoutesProvider} from 'Common/IRoutesProvider';
import {IPageData, IPageParams, PageConfig} from 'Common/PageConfig';
import {Manager} from 'Common/pages/artist/Manager';
import {Profile} from 'Common/pages/artist/Profile';
import {User} from 'Common/pages/artist/User';



export type ArtistPageConfigClass = new (inBrowser:boolean,build:BuildBasics) => PageConfig<any,IPageParams>

type ArtistRouteDetails = [ 
	ArtistPageConfigClass,
	(stringParams:{[key:string]:string}) => IPageData['params']
];

export type ArtistRoutesType = {[name:string]:ArtistRouteDetails}; 



export class ArtistRoutes implements IRoutesProvider
{
/* cf redirects... options:

	1. redirects() { ... }		[PROBLEM: ordering of paths wrt pages]

	2. '/manager': {type:'page/redirect',class:Manager,paramMapping:()=>({}),settings:{...}}	
*/

	routes()
	{
		return <ArtistRoutesType>({
			'/':			[Manager, ()=>({})],

			'/manager':		[Manager, ()=>({})],
			'/profile/:id':	[Profile, (params:IPageParams)=>({id:params.id})],
			'/user':		[User, ()=>({})],


//			'/manager',				[], (params:any) => this.pageCreator.create(Manager,params),
//			'/admin/hire/:tab':		[BackendHire, params=>({tab:params.tab}) ],

//TODO return 404			
//			'/admin/*':				[BackendPageNotFound,() => ({}) ],


//			'/*':	[new FrontendPageNotFound(this.inBrowser,this.site,this.urls,this.serverFunctions),() => ({}) ],
		});
	}

//TODO maybe trim trailing / from all...
}

