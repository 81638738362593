

//{% import 'App/forms/dualModeFields.njk' as fields with context %} TODO delete

import {css} from '@emotion/css';
import AjaxConnectionToServer from 'Browser/AjaxConnectionToServer';
import Assert from 'Common/Assert';
import {LoginMessage, RegisterMessage} from 'Common/Messages';
import {FacebookAuthButton} from 'Shared/artists/FacebookAuthButton';
import {GoogleAuthButton} from 'Shared/artists/GoogleAuthButton';
import {Show, createSignal} from 'solid-js';
import {renderPage} from 'Browser/pages/SolidPageWrapper';
import {VosLogo} from 'Shared/backend/fonts/icons/VosLogo.svg';
import {Banner, utilityPageStyle} from 'Shared/users/UtilityPageTheme';
import {SiteBasics} from 'Common/SiteBasics';

const style = () => css({
	maxWidth: 450,
	margin: '0 auto',
	padding: '2em',

	form: {	
		gridTemplateColumns: '100%'
	},

	button: {
		width: '100%',
		marginBottom: '1em'
	},

	input: {
		width: '100%'
	},

	'.forgotPasswordLink': {
		textAlign: 'right',
		color: '#888'
	}
});

const submitButtonStyle = () => css({
	borderRadius: '0.4em',
	padding: '0.44em 2.25em',
	backgroundColor: 'green',
	color: 'white',
	borderWidth: 0,
	boxShadow: 'none',
	marginTop: '1em',
	whiteSpace: 'nowrap',
	fontSize: 'inherit',

	'&:focus': {
		outline: `1px dotted white`,
		outlineOffset: -4
	},

	'&:hover': {
		filter: 'brightness(95%)'
	}
});

const orLineStyle = () => css({
	position: 'relative',
	height: 21,

	'div:first-child': {
		position: 'absolute', 
		width: '100%',
		top: 0, 
		left: 0, 
	},

	hr: {
		border: 0,
		color: '#9fa6ad',
		backgroundColor: '#9fa6ad',
		height: 1,
		marginBottom: 20,
		marginTop: 10
	},

	'div:last-child': {
		position: 'absolute', 
		top: 0, 
		left: 0, 
		right: 0,

		div: {
			width: '2.5em',
			margin: '0 auto', 
			textAlign: 'center',
			zIndex: 1,
			backgroundColor: 'white'
		}
	}
});


export function entryUrl(site:SiteBasics)
{
	switch(site.type)
	{
		case 'artist':	return '/manager';
		default:		return '/admin';
	}
}


export function AuthenticationForm(props)
{
	const [errors,setErrors] = createSignal({});

	return (
		<section class={style()}>
			<h2>Log in using</h2>

			<GoogleAuthButton label='Google' action={() => location.href='/login/google'} />
			<FacebookAuthButton label='Facebook' action={() => location.href='/login/facebook'} />

			<div class={orLineStyle()}>
				<div><hr/></div>
				<div><div>or</div></div>
			</div>

			<x-field>
				<label for='email'>Email</label>

				<Show when={errors().email}>
					<x-error>{errors().email}</x-error>
				</Show>

				<input id='email' type='email' name='email' value='' autocomplete='off' placeholder='you@example.org' />
			</x-field>

			<x-field>
				<label for='password'>Password</label>

				<Show when={errors().password}>
					<x-error>{errors().password}</x-error>
				</Show>

				<input id='password' type='password' name='password' autocomplete='off' placeholder='********'/>
			</x-field>

			<button class={submitButtonStyle()} onClick={() => logIn(setErrors)}>Log In</button>

			<div class='forgotPasswordLink'>
				<a href="/forgotPasswordGetAddress.html">Forgot?</a>
			</div>
		</section>
	);
}

export function RegistrationForm(props)
{
	const [errors,setErrors] = createSignal({});

	return (
		<section class={style()}>
			<h2>Or register using</h2>

			<GoogleAuthButton label='Google' action={() => location.href='/register/google'} />
			<FacebookAuthButton label='Facebook' action={() => location.href='/register/facebook'} />

			<div class={orLineStyle()}>
				<div><hr/></div>
				<div><div>or</div></div>
			</div>

			<x-field>
				<label for='firstName'>First name&#42;</label>

				<Show when={errors().firstName}>
					<x-error>{errors().firstName}</x-error>
				</Show>

				<input id='firstName' type='text' name='firstname' value='' />
			</x-field>

			<x-field>
				<label for='lastName'>Last name&#42;</label>

				<Show when={errors().lastName}>
					<x-error>{errors().lastName}</x-error>
				</Show>

				<input id='lastName' type='text' name='lastname' value='' />
			</x-field>

			<x-field>
				<label for='regoEmail'>Email&#42;</label>

				<Show when={errors().regoEmail}>
					<x-error>{errors().regoEmail}</x-error>
				</Show>

				<input id='regoEmail' type='email' name='email' value='' autocomplete='off' placeholder='you@example.org' />
			</x-field>

			<button class={submitButtonStyle()} onClick={() => submitRegistration(setErrors)}>
				Register
			</button>
		</section>
	);
}

async function logIn(setErrors)
{
	const userName = Assert.htmlInputElement(document.getElementById('email')).value.trim();
	const password = Assert.htmlInputElement(document.getElementById('password')).value.trim();

	const server = new AjaxConnectionToServer();

	const ret = await server.sendOperation(new LoginMessage(userName,password));

console.log('AuthenticationForm logIn()  site:',window.site);

	if (ret.loggedIn)
		document.location = entryUrl(window.site)
	else
		setErrors({[ret.field]: ret.message});
}

async function submitRegistration(setErrors)
{
	const firstName = Assert.htmlInputElement(document.getElementById('firstName')).value.trim();
	const lastName = Assert.htmlInputElement(document.getElementById('lastName')).value.trim();
	const userName = Assert.htmlInputElement(document.getElementById('regoEmail')).value.trim();

	const server = new AjaxConnectionToServer();

	const ret = await server.sendOperation(new RegisterMessage(firstName,lastName,userName));

	if (ret.errors)
		setErrors(ret.errors);
	else
		renderPage('Sent email',RegoEmailSent,{email:userName});
}


/* --- Registration sent message: --- */

const tickStyle = () => css({
	color: 'green',
	fontSize: '7em'
});

function RegoEmailSent(props)
{
	return (
		<div class={utilityPageStyle()}>
			<Banner />

			<div class={tickStyle()}>&#10004</div>

			<p>
				A registration email has been sent to {props.email}.  You will need to click on the link
				in it to complete your registration.
			</p>
			<p>
				If you don&rsquo;t see this email in your inbox within 15 minutes, 
				look for it in your junk mail folder. If you find it there, 
				please mark it as &ldquo;Not Junk&rdquo;. 
			</p>
		</div>
	);
}


