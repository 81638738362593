import {Component} from 'Common/components/Component';
import {IPage} from 'Common/pages/IPage';
import {IConnectionToServer} from 'Common/IConnectionToServer';
import {DeleteMessage} from 'Common/Messages';
import {DeleteComponent as DeleteComponentConfig} from 'Common/config/PageConfigTypes';
import {Id} from 'Common/Id';
import {IPageData} from 'Common/PageConfig';

type DeleteComponentData = {
	id:number,
	redirect:any
};

/*
	Supports deletion of a single row from a single table.
 */
export class DeleteComponent extends Component
{
	public data:DeleteComponentData = {id:0,redirect:null};

	constructor(
		private instanceName:string,
		private page:IPage<IPageData>,
		private server:IConnectionToServer,
		private config: DeleteComponentConfig<any>,
		private docId:Id
	) {
		super(instanceName);
		this.delete = this.delete.bind(this);
	}

	componentType() { return 'delete'; }

    async delete()
    {
		const msg = new DeleteMessage(this.page.name(),this.instanceName,this.docId);
        await this.server.sendOperation(msg);

		if (this.config.redirect!=undefined)
			location.href = this.config.redirect(this.page.data);
    }
}

