import {FacebookCircle} from 'Shared/backend/fonts/icons/FacebookCircle.svg';
import {IconColour} from 'Shared/backend/fonts/icons/IconColour';
import {InstagramCircle} from 'Shared/backend/fonts/icons/InstagramCircle.svg';
import {LinkOpen} from 'Shared/backend/fonts/icons/LinkOpen.svg';
import {WebsiteCircle} from 'Shared/backend/fonts/icons/WebsiteCircle.svg';
import {Text, Wrap} from 'Shared/forms/Inputs';
import {ArtistProps} from 'Shared/artists/ProfilePage';
import {facebookPageUrl, instagramPageUrl, websiteUrl} from 'Browser/InputTypes';
import {createMemo, mergeProps} from 'solid-js';


export function SocialSites(artist:ArtistProps)
{
	const searchTerms = createMemo(() => {
		//TODO fill in other performace types...
		let style;
		if (artist.performanceType == 'comedy')
			style = 'Comedian';
		else if (artist.performanceType =='dj')
			style = 'DJ';
		else 
			style = 'Music+Bands';

		let sep='';
		let location = '';
		if (artist.city) {
			location += `${sep}${artist.city}`;
			sep = '+';
		}
		if (artist.state) {
			location += `${sep}${artist.state}`;
			sep = '+';
		}
		if (artist.country) {
			location += `${sep}${artist.country}`;
			sep = '+';
		}

		return `${artist.name}+${style}+${location}`;
	});

	const props = mergeProps(artist,{searchTerms:searchTerms});

	return (<>
		<h4>
			Social media & sites
			<x-notes>Important so VOS can find artist content</x-notes>
		</h4>

		<FacebookUrl {...props} />

		<InstagramUrl {...props} />

		<WebsiteUrl {...props} />
	</>);
}	

function FacebookUrl(artist:ArtistProps & {searchTerms:()=>string})
{
	const facebookLabel = () => exists(artist.facebook) ?
			<a class='active' target='_blank' href={`https://www.facebook.com/${artist.facebook}`}>
				Facebook <LinkOpen fill={IconColour.active}/>
			</a>
		:
		   <a class='inactive' target='_blank'
					href={`https://www.google.com/search?q=+facebook+page+${artist.searchTerms()}`}>
				Search Facebook for artist <LinkOpen fill={IconColour.inactive} />
			</a>;

	return (
		<div class='step'>
			<div class='stepIcon'>
				<FacebookCircle fill={exists(artist.facebook) ? IconColour.active : IconColour.inactive} />
			</div>
			<Wrap label={facebookLabel()}>
				<Text component={artist.editArtist} field='facebook' store={artist} processInput={facebookPageUrl} placeholder='Paste web link or handle here' />
			</Wrap>
		</div>
	);
}

function InstagramUrl(artist:ArtistProps & {searchTerms:()=>string})
{
	const instagramLabel = () => exists(artist.instagram) ?
			<a class='active' target='_blank' href={`https://www.instagram.com/${artist.instagram}`}>
				Instagram <LinkOpen fill={IconColour.active}/> 
			</a>
		:
			<a class='inactive' target='_blank'
					href={`https://www.google.com/search?q=instagram+profile+${artist.searchTerms()}`}>
				Search Instagram for artist <LinkOpen fill={IconColour.inactive} />
			</a>;

	return (
		<div class='step'>
			<div class='stepIcon'>
				<InstagramCircle fill={exists(artist.instagram) ? IconColour.active : IconColour.inactive} />
			</div>

			<Wrap label={instagramLabel()}>
				<Text component={artist.editArtist} field='instagram' store={artist} processInput={instagramPageUrl} placeholder='Paste web link or handle here' />
			</Wrap>
		</div>
	);
}

function WebsiteUrl(artist:ArtistProps & {searchTerms:()=>string})
{
	const websiteLabel = () => exists(artist.website) ?
			<a class='active' target='_blank' href={artist.website}>
				Website <LinkOpen fill={IconColour.active}/> 
			</a>
		:
			<a class='inactive' target='_blank'
					href={`https://www.google.com/search?q=website+${artist.searchTerms()}`}>
				Search for artist website <LinkOpen fill={IconColour.inactive} />
			</a>;

	return (
		<div class='step'>
			<div class='stepIcon'>
				<WebsiteCircle fill={exists(artist.website) ? IconColour.active : IconColour.inactive} />
			</div>

			<Wrap label={websiteLabel()}>
				<Text component={artist.editArtist} field='website' store={artist} processInput={websiteUrl} placeholder='Paste website address here starting with http' />
			</Wrap>
		</div>
	);
}

function exists(value?:string)
{
	return value!=undefined && value.trim()!='';
}

