
export function ShrinkingNavBar(props)
{
	/* The comparators are used for adding expanded/collapsed/between classes to .desktopNavContainer */

	return (<>
		<div id='collapsedNavComparator' />
		<div id='expandedNavComparator' />

		<header class='desktopNavContainer'>
			<nav class='desktop'>
				{props.children}
			</nav>
		</header>
		<div id='resizableDesktopNavUnderlay' />
	</>);
}	

