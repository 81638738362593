import {SelectorWidget} from 'Browser/widgets/SelectorWidget';
import TomSelect from 'tom-select'; 
import Assert from 'Common/Assert';
import {TomInput} from 'tom-select/dist/types/types';

export class MultiSelectWidget extends SelectorWidget
{
	constructor()
	{
		super(['.bf-multiSelect']);
	}

	static key() { return 'multiSelect'; } 

	initInstance(anchorNode:HTMLElement):void
    {
        super.initInstance(anchorNode);

		const hidden = Assert.htmlInputElement(anchorNode.querySelector('.bf-value'));
		const select = Assert.htmlSelectElement(anchorNode.querySelector('select'));

		const value = hidden.value=='' ? '[]' : hidden.value;
		const initial = JSON.parse(value);

		const selected:{[key:string]:boolean} = {};
		for (const s of initial)
			selected[s] = true;

		const options = select.querySelectorAll('option');
		for (const o of options)
			if (selected[o.value])
				o.setAttribute('selected','true');

		const tomSelect = new TomSelect(<TomInput><unknown>select,{
			plugins: {
				remove_button:{ title:'Remove this item' }
			},
			onItemAdd(e:any) {
				hidden.value = JSON.stringify(tomSelect.items);
			},
			onItemRemove(value:any) {
				//TODO currently a change event is thrown before 'onItemRemove()' is called as well. Better if we could remove it.
				hidden.value = JSON.stringify(tomSelect.items);
				tomSelect.trigger('change',tomSelect.items);
			}
		});
    }

	beforeUpdate(fromNode:HTMLElement,toNode:HTMLElement):boolean 
    {
        return !this.isAnchorNode(fromNode); 
    }
}

