import {css} from '@emotion/css';
import {SolidImageUploadWidget} from 'Browser/widgets/SolidImageUploadWidget';
import {ArtistUrls} from 'Common/ArtistUrls';
import {usePage} from 'Shared/artists/PageProvider';
import {Image, ImageSize, Url} from 'Shared/model/basic';
import {createSignal,createEffect, onMount, Switch, Match,Show} from 'solid-js';
import * as ImageSizes from 'Shared/model/ImageSizes';
import {SolidImageUploadWidget2} from 'Browser/widgets/SolidImageUploadWidget2';
import {Location} from 'Common/config/PageConfigTypes';
import {Id} from 'Common/Id';
import { VenueUrls } from 'Common/VenueUrls';
import { unwrap } from 'solid-js/store';

export function uploaderStyle()
{
	return css({
		display: 'flex',
		flexWrap: 'wrap',
		cursor: 'pointer',
		padding: 0,

		'.bf-imagePreview': {
			position: 'relative',
			display: 'inline-block'
		},

		'.bf-loading': {
			color: 'white',
			position: 'absolute',
			top: '50%',
			left:  '50%',
			transform: 'translate(-50%,-50%) scale(0.08)'
		}
	});
}

export function buttonsStyle()
{
//XXX spinner looks dodgy

	return css({
		'.bf-imageUploadButton': {
			position: 'relative',
			marginRight: 5,

			input: {
				position: 'absolute',
				top: 0,
				left: 0,
				bottom: 0,
				right: 0,
				opacity: 0,
				cursor: 'pointer',
			}
		},

		button: {
			cursor: 'pointer',
			padding: '4px 9px'
		},

		'.bf-removeImage:not([disabled])': {
			backgroundColor: '#ff738d',
			borderColor: '#ff5a78'
		},
	});
}

export function Label(props:any) 
{
//FIXME implement this? Or delete.

	return (
		<label>{props.labelText}
			<i class='info-pop fa fa-info-circle' 
				data-content='Use this field to upload a photo or image. Click the button to upload a file 
					from your computer, or if you&rsquo;re using a recent version of Chrome or Firefox, 
					you can simply drag&rsquo;n&rsquo;drop the file from your desktop or from a different browser window.<br>
					Alternatively, you can use a previously uploaded image. To select a previously uploaded file, 
					just type (part) of the file name in the input area, and it will be autocompleted.' 
				data-html='1' data-title='Add image' data-original-title='' title=''
			><span class='sr-only'>Info</span></i>
		</label>
	);
}

export interface IImageUpload extends Image {
	widget: SolidImageUploadWidget,
	fallbackUrl: Url;
} 

/*
	Options include 'ratio', 'minWidth' and 'minHeight' which are all optional. 
	If 'ratio' is supplied only one of either 'minWidth' and 'minHeight' are required to 
	enforce minimum dimensions.
*/
export function ImageUpload(props:IImageUpload)
{
	const config = props.widget.imageUploader.def;

	const [image,setImage] = createSignal(props as IImageUpload|undefined);
	const imageExists = () => image()?.hash != undefined;

	const [anchor,setAnchor] = createSignal();
	createEffect(() => props.widget.init(anchor()));

	return (
		<div class={`${uploaderStyle}`} ref={setAnchor} id={props.id}
			on:Loading={e => setImage({dataUrl:e.detail.dataUrl, uploading:true})}
			on:Loaded={e => setImage({...e.detail, uploading:false}) }  
			on:Deleted={() => setImage(undefined) }
		>
			<input type='hidden' class='bf-value' value='' />
			<div class={buttonsStyle()}>
				<button type='button' class='bf-imageUploadButton'>
					<i class='fa fa-upload'></i> {imageExists() ? 'Replace' : 'Upload' } image
					<input type='file' accept='.jpg,.jpeg,.png' autocomplete='off' name='files[]' />
				</button>

				<button type='button' class='bf-removeImage' disabled={!imageExists()}>
					<i class='fa fa-times'></i> Remove image
				</button>

				<x-notes>
					<p>Drop PNG or JPEG file here <i>or</i> click to upload.</p>
					<p>Dimensions at least 1200&times;800px. Max file size is 10MB.</p>
				</x-notes>
			</div>

			<Preview exists={imageExists()} dimensions={props.sizes} assetFolder={config.assetFolder} size={config.previewSize} fallbackUrl={props.fallbackUrl}/>
		</div>
	);
}

export interface IPreview extends Image {
	dataUrl?:string;
	fallbackUrl:Url;
	exists:boolean;
	assetFolder:string;
	size:ImageSize;
	dimensions: Image;
} 

export function Preview(image:IPreview)
{
	const urls = new ArtistUrls(usePage().build);

	const loadingUrl = urls.resourceUrl('loading.gif');

	const previewUrl = () => {
		const size = image.dimensions[image.size];
		const useProduction = !image.dev;
		return urls.imageUrl(image.assetFolder,image.hash,image.size,size.format,useProduction);
	};


	// XXX bug - loading not centered properly 
	// XXX suppose we could use <Suspense> for the uploading part
	return (
		<x-pane class='bf-imagePreview'>
			{image.uploading ?
				<>
					<img class='bf-imagePreviewImg' alt='Image preview' src={image.dataUrl} />
					<img class='bf-loading' alt='Loading' src={loadingUrl} />
				</>
			:
				<img class='bf-imagePreviewImg' alt='Preview' 
					src={image.exists ? previewUrl() : urls.resourceUrl(image.fallbackUrl)}  /> 
			}
		</x-pane>
	);
}


//////////////////////////////
//TODO update the artist profile image and use the code below

export interface IImageUpload2 extends Image {
	docId: Id,
	location: Location,
	fallbackUrl: Url;
	dimensions: keyof typeof ImageSizes;
	previewSize: ImageSize;
	page,
	permission: string,
	image,
	setImage,
	loadingDataUrl: string,
	setLoadingDataUrl: string
} 

/*
	Options include 'ratio', 'minWidth' and 'minHeight' which are all optional. 
	If 'ratio' is supplied only one of either 'minWidth' and 'minHeight' are required to 
	enforce minimum dimensions.
*/
export function ImageUpload2(props: IImageUpload2)
{
	const [loadingDataUrl,setLoadingDataUrl] = createSignal();
	let uploadNode!:HTMLDivElement;
	const imageExists = () => props.image != undefined;

	let widget!: SolidImageUploadWidget2;

//TODO better to incorporate this widget here - the event handling could be simplified.
	onMount(() => {
		widget = (new SolidImageUploadWidget2(props.page.server,props.page.name(),props.permission,props.location,props.docId,true));
		widget.init(uploadNode);
	});
	createEffect(() => widget.setDimensions(props.dimensions));

	return (
		<div class={`${uploaderStyle}`} ref={uploadNode} id={props.docId}
			on:loading={e => setLoadingDataUrl(e.detail.dataUrl)}
			on:loaded={e => { 
				props.setImage(e.detail); 
				setLoadingDataUrl(undefined); 
			}}  
			on:deleted={() => { props.setImage(undefined); setLoadingDataUrl(undefined);  }}
		>
			<input type='hidden' class='bf-value' value='' />
			<div class={buttonsStyle()}>
				<button type='button' class='bf-imageUploadButton'>
					<i class='fa fa-upload'></i> {imageExists() ? 'Replace' : 'Upload' } image
					<input type='file' accept=".jpg,.jpeg,.png" autocomplete='off' name='files[]' />
				</button>

				<button type='button' class='bf-removeImage' disabled={!imageExists()}>
					<i class='fa fa-times'></i> Remove image
				</button>

				<x-notes>
					<p>Drop PNG or JPEG file here <i>or</i> click to upload.</p>
					<p>Dimensions at least 1200&times;800px. Max file size is 10MB.</p>
				</x-notes>
			</div>

			{/* NOTE: not used in menu items. Possiby separate out? */}
			<Preview2 exists={imageExists()}
				assetFolder={props.permission.assetFolder} 
				size={props.previewSize}
				format={ImageSizes[props.dimensions][props.previewSize].format}
				fallbackUrl={props.fallbackUrl}
				image={props.image}
				loadingDataUrl={loadingDataUrl}
			/>
		</div>
	);
}

export interface IPreview2 extends Image {
	fallbackUrl:Url;
	exists:boolean;
	assetFolder:string;
	size:ImageSize;
	format: string;
	image,
	loadingDataUrl:string,
} 

export function Preview2(props:IPreview2)
{
//	const urls = new ArtistUrls(usePage().build);   //FIXME for artist GUI!
	const urls = new VenueUrls(usePage().build,usePage().site.key);

	const loadingUrl = urls.resourceUrl('loading.gif');
	const previewUrl = () => urls.imageUrl(props.assetFolder,props.image.hash,props.size,props.format,!props.dev); 

	// XXX bug - loading not centered properly 
	// XXX suppose we could use <Suspense> for the uploading part

	//XXX dont think menu item previews have/need/want a fallbackUrl
	return (
		<x-pane class='bf-imagePreview'>
			<Switch>
				<Match when={props.uploading}>
					<img class='bf-imagePreviewImg' alt='Image preview' src={props.loadingDataUrl} />
					<img class='bf-loading' alt='Loading' src={loadingUrl} />
				</Match>
				<Match when={true}>
					<Switch>
						<Match when={props.exists}>
							<img class='bf-imagePreviewImg' alt='Preview' src={previewUrl()} /> 
						</Match>
						<Match when={props.fallbackUrl}>
							<img class='bf-imagePreviewImg' alt='Preview' src={urls.resourceUrl(props.fallbackUrl)} /> 
						</Match>
					</Switch>
				</Match>
			</Switch>
		</x-pane>
	);
}

