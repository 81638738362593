//import * as nunjucks from 'nunjucksBB';
//import SlimNunjucksEnv from 'nunjucksBB';
//import * as nunjucks from '../node_modules/nunjucksBB/dist/nunjucks-slim.js';
//import SlimNunjucksEnv from '../node_modules/nunjucksBB/dist/nunjucks-slim.js';


import NunjucksExtensions from 'Browser/NunjucksExtensions';
import Assert from 'Common/Assert';
import {ITemplateRenderer} from 'Common/ITemplateRenderer';


/* Not for use in the browser - that needs the slim version */
export class Nunjucks
{
	renderer():ITemplateRenderer
	{
		const loader = new window.nunjucksBB.PrecompiledLoader(Assert.have(window.nunjucksPrecompiled));
		const env = new window.nunjucksBB.SlimEnvironment(loader, {
			trimBlocks:true,
			lstripBlocks:true
			//throwOnUndefined:true
		});
		return NunjucksExtensions.extend(env);
	}
}
