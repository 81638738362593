import {VosLogo} from 'Shared/backend/fonts/icons/VosLogo.svg';

export function LoginBanner()
{
	return (
		<header>
			<div class='headerOntoit'>
				<VosLogo />
			</div>
		</header> 
	);
}

