import {INjkInputType} from 'Browser/NjkInputTypes';
import Assert from 'Common/Assert';

export function getInput(node:HTMLElement):any
{
	if (node.classList.contains('bf-checkbox')) 
		return (<any>node)?.checked ?? false;

	if (node.classList.contains('bf-multiSelect')) {
		const value = Assert.htmlInputElement(node.querySelector('.bf-value')).value;
		return JSON.parse(value);
	}
	
	return getInputString(node);
}

/*
	Reads the value from an input field and returns it as a string.  
	Returns null if it is not present or there is an empty string (after trimming). 
	Booleans will be converted to 'true' or 'false'.
	If an input contains a '.bf-value' input then its value will be returned.
	Strings are auto-trimmed.
	IF WE DONT WANT auto-trim or auto-nulling in some circumstance I think it would be best to
	add an option to the NJK input macro, allowing it to be disabled. FieldTypes can be
	used with different types of inputs, so its not great dealing with it there.
 */
export function getInputString(node:HTMLElement):string|null
{
	const valueNode = node.querySelector('.bf-value');
	if (valueNode!=null) {
		Assert.check(valueNode instanceof HTMLInputElement);
		const value = valueNode.value.trim();
		return value=='' ? null : value;
	}

	Assert.property('value',node);
	const value = (<any>node).value.trim();
	return value=='' ? undefined : value;
}

export function processInput(inputType:INjkInputType|null,value:string|boolean)
{
	/* Checkboxes return booleans */
	if (typeof value == 'boolean')
		return value;

	const trimmed = value?.trim();
	if (trimmed=='' || trimmed==null)
		return null;		//TODO probably should use undefined

	/* This is included for the convenience of the caller */
	if (inputType==null)
		return trimmed;

	return inputType.input(value);
}
