import {VenueView} from 'Shared/view/VenueView';
//import {Home as FrontendHome} from 'Shared/view/frontend/DifferentDrummerHome';
import {Home as FrontendHome} from 'Shared/view/frontend/Home2';
//import {Home as BackendHome} from 'Shared/view/backend/DifferentDrummerHome';

export class IndieIslandView extends VenueView
{
	view()
	{
		return <any>({
			...super.view(),
			[FrontendHome.pageName]:	FrontendHome,
	//		[BackendHome.pageName]:		BackendHome
		});
	}
}

