import {Oswald} from 'Shared/frontend/fonts/Oswald';
import {Alatsi} from 'Shared/frontend/fonts/Alatsi';
import {OpenSans} from 'Shared/frontend/fonts/OpenSans';

export function Fonts()
{
	return Oswald() + Alatsi() + OpenSans();
}


