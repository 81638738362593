import {IConnectionToServer} from 'Common/IConnectionToServer';
import {DeleteImageMessage} from 'Common/Messages';
import {Component} from 'Common/components/Component';
import { Document } from 'Common/PageConfig';
import {findEmbedded} from 'Common/components/JsonUtils';
import { ImageUploaderComponent as ImageUploaderConfig, Location } from 'Common/config/PageConfigTypes';

export interface ImageDataType
{
	uploading?: boolean,
	browserPreview?: string,  //url
	loadingUrl?: string		//url
	//+ the image sizes etc...?
}

export class ImageUploaderComponent extends Component
{
	constructor(
		name:string,
		private server:IConnectionToServer,
		/* formData is a reference to a document or embedded document */
		private pageName: string,
		readonly def: ImageUploaderConfig,
		readonly doc:Document
	)
	{ 
		super(name);
	}

    componentType() { return 'imageUploader'; }

	uploadingImage(location:Location,browserPreview:string)
	{
/*	
		this.setImageData(path,{...this.imageData,
			uploading: true,
			browserPreview: browserPreview,
			loadingUrl: this.urls.resourceUrl('loading.gif')
		});
*/		
	}

    deleteImage(location:Location)
    {
		const msg = new DeleteImageMessage(this.pageName,this.name,this.doc._id,location);
		this.server.sendOperationOptimistically(msg);
		this.setImageData(location,undefined);
    }

	loadedFile(location:Location,newData:any)
	{
		this.setImageData(location,{...this.imageData,...newData,uploading:false});
	}

	postDisplay()
	{
	}

	private imageData(path:string)
	{
//FIXME Should be using findEmbedded here any more
		const imageDataParent = path==undefined ? this.doc : findEmbedded(this.doc,path);
		return imageDataParent[this.def.field];
	}

	private setImageData(location:Location,value:any)
	{
		const imageDataParent = this.def.locate(this.doc,location);
		if (value==undefined)
			delete (<any>imageDataParent)[this.def.field];
		else
			(<any>imageDataParent)[this.def.field] = value;
	}
}

