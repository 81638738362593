import {ITimesFunctions} from 'Common/TimesFunctions';

export class FutureEventsQueryClause
{
	/*
		dayEnd - used with 'allDay' to indicate 'to close'.
		         Not bothering to use a dayStart equivalent. Doesn't seem desirable.
	 */
	constructor(
		private times:ITimesFunctions,
		private field:string,
		private cutoffInFuture:object
	) {}

	singleEndInFuture()
	{
		return ({ $or: [
			{$and: [
				{ [`${this.field}.allDay`]: {$ne:true} },
				{ [`${this.field}.endSingle`]: {$gte: this.times.db(this.times.now()) } }
			]},
			/*TODO use a schema to prevent this. This test is required for the moment though. */
			{$and: [
				/* NOTE: {$eq:null} matches undefined, not present, and null */
				{ [`${this.field}.endSingle`]: {$eq:null} },
				{ [`${this.field}.startSingle`]: {$gte: this.times.db(this.times.today())} }
			]},
			{$and: [
				{ [`${this.field}.allDay`]: true },
				{ [`${this.field}.startSingle`]: {$gte: this.times.db(this.times.today())} }
			]}
		]});
	}

	futureSingles()
	{
		return ({$and: [
			{ [`${this.field}.frequency`]: 'once' } ,
			this.singleEndInFuture(),
			{ [`${this.field}.startSingle`]: {$lt: this.times.db(this.times.add(this.times.today(),this.cutoffInFuture)) } },
		]});
	}

	repeatEndInFuture()
	{
		return ({$or: [
			{ [`${this.field}.endDate`]: {$eq: null } },
			/* This will retrieve some extra items. Relying on later trimming. */
			{ [`${this.field}.endDate`]: {$gte:this.times.dbDate(this.times.today()) }}
		]});
	}

	futureRepeats()
	{
		return ({$and: [
			{ [`${this.field}.frequency`]: {$ne:'once' } } ,
			this.repeatEndInFuture(),
			{ [`${this.field}.startDate`]: {$lt: this.times.dbDate(this.times.add(this.times.today(),this.cutoffInFuture)) } },
		]});
	}

	/*
		cutoffInFuture: eg {months:6}
	 */
	create()
	{
		return {$or:[this.futureSingles(),this.futureRepeats()]};
	}
}

