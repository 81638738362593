import { PageFunctions } from 'Browser/PageFunctions';
import { VenueUrls } from 'Common/VenueUrls';
import { usePage } from 'Shared/artists/PageProvider';
import { DateTitleWithEndTimes } from 'Shared/frontend/PosterTitle'
import { Match, Show, Switch } from 'solid-js'


export function SpecialsOverlayContent(props)
{
	const special = () => props.special;

	const imageUrl = () => {
		if (!props.special.image?.hash)
			return '';

		const url = (new PageFunctions()).createImageUrl2(
			new VenueUrls(usePage().build,usePage().site.key),
			props.special.image.hash,
			props.special.image,
			'Special-image',
			'medium'
		);
		return url;
	};

	return (<>
		<Switch>
			<Match when={special().imageType == 'suppliedPoster2'}>
				<Show when={special().name} fallback={<div class='topSpacer'/>}>
					<div class='specialName'>{special().name}</div>
				</Show>

				<div class={`${special().imageDimensions}Dimension`}>
					<img src={imageUrl()}/>
				</div>
			</Match>
			<Match when={special().imageType == 'photoPoster' || special().imageType == 'suppliedPoster'}>
				<div class='imageWrap'>
					<div class={`${special().imageDimensions}Dimension`}>
						<img src={imageUrl()}/>
					</div>
				</div>
			</Match>
			<Match when={special().imageType == 'autoNonMusicPoster'}>
				<div class='specialName'>{special().name}</div>

				<div class={`${special().imageDimensions}Dimension`}>
					<Show when={special().tagline}>
						<div class='info'>
							{special().tagline}
						</div>
					</Show>
				</div>
			</Match>
		</Switch>

		<div class='detailsWrap'>
			<Show when={special().name}>
				<div class='specialName'>
					{special().name}
				</div>
			</Show>

			<div class='date'>
				<DateTitleWithEndTimes {...props.times} />
			</div>

			<Show when={special().tagline}>
				<div class='tagline'>
					{special().tagline}
				</div>
			</Show>

{/*
XXX may need moreInfo to be innerHTML
*/}				
			<div class='moreInfo'>
				{special().moreInfo}
			</div>
		</div>
	</>);
}

