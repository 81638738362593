
export function Questrial()
{
	return (`
		<link href="https://fonts.googleapis.com/css?family=Questrial&display=swap" rel=stylesheet> 
		<link rel=preconnect href="https://fonts.googleapis.com">
		<link rel=preconnect href="https://fonts.gstatic.com" crossorigin>
		<link href="https://fonts.googleapis.com/css2?family=Open+Sans:ital@1&family=REM:wght@700&display=swap" rel=stylesheet>
	`);
}
