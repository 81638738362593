import {HttpErrorInterface,HttpErrorMessage} from 'Browser/HttpErrorInterface';
import BuildError from 'Common/config/BuildError';
import BuildErrorFactory from 'Common/config/BuildErrorFactory';

export default class BuildErrors extends Error implements HttpErrorInterface
{
	private errors:BuildError[];
	private mySaved:boolean = false;

	constructor(errors:BuildError[])
	{
		super();
		this.errors = errors;
	}

	static type():string { return 'buildErrors'; }

	public length():number { return this.errors.length; }

	public setSaved() { this.mySaved = true; }

	public saved() { return this.mySaved; }

	public toJson():HttpErrorMessage
	{
		const jsonErrors:HttpErrorMessage[] = [];
		for (const e of this.errors) 
			jsonErrors.push(e.toJson());

		return {httpCode:200,saved:this.mySaved,errorType:BuildErrors.type(),errors:jsonErrors};
	}

	public addOrRethrow(err:any)
	{
		if (err instanceof BuildError)
			this.errors.push(err);
		else if (err instanceof BuildErrors) {
			for (const e of err.errors)
				this.errors.push(e);
		}
		else
			throw err;
	}

	public toString()
	{
		const isServer = typeof window == 'undefined';

		let out = '';

		if (this.errors.length==1) {
			const s = this.errors[0].toString();
			out += isServer ? s : this.toHtml(s);
		}
		else {
			let i = 1;
			for (const e of this.errors) {
				const s = isServer ? e.toString() : this.toHtml(e.toString());
				out += `Error ${i}: ${s}\n`;
				i++;
			}
		}

		return out;
	}

	private toHtml(value:string):string
	{
		return value.replaceAll('\n','<br>').replaceAll(' ','&nbsp;').replaceAll('\t','&nbsp;&nbsp;&nbsp;&nbsp;');
	}

	public static fromJson(data:any)
	{
		const errors:BuildError[] = [];

		for (const e of data.errors) 
			errors.push(BuildErrorFactory.fromJson(e));
		const e = new BuildErrors(errors);
		if (data.saved)
			e.setSaved();
		return e;
	}
}

