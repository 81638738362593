import { z } from 'zod';
import * as t from 'Shared/model/basic';
import {staffResource as staffResourceModel} from 'Shared/model/StaffResource';
import {Components,CreateComponent,DocumentQueries, NjkEditDocumentList } from 'Common/config/PageConfigTypes';
import {AccessAreaName} from 'Common/permissions/AccessArea';
import {BackendPageConfig, backendData} from 'Common/BackendPageConfig';


export const staffResourcesParams = z.object({}).strict();	
export type StaffResourcesParams = z.infer<typeof staffResourcesParams>;

const staffResourcesDoc = z.array(staffResourceModel);
type StaffResourcesDoc = z.infer<typeof staffResourcesDoc>;


export const staffResourcesData = backendData.extend({
	staffResources: staffResourcesDoc,

	/* Component data types: */
	createStaffResource: z.object({
		id: t.id
	})
}).strict();
export type StaffResourcesData = z.infer<typeof staffResourcesData>;


export class StaffResources extends BackendPageConfig<StaffResourcesData,StaffResourcesParams>
{
	static readonly pageName = 'backend/staffResources';
	name() { return StaffResources.pageName; }

	access() { return 'venueFullEditor' as AccessAreaName; }

	documents(params:StaffResourcesParams):DocumentQueries
	{
		return ({
			...super.documents(params),
			staffResources: {
				type: 'array',
				collection: 'staffResource',
				aggregate: () => [
					{'$sort': {'position':1}},
				],
			},
		});
	}

	components():Components<StaffResourcesData>
	{
		return ({
/*		
			breadcrumbs: {
				type: 'breadcrumbs',
				parent: {page:Venue.pageName,component:'breadcrumbs'},
				link:  () => 'backend/staffResources'
			},
*/			

			createStaffResource: <CreateComponent<StaffResourcesData>>{     //XXX or use 'editArray' for creation?
				type: 'create',
				collection: 'staffResource',
				initialValues: (data:StaffResourcesData) => {
//FIXME... maybe get maxPosition from the sort staffResources				
					return {position:1};
				},
//FIXME place in links.json?
				redirect: (data:StaffResourcesData) => `/admin/staffResource/${data.createStaffResource.id}`
			},

			editDocumentList: <NjkEditDocumentList> {
				type: 'editDocumentList',
				collection: 'staffResource',
				selector: '.resourcesList'
			}
		})
	}

	defaultSettings()
	{
		return {
			...super.defaultSettings(),
			htmlClasses: `${this.htmlClasses} staffResources`,
			template: 'App/backend/staffResources/staffResourcesPage.njk'
		};
	}
}

