import {crawlerMetaData} from 'Shared/frontend/HeadMacros'
import {EventData} from 'Shared/view/frontend/Event';
import {DateTime} from 'luxon';

/*
	Should there be an option to specify a special image for Facebook?  

{#TODO nickname ==> nickName + postcode ==> postCode + website ==> webSite #}
{# TODO url #}
{# TODO share some of this with other pages (including backend pages) #}

{# TODO? <meta property="og:description" content="How much does culture influence creative thinking?" /> #}
*/

export function HeadInsert(data:EventData & {title:string}) 
{
	let image;

	const details = data.event.poster?.formats?.medium;
	if (details!=undefined) {
		image = {
			url: data.event.poster!.url,
			format: details.format,
			width: details.width,
			height: details.height
		};
	}
	else
		//TODO send warnings and errors back to server
		log.warn('Missing poster');

	/* TODO look at recurring events */ 

	const extraGoogle:any = {		//XXX any
		'@type': 'Event',
		name: data.title
	};

	if (data.event.times?.frequency=='once') {
		extraGoogle['startDate'] = DateTime.fromISO(data.event.times!.startSingle).toFormat('yyyy-LL-dd\'T\'HH:mm:ss');

		if (data.event.times.endSingle)
			extraGoogle['endDate'] = DateTime.fromISO(data.event.times!.endSingle).toFormat('yyyy-LL-dd\'T\'HH:mm:ss');
	}

	if (data.event.eventType=='music') {
		if (data.event.lineup!=undefined && data.event.lineup.length > 0) {
			extraGoogle['performer'] = [];

			for (const artist of data.event.lineup) {
				const item:any = {				//XXX any
					'@type' : 'MusicGroup',
					name : artist.name,
				};
				if (artist.website)
					item['sameAs'] = artist.website;

				extraGoogle['performer'].push(item);
			}
		}
	}


	/* TODO could include price in "offers" */

	return crawlerMetaData((<any>data).url,data.venue,data.title,<any>image,{google:{extra:extraGoogle } }); //XXX anys
}

