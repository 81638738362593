import {ArtistProps} from 'Shared/artists/ProfilePage';
import {IconColour} from 'Shared/backend/fonts/icons/IconColour';
import {PinCircle} from 'Shared/backend/fonts/icons/PinCircle.svg';
import {SingleSelect, Text, Wrap} from 'Shared/forms/Inputs';
import {Show} from 'solid-js';

export function Location(artist:ArtistProps)
{
	return <>
		<h4>Location</h4>
		<div class='step'>
			<div class='stepIcon'>
				<PinCircle fill={artist.state || artist.city || artist.country ? IconColour.active : IconColour.inactive} />
			</div>

			<div class='location'>
				{/*
					TODO ben, CJ tried adding international:'International', as state option so Country isnt displayed if venue is adding artist
								and selects Austraian State. but vm wasnt storing international in database, please add
				*/}

				<Wrap label='City/Town'>
					<Text component={artist.editArtist} store={artist} field='city' />
				</Wrap>

				<Wrap label='State'>
					<SingleSelect component={artist.editArtist} store={artist} field='state' 
						options={{
							act: 'ACT',
							nsw: 'NSW',
							nt: 'NT',
							qld: 'QLD',
							sa: 'SA',
							tas: 'TAS',
							vic: 'VIC',
							wa: 'WA'
						}}
					/>
				</Wrap>


				{/* TODO ben, pl note: add or 'international' to if statement when international option added to State */}
				<Show when={!artist.state}>
					<Wrap label='Country'>
						<Text component={artist.editArtist} store={artist} field='country' placeholder='Australia' />
					</Wrap>
				</Show>
			</div>
		</div>
	</>;
}	

