import { Match,Switch } from 'solid-js';
import { DateTime } from 'luxon';

export function TrimmedTime(props:{time:string})
{
	return (
		<Switch>
			<Match when={date(props.time,'mm') == '00'}>
        		{date(props.time,'ha')}
			</Match>
			<Match when={true}>
        		{date(props.time,'h:mma')}
			</Match>
		</Switch>
	);
}


/* TODO CJ create this if needed to standardise date options 
{% macro dateTime(start,end) %}

    {{start|date('ccc d LLL')}}
    from
    {% if start|date('mm') == '00' %}
        {{start|date('ha')}}
    {% else %}
        {{start|date('h:mma')}} 
    {% endif %}

{% endmacro %}
*/

export function CleanTime(props:{time:string}) 
{
	return (
		<Switch>
			<Match when={date(props.time,'mm') == '00'}>
        		{date(props.time,'ha')}
			</Match>
			<Match when={true}>
        		{date(props.time,'h:mma')}
			</Match>
		</Switch>
	);
}

export function genreList(a)
{
	let sep = '',ret='';

	if (a.mainGenre) { ret += a.mainGenre; sep = ', '; }
	if (a.otherGenre1) { ret += `${sep}${a.otherGenre1}`; sep = ', '; }
	if (a.otherGenre2) { ret += `${sep}${a.otherGenre2}`; sep=', '; }
	if (a.extraGenre) ret += `${sep}${a.extraGenre}`;

	return ret;
}

export function date(value:string,format:string)
{
	return DateTime.fromISO(value).toFormat(format);
}	

