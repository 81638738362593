import {SiteType} from 'Common/SiteType';
import {Id} from 'Common/Id';
import {SiteBasics} from 'Common/SiteBasics';
import {VenueDoc} from 'Shared/model/venue';

export class VenueBasics extends SiteBasics
{
	constructor(
		id:Id,
		type:SiteType,
		key:string,
		readonly document: VenueDoc
	) 
	{
		super(id,type,key);
	}
}

