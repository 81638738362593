import {Id} from 'Common/Id';
import {AccessArea} from 'Common/permissions/AccessArea';

export abstract class Role
{
	constructor(
		readonly name:string,
	)
	{ }

	public abstract topAccess(): AccessArea;

	public abstract canAccess(area:AccessArea,id:Id):boolean;

	public abstract toJson(): any;
}

