import {Components, DocumentQueries} from 'Common/config/PageConfigTypes';
import {ProfileParams} from 'Common/pages/artist/Profile';
import {personDoc} from 'Shared/model/Person';
import * as t from 'Shared/model/basic';
import { z } from 'zod';
import {ArtistPageConfig, artistData, artistParams} from 'Common/pages/artist/ArtistPageConfig';
import {EditComponent} from 'Common/components/EditComponent';
import {UserPage} from 'Shared/users/UserPage';

export type Params = {[name:string]:any}; 


export const userParams = artistParams.extend({
	id: t.id
}).strict();	
export type UserParams = z.infer<typeof userParams>;


//TODO Try IUserData her instead
export const userData = artistData.extend({
	person: personDoc,
	page: z.any().optional(),				//FIXME any optional
	wrapper: z.any().optional(), 			//FIXME any optional  NOTE wrapper.page exists. Could do without page
}).strict();
export type UserData = z.infer<typeof userData>;


//XXX cf ==> 'Person'

export class User extends ArtistPageConfig<UserData,UserParams>
{
	static readonly pageName = 'user';
	name() { return User.pageName; }

	documents(params:ProfileParams): DocumentQueries
	{
		return ({
			person: {
				type:'object',
				collection: 'person'
			}
		});
//TODO pass in server functions into transforms too, and remove them as PageConfig dependencies... 
	}

	components()
	{
		return <Components<UserData>>({
			editPerson: {
				type: EditComponent.Id,
				collection: 'person',
				subdocument: []
			}
		});
	}

	settings()
	{
		return {
			...super.settings(),
			template: UserPage,
		};
	}


/*

    changeParams(context,next)
    {
		window.loadPasswordTestingLibrary();
		super.changeParams(context,next);
    }

    submit(e)
    {
//FIXME: replace with something like:
//     runOnComponent(event,'form','save','/user/'+this.form.id()).redirect(***)
// See FilesPage for some examples

        e.preventDefault();
        this.form.save().then(() => toPage('/user/'+this.form.id(),null));
    }

	delete(e)
	{
        e.preventDefault();
//FIXME return to caller.  Does page.js keep a record?
        this.form.delete().then(() => toPage('/users',null) );
	}

	disconnectProvider(provider)
	{
//TODO turn DISCONNECT_PROVIDER and CHANGE_PASSWORD into operations (want browser and server-side operations)
		db.sendOperation({operation:'DISCONNECT_PROVIDER',provider:provider})
			.then(data => {
				if ('error' in data)
					alert(data.error);
				else
				{						
					this.form.needsLoad = true;  //XXX may not be necessary
					this.form.loadedBaseTableId = -1;  //XXX poxy
					this.load().then( () => this.display() );
				}						
			});
	}
*/
}

