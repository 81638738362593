import { DateTime } from 'luxon';
import Assert from  'Common/Assert';

export class DateTimes
{
    toDb(dateTime:string):string { 
		const sql = DateTime.fromISO(dateTime).toSQL({includeOffset:false});
		const bits = Assert.have(sql.match(/^(.*?)(\.\d{3})?$/));
		return bits[1];
	}

	fromLuxon(lux:DateTime) { return lux.toISO({includeOffset:false,suppressMilliseconds:true}); }

	now() { return this.fromLuxon(DateTime.now())}

	luxonToSql(dateTime:DateTime) {
		const sql = dateTime.toSQL({includeOffset:false});
		const bits = Assert.have(sql.match(/^(.*?)(\.\d{3})?$/));
		return bits[1];
	}
}

export class Date
{
	fromLuxon(lux:DateTime) { return lux.toISODate(); }
}

export class Time
{
	fromLuxon(lux:DateTime):string { return lux.toISOTime({includeOffset:false,suppressMilliseconds:true}); }
}

