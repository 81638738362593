/* 
	Doesn't extend Error. Not interested in stack traces here. 
	"fieldName" is only required when displaying an error under a GUI field. 
*/
export class InvalidFieldValueError 
{
	constructor(
		readonly field:string|null,  //TODO delete in time
		readonly message:string
	)
	{
	}

	public toJson():any
	{
		return {field:this.field,message:this.message};
	}

	public toString()
	{
		return this.message;
	}
}

