import {Component} from 'Common/components/Component';

export class ExtraDataComponent extends Component
{
	constructor(
		instanceName:string,
		public data:{data:any} 
	)
	{
		super(instanceName);
		this.setData = this.setData.bind(this);
	}

	async load()
	{
		return {data:null};
	}

    public componentType():string { return 'extraData'; }

	public setData(newData:any)
	{
		this.data.data = newData;
	}
}
