import {Role} from 'Common/permissions/Role';
import {AccessArea} from 'Common/permissions/AccessArea';
import {RolesLoader} from 'Common/permissions/RolesLoader';
import { Role as ConfigRole } from 'Common/config/Role';
import {Id} from 'Common/Id';

export class Roles implements Iterable<Role>
{
	constructor(
		private roles:Role[]
	)
	{ }

	public *[Symbol.iterator] (): Iterator<Role>
	{
		yield *(this.roles)[Symbol.iterator]();
	}

	public canAccess(area:AccessArea,id:Id):boolean 
	{
		for (const role of this.roles)
			if (role.canAccess(area,id))
				return true;
		return false;
	}

	public venueAccess(siteId:Id)
	{
		let areas:string[] = []; 	//XXX could tighten up the type
		
		for (const role of this.roles) {
			const top = role.topAccess();

			if (role.canAccess(top,siteId)) 
				areas = [...areas,top.name, ...(top.subAreas().map(i => i.name))];
		}
		return areas;
	}

	public toJson()
	{
		const ret = [];
		for (const r of this.roles)
			ret.push(r.toJson());
		return ret;
	}

	public fromJson(json:ConfigRole[])
	{
		return (new RolesLoader()).createRoles(json);
	}
}

